const generateKey = importId => 'Customer-Import-' + importId;

export const storeImportDetails = (importId, details) => {
  window.localStorage.setItem(generateKey(importId), JSON.stringify(details));
};

export const loadImportDetails = importId => {
  const details = window.localStorage.getItem(generateKey(importId));
  return details ? JSON.parse(details) : null;
};
