import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';

import VideoPreview from 'Components/VideoPreview';

import { ReactComponent as VideoFileIcon } from 'Icons/video-file.svg';
import { ReactComponent as TextFileIcon } from 'Icons/text-file.svg';
//import { ReactComponent as StarIcon } from 'Icons/star.svg';
import { ReactComponent as BackArrowIcon } from 'Icons/back-arrow.svg';
import './MessageTemplateItem.css';
import { MESSAGE_SENDER } from '../../../roles';
import { VIDEO_CREATION_PATH } from 'Utils/whitelabeling';

class MessageTemplateItem extends Component {
  static propTypes = {
    ProfileStore: PropTypes.observableObject,
    MessageTemplateStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject,
  };

  state = {};

  get canSendMessages() {
    return this.props.ProfileStore.roles.indexOf(MESSAGE_SENDER) !== -1;
  }

  openBuilder = ({ id }) =>
    this.canSendMessages && VIDEO_CREATION_PATH === 'message'
      ? this.props.Routing.push(`/builder/from/template/${id}`)
      : this.props.Routing.push(`/video/record/${id}`);

  componentWillUpdate(nextProps) {
    const { activeTemplate } = nextProps.MessageTemplateStore;
    const newMessage = activeTemplate || { id: null };
    if (this.currentMessageid !== newMessage.id) {
      this.currentMessageId = newMessage.id;
      window.scrollTo(0, 0);
    }
  }

  getEstimatedLength() {
    const { activeTemplate: message } = this.props.MessageTemplateStore;
    let text = message.scriptText || message.messageText;

    const { estimatedVideoLength } = this.state;
    const totalSeconds =
      Math.floor(estimatedVideoLength) || Math.round(text.length / 4);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = (totalSeconds - minutes * 60).toFixed(0);

    return `${minutes}:${seconds.padStart(2, '0')}`;
  }

  toggleFavorite() {
    const { activeTemplate: message } = this.props.MessageTemplateStore;
    const { templateFavorites } = this.props.ProfileStore;

    if (templateFavorites[message.id] || false) {
      this.props.ProfileStore.removeTemplateFromFavorites(message.id);
    } else {
      this.props.ProfileStore.addTemplateToFavorites(message.id);
    }
  }

  render() {
    const { activeTemplate: message } = this.props.MessageTemplateStore;

    if (!message) {
      return null;
    }

    const isVideoMessage = !!message.scriptText;

    //const { templateFavorites } = this.props.ProfileStore;
    //const isFavorited = templateFavorites[message.id] || false;

    return (
      <div className="library__preview">
        <div className="library__preview-header">
          <button
            className="library__preview-header-back"
            onClick={() => this.props.MessageTemplateStore.setActive(null)}
          >
            <BackArrowIcon />
          </button>

          {
            isVideoMessage && false && (
              <div className="library__preview-header-duration">
                Estimated Length: {this.getEstimatedLength()}
              </div>
            ) /** hide estimated length for now */
          }
          <div className="library__preview-header-duration" />
          {/*<div
            onClick={() => this.toggleFavorite()}
            className="library__preview-header-favourite is-active"
            toggle="self"
          >
            <StarIcon />
            <span>
              {isFavorited ? 'Remove from favorites' : 'Add to favorites'}
            </span>
          </div>*/}
          <button
            onClick={() => this.openBuilder(message)}
            className="library__preview-header-button button button--style-default button--size-m button--color-blue-ribbon"
          >
            select
          </button>
        </div>

        <div className="library__preview-title">{message.title}</div>

        <div className="library__preview-subtitle">
          {isVideoMessage ? <VideoFileIcon /> : <TextFileIcon />}
          {isVideoMessage ? 'recording script' : 'text message'}
        </div>

        <div className="library__preview-text">
          {message.scriptText || message.messageText}
        </div>

        {isVideoMessage && message.exampleVideoUrl && (
          <div className="library__preview-video">
            <VideoPreview
              {...{
                videoRecordedBy: 'demo',
                key: message.exampleVideoUrl,
                videoUrl: message.exampleVideoUrl,
                posterUrl: message.exampleVideoThumbnailUrl,
                videoTitle: 'Watch demo video',
                videoOverlay: message.exampleVideoOverlay,
                showFrame: true,
                frameSource: message.videoFrameImageSource,
                onVideoPropsReceived: ({ duration }) =>
                  duration && this.setState({ estimatedVideoLength: duration }),
              }}
            />
          </div>
        )}
        {!isVideoMessage && message.imageUrl && (
          <div className="library__preview-giphy">
            <img src={message.imageUrl} alt="giphy" />
          </div>
        )}
      </div>
    );
  }
}

export default inject(
  'ProfileStore',
  'MessageTemplateStore',
  'Routing'
)(observer(MessageTemplateItem));
