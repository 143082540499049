/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';

import { ReactComponent as UserIcon } from 'Icons/user.svg';
import { ReactComponent as CloseIcon } from 'Icons/close.svg';
import AddCustomerForm from './AddCustomerForm';
import AddCustomerSuccess from './AddCustomerSuccess';
import ImportFromFileForm from './ImportFromFileForm';
import { Link } from 'react-router-dom';

class AddCustomer extends Component {
  goBack = () => window.history.back();

  getActiveForm() {
    return window.location.pathname === '/people/add/batch'
      ? ImportFromFileForm
      : AddCustomerForm;
  }

  render() {
    const { isAdded } = this.props.CustomerStore.customerFormObj;
    const Contents = this.getActiveForm();
    if (isAdded) {
      return <AddCustomerSuccess />;
    }
    return (
      <div className="add-people add-people--style-individual">
        <div className="cnt">
          <div className="add-people__wrapper">
            <div className="add-people__header">
              <h2 className="add-people__header-title">
                <UserIcon />
                <span>add contacts</span>
              </h2>
              <div className="add-people__header-intro">
                Engage with your contacts immediately by importing your data.
              </div>
              <Link to="/people" className="add-people__header-close">
                <CloseIcon />
              </Link>
            </div>

            <ul className="add-people__nav">
              <li className={Contents === AddCustomerForm ? 'is-active' : ''}>
                <Link to="/people/add">add individual</Link>
              </li>
              <li
                className={Contents === ImportFromFileForm ? 'is-active' : ''}
              >
                <Link to="/people/add/batch">import from file</Link>
              </li>
            </ul>
            <Contents />
          </div>
        </div>
      </div>
    );
  }
}

AddCustomer.propTypes = {
  CustomerStore: PropTypes.observableObject
};

export default inject('CustomerStore')(observer(AddCustomer));
