import LibraryRoutes from './Library/routes';
import CareRoutes from './Care/routes';
import ChatRoutes from './Chat/routes';
import CustomerRoutes from './Customer/routes';
import AccountRoutes from './Account/routes';
import AuthRoutes from './Auth/routes';
import MagicLinkRoutes from './MagicLinkRouter/routes';
import BuilderRoutes from './Builder/routes';
import CustomerLandingRoutes from './CustomerLanding/routes';
import VideoLandingRoutes from './VideoLanding/routes';
import VideoRecorderRoutes from './VideoStudio/routes';
import TestingRoutes from './Testing/routes';
import MobileVideoRecorderRoutes from './MobileVideoRecorder/routes';
import AnalyticRoutes from './MessageAnalytic/routes';
import VideoAnalyticRoutes from './VideoAnalytic/routes';

export default {
  default: '/library/categories',
  private: [
    LibraryRoutes,
    BuilderRoutes,
    CareRoutes,
    CustomerRoutes,
    ChatRoutes,
    AccountRoutes,
    TestingRoutes,
    MobileVideoRecorderRoutes,
    VideoRecorderRoutes,
    AnalyticRoutes,
    VideoAnalyticRoutes
  ],
  public: [
    AuthRoutes,
    MagicLinkRoutes,
    CustomerLandingRoutes,
    VideoLandingRoutes
  ]
};
