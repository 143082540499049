import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import ActionList from 'Components/ActionList';
import { formatDate } from 'Utils/date';
import { confirmYesNo } from 'Components/ConfirmModal';
import { ReactComponent as VideoFileIcon } from 'Icons/video-file.svg';
import { ReactComponent as TextFileIcon } from 'Icons/text-file.svg';
import { ReactComponent as PencilIcon } from 'Icons/pencil.svg';
import { ReactComponent as CopyIcon } from 'Icons/copy.svg';
import { ReactComponent as TrashIcon } from 'Icons/Buttons/trash.svg';
import { ReactComponent as EnrollIcon } from 'Icons/get-link.svg';
import SidebarLayout from '../SidebarLayout';
import './index.css';

class Sequences extends React.Component {
  componentDidMount() {
    const { MyLibraryStore, SequenceStore } = this.props;
    MyLibraryStore.setLibraryFolder('sequences');
    MyLibraryStore.changeOpened('sequences', true);
    SequenceStore.setSearchTerm('');
    SequenceStore.load();
  }

  handleDelete = async items => {
    if (
      !(await confirmYesNo(
        'Are you sure that you want to delete these sequences?'
      ))
    ) {
      return;
    }
    this.props.SequenceStore.deleteSequences(items);
  };

  handleCopy = async items => {
    if (
      !(await confirmYesNo(
        'Are you sure that you want to copy these sequences?'
      ))
    ) {
      return;
    }
    this.props.SequenceStore.copySequences(items);
  };

  render() {
    const { SequenceStore, Routing } = this.props;
    const {
      sequences,
      totalSequences,
      currentPage,
      searchTerm,
      isLoading
    } = SequenceStore;

    return (
      <SidebarLayout
        styleType="library"
        searchText="search sequence"
        searchTerm={searchTerm}
        onSearch={({ target }) => SequenceStore.setSearchTerm(target.value)}
      >
        <ActionList
          title="my sequences"
          isLoading={isLoading}
          items={sequences}
          itemsTotal={totalSequences}
          perPage={10}
          renderItem={item => {
            const { name, messages } = item;
            const firstMessage = (messages[0] || {}).message || {};
            return (
              <div className="table__video">
                <div className="video-link video-link--size-s">
                  <div
                    className="video-link__image"
                    style={
                      firstMessage.landingPageImage
                        ? {
                            backgroundImage: `url(${firstMessage.landingPageImage})`
                          }
                        : null
                    }
                  >
                    <i className="message-type-icon">
                      {firstMessage.type === 'text' ? (
                        <TextFileIcon />
                      ) : (
                        <VideoFileIcon />
                      )}
                    </i>
                  </div>
                </div>
                <h3 className="table__video-title">{name}</h3>
                <div
                  className="table__video-description"
                  style={{ WebkitBoxOrient: 'vertical' }}
                >
                  {messages
                    .map(
                      ({ message: { internalTitle } }, index) =>
                        `Campaign #${index + 1}: ${internalTitle}`
                    )
                    .join(', ')}
                </div>
              </div>
            );
          }}
          renderItemDate={item => formatDate(item.createdAt)}
          currentPage={currentPage}
          actions={[
            {
              onClick: ([sequence]) =>
                Routing.push(`/library/sequences/enroll/${sequence.id}`),
              component: (
                <i title="Enroll">
                  <EnrollIcon />
                </i>
              ),
              onlyPerItem: true
            },
            {
              onClick: ([sequence]) =>
                Routing.push(`/library/sequences/edit/${sequence.id}`),
              component: (
                <i title="Edit">
                  <PencilIcon />
                </i>
              ),
              onlyPerItem: true
            },
            {
              onClick: this.handleCopy,
              component: (
                <i title="Copy">
                  <CopyIcon />
                </i>
              )
            },
            {
              onClick: this.handleDelete,
              component: (
                <i title="Delete">
                  <TrashIcon />
                </i>
              )
            }
          ]}
        />
      </SidebarLayout>
    );
  }
}

Sequences.propTypes = {
  MyLibraryStore: PropTypes.observableObject,
  Routing: PropTypes.observableObject,
  SequenceStore: PropTypes.observableObject
};

export default inject(
  'MyLibraryStore',
  'SequenceStore',
  'Routing'
)(observer(Sequences));
