import React, { Component } from 'react';
import VideoEditorComponent from 'Components/VideoEditor';
import { Redirect } from 'react-router-dom';
import { inject, observer, PropTypes } from 'mobx-react';
import { getInitialVideo } from 'Stores/BuilderStore';
import { MerchantAssetsApi, UploadApi } from 'Api';
import mobilecheck from 'Utils/mobilecheck';

import { GraphicAssetsApi } from 'Api';

class VideoEditor extends Component {
  static propTypes = {
    BuilderStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject,
    ProfileStore: PropTypes.observableObject,
  };

  componentWillMount() {
    this.props.BuilderStore.setStep('videoEditor', true);
  }

  showClosingDialog = () => this.props.Routing.push('/builder/close-editor');

  saveOverlay = async (overlay, selectedFrame) =>
    this.props.BuilderStore.updateOverlay(overlay, selectedFrame);

  saveOverlayAndMoveForward = async (overlay, selectedFrame) => {
    await this.saveOverlay(overlay, selectedFrame);
    this.props.Routing.push(this.props.BuilderStore.nextRoute);
  };

  uploadAssets = async (assets) => {
    const url = await UploadApi.uploadImage(assets[0]);
    await MerchantAssetsApi.create(url);
  };

  deleteAsset = async (asset) => await MerchantAssetsApi.delete(asset.url);

  loadUploads = async (offset, pageSize) => {
    const page = Math.floor(offset / pageSize);
    const { data } = await MerchantAssetsApi.list(page, pageSize);
    const { profile_image } = this.props.ProfileStore.currentProfile;
    return (offset === 0 ? [{ title: '', url: profile_image }] : []).concat(
      data.map((record) => ({ title: '', url: record.url }))
    );
  };

  loadAssets = async (offset, pageSize, filters, searchKey) => {
    if (filters && filters.find((filter) => filter.filter === 'uploads')) {
      return this.loadUploads(offset, pageSize);
    }

    const resolvedFilters = (filters || [])
      .map(({ filter, value }) => {
        return [filter, value];
      })
      .reduce(
        (prev, [filter, value]) => {
          prev[filter] = value;
          return prev;
        },
        { type: 'overlay' }
      );

    const page = Math.floor(offset / pageSize);

    const { data } = await GraphicAssetsApi.find(
      { ...resolvedFilters, searchKey },
      page,
      pageSize
    );
    return data.map((record) => ({ title: '', url: record.url }));
  };

  render() {
    const { BuilderStore } = this.props;
    const scriptText = BuilderStore.getMessageAttribute('scriptText', '');
    const { url, overlay, frameId } = BuilderStore.video;
    const templateId = BuilderStore.getMessageAttribute('templateId', '');
    if (mobilecheck()) {
      return <Redirect to={{ pathname: '/builder/recording/settings' }} />;
    }
    return (
      <VideoEditorComponent
        onClose={this.showClosingDialog}
        videoSrc={[`${url}.mp4`, url]}
        scriptText={scriptText || ''}
        assetsSource={this.loadAssets}
        supportSearch={true}
        assetsFilters={(templateId
          ? [
              {
                title: 'suggested',
                filter: 'messageTemplateId',
                value: templateId,
                supportSearch: true,
              },
            ]
          : []
        ).concat([
          {
            title: 'treatment',
            filter: 'type',
            value: 'treatment',
            supportSearch: true,
          },
          {
            title: 'my uploads',
            filter: 'uploads',
            value: 1,
            uploadAssets: this.uploadAssets,
            deleteAsset: this.deleteAsset,
          },
        ])}
        overlay={overlay || getInitialVideo()}
        selectedFrame={frameId}
        onSave={this.saveOverlayAndMoveForward}
        onAutoSave={this.saveOverlay}
        enableWindowsChromeWorkaround={true}
      />
    );
  }
}

export default inject(
  'BuilderStore',
  'Routing',
  'ProfileStore'
)(observer(VideoEditor));
