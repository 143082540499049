import { observable, action, flow, decorate } from 'mobx';

import { PhoneApi } from 'Api';

class PhoneStore {
  isUpdatingPhones = false;
  validationErrors = [];
  errorMessage = undefined;
  availablePhones = [];

  values = {
    zip: ''
  };

  setZipCode(code) {
    this.values.zip = code;
  }

  getAvailablePhones = flow(function*() {
    try {
      this.isUpdatingPhones = true;
      this.availablePhones = yield PhoneApi.findAvailablePhones(
        this.values.zip
      ).then(response => response);
    } catch (e) {
      this.handleErrors(e);
    }

    this.isUpdatingPhones = false;
  });

  handleErrors(err) {
    const { validationErrors = [], message = undefined } = err.response.body;
    this.validationErrors = validationErrors;
    this.errorMessage = message;
  }
}

const MobxPhoneStore = decorate(PhoneStore, {
  validationErrors: observable,
  errorMessage: observable,
  values: observable,
  isUpdatingPhones: observable,
  availablePhones: observable,
  getAvailablePhones: action,
  setZipCode: action,
  callNumber: action
});

export default new MobxPhoneStore();
