import request from './request';
import { limit, toSearchQuery, merge } from './helpers';

export default {
  createFromTemplate: templateId =>
    request.post('/message/fromTemplate', { templateId }),
  create: message => request.post(`/message`, message),
  update: (id, message) => request.put(`/message/${id}`, message),
  getById: messageId => request.get(`/message/get/${messageId}`),
  get: (search = null, page = 0, limitBy = 10, options = {}) =>
    request.get(
      `/message?${merge(
        limit(limitBy, page),
        toSearchQuery({ ...options, search })
      )}`
    ),
  getRecent: (search = null, page = 0, limitBy = 10, options = {}) =>
    request.get(
      `/message/recent?${merge(
        limit(limitBy, page),
        toSearchQuery({ ...options, search })
      )}`
    ),
  sendNow: id => request.post(`/message/send-now/${id}`),
  delete: ids => request.delWithBody('/message/multiple', { ids }),
  testSMS: (id, phone) => request.post(`/message/${id}/test`, { phone }),
  copy: ids => request.post(`/message/copy`, { ids }),
  customize: (messageId, customerId) =>
    request.get(
      `/message/customize?${toSearchQuery({ messageId, customerId })}`
    ),
  trackView: (messageId, customerId, referrer) =>
    request.sendBeacon(`/message/${messageId}/view`, { customerId, referrer }),
  trackShare: (messageId, customerId, source) =>
    request.sendBeacon(`/message/${messageId}/share`, { customerId, source }),
  reportPlayedSeconds: (messageId, customerId, playedSeconds) =>
    request.sendBeacon(
      `/message/${messageId}/playback`,
      {
        customerId,
        playedSeconds
      },
      true
    )
};
