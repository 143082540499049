/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';

import Checkbox from 'Components/Checkbox';
import CustomerDetail from './CustomerDetail';
import { userToColor, getNameInitals } from 'Utils/string';
import { ReactComponent as ChatIcon } from 'Icons/text-file.svg';
import { Link } from 'react-router-dom';
import BatchTaggingToolbar from './BatchTaggingToolbar';
import LineLoader from 'Components/Loader/LineLoader';
import { TAB_INVALID_CONTACTS } from './const';
import { CUSTOMER_ATTRIBUTES } from 'Utils/whitelabeling';

class CustomerList extends Component {
  static propTypes = {
    CustomerStore: PropTypes.observableObject,
  };

  handleDeleteCustomer = () => {
    this.props.CustomerStore.setSelectedCustomer({});
  };

  handleCloseDetail = () => {
    const { customerTagsUpdated } = this.props.CustomerStore.tags;
    if (customerTagsUpdated) {
      this.props.CustomerStore.loadCustomers();
      this.props.CustomerStore.loadTags();
    }
    this.props.CustomerStore.setSelectedCustomer({});
  };

  get customers() {
    const {
      currentTab,
      customers,
      invalidCustomers: { allInvalidCustomers: invalidCustomers },
    } = this.props.CustomerStore;

    switch (currentTab) {
      case TAB_INVALID_CONTACTS:
        return invalidCustomers;
      default:
        return customers;
    }
  }

  get isLoading() {
    const {
      currentTab,
      isLoading,
      invalidCustomers: { isLoading: isLoadingInvalidCustomers },
    } = this.props.CustomerStore;

    switch (currentTab) {
      case TAB_INVALID_CONTACTS:
        return isLoadingInvalidCustomers;
      default:
        return isLoading;
    }
  }

  get isValidCustomersList() {
    return this.props.CustomerStore.currentTab !== TAB_INVALID_CONTACTS;
  }

  invalidCarrierLabel = (carrierType) => {
    switch (carrierType) {
      case 'voip':
        return 'Voip number';
      case 'landline':
        return 'Landline number';
      case 'invalid':
      default:
        return 'Invalid phone';
    }
  };

  render() {
    const {
      selectedCustomer,
      selectedCustomers,
      toggleCustomerSelection,
      toggleAllCustomersSelection,
      isBatchTaggingActive,
      batchOperationActive,
    } = this.props.CustomerStore;
    const { customers, isValidCustomersList } = this;

    return (
      <React.Fragment>
        {(batchOperationActive || this.isLoading) && <LineLoader />}
        {isBatchTaggingActive && !this.isLoading && <BatchTaggingToolbar />}
        {selectedCustomer.id && (
          <CustomerDetail
            onDeleteCustomer={this.handleDeleteCustomer}
            onCloseClick={this.handleCloseDetail}
          />
        )}
        {!this.isLoading && (
          <table className="table__table">
            <thead className="table__head">
              <tr>
                {CUSTOMER_ATTRIBUTES.name && <th>name</th>}
                {CUSTOMER_ATTRIBUTES.phone && <th>phone</th>}
                {CUSTOMER_ATTRIBUTES.birthday && <th>birthday</th>}
                {isValidCustomersList && CUSTOMER_ATTRIBUTES.phone && (
                  <th>{/* chat buttons*/}</th>
                )}
                {!isValidCustomersList && CUSTOMER_ATTRIBUTES.phone && (
                  <th>{/* invalid carrier message*/}</th>
                )}
                <th>
                  <Checkbox
                    onChange={toggleAllCustomersSelection}
                    checked={selectedCustomers.length === customers.length}
                  />
                </th>
              </tr>
            </thead>
            <tbody className="table__body">
              {customers.map((customer) => {
                const fullName = [customer.firstName, customer.lastName];
                return (
                  <tr
                    key={customer.id}
                    onClick={() => toggleCustomerSelection(customer.id)}
                  >
                    {CUSTOMER_ATTRIBUTES.name && (
                      <td>
                        <span>
                          <div
                            className="user-icon user-icon--size-m"
                            style={{
                              backgroundColor: userToColor(...fullName),
                            }}
                          >
                            {getNameInitals(...fullName)}
                          </div>
                          {fullName.join(' ')}
                        </span>
                      </td>
                    )}
                    {CUSTOMER_ATTRIBUTES.phone && <td>{customer.phone}</td>}
                    {CUSTOMER_ATTRIBUTES.birthday && (
                      <td>{customer.birthday}</td>
                    )}
                    {isValidCustomersList && CUSTOMER_ATTRIBUTES.phone && (
                      <td>
                        <div style={{ paddingTop: 5, fontSize: 20 }}>
                          <Link to={`/chat/${customer.id}`} title="Start chat">
                            <ChatIcon />
                          </Link>
                        </div>
                      </td>
                    )}
                    {!isValidCustomersList && CUSTOMER_ATTRIBUTES.phone && (
                      <td style={{ color: 'red' }}>
                        {this.invalidCarrierLabel(customer.carrierType)}
                      </td>
                    )}
                    <td>
                      <Checkbox
                        onChange={() => toggleCustomerSelection(customer.id)}
                        checked={selectedCustomers.indexOf(customer.id) !== -1}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </React.Fragment>
    );
  }
}

export default inject('CustomerStore')(observer(CustomerList));
