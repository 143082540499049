import React, { Component } from 'react';
import ReactPropTypes from 'prop-types';
import { inject, observer, PropTypes } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { ReactComponent as BackArrowIcon } from 'Icons/back-arrow.svg';

import MessageTemplateItem from './MessageTemplateItem';
import MessageTemplateList from './MessageTemplateList';
import { MESSAGE_SENDER } from '../../../roles';

class OneCategory extends Component {
  static propTypes = {
    CategoryStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject,
    MyLibraryStore: PropTypes.observableObject,
    MessageTemplateStore: PropTypes.observableObject,
    match: ReactPropTypes.any
  };

  componentWillMount() {
    const { CategoryStore, MyLibraryStore, MessageTemplateStore } = this.props;

    if (!CategoryStore.taxonomyLoaded) {
      this.moveToCategoryList();
      return;
    }

    MyLibraryStore.setLibraryFolder(null);

    MessageTemplateStore.loadTemplateByIds(
      CategoryStore.getCategoryItemsById(this.categoryId)
    );
  }

  get categoryId() {
    const { match } = this.props;
    return decodeURIComponent(match.params.categoryId);
  }

  moveToCategoryList = () => this.props.Routing.push('/library/categories');

  get canSendMessages() {
    return this.props.ProfileStore.roles.indexOf(MESSAGE_SENDER) !== -1;
  }

  get sidebarTitle() {
    return this.canSendMessages
      ? 'Select the script that you want to use in your video, or choose a plain text message script.'
      : 'Select the script that you want to use in your video.';
  }

  render() {
    const { CategoryStore } = this.props;

    const category = CategoryStore.getCategory(this.categoryId);

    if (!CategoryStore.taxonomyLoaded) {
      return null;
    }

    const isTemplateViewActive = !!this.props.MessageTemplateStore
      .activeTemplate;

    return (
      <div className="library library--style-category">
        <div className="cnt">
          <div
            className={`library__split ${
              isTemplateViewActive ? 'is-active' : ''
            }`}
          >
            <div className="library__split-left">
              <div className="library__messages">
                <div className="library__messages-header">
                  <button
                    onClick={this.moveToCategoryList}
                    className="library__messages-header-close"
                  >
                    <BackArrowIcon />
                  </button>
                  <div className="library__messages-header-title">
                    {category.title}
                  </div>
                  <div className="library__messages-header-details">
                    {category.items.length} topic
                    {category.items.length === 1 ? '' : 's'}
                  </div>
                </div>

                <div className="library__messages-intro">
                  {this.sidebarTitle}
                </div>

                <MessageTemplateList grouped={true} />
              </div>
            </div>
            <div className="library__split-right">
              <MessageTemplateItem />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject(
  'CategoryStore',
  'ProfileStore',
  'Routing',
  'MyLibraryStore',
  'MessageTemplateStore'
)(withRouter(observer(OneCategory)));
