import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';

import { ReactComponent as CloseIcon } from 'Icons/close.svg';
import { ReactComponent as VideoFileIcon } from 'Icons/video-file.svg';
import { ReactComponent as TextFileIcon } from 'Icons/text-file.svg';

import './SelectMessageType.scss';

class SelectMessageType extends Component {
  static propTypes = {
    Routing: PropTypes.observableObject,
  };

  goBackward = () => this.props.Routing.push('/');

  createTextMessage = () => this.props.Routing.push('/builder/from/empty/text');

  createVideoMessage = () =>
    this.props.Routing.push('/builder/from/empty/video');

  render() {
    return (
      <div className="builder builder--style-select-type">
        <div className="cnt">
          <button
            className="button button--reset button--link builder__close"
            onClick={this.goBackward}
          >
            <CloseIcon />
          </button>

          <div className="builder__title">choose custom care message</div>

          <div className="builder__content">
            <div
              className="builder__type builder__type--small"
              onClick={this.createTextMessage}
            >
              <div className="builder__type-icon">
                <TextFileIcon />
              </div>
              <div className="builder__type-title">Text Message</div>
              <div className="builder__type-description">
                Create your custom text message from scratch.
              </div>
            </div>

            <div
              className="builder__type builder__type--small"
              onClick={this.createVideoMessage}
            >
              <div className="builder__type-icon">
                <VideoFileIcon />
              </div>
              <div className="builder__type-title">Video Message</div>
              <div className="builder__type-description">
                Record on the fly or create a custom video script.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('Routing')(observer(SelectMessageType));
