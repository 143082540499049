import React, { Component } from 'react';
import { PropTypes, inject, observer } from 'mobx-react';
import ReactPropTypes from 'prop-types';
import RespondentsList from 'Components/Respondents';

class Respondents extends Component {
  static propTypes = {
    SmsStore: PropTypes.observableObject,
    children: ReactPropTypes.any
  };

  componentWillMount() {
    this.props.SmsStore.loadRespondents();
  }

  render() {
    const {
      respondents,
      respondentsCount,
      isLoadingRespondents,
      selectedRespondent
    } = this.props.SmsStore;
    return (
      <RespondentsList
        respondents={respondents}
        selectedRespondentId={
          selectedRespondent ? selectedRespondent.customerId : null
        }
        respondentsCount={respondentsCount || 0}
        isLoading={isLoadingRespondents}
        loadMore={() => this.props.SmsStore.loadRespondents()}
      >
        {this.props.children}
      </RespondentsList>
    );
  }
}
export default inject('SmsStore')(observer(Respondents));
