const pending = {
  previous: 'pending',
  next: 'videoRecording',
  route: 'recording/pending'
};

const videoRecording = {
  previous: 'pending',
  next: 'videoEditor',
  route: 'recording/record'
};

const videoEditor = {
  previous: 'videoRecording',
  next: 'videoSettings',
  route: 'recording/editor'
};

const videoSettings = {
  previous: 'videoEditor',
  next: 'landing',
  route: 'recording/settings'
};

const landing = {
  previous: 'videoSettings',
  next: 'message',
  route: 'landing'
};

const message = {
  previous: 'landing',
  next: 'schedule',
  route: 'message'
};

const schedule = {
  previous: 'message',
  next: 'done',
  route: 'schedule'
};

const done = {
  previous: 'schedule',
  next: 'done',
  route: 'schedule'
};

export const videoMessageSteps = {
  pending,
  videoRecording,
  videoEditor,
  videoSettings,
  landing,
  message,
  schedule,
  done
};

export const textMessageSteps = {
  pending: { ...pending, next: 'message' },
  message: { ...message, previous: 'pending' },
  schedule,
  done
};

export default {
  text: textMessageSteps,
  video: videoMessageSteps
};
