// @flow

import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { Redirect, withRouter } from 'react-router-dom';

import Modal from 'Components/Modal';
import AuthLayout from 'Pages/Auth/AuthLayout';
import LoginError from './LoginError';
import LoginForm from './LoginForm';

class Login extends React.Component {
  static propTypes = {
    AuthStore: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  componentWillMount() {
    const { type: typeId } = this.props.match.params;
    if (typeId) {
      const { AuthStore, Routing } = this.props;
      AuthStore.setSelectedMerchantType(typeId);
      Routing.push('/login');
    }
  }

  tryLoginAgain = () => {
    this.props.AuthStore.tryLoginAgain();
  };

  render() {
    const { from } = this.props.Routing.location.state || {
      from: { pathname: '/' }
    };
    if (this.props.CommonStore.token) {
      return <Redirect to={from} />;
    }
    const { loginHasError } = this.props.AuthStore;
    if (loginHasError) {
      return (
        <React.Fragment>
          <Modal>
            <LoginError onTryAgain={this.tryLoginAgain} />
          </Modal>
        </React.Fragment>
      );
    }

    return <LoginForm />;
  }
}

const ObserverLogin = inject(
  'AuthStore',
  'CommonStore',
  'Routing'
)(withRouter(observer(Login)));

export default () => (
  <AuthLayout page="sign-in">
    <ObserverLogin />
  </AuthLayout>
);
