import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { textToColor } from 'Utils/string';
import { ReactComponent as BackArrowIcon } from 'Icons/back-arrow.svg';

class ChatHeader extends Component {
  render() {
    const { firstName, lastName, linkedToNumber } = this.props.respondent;
    const fullName = [firstName, lastName].filter(val => !!val);
    return (
      <div className="chat__preview-title">
        <div className="chat__preview-title-row">
          <div
            className="user-icon user-icon--size-m"
            style={{ backgroundColor: `${textToColor(fullName.join())}` }}
          >
            {fullName.map(word => word[0])}
          </div>
        </div>

        <div className="chat__preview-title-row chat__preview-title-row--expanded">
          <h2 className="chat__preview-title-sender">
            {fullName.join(' ')}
            {linkedToNumber ? (
              <span className="chat__preview-title-sender-number">
                (assigned number {linkedToNumber})
              </span>
            ) : (
              ''
            )}
          </h2>
        </div>

        <Link
          to="/chat"
          className="chat__preview-title-row chat__preview-title-back"
        >
          <BackArrowIcon />
        </Link>
      </div>
    );
  }
}

ChatHeader.propTypes = {
  respondent: PropTypes.object.isRequired
};

export default ChatHeader;
