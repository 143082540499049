import request from './request';
import { merge, toSearchQuery, limit } from './helpers';

export default {
  getMessageTemplateTaxonomy: () => request.get('/message-template-taxonomy'),
  get: (search = null, page = 0, limitBy = 10) =>
    request.get(
      `/message-template?${merge(
        limit(limitBy, page),
        toSearchQuery({ search })
      )}`
    ),
  getMessageTemplates: ids =>
    request.post('/message-template/get-multiple', { ids })
};
