import { observable, action, flow, decorate } from 'mobx';
import { apiError } from 'Utils/alert';
import { MessageTemplateApi } from 'Api';

class CategoryStore {
  isLoading = false;
  taxonomyLoaded = false;
  categories = [];

  loadMessageTemplateTaxonomy = flow(function*() {
    try {
      this.isLoading = true;
      const newCategories = [];
      const { tree } = yield MessageTemplateApi.getMessageTemplateTaxonomy();

      for (const key of Object.keys(tree.categories)) {
        newCategories.push({
          ...tree.categories[key],
          items: this.getCategoryItems(tree.categories[key])
        });
      }

      this.categories = newCategories;
      this.taxonomyLoaded = true;
    } catch (err) {
      apiError(err);
    }

    this.isLoading = false;
  });

  getCategory(categoryId) {
    return this.categories.find(({ title }) => title === categoryId);
  }

  getCategoryItems(category) {
    const subcategories = Object.values(category.categories);

    const categoryItems = category.items.concat(
      subcategories.reduce(
        (acc, item) => acc.concat(this.getCategoryItems(item)),
        []
      )
    );

    return categoryItems.filter(
      (item, pos) => categoryItems.indexOf(item) === pos
    );
  }

  getCategoryItemsById(categoryId) {
    return this.getCategoryItems(this.getCategory(categoryId));
  }
}

const MobxCategoryStore = decorate(CategoryStore, {
  isLoading: observable,
  taxonomyLoaded: observable,
  categories: observable,
  loadMessageTemplateList: action,
  selectCategory: action
});

export default new MobxCategoryStore();
