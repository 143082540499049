/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { VideosApi } from 'Api';
import { debounce } from 'Utils/decorators';
import EmbedVideoModal from 'Components/EmbedVideoModal';
import Toolbar from 'Components/ActionList/Toolbar';
import Pagination from 'Components/Pagination';
import { ReactComponent as GetLinkIcon } from 'Icons/get-link.svg';
import { ReactComponent as AddIcon } from 'Icons/VideoIcons/add.svg';
import SidebarLayout from '../SidebarLayout';
import Loader from '../MyLibrary/Loader';
import { formatDate } from '../../../Utils/date';

const PAGE_SIZE = 10;

class Sequences extends React.Component {
  state = {
    searchTerm: '',
    videos: [],
    totalVideosCount: 0,
    page: 1,
    isLoading: false
  };
  componentDidMount() {
    const { MyLibraryStore } = this.props;
    MyLibraryStore.setLibraryFolder(null);
    MyLibraryStore.changeOpened('curated-videos', true);
    this.loadVideos();
  }
  componentWillUnmount() {
    const { MyLibraryStore } = this.props;
    MyLibraryStore.changeOpened('curated-videos', false);
  }
  handlePageChanged = page => {
    if (this.state.isLoading) {
      return;
    }
    this.setState({ page });
    this.loadVideos();
  };

  useVideoForNewMessage = videos => {
    this.props.Routing.push(`/builder/from/video/${videos[0].id}`);
  };
  embedVideo = videos => this.setState({ videoToEmbed: videos[0] });
  closeEmbedVideoModal = () => this.setState({ videoToEmbed: null });
  handleSearch = ({ target }) => {
    if (this.state.isLoading) {
      return;
    }
    this.setState({ searchTerm: target.value });
    this.loadVideos();
  };

  loadVideos = debounce(async () => {
    if (this.state.isLoading) {
      return;
    }

    this.setState({ isLoading: true });
    try {
      const { page, searchTerm } = this.state;
      const {
        data: videos,
        count: totalVideosCount
      } = await VideosApi.getCurated(searchTerm, page - 1, PAGE_SIZE);
      this.setState({ isLoading: false, videos, totalVideosCount });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  }, 500);

  get actions() {
    return [
      {
        onClick: this.useVideoForNewMessage,
        component: (
          <a title="Use in new message">
            <AddIcon />
          </a>
        )
      },
      {
        onClick: this.embedVideo,
        component: (
          <a title="Share">
            <GetLinkIcon />
          </a>
        )
      }
    ];
  }

  renderActions(passItems) {
    return this.actions.map(({ onClick, component }, key) => (
      <li key={key} onClick={() => onClick(passItems)}>
        <button>{component}</button>
      </li>
    ));
  }

  renderEmbedVideoModal = () => {
    const { videoToEmbed } = this.state || {};
    if (!videoToEmbed) {
      return null;
    }

    return (
      <EmbedVideoModal
        videoId={videoToEmbed.id}
        videoRecordedBy={videoToEmbed.recordedBy}
        title={videoToEmbed.title}
        embeddableUrl={videoToEmbed.embeddableUrl}
        landingPageUrl={videoToEmbed.landingPageUrl}
        src={[`${videoToEmbed.url}.mp4`, videoToEmbed.url]}
        poster={
          videoToEmbed.thumbnailUrl
            ? `${videoToEmbed.thumbnailUrl}-decorated`
            : null
        }
        overlay={videoToEmbed.overlay} //should get the logo image from video itself
        frameImageSource={videoToEmbed.frameImageSource}
        onClose={this.closeEmbedVideoModal}
        disableDownload={true}
      />
    );
  };

  render() {
    const {
      searchTerm,
      videos,
      totalVideosCount,
      page,
      isLoading
    } = this.state;
    return (
      <React.Fragment>
        {this.renderEmbedVideoModal()}
        <SidebarLayout
          styleType="library"
          searchText="search video"
          searchTerm={searchTerm}
          onSearch={this.handleSearch}
        >
          <Toolbar title="Premium Partner Videos" />

          <div className="library__videos">
            {isLoading && <Loader />}
            {!isLoading && videos.length === 0 && (
              <span>No videos in the curated list.</span>
            )}
            {!isLoading &&
              videos.map((item, key) => (
                <div key={key} className="library__video">
                  <div className="library__video-container">
                    <div className="video-link video-link--size-m">
                      <div
                        className="video-link__image"
                        style={{
                          backgroundImage: `url('${item.thumbnailUrl}-decorated')`
                        }}
                      />
                      <div className="video-link__title">
                        <span className="video-link__name">{item.title}</span>
                        <span className="video-link__duration">
                          {formatDate(item.recordedAt)}
                        </span>

                        <div className="video-link__actions">
                          <ul className="actions actions--style-delimeter">
                            {this.renderActions([item])}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {totalVideosCount > PAGE_SIZE && (
            <Pagination
              limit={PAGE_SIZE}
              total={totalVideosCount}
              page={page}
              onPageClick={this.handlePageChanged}
            />
          )}
        </SidebarLayout>
      </React.Fragment>
    );
  }
}

Sequences.propTypes = {
  MyLibraryStore: PropTypes.observableObject,
  Routing: PropTypes.observableObject
};

export default inject('MyLibraryStore', 'Routing')(observer(Sequences));
