import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/lib/Creatable';

import './Tags.css';
export default class index extends Component {
  static propTypes = {
    tags: PropTypes.array,
    onChange: PropTypes.func,
    suggestions: PropTypes.array,
    disabled: PropTypes.bool
  };

  customStyles = {
    control: styles => ({
      ...styles,
      width: '100%',
      border: 'none',
      background: 'transparent'
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      color: '#4786FB'
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        color: '#4786FB',
        backgroundColor: 'rgba(155, 189, 252, 0.3)',
        borderRadius: '15px',
        fontSize: '18px',
        lineHeight: '30px',
        padding: '0 15px'
      };
    }
  };

  render() {
    const { tags, onChange, suggestions, disabled } = this.props;
    return (
      <div className="tags-wrapper">
        <CreatableSelect
          isDisabled={disabled}
          styles={this.customStyles}
          isMulti
          isClearable={false}
          defaultValue={tags.map(tag => ({
            label: tag,
            value: tag
          }))}
          onChange={onChange}
          options={suggestions.map(element => ({
            label: element.tag,
            value: element.tag
          }))}
        />
      </div>
    );
  }
}
