import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SingleMsg from './SingleMsg';
import Waypoint from 'react-waypoint';
import LineLoader from 'Components/Loader/LineLoader';

class MessagesList extends Component {
  static propTypes = {
    messages: PropTypes.array,
    messagesCount: PropTypes.number.isRequired,
    loadMore: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    scrolledDown: PropTypes.bool
  };

  componentWillUpdate() {
    this.lastScrollHeight = this.scrollContainer.scrollHeight;
  }

  componentDidUpdate() {
    if (this.props.scrolledDown) {
      this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight;
      return;
    }
    this.scrollContainer.scrollTop =
      this.scrollContainer.scrollHeight - this.lastScrollHeight;
  }

  render() {
    const { messages, messagesCount, isLoading, loadMore } = this.props;
    return (
      <div className="chat__preview-main">
        <div
          ref={node => (this.scrollContainer = node)}
          className="scroll scroll--vertical scroll--absolute"
        >
          <div className="chat__conversation">
            {messages.length < messagesCount && !isLoading && (
              <Waypoint onEnter={loadMore} topOffset="-50%" />
            )}
            {isLoading && <LineLoader />}
            {messages
              .slice()
              .reverse()
              .map(message => (
                <SingleMsg
                  key={message.id}
                  message={message.message}
                  direction={message.direction}
                  createdAt={message.createdAt}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default MessagesList;
