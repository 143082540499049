import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer, PropTypes } from 'mobx-react';
import Chat from './Chat';
import Respondents from './Respondents';
import ReactPropTypes from 'prop-types';

class ChatApp extends React.Component {
  componentWillMount() {
    const { respondentId = null } = this.props.match.params || {};
    this.props.SmsStore.reset();
    this.setSelectedRespondent(respondentId);
  }

  componentWillReceiveProps(nextProps) {
    const { respondentId } = nextProps.match.params || {};
    this.setSelectedRespondent(respondentId);
  }
  setSelectedRespondent(id) {
    this.props.SmsStore.setSelectedRespondent(id);
  }
  render() {
    const { selectedRespondent } = this.props.SmsStore;
    return (
      <div className={`chat ${selectedRespondent ? 'is-active' : ''}`}>
        <Respondents>{selectedRespondent && <Chat />}</Respondents>
      </div>
    );
  }
}

ChatApp.propTypes = {
  SmsStore: PropTypes.observableObject,
  match: ReactPropTypes.object
};

export default inject('SmsStore')(withRouter(observer(ChatApp)));
