import React from 'react';
import {
  SnippetsBar,
  PersonalizedText
} from 'Components/PersonalizedTextEditor';
import { wrap } from 'Components/layout';

const SNIPPETS = [
  { snippet: '{{name}}', title: 'Full name' },
  { snippet: '{{firstName}}', title: 'First name' },
  { snippet: '{{lastName}}', title: 'Last name' },
  { snippet: '{{phone}}', title: 'Phone' },
  { snippet: '{{birthday}}', title: 'Birthday' },
  { snippet: '{{visitsCount}}', title: 'Visits count' },
  { snippet: '{{lastVisitedAt}}', title: 'Last visit date' },
  { snippet: '{{nextVisitDate}}', title: 'Next visit date' }
];

const TEXT =
  'Lorem ipsum dolor sit amet, {{firstName}} consectetur adipiscing elit, \n sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

class Editor extends React.Component {
  state = { text: TEXT };
  onChange = newText => {
    this.setState({ text: newText });
  };
  render() {
    const { text } = this.state;
    return (
      <div className="cnt">
        <SnippetsBar snippets={SNIPPETS} />
        <PersonalizedText
          text={text}
          snippets={SNIPPETS}
          onChange={this.onChange}
          placeholder="customized text"
        />
        <br />
        <br />
        <textarea
          value={text}
          disabled
          style={{ width: '100%', height: 400 }}
        />
      </div>
    );
  }
}

export default wrap(Editor);
