import React from 'react';
import PropTypes from 'prop-types';
import { formatDateTime } from 'Utils/date';
import { Link } from 'react-router-dom';
import { textToColor } from 'Utils/string';

class Respondent extends React.Component {
  render() {
    const { respondent, isActive, refCallback } = this.props;
    const {
      customerId,
      firstName,
      lastName,
      lastMessageDate,
      lastMessage,
      notReadMessagesCount
    } = respondent;
    const fullName = [firstName, lastName].filter(val => !!val);

    return (
      <Link
        innerRef={refCallback}
        to={`/chat/${customerId}`}
        className={`chat__list-item ${isActive ? 'is-active' : ''}`}
      >
        <div
          className="user-icon user-icon--size-l "
          style={{ backgroundColor: `${textToColor(fullName.join())}` }}
        >
          {fullName.map(word => word[0])}
        </div>

        <div className="chat__list-item-row">
          <div className="chat__list-item-col">
            <h3 className="chat__list-item-sender">{fullName.join(' ')}</h3>

            <div className="chat__list-item-date">
              {lastMessageDate && formatDateTime(lastMessageDate)}
            </div>
          </div>

          <div className="chat__list-item-col">
            <div className="chat__list-item-intro">{lastMessage}</div>

            {notReadMessagesCount > 0 && (
              <div className="chat__list-item-unread">
                {notReadMessagesCount}
              </div>
            )}
          </div>
        </div>
      </Link>
    );
  }
}

Respondent.propTypes = {
  respondent: PropTypes.object,
  isActive: PropTypes.bool,
  refCallback: PropTypes.func
};

export default Respondent;
