import React from 'react';
import PropTypes from 'prop-types';
import { warning } from 'Utils/alert';
import { Elements, injectStripe, CardElement } from 'react-stripe-elements';
import Button from 'Components/Button';
import Modal from 'Components/Modal';
import { SUPPORT_EMAIL } from 'Utils/whitelabeling';
import './BillingPlan.css';

const BillingPlan = ({ plan, isSelected, onClick }) => {
  const { title, price, period } = plan;
  return (
    <div className={`price ${isSelected ? 'is-active' : ''}`}>
      <h3 className="price__name">{title}</h3>
      <div className="price__price">
        ${price} <span>/ {period}</span>
      </div>
      <div className="price__separator" />
      <div className="price__cta">
        <button
          onClick={onClick}
          className="button button--style-default button--size-m button--color-blue-ribbon"
        >
          get started
        </button>
      </div>
    </div>
  );
};

BillingPlan.propTypes = {
  plan: PropTypes.object,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

class NewSubscriptionView extends React.Component {
  state = {
    showWhyCCModal: false,
    showSuccessModal: false,
  };
  toggleWhyCCModal = () =>
    this.setState({ showWhyCCModal: !this.state.showWhyCCModal });
  closeSuccessModal = () => this.setState({ showSuccessModal: false });
  subscribe = async (e) => {
    e.preventDefault();
    const { selectedPlanId } = this.state;
    if (!selectedPlanId) {
      warning('Please select billing plan');
      return;
    }
    const { hasBillingMethods, stripe, subscribe, billingPlans } = this.props;
    const selectedPlan = billingPlans.find((plan) => plan.id);

    try {
      if (selectedPlan.price > 0) {
        this.setState({ showSuccessModal: true });
      }

      if (hasBillingMethods || selectedPlan.price === 0) {
        await subscribe(selectedPlanId);
        return;
      }
      const { token } = await stripe.createToken();
      if (token) {
        await subscribe(selectedPlanId, token.id);
      }
    } catch (e) {
      // do nothing;
    }
  };
  render() {
    const { hasBillingMethods, billingPlans, processingSubscribe } = this.props;
    const { selectedPlanId, showWhyCCModal, showSuccessModal } = this.state;
    const selectedPlan = selectedPlanId
      ? billingPlans.find((plan) => plan.id === selectedPlanId)
      : null;

    return (
      <React.Fragment>
        <Modal
          visible={showWhyCCModal}
          className="modal--type-why-mobile-number"
        >
          <h3 className="modal__title">Why do we need your credit card?</h3>
          <div className="modal__text">
            <p>
              Thank you for signing up. Your card will be charged the
              subscription fee, plus an initial $25.00 to cover the hard costs
              for your initial text messaging fee’s. If your balance falls below
              $10.00, we will reload your account automatically to $25.00, which
              ensures your service is not interrupted.
            </p>
            <p>
              More questions? <a href={`mailto:${SUPPORT_EMAIL}`}>Ask us</a>
            </p>
          </div>
          <Button name="Ok, I understand" onClick={this.toggleWhyCCModal} />
        </Modal>
        <Modal
          visible={showSuccessModal}
          className="modal--type-why-mobile-number"
        >
          <div className="modal__text">
            <p>
              Thank you for signing up. Your card will be charged the monthly
              subscription fee, plus an initial $25.00 to cover the hard costs
              for your initial text messaging fee's. If your balance falls below
              $10.00, we will reload your account automatically to $25.00, which
              ensures your service is not interrupted.
            </p>
          </div>
          <Button name="Ok, I understand" onClick={this.closeSuccessModal} />
        </Modal>
        <div className="builder__title">Select plan</div>
        <div className="settings__section billing-plans">
          {billingPlans
            .filter((plan) => plan.isActive)
            .map((plan) => (
              <BillingPlan
                key={plan.id}
                plan={plan}
                isSelected={selectedPlanId === plan.id}
                onClick={() => this.setState({ selectedPlanId: plan.id })}
              />
            ))}
        </div>
        <form
          onSubmit={this.subscribe}
          style={{ textAlign: 'center', marginTop: 50 }}
        >
          {!hasBillingMethods && selectedPlanId && selectedPlan.price > 0 ? (
            <React.Fragment>
              <CardElement />
              <div className="form__caption">
                <button
                  className="button button--link is-active"
                  type="button"
                  onClick={this.toggleWhyCCModal}
                >
                  Why do we need your credit card?
                </button>
              </div>
            </React.Fragment>
          ) : null}
          <div style={{ marginTop: 20 }}>
            <Button
              name="subscribe"
              type="submit"
              disabled={processingSubscribe}
            />
          </div>
        </form>
      </React.Fragment>
    );
  }
}

NewSubscriptionView.propTypes = {
  hasBillingMethods: PropTypes.bool,
  billingPlans: PropTypes.array.isRequired,
  stripe: PropTypes.object.isRequired,
  subscribe: PropTypes.func.isRequired,
  processingSubscribe: PropTypes.bool,
};

const StripeInjectedView = injectStripe(NewSubscriptionView);

export default (props) => (
  <Elements>
    <StripeInjectedView {...props} />
  </Elements>
);
