import React, { Component } from 'react';
import { ReactComponent as UserIcon } from 'Icons/user.svg';
import { ReactComponent as UserFileIcon } from 'Icons/user-file.svg';
import Link from 'react-router-dom/Link';

export default class CustomerEmpty extends Component {
  render() {
    return (
      <div className="empty empty--style-people">
        <div className="cnt">
          <h2 className="empty__title">add your contacts</h2>

          <div className="empty__intro">
            Engage with your contacts immediately by importing your data.
          </div>

          <div className="empty__list">
            <div className="empty__item">
              <Link to="people/add/batch">
                <div className="empty__item-icon">
                  <UserFileIcon />
                </div>
                <h3 className="empty__item-title">Import from file</h3>
                <div className="empty__item-text">
                  Upload your entire client roster.
                </div>
              </Link>
            </div>
            <div className="empty__item">
              <Link to="people/add">
                <div className="empty__item-icon">
                  <UserIcon />
                </div>
                <h3 className="empty__item-title">Add individual</h3>
                <div className="empty__item-text">
                  Upload one contact (i.e. new client).
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
