import { wrap } from 'Components/layout';
import ChatApp from '.';
import { MESSAGE_SENDER } from '../../roles';

const WrappedChatApp = wrap(ChatApp);

export default {
  base: '/chat',
  merge: {
    activePage: 'chat',
    roles: [MESSAGE_SENDER]
  },
  items: [{ path: '/:respondentId?', component: WrappedChatApp }]
};
