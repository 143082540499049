export default {
  control: provided => {
    return {
      ...provided,
      outline: 'none',
      border: 'none',
      padding: 0,
      margin: 0,
      borderRadius: 0,
      background: 'transparent',
      borderBottom: 'solid 1px gray',
      minHeight: 'auto'
    };
  },
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: () => ({
    display: 'none'
  }),
  menu: function(style) {
    return { ...style, width: 'auto', minWidth: '100%' };
  },
  menuList: function(style) {
    return { ...style, width: 'auto' };
  }
};
