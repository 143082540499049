const IOS_APP_LINK = process.env.REACT_APP_IOS_APP_LINK;
const ANDROID_APP_LINK = process.env.REACT_APP_ANDROID_APP_LINK;

export default {
  'record-video-mobile': (params, routing) => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    const link = isIOS ? IOS_APP_LINK : ANDROID_APP_LINK;

    if (!isIOS) {
      routing.push(`/record-video-mobile/${params.messageId}`);
    }
    if (link) {
      window.location.href = link;
    }
  },
  'video/record-mobile': (params, routing) => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    const link = isIOS ? IOS_APP_LINK : ANDROID_APP_LINK;

    if (!isIOS) {
      routing.push(
        ['video/record-mobile', params.templateId]
          .filter(val => !!val)
          .join('/')
      );
    }
    if (link) {
      window.location.href = link;
    }
  }
};
