import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = props => (
  <ContentLoader
    height={100}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#0B59F0"
    {...props}
  >
    <circle cx="10" cy="20" r="8" />
    <rect x="25" y="15" rx="5" ry="5" width="150" height="10" />
    <circle cx="10" cy="50" r="8" />
    <rect x="25" y="45" rx="5" ry="5" width="150" height="10" />
    <circle cx="10" cy="80" r="8" />
    <rect x="25" y="75" rx="5" ry="5" width="150" height="10" />
    <rect x="291.69" y="40.67" rx="0" ry="0" width="1" height="1" />
    <rect x="308.69" y="31.67" rx="0" ry="0" width="0" height="0" />
    <rect x="278.13" y="16" rx="0" ry="0" width="0" height="0" />
    <rect x="180" y="15" rx="5" ry="5" width="50" height="10" />
    <rect x="180" y="45" rx="5" ry="5" width="50" height="10" />
    <rect x="180" y="75" rx="5" ry="5" width="50" height="10" />
    <rect x="235" y="15" rx="5" ry="5" width="50" height="10" />
    <rect x="235" y="45" rx="5" ry="5" width="50" height="10" />
    <rect x="235" y="75" rx="5" ry="5" width="50" height="10" />
    <rect x="290" y="15" rx="5" ry="5" width="50" height="10" />
    <rect x="290" y="45" rx="5" ry="5" width="50" height="10" />
    <rect x="290" y="75" rx="5" ry="5" width="50" height="10" />
    <rect x="345" y="15" rx="5" ry="5" width="25" height="10" />
    <rect x="345" y="45" rx="5" ry="5" width="25" height="10" />
    <rect x="345" y="75" rx="5" ry="5" width="25" height="10" />
  </ContentLoader>
);

export default Loader;
