import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { Link } from 'react-router-dom';

import { ReactComponent as FolderIcon } from 'Icons/folder.svg';
import { ReactComponent as FolderOpenOutline } from 'Icons/folder-open-outline.svg';
import { ReactComponent as FolderOutlineIcon } from 'Icons/folder-outline.svg';
import { ReactComponent as AddIcon } from 'Icons/VideoIcons/add.svg';
import { MESSAGE_SENDER } from '../../../roles';

const SEQUENCES = 'sequences';

class MySequencesFolder extends Component {
  static propTypes = {
    MyLibraryStore: PropTypes.observableObject
  };

  canSendMessages = () =>
    this.props.ProfileStore.roles.indexOf(MESSAGE_SENDER) !== -1;

  render() {
    if (!this.canSendMessages()) {
      return null;
    }
    const { MyLibraryStore } = this.props;
    const { currentFolder, openedSections } = MyLibraryStore;
    const isOpened = openedSections[SEQUENCES];
    return (
      <React.Fragment>
        <a
          href="#my-sequences"
          onClick={e => {
            e.preventDefault();
            MyLibraryStore.changeOpened(SEQUENCES, !isOpened);
          }}
          className={`library__sidebar-buttons-button ${
            currentFolder !== null ? 'is-active' : ''
          }`}
        >
          <FolderIcon /> <span>my sequences</span>
        </a>
        {isOpened && (
          <div className="library__sidebar-folders">
            {
              <React.Fragment>
                <Link
                  to="/library/sequences"
                  className={`library__sidebar-folders-folder ${
                    currentFolder === SEQUENCES ? 'is-active' : ''
                  }`}
                >
                  {currentFolder === SEQUENCES ? (
                    <FolderOpenOutline />
                  ) : (
                    <FolderOutlineIcon />
                  )}
                  <span>sequences</span>
                </Link>
                <Link
                  to="/library/sequences/create"
                  className="library__sidebar-folders-folder"
                >
                  <span>
                    <i style={{ display: 'inline-block' }}>
                      <AddIcon />
                    </i>{' '}
                    create new sequence
                  </span>
                </Link>
              </React.Fragment>
            }
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default inject(
  'MyLibraryStore',
  'ProfileStore'
)(observer(MySequencesFolder));
