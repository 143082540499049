import LandingPage from './';
import CompiledVideo from './CompiledVideo';

export default {
  base: '/video/:videoId',
  merge: {
    exact: true
  },
  items: [
    { path: '', component: LandingPage },
    { path: '/embed', component: LandingPage },
    { path: '/compiled', component: CompiledVideo },
    { path: '/:customerId', component: LandingPage }
  ]
};
