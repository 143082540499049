import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class RouterLink extends Component {
  static propTypes = {
    name: PropTypes.string,
    width: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    path: PropTypes.string,
    removeRibbon: PropTypes.bool
  };

  static defaultProps = {
    size: 'm',
    color: 'blue',
    width: 'm',
    path: '/'
  };

  render() {
    const { path, size, color, removeRibbon, width, name } = this.props;
    return (
      <Link
        to={path}
        className={`button button--style-default button--size-${size} button--color-${color}${
          removeRibbon ? '' : '-ribbon'
        } button--width-${width}`}
      >
        {name}
      </Link>
    );
  }
}

export default RouterLink;
