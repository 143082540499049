export const BRAND = process.env.REACT_APP_BRAND_NAME || 'digitalcare100';
export const SHORT_BRAND = process.env.REACT_APP_SHORT_BRAND_NAME || 'dc100';
export const SUPPORT_EMAIL =
  process.env.REACT_APP_SUPPORT_EMAIL || 'support@digitalcare100.com';
export const PORTAL_HOST =
  process.env.REACT_APP_PORTAL_HOST || 'portal.digitalcare100.com';
export const MARKETING_HOST =
  process.env.REACT_APP_MARKETING_HOST || 'digitalcare100.info';
export const EDITOR_TUTORIAL =
  process.env.REACT_APP_EDITOR_TUTORIAL ||
  'https://www.digitalcare100.com/video-resources';
export const PRIVACY_LINK =
  process.env.REACT_APP_EDITOR_PRIVACY ||
  'https://digitalcare100.info/privacy-policy';
export const TERMS_LINK =
  process.env.REACT_APP_EDITOR_TERMS ||
  'https://digitalcare100.info/terms-of-use';
export const VIDEO_RESOURCES_LINK =
  process.env.REACT_APP_VIDEO_RESOURCES ||
  'https://www.digitalcare100.com/video-resources';
export const DISABLE_BILLING = !!process.env.REACT_APP_DISABLE_BILLING;

export const AVAILABLE_REPORTS = process.env.REACT_APP_AVAILABLE_REPORTS
  ? process.env.REACT_APP_AVAILABLE_REPORTS.split(',')
      .map((value) => value.trim())
      .filter((val) => !!val)
  : ['text-messages', 'video-views'];

export const CUSTOMER_ATTRIBUTES = (
  process.env.REACT_APP_CUSTOMER_ATTRIBUTES
    ? process.env.REACT_APP_CUSTOMER_ATTRIBUTES.split(',')
        .map((value) => value.trim())
        .filter((val) => !!val)
    : ['name', 'phone', 'birthday', 'gender']
).reduce((result, value) => ({ ...result, [value]: true }), {});

export const VIDEO_CREATION_PATH =
  process.env.REACT_APP_VIDEO_CREATION_PATH || 'message';
