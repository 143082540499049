import React from 'react';
import ContentLoader from 'react-content-loader';

export default () => (
  <li>
    <ContentLoader
      height={40}
      speed={1}
      primaryColor={'#888'}
      secondaryColor={'#EEE'}
    >
      <rect x="0" y="0" rx="5" ry="5" width="50" height="30" />
      <rect x="63" y="5" rx="5" ry="5" width="100" height="5" />
      <rect x="63" y="15" rx="4" ry="4" width="80" height="4" />
    </ContentLoader>
  </li>
);
