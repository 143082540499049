import { observable, action, flow, decorate, reaction } from 'mobx';
import { apiError } from 'Utils/alert';
import { VideosApi } from 'Api';

class MyVideosStore {
  isLoading = false;
  isReloading = false;
  searchTerm = '';
  allVideosPage = 1;
  recentVideosPage = 1;
  selectedVideos = [];
  allVideos = [];
  recentVideos = [];

  groups = [
    { title: 'all', count: 0 },
    { title: 'recently added', count: 0 }
  ];

  allGroup = this.groups[0];
  recentGroup = this.groups[1];
  activeGroup = this.groups[0];

  constructor() {
    reaction(
      () => this.searchTerm,
      () => this.reloadAll(),
      { delay: 500 }
    );
  }

  setActiveGroup(group) {
    this.activeGroup = group;
    this.selectedVideos = [];
  }

  toggleSelectVideo(video) {
    if (this.selectedVideos.includes(video)) {
      this.selectedVideos = this.selectedVideos.filter(
        findVideo => findVideo !== video
      );
      return;
    }

    this.selectedVideos.push(video);
  }

  reloadAll = flow(function*() {
    this.isReloading = true;
    this.selectedVideos = [];
    yield Promise.all([this.setAllVideosPage(1), this.setRecentVideosPage(1)]);
    this.isReloading = false;
  });

  loadAllVideos = flow(function*() {
    try {
      this.isLoading = true;
      const { count, data } = yield VideosApi.get(
        this.searchTerm,
        Math.max(this.allVideosPage - 1, 0)
      );
      this.allGroup.count = count;
      this.allVideos = data;
    } catch (err) {
      apiError(err);
    }

    this.isLoading = false;
  });

  loadRecentVideos = flow(function*() {
    try {
      this.isLoading = true;
      const { count, data } = yield VideosApi.getRecent(
        this.searchTerm,
        Math.max(this.recentVideosPage - 1, 0)
      );
      this.recentGroup.count = count;
      this.recentVideos = data;
    } catch (err) {
      apiError(err);
    }

    this.isLoading = false;
  });

  deleteVideos = flow(function*(Videos) {
    try {
      yield VideosApi.delete(Videos.map(({ id }) => id));
    } catch (err) {
      apiError(err);
    }

    yield this.reloadAll();
  });

  setRecentVideosPage(page) {
    this.recentVideosPage = page;
    return this.loadRecentVideos();
  }

  setAllVideosPage(page) {
    this.allVideosPage = page;
    return this.loadAllVideos();
  }

  search(term) {
    this.searchTerm = term;
  }
}

const MobxMyVideosStore = decorate(MyVideosStore, {
  isLoading: observable,
  isReloading: observable,
  allVideos: observable,
  recentVideos: observable,
  selectedVideos: observable,
  allVideosPage: observable,
  recentVideosPage: observable,
  searchTerm: observable,
  groups: observable,
  activeGroup: observable,
  setActiveGroup: action,
  setAllVideosPage: action,
  toggleSelectVideo: action,
  setRecentVideosPage: action,
  search: action,
  reloadAll: action,
  loadAllVideos: action,
  loadRecentVideos: action
});

export default new MobxMyVideosStore();
