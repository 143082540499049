/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MerchantApi } from 'Api';
import { apiError } from 'Utils/alert';
import { inject, observer, PropTypes } from 'mobx-react';
import { ReactComponent as StarIcon } from 'Icons/star.svg';
import './index.css';

class Sending extends Component {
  static propTypes = {
    AudienceStore: PropTypes.observableObject,
    BuilderStore: PropTypes.observableObject
  };

  componentWillMount() {
    this.props.BuilderStore.setStep('done', false);
  }

  state = {};

  addMessageToFavorites = async () => {
    const { isAddingToFavorites, isAddedToFavorites } = this.state;

    if (isAddingToFavorites || isAddedToFavorites) {
      return;
    }

    this.setState({ isAddingToFavorites: true });

    try {
      await MerchantApi.addMessageToFavorites(this.message.id);
    } catch (e) {
      apiError(e);
      this.setState({ isAddingToFavorites: false });
      return;
    }

    this.setState({ isAddingToFavorites: false, isAddedToFavorites: true });
  };

  get message() {
    return this.props.BuilderStore.message || {};
  }

  render() {
    const { peopleCount } = this.props.AudienceStore;

    const { isAddingToFavorites, isAddedToFavorites } = this.state;
    return (
      <div className="builder builder--style-sending">
        <div
          className="builder__graphic"
          style={{ backgroundImage: `url('/sending.png')` }}
        />

        <div className="cnt">
          <div className="builder__sending">
            <div className="builder__sending-title">
              <span aria-label="done" role="img">
                💯
              </span>{' '}
              sending your message…
            </div>

            <div className="builder__sending-intro">
              We’re sending your message to{' '}
              <span>
                {peopleCount} {peopleCount === 1 ? 'contact' : 'contacts'}
              </span>
              .
            </div>

            <div className="builder__sending-text">
              <p>
                As soon as there is some activity you can track{' '}
                <Link to="/analytic/messages">Reports</Link>.
              </p>
              <p>
                Read and reply to customer <Link to="/chat">Responses</Link>.
              </p>
              <p>
                View and manage all <Link to="/care">Messages</Link>.
              </p>
              {!(isAddedToFavorites || isAddingToFavorites) && (
                <p
                  className="builder-add-to-favorites"
                  onClick={this.addMessageToFavorites}
                >
                  <i>
                    <StarIcon />
                  </i>
                  Add this finalized message to "my campaigns"
                </p>
              )}
              {isAddedToFavorites && (
                <p>
                  This finalized message has been added to&nbsp;
                  <Link
                    to={`/library/my-library/${
                      this.message.type === 'text'
                        ? 'text-messages'
                        : 'video-messages'
                    }`}
                  >
                    your campaigns
                  </Link>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('AudienceStore', 'BuilderStore')(observer(Sending));
