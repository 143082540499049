import request from './request';

export default {
  current: async () => {
    const { body, header } = await request.getWithHeaders('/merchant/account');
    if (header['http-password-recovery']) {
      body.passwordRecovery = true;
    }
    return body;
  },
  login: (email, password) =>
    request.post('/merchant/authenticate', { email, password }),
  register: (merchantTypeId, name, email, businessPhone, password) =>
    request.post('/merchant/register', {
      merchantTypeId,
      name,
      email,
      businessPhone,
      password
    }),
  save: user => request.put('/user', { user }),
  passwordRecover: email =>
    request.post('/merchant/recover-password', { email })
};
