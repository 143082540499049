let container = null;

export const _setConfirmContainer = confirmContainer =>
  (container = confirmContainer);

export const confirm = async (text, title = 'please confirm') =>
  container
    ? await container.open({
        text,
        title
      })
    : false;

export const confirmYesNo = async (text, title = 'please confirm') =>
  container
    ? await container.open({
        text,
        title,
        confirmButton: 'Yes',
        cancelButton: 'No'
      })
    : false;
