import { observable, action, decorate, flow, reaction, computed } from 'mobx';
import { MessagesAnalyticApi } from 'Api';
import { apiError } from 'Utils/alert';
import RoutingStore from './RoutingStore';
import { formatDate } from 'Utils/date';

const moment = require('moment');

function dateToLocal(dateStr) {
  const currentDate = new Date();
  const date = new Date(dateStr);
  date.setHours(
    currentDate.getHours(),
    currentDate.getMinutes(),
    currentDate.getSeconds()
  );
  return date;
}

class MessageAnalyticStore {
  interval = 'month';
  isLoadingMessages = false;
  isLoadingCustomers = false;
  isLoadingGeneral = false;
  general = {};
  messagesMap = [];
  customersMap = [];
  detailedMessages = observable.map({});
  detailedCustomers = observable.map({});
  reportType = null;
  data = [];
  startDate = null;
  endDate = null;
  searchTerm = '';

  selectedItemId = null;

  messagesInProcess = {};
  customersInProcess = {};

  constructor() {
    this.applyPredefinedInterval(this.interval);
    reaction(
      () => this.startDate,
      async () => {
        this.reload();
      },
      {
        delay: 100
      }
    );
    reaction(
      () => this.endDate,
      async () => {
        this.reload();
      },
      {
        delay: 100
      }
    );
    reaction(
      () => this.selectedItemId,
      async () => this.loadSelectedItem()
    );
    reaction(
      () => this.messagesMap,
      () => {
        if (this.reportType !== 'messages') {
          return;
        }
        this.ensureSelectedReportItem(
          this.messagesMap,
          message => message.groupId,
          (id, messages) => {
            const parentMessage = messages.find(
              message => message.parentMessageIds.indexOf(id) !== -1
            );
            if (parentMessage) {
              return parentMessage.groupId;
            }

            return messages.length > 0 ? messages[0].groupId : null;
          }
        );
      },
      {
        delay: 100
      }
    );
    reaction(
      () => this.customersMap,
      () => {
        if (this.reportType !== 'customers') {
          return;
        }
        this.ensureSelectedReportItem(
          this.customersMap,
          customer => customer.customerId
        );
      },
      {
        delay: 100
      }
    );
  }

  ensureSelectedReportItem = (items, idGetter, redirectIdGetter = null) => {
    if (items.length === 0) {
      return;
    }
    const selectedItemStillExists = !!items.find(
      item => idGetter(item) === this.selectedItemId
    );
    if (selectedItemStillExists) {
      return;
    }

    if (window.innerWidth < 768) {
      return;
    }
    RoutingStore.push(
      `/analytic/${this.reportType}/${
        redirectIdGetter
          ? redirectIdGetter(this.selectedItemId, items)
          : idGetter(items[0])
      }`
    );
  };

  getStartDate() {
    return moment.utc(dateToLocal(this.startDate)).format('L');
  }

  getEndDate() {
    return moment.utc(dateToLocal(this.endDate)).format('L');
  }

  applyPredefinedInterval = interval => {
    this.endDate = formatDate(new Date());
    const startDate = new Date();
    switch (interval) {
      case 'week':
        startDate.setDate(startDate.getDate() - 7);
        break;
      case 'month':
        startDate.setDate(startDate.getDate() - 31);
        break;
      case 'year':
        startDate.setDate(startDate.getDate() - 365);
        break;
      default:
        startDate.setDate(startDate.getDate() - 7);
        break;
    }
    this.startDate = formatDate(startDate);
  };

  setReportType = type => {
    if (this.reportType !== type) {
      this.reportType = type;
      this.searchTerm = '';
    }
  };

  setDateRange = (startDate, endDate) => {
    if (new Date(startDate).getTime() >= new Date(endDate).getTime()) {
      return;
    }
    if (this.startDate !== startDate) {
      this.startDate = startDate;
    }
    if (this.endDate !== endDate) {
      this.endDate = endDate;
    }
  };

  changeInterval = interval => {
    this.applyPredefinedInterval(interval);
    if (this.interval !== interval && !this.isLoadingMessages) {
      this.interval = interval;
    }
  };

  selectItem = id => {
    if (this.selectedItemId !== id) {
      this.selectedItemId = id;
    }
  };

  setSearch = searchTerm => {
    this.searchTerm = searchTerm;
  };

  reload = () => {
    this.loadGeneralData();
    this.reloadCustomers();
    this.reloadMessages();
    this.loadSelectedItem();
  };

  reloadMessages() {
    this.messagesMap = [];
    this.detailedMessages.clear();
    this.loadMessages();
  }

  reloadCustomers() {
    this.customersMap = [];
    this.detailedCustomers.clear();
    this.loadCustomers();
  }

  loadSelectedItem() {
    if (!this.selectedItemId) {
      return;
    }
    switch (this.reportType) {
      case 'messages':
        this.loadMessage(this.selectedItemId);
        break;
      case 'customers':
        this.loadCustomer(this.selectedItemId);
        break;
      default:
        break;
    }
  }

  loadMessages = flow(function*() {
    if (this.isLoadingMessages) {
      return;
    }
    try {
      this.isLoadingMessages = true;
      const messages = yield MessagesAnalyticApi.messages(
        this.getStartDate(),
        this.getEndDate()
      );
      this.messagesMap = messages.map(message => ({
        ...message,
        confirmedDeliveriesCount:
          message.deliveriesCount - message.deliveryFailsCount
      }));
    } catch (e) {
      apiError(e);
    }
    this.isLoadingMessages = false;
  });

  loadCustomers = flow(function*() {
    if (this.isLoadingCustomers) {
      return;
    }
    try {
      this.isLoadingCustomers = true;
      const customers = yield MessagesAnalyticApi.customers(
        this.getStartDate(),
        this.getEndDate()
      );
      this.customersMap = customers
        .map(customer => ({
          ...customer,
          confirmedDeliveriesCount:
            customer.deliveriesCount - customer.deliveryFailsCount
        }))
        .filter(val => !!val.customerId);
    } catch (e) {
      apiError(e);
    }
    this.isLoadingCustomers = false;
  });

  loadGeneralData = flow(function*() {
    if (this.isLoadingGeneral) {
      return;
    }
    try {
      this.isLoadingGeneral = true;
      const generalData = yield MessagesAnalyticApi.general(
        this.getStartDate(),
        this.getEndDate()
      );
      this.general = {
        ...generalData,
        performance: generalData.performance.map(record => ({
          ...record,
          confirmedDeliveriesCount:
            record.deliveriesCount - record.deliveryFailsCount
        }))
      };
    } catch (e) {
      apiError(e);
    }
    this.isLoadingGeneral = false;
  });

  loadMessage = flow(function*(id) {
    if (this.messagesInProcess[id]) {
      return;
    }
    this.messagesInProcess[id] = true;
    try {
      const message = yield MessagesAnalyticApi.message(
        id,
        this.getStartDate(),
        this.getEndDate()
      );
      this.detailedMessages.set(id, {
        ...message,
        performance: message.performance.map(record => ({
          ...record,
          confirmedDeliveriesCount:
            record.deliveriesCount - record.deliveryFailsCount
        }))
      });
    } catch (e) {
      apiError(e);
    }
    delete this.messagesInProcess[id];
  });

  loadCustomer = flow(function*(id) {
    if (this.customersInProcess[id]) {
      return;
    }
    this.customersInProcess[id] = true;
    try {
      const customer = yield MessagesAnalyticApi.customer(
        id,
        this.getStartDate(),
        this.getEndDate()
      );
      this.detailedCustomers.set(id, customer);
    } catch (e) {
      apiError(e);
    }
    delete this.customersInProcess[id];
  });

  get selectedItem() {
    switch (this.reportType) {
      case 'messages': {
        const detailedMessage = this.detailedMessages.get(this.selectedItemId);
        if (!detailedMessage) {
          return null;
        }

        const message = this.messagesMap.find(
          message => message.groupId === this.selectedItemId
        );

        return message ? { ...detailedMessage, message } : null;
      }
      case 'customers': {
        const detailedCustomerReport = this.detailedCustomers.get(
          this.selectedItemId
        );
        if (!detailedCustomerReport) {
          return null;
        }
        const customer = this.customers.find(
          customer => customer.customerId === this.selectedItemId
        );

        return customer ? { ...detailedCustomerReport, customer } : null;
      }
      default:
        return null;
    }
  }

  get messages() {
    const searchTerm = this.searchTerm ? this.searchTerm.toLowerCase() : null;
    return searchTerm
      ? this.messagesMap.filter(message => {
          return message.title.toLowerCase().indexOf(searchTerm) !== -1;
        })
      : this.messagesMap;
  }

  get customers() {
    const searchTerm = this.searchTerm ? this.searchTerm.toLowerCase() : null;
    return searchTerm
      ? this.customersMap.filter(message => {
          return message.customerName.toLowerCase().indexOf(searchTerm) !== -1;
        })
      : this.customersMap;
  }
}

const MobxMessageAnalyticStore = decorate(MessageAnalyticStore, {
  isLoadingMessages: observable,
  isLoadingGeneral: observable,
  selectedItemId: observable,
  messagesMap: observable,
  messages: computed,
  customersMap: observable,
  customers: computed,
  general: observable,
  reportType: observable,
  interval: observable,
  startDate: observable,
  endDate: observable,
  changeInterval: action,
  reload: action,
  setDateRange: action,
  selectedItem: computed,
  setReportType: action,
  selectItem: action,
  searchTerm: observable,
  setSearch: action
});

export default new MobxMessageAnalyticStore();
