import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { ReactComponent as BackArrowIcon } from 'Icons/back-arrow.svg';
import { success } from 'Utils/alert';

import DateTimePick from './DateTimePick';
import Recurrence from './Recurrence';
import moment from 'moment';

class Setup extends Component {
  static propTypes = {
    Routing: PropTypes.observableObject,
    BuilderStore: PropTypes.observableObject
  };

  state = {
    pickedTime: moment(),
    internalTitle: '',
    recurrences: []
  };

  goBackward = () => this.props.Routing.push('/builder/schedule');

  handleDateTimePicked = pickedTime => this.setState({ pickedTime });
  handleRecurrenceChange = recurrences => this.setState({ recurrences });
  componentWillMount() {
    const { BuilderStore } = this.props;
    const startingFrom = BuilderStore.getMessageAttribute('startingFrom');
    const recurrences = [
      ...BuilderStore.getMessageAttribute('recurrences', [])
    ];
    this.setState({
      internalTitle: BuilderStore.getMessageAttribute('internalTitle', ''),
      recurrences,
      pickedTime: startingFrom ? moment.utc(startingFrom).local() : moment()
    });
  }

  finishScheduling = async () => {
    const { BuilderStore } = this.props;
    const { recurrences, pickedTime, internalTitle } = this.state;
    BuilderStore.setMessageAttribute('recurrences', recurrences);
    BuilderStore.setMessageAttribute(
      'startingFrom',
      pickedTime.utc().format('YYYY-MM-DD HH:mm:ss')
    );
    BuilderStore.setMessageAttribute('internalTitle', internalTitle);
    BuilderStore.setStep('done', true);
    await BuilderStore.saveMessageData();
    success('Message was scheduled for sending successfully.');
    this.props.Routing.push('/care/scheduled');
  };

  handleInternalTitle = ({ target }) =>
    this.setState({ internalTitle: target.value });

  render() {
    const { recurrences, pickedTime, internalTitle } = this.state;
    return (
      <div className="builder builder--style-message">
        <div
          className="builder__graphic"
          style={{ backgroundImage: `url('/schedule.png')` }}
        />

        <div className="cnt">
          <div className="builder__back">
            <button onClick={this.goBackward} className="button button--reset">
              <BackArrowIcon />
            </button>
          </div>

          <div className="builder__schedule">
            <div className="builder__schedule-title">schedule to send</div>

            <div className="builder__schedule-form">
              <DateTimePick
                onPick={this.handleDateTimePicked}
                startTime={pickedTime}
              />
              <Recurrence
                onChange={this.handleRecurrenceChange}
                recurrences={recurrences}
              />

              <div className="builder__schedule-form-title form form--style-account">
                <label className="form__label">message title</label>
                <div className="form__container">
                  <input
                    type="text"
                    className="form__input"
                    placeholder="enter message title"
                    onChange={this.handleInternalTitle}
                    value={internalTitle}
                  />
                </div>
              </div>
            </div>

            <div className="builder__schedule-buttons">
              <button
                onClick={this.finishScheduling}
                className="button button--style-default button--size-m button--color-blue-ribbon button--width-m"
              >
                schedule
              </button>
              <button
                onClick={this.goBackward}
                className="button button--reset button--link"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('Routing', 'BuilderStore')(observer(Setup));
