import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import './index.css';
import PropTypes from 'prop-types';
import { formatDate } from 'Utils/date';

class DateRangeSelector extends React.PureComponent {
  handleChangeStart = selectedStartDate => {
    const { setDateRange, endDate } = this.props;
    setDateRange(formatDate(selectedStartDate), endDate);
  };
  handleChangeEnd = selectedEndDate => {
    const { setDateRange, startDate } = this.props;
    setDateRange(startDate, formatDate(selectedEndDate));
  };
  render() {
    const startDate = new Date(this.props.startDate);
    const endDate = new Date(this.props.endDate);
    return (
      <div className="date-range-selector">
        <DatePicker
          selectsStart
          customInput={
            <span className="date-picker-text">
              {moment(startDate).format('MMM DD, YYYY')}
            </span>
          }
          maxDate={new Date()}
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          onChange={this.handleChangeStart}
          disabled={this.props.disabled}
        />
        <span className="date-range-separator">-</span>
        <DatePicker
          selectsEnd
          customInput={
            <span className="date-picker-text">
              {moment(endDate).format('MMM DD, YYYY')}
            </span>
          }
          maxDate={new Date()}
          selected={endDate}
          startDate={startDate}
          endDate={endDate}
          onChange={this.handleChangeEnd}
        />
      </div>
    );
  }
}

DateRangeSelector.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  setDateRange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default DateRangeSelector;
