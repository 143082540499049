import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { extractQueryObject } from 'Utils/string';
import handlers from './handlers';

class MagicLink extends Component {
  static propTypes = {
    Routing: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject
  };

  state = {
    params: {}
  };

  params = {};

  componentWillMount() {
    this.setState({ params: extractQueryObject(window.location.search) });
  }

  componentDidMount() {
    const { to = 'home', jwt } = this.state.params;

    if (jwt) {
      this.props.CommonStore.setToken(jwt);
    }

    if (!handlers[to]) {
      this.props.Routing.push('/');
      return;
    }

    handlers[to](this.state.params, this.props.Routing, this);
  }

  render() {
    return (
      <React.Fragment>
        <div className="cnt">
          <div>Loading...</div>
        </div>
      </React.Fragment>
    );
  }
}
export default inject('Routing', 'CommonStore')(observer(MagicLink));
