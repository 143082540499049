import React, { Component } from 'react';
import Pagination from '.';
import PropTypes from 'prop-types';

export default class StatefulPagination extends Component {
  static propTypes = {
    total: PropTypes.number,
    limit: PropTypes.number,
    onPageClick: PropTypes.func
  };

  state = {
    page: 1
  };

  pageChanged = page => {
    const { onPageClick } = this.props;

    if (page === this.state.page) {
      return;
    }

    this.setState({ page }, () => onPageClick && onPageClick(page));
  };

  render() {
    const { total, limit } = this.props;
    const { page } = this.state;
    return (
      <Pagination
        total={total}
        limit={limit}
        page={page}
        onPageClick={this.pageChanged}
      />
    );
  }
}
