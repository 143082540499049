/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { PropTypes as ReactPropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { loadImportDetails } from './utils';

class ImportSuccess extends Component {
  static propTypes = {
    match: ReactPropTypes.any
  };

  downloadInvalidCustomers = () => {
    const { importId } = this.props.match.params;
    const { invalidCustomers } = loadImportDetails(importId);

    const rows = [['Name', 'Phone', 'Birthday', 'Gender', 'Error']].concat(
      invalidCustomers.map(row => {
        return [
          row.name,
          row.phone,
          row.birthday,
          row.gender,
          Object.values(row.validationErrors).join('\n')
        ];
      })
    );

    const csvContent = rows.map(e => e.join(',')).join('\n');

    const filename = `${importId}.csv`;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  render() {
    const { importId, importCount } = this.props.match.params;
    const importDetails = loadImportDetails(importId);
    const invalidCustomers = importDetails
      ? importDetails.invalidCustomers
      : [];

    return (
      <div className="add-people add-people--style-success">
        <div className="cnt">
          <div className="add-people__wrapper">
            <div className="add-people__header">
              <Link to="/people" className="add-people__header-close" />
            </div>

            <div className="add-people__success">
              <img
                className="add-people__success-icon"
                alt=""
                src="/raised-hands.png"
              />
              <h2 className="add-people__success-title">
                now, show’em that you care
              </h2>
              <div className="add-people__success-text">
                <p>
                  You have successfully imported&nbsp;
                  <span>{importCount} people</span>.
                </p>
                {invalidCustomers.length > 0 && (
                  <React.Fragment>
                    <p>
                      However, we couldn't import {invalidCustomers.length}{' '}
                      people from uploaded file.
                    </p>
                    <p>
                      <a
                        href="javascript:void(0)"
                        onClick={this.downloadInvalidCustomers}
                      >
                        Click here
                      </a>{' '}
                      to download list of these people.
                    </p>
                  </React.Fragment>
                )}
              </div>
              <Link
                to="/"
                className="button button--style-default button--color-blue-ribbon button--size-m"
              >
                send them a care message
              </Link>
              &nbsp;
              <Link
                to="/library/sequences"
                className="button button--style-default button--color-blue-ribbon button--size-m"
              >
                enroll in a sequence
              </Link>
              <ul className="add-people__success-links">
                <li>
                  <Link to={`/people/list-imported/${importId}`}>
                    View imported people
                  </Link>
                </li>
                <li>
                  <Link to="/people">Back to all people</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('Routing')(withRouter(observer(ImportSuccess)));
