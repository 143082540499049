import React from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer, PropTypes } from 'mobx-react';
import ActiveSubscription from './ActiveSubscription';
import BillingMethods from './BillingMethods';
import BillingHistory from './History';
import { confirmYesNo } from 'Components/ConfirmModal';
import FacebookLoader from 'Components/Loader/FacebookLoader';
import Balance from './Balance';

class Billing extends React.Component {
  state = { showBillingMethodForm: false };
  componentWillMount() {
    const { BillingStore, ProfileStore } = this.props;
    BillingStore.loadBillingMethods();
    BillingStore.loadBillingPlans();
    BillingStore.loadNextPaymentsPage();
    BillingStore.loadPricingSettings();
    ProfileStore.loadProfile();
  }
  componentDidMount() {
    this.props.CommonStore.setActivePage('billing');
  }
  addBillingMethod = token => {
    this.props.BillingStore.addBillingMethod(token);
  };
  makeBillingMethodDefault = async id => {
    if (
      !(await confirmYesNo(
        'Are you sure that you want to make this payment method default?'
      ))
    ) {
      return;
    }
    this.props.BillingStore.makeBillingMethodDefault(id);
  };
  deleteBillingMethod = async id => {
    if (
      !(await confirmYesNo(
        'Are you sure that you want to delete this payment method?'
      ))
    ) {
      return;
    }
    this.props.BillingStore.deleteBillingMethod(id);
  };
  render() {
    const { BillingStore, ProfileStore } = this.props;
    const {
      isLoading,
      isLoadingPayments,
      isLoadingBillingPlans,
      isAddingFunds,
      changeInProgress,
      billingMethods,
      billingPlans,
      pricingSettings,
      payments,
      hasMorePayments
    } = BillingStore;
    const { currentProfile } = ProfileStore;

    if (
      !currentProfile.planId &&
      currentProfile.id &&
      !currentProfile.freePortalAccess
    ) {
      return <Redirect to={{ pathname: '/subscription' }} />;
    }

    const billingPlan = billingPlans.find(
      plan => plan.id === currentProfile.planId
    );
    return (
      <div className="settings">
        <div className="cnt">
          <div className="settings__wrapper">
            <div className="settings__content" style={{ width: '100%' }}>
              {isLoading || isLoadingBillingPlans || !currentProfile.id ? (
                <FacebookLoader />
              ) : (
                <React.Fragment>
                  {billingPlan && (
                    <ActiveSubscription billingPlan={billingPlan} />
                  )}
                  <BillingMethods
                    isLoading={isLoading}
                    billingMethods={billingMethods}
                    onAddNewMethod={this.addBillingMethod}
                    onMethodDefault={this.makeBillingMethodDefault}
                    onMethodDelete={this.deleteBillingMethod}
                    actionsDisabled={changeInProgress}
                  />
                  {currentProfile.id && (
                    <Balance
                      balance={currentProfile.balance}
                      isAddingFunds={isAddingFunds}
                      pricingSettings={pricingSettings}
                      addFunds={
                        billingMethods.length > 0
                          ? amount => this.props.BillingStore.addFunds(amount)
                          : null
                      }
                    />
                  )}
                  <BillingHistory
                    isLoading={isLoadingPayments}
                    hasMore={hasMorePayments}
                    payments={payments}
                    loadMore={() =>
                      this.props.BillingStore.loadNextPaymentsPage()
                    }
                  />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Billing.propTypes = {
  ProfileStore: PropTypes.observableObject,
  CommonStore: PropTypes.observableObject,
  BillingStore: PropTypes.observableObject
};

export default inject(
  'CommonStore',
  'BillingStore',
  'ProfileStore'
)(observer(Billing));
