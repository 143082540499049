import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import FacebookLoader from 'Components/Loader/FacebookLoader';
import NewSubscriptionView from './NewSubscriptionView';

class Subscription extends React.Component {
  componentWillMount() {
    const { BillingStore } = this.props;
    BillingStore.loadBillingMethods();
    BillingStore.loadBillingPlans();
  }
  componentDidMount() {
    this.props.CommonStore.setActivePage('subscription');
  }
  subscribe = (planId, token) => {
    this.props.BillingStore.subscribe(planId, token);
  };
  renderComponent = () => {
    const { BillingStore, ProfileStore } = this.props;
    const {
      isLoading,
      isLoadingBillingPlans,
      processingSubscribe,
      billingPlans,
      billingMethods
    } = BillingStore;
    const { currentProfile } = ProfileStore;
    if (isLoading || isLoadingBillingPlans) {
      return <FacebookLoader />;
    }
    if (currentProfile.planId || currentProfile.freePortalAccess) {
      return <Redirect to={{ pathname: '/billing' }} />;
    }
    return (
      <NewSubscriptionView
        billingPlans={billingPlans.filter(plan => {
          return (
            plan.availableForMerchantTypes.indexOf(
              currentProfile.merchantTypeId
            ) !== -1
          );
        })}
        hasBillingMethods={billingMethods.length > 0}
        subscribe={this.subscribe}
        processingSubscribe={processingSubscribe}
      />
    );
  };
  render() {
    return (
      <div className="settings builder builder--style-select-type">
        <div className="cnt">
          <div className="settings__wrapper">
            <div className="settings__content" style={{ width: '100%' }}>
              {this.renderComponent()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Subscription.propTypes = {
  CommonStore: PropTypes.observableObject,
  BillingStore: PropTypes.observableObject,
  ProfileStore: PropTypes.observableObject
};

export default inject(
  'CommonStore',
  'BillingStore',
  'ProfileStore'
)(observer(Subscription));
