/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Components/Button';
import { Elements, injectStripe, CardElement } from 'react-stripe-elements';
import './BillingMethodForm.css';

class BillingMethodForm extends React.Component {
  submit = async e => {
    e.preventDefault();
    let { token } = await this.props.stripe.createToken();
    if (token) {
      this.props.onTokenReady(token.id);
    }
  };
  close = e => {
    e.preventDefault();
    this.props.onClose();
  };
  render() {
    return (
      <form onSubmit={this.submit}>
        <CardElement />
        <div>
          <Button name="Add" type="submit" />
          &nbsp;&nbsp;&nbsp;
          <Button name="Cancel" type="reset" onClick={this.close} />
        </div>
      </form>
    );
  }
}

BillingMethodForm.propTypes = {
  onTokenReady: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  stripe: PropTypes.object.isRequired
};

const StripeInjectedForm = injectStripe(BillingMethodForm);

export default props => (
  <Elements>
    <StripeInjectedForm {...props} />
  </Elements>
);
