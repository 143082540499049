import React, { Component } from 'react';
import ReactPropTypes from 'prop-types';
import { inject, observer, PropTypes } from 'mobx-react';
import { MessagePreview } from 'Components/Messages';

class MessagesViewPage extends Component {
  static propTypes = {
    MessagesListStore: PropTypes.observableObject,
    ProfileStore: PropTypes.observableObject,
    resendInsteadOfCopy: ReactPropTypes.bool
  };

  render() {
    const { MessagesListStore, ProfileStore, resendInsteadOfCopy } = this.props;
    const { previewMessage, setPreviewMessage } = MessagesListStore;
    const { currentProfile, messageFavorites } = ProfileStore;
    const isFavorite = !!messageFavorites[previewMessage.id];

    return (
      <MessagePreview
        logo={currentProfile.profile_image}
        message={previewMessage}
        isFavorite={isFavorite}
        toggleFavorite={() => {
          isFavorite
            ? ProfileStore.removeMessageFromFavorites(previewMessage.id)
            : ProfileStore.addMessageToFavorites(previewMessage.id);
        }}
        onEdit={
          resendInsteadOfCopy
            ? null
            : () => MessagesListStore.editSelectedMessage(previewMessage.id)
        }
        onCopy={() =>
          MessagesListStore.copySelectedMessages(
            previewMessage.id,
            resendInsteadOfCopy
          )
        }
        onDelete={() =>
          MessagesListStore.deleteSelectedMessages(previewMessage.id)
        }
        close={() => setPreviewMessage(null)}
        resendInsteadOfCopy={resendInsteadOfCopy}
      />
    );
  }
}

export default inject(
  'MessagesListStore',
  'ProfileStore'
)(observer(MessagesViewPage));
