import request from './request';
import { limit } from './helpers';

export default {
  pricingSettings: () => request.get('/billing-plan/pricing'),
  listBillingPlans: () => request.get('/billing-plan'),
  listPayments: (limitBy = 10, page = 0) =>
    request.get(`/billing-plan/payments?${limit(limitBy, page)}`),
  subscribe: (planId, token) =>
    request.post('/billing-plan/subscribe', { planId, token }),
  listBillingMethods: () => request.get('/billing-method'),
  addBillingMethod: token => request.post('/billing-method', { token }),
  makeBillingMethodDefault: id => request.put(`/billing-method/${id}/default`),
  deleteBillingMethod: id => request.del(`/billing-method/${id}`),
  addFunds: amount => request.post('/billing-plan/balance', { amount })
};
