import SignUp from './SignUp';
import LogIn from './Login';
import RecoverPassword from './RecoverPass';
import Welcome from './Welcome';

export default {
  base: '',
  items: [
    { path: '/sign-up/:type', component: SignUp },
    { path: '/sign-up', component: SignUp },
    { path: '/login/:type', component: LogIn },
    { path: '/login', component: LogIn },
    { path: '/recovery', component: RecoverPassword },
    { path: '/welcome', component: Welcome }
  ]
};
