import { wrap } from 'Components/layout';
import Care from '.';
import { MESSAGE_SENDER } from '../../roles';

const WrappedCareApp = wrap(Care);

export default {
  base: '/care',
  merge: {
    activePage: 'care',
    exact: true,
    roles: [MESSAGE_SENDER]
  },
  items: [
    { path: '', component: WrappedCareApp, exact: true },
    { path: '/:tab', component: WrappedCareApp }
  ]
};
