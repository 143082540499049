/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

const TYPES = [
  { title: 'Messages', value: 'messages' },
  { title: 'Contacts', value: 'customers' }
];

const Toolbar = () => {
  return (
    <div className="toolbar toolbar--style-reports-type">
      <div className="cnt">
        <div className="toolbar__content">
          <nav className="toolbar__navigation">
            <ul>
              {TYPES.map(({ title, value }) => (
                <li
                  key={value}
                  className={
                    document.location.pathname.search(value) !== -1
                      ? 'is-active'
                      : null
                  }
                >
                  <Link to={`/analytic/${value}`}>{title}</Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
