import React from 'react';
import { Link } from 'react-router-dom';
import { AVAILABLE_REPORTS } from 'Utils/whitelabeling';

const TABS = {
  'text-messages': {
    link: '/analytic/messages',
    label: 'Text Messages',
  },
  'video-views': {
    link: '/video-analytic/videos',
    label: 'Video Views',
  },
};

const Header = function ({ active }) {
  return (
    <div className="toolbar toolbar--style-reports-type">
      <div className="cnt">
        <div className="toolbar__content">
          <nav className="toolbar__navigation analytic-type-selector">
            <ul>
              {AVAILABLE_REPORTS.map((reportId) =>
                TABS[reportId] ? (
                  <li
                    key={reportId}
                    className={reportId === active ? 'is-active' : ''}
                  >
                    <Link to={TABS[reportId].link}>{TABS[reportId].label}</Link>
                  </li>
                ) : null
              )}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
