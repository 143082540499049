import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import InputField from 'Components/InputField';
import UploadButton from 'Components/UploadButton';
import { ReactComponent as PhoneIcon } from 'Icons/phone.svg';
import { SHORT_BRAND } from 'Utils/whitelabeling';

class AccountBasicInfo extends Component {
  static propTypes = {
    ProfileStore: PropTypes.observableObject
  };

  attributeChangeHandler = attributeName => {
    return e => {
      if (!e) return;
      if (!e.target) {
        return this.props.ProfileStore.setAttribute(attributeName, e);
      }
      this.props.ProfileStore.setAttribute(attributeName, e.target.value);
    };
  };

  handleLogoUpload = file => this.props.ProfileStore.uploadLogo(file);
  fields() {
    const { currentProfile } = this.props.ProfileStore;
    return [
      {
        label: 'office name',
        attributeName: 'office_name',
        inputType: 'text',
        placeholder: 'enter your office name',
        changeHandler: this.attributeChangeHandler('officeName')
      },
      {
        label: 'business phone',
        attributeName: 'businessPhone',
        inputType: 'phone',
        placeholder: 'enter business phone',
        changeHandler: this.attributeChangeHandler('businessPhone'),
        disabled: !!currentProfile.phone
      },
      {
        label: 'name',
        attributeName: 'name',
        inputType: 'text',
        placeholder: 'enter your name',
        changeHandler: this.attributeChangeHandler('name')
      },
      {
        label: 'email address',
        attributeName: 'email',
        disabled: true,
        inputType: 'text',
        placeholder: 'enter email address',
        changeHandler: this.attributeChangeHandler('email')
      }
    ];
  }

  render() {
    const { currentProfile, settingsFormData } = this.props.ProfileStore;
    return (
      <React.Fragment>
        <h3 className="settings__subtitle" id="basic-information">
          basic information
        </h3>

        <div className="settings__photo settings__photo--style-profile">
          <img
            src={currentProfile.profile_image}
            alt={currentProfile.officeName}
            className="profile-logo"
          />

          <div className="settings__photo-content">
            <div className="settings__photo-buttons">
              <UploadButton
                onUpload={this.handleLogoUpload}
                buttonText="upload logo"
              />
            </div>

            <div className="settings__photo__info">
              Recommended 500x500px, jpeg, png, gif
            </div>
          </div>
        </div>
        {currentProfile.phones.length > 0 && (
          <div className="settings__mobile">
            <h4 className="settings__label">
              your {SHORT_BRAND} assigned phone numbers
            </h4>

            <div style={{ maxHeight: 300, overflow: 'auto' }}>
              {currentProfile.phones.map(phone => (
                <p key={phone} className="mobile mobile--style-light">
                  <span className="mobile__icon">
                    <PhoneIcon />
                  </span>
                  <span className="mobile__number">{phone}</span>
                </p>
              ))}
            </div>
          </div>
        )}

        <div className="settings__section">
          {this.fields().map((field, key) => (
            <InputField
              key={key}
              {...field}
              values={currentProfile}
              error={settingsFormData.validationErrors[field.attributeName]}
            />
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default inject('ProfileStore')(observer(AccountBasicInfo));
