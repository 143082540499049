import React from 'react';
import PropTypes from 'prop-types';

import './DatePickerInput.css';

class DatePickerInput extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyUp: PropTypes.func,
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    attributeName: PropTypes.string,
    inputType: PropTypes.string,
    placeholder: PropTypes.string,
    inline: PropTypes.bool,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool
  };

  render() {
    const {
      attributeName,
      label,
      inputType,
      placeholder,
      error,
      onChange,
      onFocus,
      onKeyUp,
      onClick,
      onBlur,
      value,
      inline,
      defaultValue,
      disabled
    } = this.props;
    return (
      <div
        key={attributeName}
        className={`form form--style-${inline ? 'inline' : 'account'}`}
      >
        <label className="form__label">{label}</label>
        <div className="form__container">
          <input
            className="form__input"
            placeholder={placeholder}
            type={inputType}
            value={value || defaultValue || ''}
            onChange={onChange}
            onFocus={onFocus}
            onKeyUp={onKeyUp}
            onClick={onClick}
            onBlur={onBlur}
            disabled={disabled}
          />
        </div>
        {error && <div className="form__note">{error}</div>}
      </div>
    );
  }
}

export default DatePickerInput;
