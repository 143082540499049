import Categories from './Categories';
import Favorites from './MyFavorites';
import MyLibraryMessages from './MyLibrary/Messages';
import MyLibraryVideos from './MyLibrary/Videos';
import { wrap } from 'Components/layout';
import OneCategory from './Categories/OneCategory';
import SelectMessageType from './SelectMessageType';
import Sequences from './Sequences';
import SequenceEditor from './SequenceEditor';
import EnrollSequence from './EnrollSequence';
import CuratedVideos from './CuratedVideos';
import { MESSAGE_SENDER } from '../../roles';

export default {
  base: '/library',
  merge: {
    activePage: 'library',
    exact: true
  },
  items: [
    { path: '/categories', component: wrap(Categories) },
    {
      path: '/category/:categoryId',
      component: wrap(OneCategory)
    },
    { path: '/my-favorites', component: wrap(Favorites) },
    {
      path: '/create-custom',
      component: SelectMessageType,
      roles: [MESSAGE_SENDER]
    },
    {
      path: '/my-library/video-messages',
      component: wrap(MyLibraryMessages),
      roles: [MESSAGE_SENDER],
      properties: { messageType: 'video' }
    },
    {
      path: '/my-library/text-messages',
      component: wrap(MyLibraryMessages),
      roles: [MESSAGE_SENDER],
      properties: { messageType: 'text' }
    },
    { path: '/my-library/videos', component: wrap(MyLibraryVideos) },
    { path: '/sequences', component: wrap(Sequences), roles: [MESSAGE_SENDER] },
    {
      path: '/sequences/create',
      component: wrap(SequenceEditor),
      roles: [MESSAGE_SENDER]
    },
    {
      path: '/sequences/edit',
      component: wrap(SequenceEditor),
      roles: [MESSAGE_SENDER]
    },
    {
      path: '/sequences/edit/:sequenceId',
      component: wrap(SequenceEditor),
      roles: [MESSAGE_SENDER]
    },
    {
      path: '/sequences/enroll/:sequenceId',
      component: wrap(EnrollSequence),
      roles: [MESSAGE_SENDER]
    },
    { path: '/curated-videos', component: wrap(CuratedVideos) }
  ]
};
