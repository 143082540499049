import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToolbarFilters from 'Components/Messages/ToolbarFilters';

export default class Toolbar extends Component {
  static propTypes = {
    title: PropTypes.string,
    activeGroup: PropTypes.any,
    groups: PropTypes.array,
    onGroupChanged: PropTypes.func,
    selectedCount: PropTypes.number,
    actions: PropTypes.array,
    activeFilters: PropTypes.object,
    setFilter: PropTypes.func
  };

  static defaultProps = {
    groups: []
  };

  triggerGroupChanged(group) {
    const { onGroupChanged } = this.props;
    onGroupChanged && onGroupChanged(group);
  }

  render() {
    const {
      title,
      groups,
      activeGroup,
      actions,
      selectedCount,
      activeFilters,
      setFilter
    } = this.props;

    return (
      <div className="toolbar toolbar--style-library toolbar--style-care">
        <div className="toolbar-content" />
        <div className="toolbar__content">
          <div className="toolbar__title">{title} </div>

          <nav className="toolbar__navigation">
            <ul>
              {groups.map((group, key) => (
                <li
                  key={key}
                  className={group === activeGroup ? 'is-active' : ''}
                  onClick={() => this.triggerGroupChanged(group)}
                >
                  <a
                    href={`#${group.title
                      .toLowerCase()
                      .replace(/[^a-z]+/, '-')}`}
                  >
                    {group.title}{' '}
                    {group.count !== undefined ? `(${group.count})` : ''}
                  </a>
                </li>
              ))}
            </ul>
          </nav>

          {setFilter && (
            <ToolbarFilters
              {...{
                activeFilters,
                setFilter
              }}
            />
          )}

          <div
            className={`toolbar__actions ${
              selectedCount > 0 ? 'is-active' : ''
            }`}
          >
            <div className="toolbar__actions-content">
              <div className="toolbar__actions-selected">
                <span>{selectedCount}</span> selected
              </div>

              <ul className="actions actions--style-delimeter">{actions}</ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
