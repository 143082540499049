import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer, PropTypes } from 'mobx-react';
import { sortCategories } from '../utils';

class CategorySidebarList extends Component {
  static propTypes = {
    CategoryStore: PropTypes.observableObject
  };

  componentWillMount() {
    const { isLoading, taxonomyLoaded } = this.props.CategoryStore;
    if (!taxonomyLoaded && !isLoading) {
      this.props.CategoryStore.loadMessageTemplateTaxonomy();
    }
  }

  render() {
    const { categories, isLoading } = this.props.CategoryStore;
    return (
      <div className="library__sidebar-categories">
        {!isLoading &&
          sortCategories(categories).map((category, index) => (
            <Link
              to={`/library/category/${encodeURIComponent(category.title)}`}
              key={index}
              className="library__sidebar-categories-item"
            >
              <span className="library__sidebar-categories-item-title">
                {category.title}
              </span>
              <span className="library__sidebar-categories-item-topics">
                {category.items.length} topic
                {category.items.length === 1 ? '' : 's'}
              </span>
            </Link>
          ))}
      </div>
    );
  }
}

export default inject('CategoryStore')(observer(CategorySidebarList));
