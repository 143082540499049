import React from 'react';
import PropTypes from 'prop-types';

const Snippet = ({ title, snippet }) => (
  <span
    className="attribute"
    draggable
    contentEditable={false}
    onDragStart={e => e.dataTransfer.setData('text/plain', snippet)}
    data-snippet={snippet}
  >
    {title}
  </span>
);

Snippet.propTypes = {
  title: PropTypes.string.isRequired,
  snippet: PropTypes.string.isRequired
};

export default Snippet;
