import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';

import { Link } from 'react-router-dom';
import Loader from 'Components/Loader';
import Pagination from 'Components/Pagination';
import { textToColor } from 'Utils/string';

class CustomerSegments extends Component {
  static propTypes = {
    CustomerStore: PropTypes.observableObject
  };

  render() {
    const {
      allTags,
      isLoading,
      total,
      limit,
      offset
    } = this.props.CustomerStore.tags;
    if (isLoading) {
      return <Loader>Loading Tags...</Loader>;
    }
    if (allTags.length === 0) {
      return <h4 style={{ textAlign: 'center' }}>No groups to display</h4>;
    }
    return (
      <React.Fragment>
        <table className="table__table">
          <thead className="table__head">
            <tr>
              <th>group name</th>
              <th>contacts</th>
            </tr>
          </thead>
          <tbody className="table__body">
            {allTags.map(segment => (
              <tr key={segment.tag}>
                <td>
                  <span>
                    <div
                      className="user-icon user-icon--size-m"
                      style={{
                        backgroundColor: `${textToColor(segment.tag)}`
                      }}
                    >
                      {segment.tag.split(' ').map(word => word[0])}
                    </div>
                    {segment.tag}
                  </span>
                </td>
                <td>
                  <Link to={`/people/tag/${encodeURIComponent(segment.tag)}`}>
                    {`see ${segment.customersCount} people`}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          total={total}
          limit={limit}
          page={offset + 1}
          onPageClick={page => this.props.CustomerStore.setTagsOffset(page - 1)}
        />
      </React.Fragment>
    );
  }
}
export default inject('CustomerStore')(observer(CustomerSegments));
