import React from 'react';
import PropTypes from 'prop-types';
import { toggleArrayItem } from 'Utils/arrayHelper';
import { ReactComponent as VideoIcon } from 'Icons/snap-video.svg';
import { ReactComponent as TextIcon } from 'Icons/text-file.svg';
import { ReactComponent as CheckIcon } from 'Icons/check-round.svg';
import { ReactComponent as FiltersIcon } from 'Icons/filters.svg';
import './ToolbarFilters.css';

class Filters extends React.Component {
  state = { active: false };
  toggle = value =>
    this.setState({ active: value === undefined ? !this.state.active : value });
  getTypeFilterConfig = () => {
    const { activeFilters, setFilter } = this.props;
    const typeFilter = activeFilters.type || [];
    const VIDEO = 'video';
    const TEXT = 'text';
    const makeClickHandler = type => {
      return e => {
        e.stopPropagation();
        setFilter('type', toggleArrayItem(typeFilter, type));
      };
    };

    return [
      {
        value: VIDEO,
        icon: <VideoIcon />,
        active: typeFilter.indexOf(VIDEO) !== -1,
        onClick: makeClickHandler(VIDEO)
      },
      {
        value: TEXT,
        icon: <TextIcon />,
        active: typeFilter.indexOf(TEXT) !== -1,
        onClick: makeClickHandler(TEXT)
      }
    ];
  };
  clearAllFilters = () => this.props.setFilter('type', []);
  render() {
    const { active } = this.state;

    return (
      <div className="toolbar__options is-active">
        <div
          className="toolbar__options-content"
          onMouseLeave={() => this.toggle(false)}
        >
          <div
            onClick={this.toggle}
            className={`toolbar__options-item toolbar__options-item--filters ${active &&
              'is-active'}`}
          >
            <button className="toolbar__options-button">
              <FiltersIcon />
              filters
            </button>
            <div className="popover popover--palcement-right popover--style-care-options">
              <div className="popover__header">
                <button
                  className="button button--link"
                  onClick={e => {
                    e.stopPropagation();
                    this.clearAllFilters();
                  }}
                >
                  clear all
                </button>
              </div>
              <div className="popover__label">type</div>
              <div className="popover__filters">
                {this.getTypeFilterConfig().map(config => (
                  <button
                    key={config.value}
                    className={`popover__filters-item ${config.active &&
                      'is-active'}`}
                    onClick={config.onClick}
                  >
                    <div className="popover__filters-item-icon">
                      {config.icon}
                    </div>
                    <div className="popover__filters-item-check">
                      <CheckIcon />
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Filters.propTypes = {
  activeFilters: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired
};

export default Filters;
