import React from 'react';
import Layout from './Layout';

class SignInPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="account__form">
          <h2 className="account__title">sign in</h2>

          <div className="form form--style-account">
            <label className="form__label">sign in</label>
            <div className="form__container">
              <input
                type="text"
                className="form__input"
                placeholder="email address"
              />
            </div>
            <label className="form__label">password</label>
            <div className="form__container">
              <input
                type="password"
                className="form__input"
                placeholder="type password"
              />
            </div>
          </div>

          <div className="account__form-buttons">
            <button className="button button--style-default button--size-m button--color-blue-ribbon button--width-m">
              sign in
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default () => (
  <Layout>
    <SignInPage />
  </Layout>
);
