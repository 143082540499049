import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { Link } from 'react-router-dom';
import { MagicLinkApi } from 'Api';

import QRCode from 'qrcode.react';

import ActionList from 'Components/ActionList';

import Button from 'Components/Button';

class Messages extends Component {
  static propTypes = {
    MyLibraryStore: PropTypes.observableObject,
    MyMessagesStore: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  state = {
    loading: false,
    openItem: null
  };

  openBuilder = ({ id }) =>
    this.props.Routing.push(`/builder/from/message/${id}`);

  handlePageChanged = page => {
    const { allMessagesPage: nowPage } = this.props.MyMessagesStore;

    if (nowPage === page) {
      return;
    }

    this.props.MyMessagesStore.setAllMessagesPage(page);
  };

  componentWillMount() {
    this.props.MyLibraryStore.setLibraryFolder('messages');
    this.props.MyMessagesStore.reloadAll();
  }

  openAbove = ({ id: messageId }) => {
    this.setState({ loading: true, openItem: null });
    const long = `${window.location.origin}/magic-link?to=record-video-mobile&messageId=${messageId}&jwt=${this.props.CommonStore.token}`;

    MagicLinkApi.getLink('record-video-mobile', { messageId }).then(
      link => {
        this.setState({
          loading: false,
          openItem: {
            messageId: messageId,
            shortLink: link,
            long
          }
        });
      },
      () => {
        this.setState({
          loading: false,
          openItem: {
            messageId: messageId,
            shortLink: '',
            long
          }
        });
      }
    );
  };

  render() {
    const {
      activeMessageGroup,
      isLoading,
      allMessages,
      allMessagesPage
    } = this.props.MyMessagesStore;

    const { openItem, loading } = this.state;

    const items = allMessages;

    const page = allMessagesPage;

    return (
      <div className="library library--style-library">
        <div className="cnt">
          <div className="library__split">
            <div className="library__split-right">
              {loading && 'Loading...'}
              {!loading && openItem && (
                <div className="debug-item">
                  <div
                    className="qrcode"
                    style={{ display: 'inline-block', verticalAlign: 'top' }}
                  >
                    <h2>QR Short</h2>
                    {openItem.shortLink ? (
                      <QRCode
                        value={openItem.shortLink}
                        size={200}
                        includeMargin={true}
                      />
                    ) : (
                      <div>Not available</div>
                    )}
                    <h2 style={{ marginTop: '40px' }}>QR Full</h2>
                    <QRCode
                      value={openItem.long}
                      size={200}
                      includeMargin={true}
                    />
                  </div>
                  <div
                    className="qrcode"
                    style={{ display: 'inline-block', marginLeft: '30px' }}
                  >
                    <h2>Message ID: {openItem.messageId}</h2>
                    <h3>
                      <a href={openItem.shortLink}>Open in APP - Short Link</a>
                      <br />
                      <br />
                    </h3>
                    <h3>
                      <a href={openItem.long}>Open in APP - Long Link</a>
                      <br />
                      <br />
                    </h3>
                    <h3>
                      <Link to={`/builder/from/message/${openItem.messageId}`}>
                        Open in Message Builder
                      </Link>
                    </h3>
                  </div>
                </div>
              )}
              <ActionList
                title="messages"
                isLoading={isLoading}
                perPage={10}
                items={items}
                renderItem={item => (
                  <div className="table__video">
                    <div className="video-link video-link--size-s">
                      <div className="video-link__image" />
                    </div>
                    <h3 className="table__video-title">{item.internalTitle}</h3>
                    <div className="table__video-description">
                      <Button
                        onClick={() => this.openAbove(item)}
                        name="Open above"
                      />
                    </div>
                  </div>
                )}
                itemsTotal={activeMessageGroup.count}
                activeGroup={activeMessageGroup}
                onPageClick={this.handlePageChanged}
                currentPage={page}
                leftColumnName="video"
                rightColumnName="created"
                actions={[]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject(
  'MyLibraryStore',
  'CommonStore',
  'MyMessagesStore',
  'Routing'
)(observer(Messages));
