import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import ReactPropTypes from 'prop-types';

import Loader from './Loader';

import { ReactComponent as RightArrowIcon } from 'Icons/right-arrow.svg';
import { ReactComponent as VideoFileIcon } from 'Icons/video-file.svg';
import { ReactComponent as TextFileIcon } from 'Icons/text-file.svg';
//import { ReactComponent as StarIcon } from 'Icons/star.svg';
import { MESSAGE_SENDER } from '../../../roles';

class MessageTemplateList extends Component {
  static propTypes = {
    grouped: ReactPropTypes.bool,
    MessageTemplateStore: PropTypes.observableObject,
    ProfileStore: PropTypes.observableObject
  };

  get canSendMessages() {
    return this.props.ProfileStore.roles.indexOf(MESSAGE_SENDER) !== -1;
  }

  renderItem(message, index) {
    const { activeTemplate } = this.props.MessageTemplateStore;
    const { templateFavorites } = this.props.ProfileStore;

    const isVideoMessage = message.scriptText || false;
    const thumbnailUrl = isVideoMessage
      ? message.exampleVideoThumbnailUrl
      : message.imageUrl;
    const isActive = activeTemplate && message.id === activeTemplate.id;
    const isFavorited = templateFavorites[message.id] || false;

    return (
      <li
        key={index}
        onClick={() => this.props.MessageTemplateStore.setActive(message)}
        className={`library__messages-item ${isActive ? 'is-active' : ''} ${
          isFavorited ? 'is-favorite' : ''
        }`}
      >
        <div
          className="library__messages-item-thumbnail"
          style={{
            backgroundImage: thumbnailUrl && `url('${thumbnailUrl}')`
          }}
        >
          {isVideoMessage ? <VideoFileIcon /> : <TextFileIcon />}
        </div>
        <div className="library__messages-item-title">{message.title}</div>
        <div className="library__messages-item-excerpt">
          {message.description}
        </div>

        {/*<div className="library__messages-item-favorite">
          {isFavorited && <StarIcon />}
        </div>*/}
        <div className="library__messages-item-arrow">
          {isActive && <RightArrowIcon />}
        </div>
      </li>
    );
  }

  renderAllList() {
    const { allTemplates } = this.props.MessageTemplateStore;

    return (
      <React.Fragment>
        <div className="library__messages-title">messages</div>

        <ul className="library__messages-list">
          {allTemplates
            .filter(({ scriptText }) => this.canSendMessages || !!scriptText)
            .map((message, index) => this.renderItem(message, index))}
        </ul>
      </React.Fragment>
    );
  }

  renderLoader(type) {
    return (
      <React.Fragment>
        <div className="library__messages-title">{type}</div>

        <ul className="library__messages-list">
          <Loader />
        </ul>
      </React.Fragment>
    );
  }

  renderGroupedList() {
    const { videoTemplates, textTemplates } = this.props.MessageTemplateStore;

    return (
      <React.Fragment>
        {videoTemplates.length > 0 && (
          <div className="library__messages-title">video messages</div>
        )}

        <ul className="library__messages-list">
          {videoTemplates.map((message, index) =>
            this.renderItem(message, index)
          )}
        </ul>

        {this.canSendMessages && (
          <React.Fragment>
            {textTemplates.length > 0 && (
              <div className="library__messages-title">text-only messages</div>
            )}

            <ul className="library__messages-list">
              {textTemplates.map((message, index) =>
                this.renderItem(message, index)
              )}
            </ul>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { grouped } = this.props;
    const { allTemplates, isLoading } = this.props.MessageTemplateStore;

    if (isLoading) {
      return (
        <React.Fragment>
          {this.renderLoader('video messages')}
          {this.canSendMessages && this.renderLoader('text-only messages')}
        </React.Fragment>
      );
    }

    if (allTemplates.length === 0) {
      return (
        <div className="library__messages-title">
          No message templates available.
        </div>
      );
    }

    return (
      <React.Fragment>
        {grouped ? this.renderGroupedList() : this.renderAllList()}
      </React.Fragment>
    );
  }
}

export default inject(
  'MessageTemplateStore',
  'ProfileStore'
)(observer(MessageTemplateList));
