import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckIcon } from 'Icons/check.svg';

class Checkbox extends Component {
  static propTypes = {
    name: PropTypes.string,
    checked: PropTypes.bool,
    onChecked: PropTypes.func,
    onUnchecked: PropTypes.func,
    onChange: PropTypes.func
  };

  handleChange = ({ target }) => {
    const { onChecked, onUnchecked, onChange } = this.props;

    if (target.checked) {
      onChecked && onChecked();
    } else {
      onUnchecked && onUnchecked();
    }

    onChange && onChange(target.checked);

    this.setState({ checked: target.checked });
  };

  render() {
    const { checked, name } = this.props;
    return (
      <label
        className={`check check--size-m check--color-java ${
          name ? 'check--style-label' : ''
        }`}
      >
        <input
          type="checkbox"
          className="check__helper"
          checked={checked}
          value={checked}
          onChange={this.handleChange}
        />
        <span className="check__icon">
          <CheckIcon />
        </span>
        {name && <span className="check__label">{name}</span>}
      </label>
    );
  }
}

export default Checkbox;
