/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer, PropTypes } from 'mobx-react';
import { MESSAGE_SENDER } from '../../roles';

import ReactPropTypes from 'prop-types';
import Logo from './Logo';
import { ReactComponent as SearchIcon } from 'Icons/Buttons/search.svg';
import { ReactComponent as UserIcon } from 'Icons/menu-user.svg';
import { ReactComponent as HamburgerIcon } from 'Icons/hamburger.svg';
import { ReactComponent as DropdownIcon } from 'Icons/Buttons/dropdown.svg';
import { warning } from 'Utils/alert';
import { DISABLE_BILLING } from 'Utils/whitelabeling';

import './header.css';

const MENU_ITEMS = [
  { page: 'library', url: '/', title: 'create' },
  { page: 'care', url: '/care', title: 'messages', roles: [MESSAGE_SENDER] },
  {
    page: 'chat',
    url: '/chat',
    title: 'responses',
    roles: [MESSAGE_SENDER],
  },
  {
    page: 'people',
    url: '/people',
    title: 'contacts',
    roles: [MESSAGE_SENDER],
  },
  {
    page: 'analytic',
    url: '/analytic/messages',
    title: 'reports',
    roles: [MESSAGE_SENDER],
  },
];

class Header extends Component {
  static propTypes = {
    CommonStore: PropTypes.observableObject,
    ProfileStore: PropTypes.observableObject,
    AuthStore: PropTypes.observableObject,
    hideNavigation: ReactPropTypes.bool,
    Routing: PropTypes.observableObject,
    SmsStore: PropTypes.observableObject,
  };

  state = {
    displaySearch: false,
    showAccountPopover: false,
    showNavigationPopover: false,
  };

  componentWillMount() {
    this.props.SmsStore.calculateNotReadMessages();
  }

  handleLogout = () => {
    this.props.AuthStore.logout();
    this.props.Routing.push('/login');
  };

  handleSearchField = () => {
    this.setState({
      displaySearch: !this.state.displaySearch,
    });
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.setState({
      displaySearch: false,
    });
    this.props.Routing.push(`/people/search/${this.searchInput.value}`);
    this.searchInput.value = '';
  };

  toggleAccountPopover = () =>
    this.setState({ showAccountPopover: !this.state.showAccountPopover });

  toggleNavigationPopover = () =>
    this.setState({ showNavigationPopover: !this.state.showNavigationPopover });

  getMenuItems = () => {
    const {
      SmsStore: { notReadMessagesCount },
      ProfileStore: { roles: merchantRoles },
    } = this.props;

    return MENU_ITEMS.map((menuItem) => {
      const isEnabled = menuItem.roles
        ? menuItem.roles.reduce(
            (result, role) =>
              merchantRoles.indexOf(role) !== -1 ? true : result,
            false
          )
        : true;
      return menuItem.page === 'chat'
        ? { ...menuItem, isEnabled, notification: notReadMessagesCount > 0 }
        : { ...menuItem, isEnabled };
    });
  };

  isSearchAvailable = () => {
    const { roles } = this.props.ProfileStore;
    return roles.indexOf(MESSAGE_SENDER) !== -1;
  };

  disabledLinkClickHandler = (e) => {
    e.preventDefault();
    warning(
      DISABLE_BILLING
        ? 'This feature is disabled'
        : 'Upgrade subscription plan to enable full access'
    );
  };

  renderNavigation() {
    const { activePage } = this.props.CommonStore;
    const { hideNavigation } = this.props;

    const { displaySearch, showAccountPopover, showNavigationPopover } =
      this.state;

    if (hideNavigation) {
      return null;
    }

    return (
      <Fragment>
        <nav className="header__navigation">
          <h2>Navigation</h2>
          <ul>
            {this.getMenuItems().map(
              ({ page, url, title, notification, isEnabled }) => (
                <li
                  key={page}
                  className={`${activePage === page ? 'is-active' : ''} ${
                    notification > 0 ? 'is-notification' : ''
                  } ${isEnabled ? '' : 'disabled'}`}
                >
                  <Link
                    to={url}
                    onClick={isEnabled ? null : this.disabledLinkClickHandler}
                  >
                    <span>{title}</span>
                  </Link>
                </li>
              )
            )}
          </ul>
        </nav>

        {this.isSearchAvailable() && (
          <div className={`header__search ${displaySearch && 'is-active'}`}>
            <div className="header__search-wrapper">
              <div className="header__search-icon">
                <SearchIcon />
              </div>
              <div className="header__search-form form form--style-account">
                <form
                  className="form__container"
                  onSubmit={this.handleSearchSubmit}
                >
                  <input
                    ref={(ref) => (this.searchInput = ref)}
                    type="text"
                    className="form__input"
                    placeholder="search..."
                  />
                </form>
              </div>
              <div className="header__search-clear">
                <button
                  className="button button--link is-active"
                  onClick={this.handleSearchField}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="header__options">
          {this.isSearchAvailable() && (
            <div className="header__options-item header__options-search">
              <button
                className="header__options-button"
                onClick={this.handleSearchField}
              >
                <SearchIcon />
              </button>
            </div>
          )}

          <div className="header__options-item header__options-account">
            <button
              className="header__options-button"
              onClick={this.toggleAccountPopover}
            >
              <span className="header__options-button-icon">
                <UserIcon />
              </span>
              <span className="header__options-button-text">account</span>
              <DropdownIcon />
            </button>
            <div
              className={`popover popover--placement-right popover--offset-top ${
                showAccountPopover ? 'is-active' : ''
              }`}
            >
              <ul className="popover__menu">
                <li className="popover__menu-item">
                  <div>{this.props.ProfileStore.currentProfile.name}</div>
                </li>
                <li className="popover__menu-item">
                  <div className="popover__menu-item-title">
                    <Link to="/profile">profile</Link>
                  </div>
                  <div className="popover__menu-item-title">
                    <Link to="/profile">
                      Name, Photo, Email, Phone, Password
                    </Link>
                  </div>
                </li>
                <li className="popover__menu-item" onClick={this.handleLogout}>
                  <a className="popover__menu-item-title">sign out</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="header__options-item header__options-menu-trigger">
            <button
              className="header__options-button"
              onClick={this.toggleNavigationPopover}
            >
              <HamburgerIcon />
            </button>
            <div
              className={`popover popover--placement-right popover--offset-top popover--style-navigation ${
                showNavigationPopover ? 'is-active' : ''
              }`}
            >
              <ul className="popover__menu">
                {this.getMenuItems().map(
                  ({ page, url, title, notification, isEnabled }) => (
                    <li
                      key={page}
                      className={`popover__menu-item ${
                        activePage === page ? 'is-active' : ''
                      } ${notification > 0 ? 'is-notification' : ''} ${
                        isEnabled ? '' : 'disabled'
                      }`}
                    >
                      <Link
                        to={url}
                        onClick={
                          isEnabled ? null : this.disabledLinkClickHandler
                        }
                      >
                        <span>{title}</span>
                      </Link>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    const { hideNavigation } = this.props;
    return (
      <div className="header">
        <div className="cnt">
          <div className="header__content">
            <h1
              className={`header__logo ${
                hideNavigation ? 'navigation-hidden' : ''
              }`}
            >
              {hideNavigation ? (
                <Logo />
              ) : (
                <Link to="/">
                  <Logo />
                </Link>
              )}
            </h1>
            {this.renderNavigation()}
          </div>
        </div>
      </div>
    );
  }
}

export default inject(
  'CommonStore',
  'AuthStore',
  'Routing',
  'SmsStore',
  'ProfileStore'
)(observer(Header));
