import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import Pagination from 'Components/Pagination';
import CustomerList from './CustomerList';

function InvalidCustomers({ CustomerStore }) {
  const {
    invalidCustomers: { total, offset, limit }
  } = CustomerStore;
  return (
    <React.Fragment>
      <CustomerList />
      <Pagination
        total={total}
        limit={limit}
        page={offset + 1}
        onPageClick={page =>
          this.props.CustomerStore.setInvalidCustomersOffset(page - 1)
        }
      />
    </React.Fragment>
  );
}

InvalidCustomers.propTypes = {
  CustomerStore: PropTypes.observableObject
};

export default inject('CustomerStore')(observer(InvalidCustomers));
