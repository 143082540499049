import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'Utils/date';
import { ReactComponent as VideoIcon } from 'Icons/snap-video.svg';
import { ReactComponent as TextIcon } from 'Icons/text-file.svg';
import { ReactComponent as EyeIcon } from 'Icons/eye.svg';
import { ReactComponent as PhoneIcon } from 'Icons/phone.svg';
import { ReactComponent as BackArrowIcon } from 'Icons/back-arrow.svg';
import Actions from './Actions';
import VideoPreview from 'Components/VideoPreview';

const MessagePreview = ({
  logo,
  message,
  close,
  toggleFavorite,
  onEdit,
  onCopy,
  onDelete,
  resendInsteadOfCopy,
  isFavorite
}) => {
  const {
    id,
    internalTitle,
    text,
    landingPageText,
    scriptText,
    type,
    viewsCount,
    deliveriesCount,
    videoRecordedBy,
    videoUrl,
    videoThumbnailUrl,
    videoOverlay,
    videoTitle,
    videoFrameImageSource,
    createdAt
  } = message;

  return (
    <React.Fragment>
      <div className="toolbar toolbar--style-back">
        <div className="cnt">
          <div className="toolbar__content">
            <button className="toolbar__back" onClick={close}>
              <BackArrowIcon />
              <span>messages</span>
            </button>
          </div>
        </div>
      </div>
      <div className="care">
        <div className="cnt">
          <div className="care__single">
            <div className="care__header">
              <div className="care__header-title">
                {type === 'text' ? <TextIcon /> : <VideoIcon />}
                <span>{internalTitle}</span>
              </div>
              <div className="care__header-stats">
                {message.type === 'video' && (
                  <ul className="actions actions--style-delimeter actions--style-count">
                    <li>
                      <button title="Views">
                        <EyeIcon />
                        <span>{viewsCount}</span>
                      </button>
                    </li>
                    <li>
                      <button title="Sends">
                        <PhoneIcon />
                        <span>{deliveriesCount}</span>
                      </button>
                    </li>
                  </ul>
                )}
              </div>
              <Actions
                selectedMessageId={id}
                selectedMessageType={type}
                isFavorite={isFavorite}
                toggleFavorite={toggleFavorite}
                onEdit={onEdit}
                onCopy={onCopy}
                onDelete={onDelete}
                resendInsteadOfCopy={resendInsteadOfCopy}
              />
              <div className="care__header-date">{formatDate(createdAt)}</div>
            </div>
            <div className="care__content">
              <div className="care__aside">
                <div className="care__aside-label">script</div>

                <div className="care__aside-text">{scriptText}</div>

                {/*<div className="care__aside-label">recipients</div>

                <ul className="care__aside-recipients">
                  <li>
                    <b>Jina Smith</b>
                    <span>1-(130)-276-2259</span>
                  </li>
                  <li>
                    <b>Austin Nguyen</b>
                    <span>1-(130)276-2259</span>
                  </li>
                  <li>
                    <b>Phillip Thompson</b>
                    <span>1-(130)276-2259</span>
                  </li>
                  <li>
                    <b>Nicole Scott</b>
                    <span>1-(130)276-2259</span>
                  </li>
                </ul>*/}
              </div>

              <div className="care__preview">
                <h1 className="care__preview-title">{text}</h1>

                <div className="care__preview-text">
                  <p>{landingPageText}</p>
                </div>

                {videoUrl && (
                  <VideoPreview
                    {...{
                      frameSource: videoFrameImageSource,
                      posterUrl: videoThumbnailUrl
                        ? `${videoThumbnailUrl}-decorated`
                        : null,
                      videoRecordedBy,
                      videoTitle,
                      videoOverlay,
                      videoUrl,
                      logo
                    }}
                    showFrame
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

MessagePreview.propTypes = {
  logo: PropTypes.string,
  message: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onCopy: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  resendInsteadOfCopy: PropTypes.bool
};

export default MessagePreview;
