import { inject, observer, PropTypes } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import ReactPropTypes from 'prop-types';

class Layout extends Component {
  static propTypes = {
    BuilderStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject,
    Component: ReactPropTypes.any
  };

  state = {
    previousRoute: null,
    passthrough: false
  };

  get isCurrentRouteInStep() {
    const steps = this.props.BuilderStore.steps;
    const { pathname } = this.props.Routing.location;

    const step = Object.keys(steps).find(
      stepName => `/builder/${steps[stepName].route}` === pathname
    );

    return step !== null;
  }

  componentWillMount() {
    const { BuilderStore, Routing } = this.props;

    if (!BuilderStore.messageReady) {
      Routing.push('');
      this.updatePassthrough(true);
    }
  }

  componentWillReceiveProps() {
    const { BuilderStore, Routing } = this.props;

    if (!this.isCurrentRouteInStep) {
      Routing.push(BuilderStore.currentRoute);
      this.updatePassthrough(true);
      return;
    }

    this.updatePassthrough(false);
  }

  updatePassthrough(passthrough) {
    this.setState({ passthrough });
  }

  render() {
    if (this.state.passthrough) {
      return null;
    }

    const { Component } = this.props;

    return <Component />;
  }
}
const MobxLayout = inject(
  'BuilderStore',
  'Routing'
)(withRouter(observer(Layout)));

export default Component => () => <MobxLayout Component={Component} />;
