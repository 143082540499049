import request from './request';
import { limit, toSearchQuery } from './helpers';
import { CUSTOMER_ATTRIBUTES } from 'Utils/whitelabeling';

const DEFAULT_PHONE = '+00000000000';

export default {
  get: (page, limitBy = 10, tag = undefined, search = null, importId = null) =>
    request.get(
      `/customer?${limit(limitBy, page)}&${toSearchQuery({
        validOnly: true,
        tag: tag ? decodeURIComponent(tag) : undefined,
        search,
        importId,
      })}`
    ),
  getInvalid: (page, limitBy = 10) =>
    request.get(
      `/customer?${limit(limitBy, page)}&${toSearchQuery({
        invalidOnly: true,
      })}`
    ),
  getMultiple: (ids) => request.post(`/customer/multiple`, { ids }),
  calculateSelected: (ids, tags) =>
    request
      .post(`/customer/people-count`, { tags, ids })
      .then(({ selected }) => selected),
  add: (customer) =>
    request.post('/customer', {
      ...customer,
      phone:
        customer.phone || (CUSTOMER_ATTRIBUTES.phone ? null : DEFAULT_PHONE),
    }),
  update: (customerId, customer) =>
    request.put(`/customer/${customerId}`, {
      ...customer,
      phone:
        customer.phone || (CUSTOMER_ATTRIBUTES.phone ? null : DEFAULT_PHONE),
    }),
  delete: (id) => request.del(`/customer/${id}`),
  getTags: (page, limitBy = 10) =>
    request.get(`/customer-tags?${limit(limitBy, page)}`),
  getMultipleTags: (tags) => request.post(`/customer-tags/multiple`, { tags }),
  addTag: (customerId, tag) =>
    request.post(`/customer/${customerId}/tag`, { tag }),
  batchTagging: (ids, tags) =>
    request.post(`/customer-tags/batchTagging`, { ids, tags }),
  getMessagesHistory: (customerId, page, limitBy = 10) =>
    request.get(
      `/customer/${customerId}/messages-history?${limit(limitBy, page)}`
    ),
  removeTag: (customerId, tag) =>
    request.delWithBody(`/customer/${customerId}/tag`, tag),
  sendSms: (customerId, text) =>
    request.post(`/customer/${customerId}/sms`, { text }),
  batchImport: (file, segments) =>
    request.formUpload('/customer/import', file, {
      info: JSON.stringify({ segments }),
    }),
  batchDelete: (ids) => request.delWithBody(`/customer/batchDelete`, { ids }),
  truncate: () => request.del('/customer'),
};
