import { observable, action, flow, decorate, reaction } from 'mobx';
import { apiError } from 'Utils/alert';
import { MessageTemplateApi } from 'Api';
import { partitionBy } from 'Utils/arrayHelper';

class MessageTemplateStore {
  isLoading = false;
  searchTerm = '';
  totalFoundCount = 0;
  searchPage = 1;
  activeTemplate = null;
  allTemplates = [];
  videoTemplates = [];
  textTemplates = [];

  constructor() {
    reaction(
      () => this.searchTerm,
      () => this.searchMessageTemplates(),
      {
        delay: 500
      }
    );
  }

  loadTemplateByIds = flow(function*(templateIds) {
    try {
      this.isLoading = true;
      this.resetList();

      if (templateIds.length > 0) {
        const { data } = yield MessageTemplateApi.getMessageTemplates(
          templateIds
        );
        this.setAllTemplates(data);
      }
    } catch (err) {
      apiError(err);
    }

    this.isLoading = false;
  });

  searchMessageTemplates = flow(function*() {
    try {
      this.isLoading = true;
      this.resetList();

      const { count, data } = yield MessageTemplateApi.get(
        this.searchTerm,
        this.searchPage - 1
      );

      this.totalFoundCount = count;

      this.setAllTemplates(data);
    } catch (err) {
      apiError(err);
    }

    this.isLoading = false;
  });

  resetList() {
    this.allTemplates = [];
    this.videoTemplates = [];
    this.textTemplates = [];
    this.totalFoundCount = 0;
    this.setActive(null);
  }

  setAllTemplates(templates) {
    this.resetList();
    [this.videoTemplates, this.textTemplates] = partitionBy(
      templates,
      item => item.scriptText
    );

    this.allTemplates = templates;

    if (window.innerWidth < 768) {
      return;
    }
    this.setActive(templates.length > 0 ? templates[0] : null);
  }

  setActive(messageTemplate) {
    this.activeTemplate = messageTemplate;
  }

  search(term) {
    this.searchTerm = term;
    this.searchPage = 1;
    this.totalFoundCount = 0;
  }

  setSearchPage(page) {
    this.searchPage = page;
    this.isLoading = true;
    return this.searchMessageTemplates();
  }
}

const MobxMessageTemplateStore = decorate(MessageTemplateStore, {
  isLoading: observable,
  searchTerm: observable,
  searchPage: observable,
  totalFoundCount: observable,
  activeTemplate: observable,
  allTemplates: observable,
  videoTemplates: observable,
  textTemplates: observable,
  loadTemplateByIds: action,
  resetList: action,
  searchMessageTemplates: action,
  setAllTemplates: action,
  setActive: action,
  setSearchPage: action,
  search: action
});

export default new MobxMessageTemplateStore();
