/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ReactComponent as CloseIcon } from 'Icons/close.svg';
import Button from 'Components/Button';
import IntervalSelector from './IntervalSelector';
import TypeSelector from './TypeSelector';
import MessageSelector from './MessageSelector';

function Steps({ onChange, steps = [], error }) {
  const onAddStep = () => {
    onChange(steps.concat([{}]));
  };

  const onStepChange = (stepId, attribute, value) => {
    const newSteps = [...steps];
    newSteps[stepId][attribute] = value;
    onChange(newSteps);
  };

  const makeStepChangeHandler = (stepId, attribute) => {
    const isMessageId = attribute === 'messageId';
    return function(value) {
      onStepChange(stepId, attribute, isMessageId ? value : value.value);
      if (isMessageId) {
        onStepChange(stepId, 'messageType', value.type);
      }
    };
  };

  const makeStepTextChangeHandler = (stepId, attribute) => {
    return function(e) {
      onStepChange(stepId, attribute, e.target.value);
    };
  };

  const onStepDelete = stepId => {
    const newSteps = [...steps];
    newSteps[stepId] = null;

    onChange(newSteps.filter(val => !!val));
  };

  return (
    <div className="table table--style-reports-shares sequence-steps">
      <div className="add-step">
        <Button name="+ Add Step" onClick={onAddStep} size="s" width="s" />
      </div>
      <div className="table__wrapper">
        <table className="table__table">
          <thead className="table__head">
            <tr>
              <th></th>
              <th>message</th>
              <th>type</th>
              <th>interval</th>
              <th>interval qty</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="table__body">
            {steps.map((step, stepId) => {
              return (
                <tr key={stepId}>
                  <td>
                    {step.messageId && step.messageId.landingPageImage && (
                      <img
                        src={step.messageId.landingPageImage}
                        alt={step.messageId.label}
                      />
                    )}
                  </td>
                  <td>
                    <MessageSelector
                      value={step.messageId}
                      type={step.messageType}
                      onChange={makeStepChangeHandler(stepId, 'messageId')}
                    />
                  </td>
                  <td>
                    <TypeSelector
                      value={step.messageType}
                      onChange={makeStepChangeHandler(stepId, 'messageType')}
                    />
                  </td>
                  <td>
                    <IntervalSelector
                      value={step.interval}
                      onChange={makeStepChangeHandler(stepId, 'interval')}
                    />
                  </td>
                  <td>
                    <input
                      className="form__input"
                      type="number"
                      value={step.step}
                      onChange={makeStepTextChangeHandler(stepId, 'step')}
                    />
                  </td>
                  <td className="buttons">
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        onStepDelete(stepId);
                      }}
                    >
                      <CloseIcon
                        style={{
                          display: 'inline-block',
                          height: '0.5em',
                          width: '0.5em'
                        }}
                      />
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {error && <div className="error form__note">{error}</div>}
    </div>
  );
}

export default Steps;
