import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from 'Components/Button';
import Logo from 'Components/layout/Logo';

class LoginError extends React.Component {
  static propTypes = {
    onTryAgain: PropTypes.func.isRequired
  };

  render() {
    return (
      <div className="account account--style-success">
        <div>
          <div className="account__header">
            <h1 className="account__header-logo">
              <Link to="/">
                <Logo />
              </Link>
            </h1>
          </div>

          <div className="account__content">
            <div className="account__form">
              <h2 className="account__title">oops!</h2>

              <div className="account__text account__text--first">
                We couldn’t find the account associated with the information you
                provided. Maybe you mistyped it or you didn’t register an
                account with us.
              </div>

              <div className="account__text account__text--last">
                <Button onClick={this.props.onTryAgain} name="try again" />
                or
                <Link to="/sign-up">Sign up</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginError;
