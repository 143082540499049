/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { error } from 'Utils/alert';
import InputField from 'Components/InputField';
import Button from 'Components/Button';
import Tags from 'Components/Tags';
import { ReactComponent as CheckIcon } from 'Icons/check.svg';
import { CUSTOMER_ATTRIBUTES } from 'Utils/whitelabeling';

class AddCustomerForm extends Component {
  static propTypes = {
    CustomerStore: PropTypes.observableObject,
  };

  state = { consentGiven: false };

  componentWillMount() {
    this.props.CustomerStore.resetFormObj();
    this.props.CustomerStore.loadTags();
  }

  attributeChangeHandler = (attributeName) => {
    return (e) => {
      if (!e) return;
      if (!e.target) {
        return this.props.CustomerStore.setAttribute(attributeName, e);
      }
      this.props.CustomerStore.setAttribute(attributeName, e.target.value);
    };
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    if (this.state.consentGiven) {
      return this.props.CustomerStore.addCustomer();
    }
    error(
      'Please certify that this is not purchased contact and that You have received their permission'
    );
  };

  toggleConsent = () =>
    this.setState({ consentGiven: !this.state.consentGiven });

  renderTextInput = (config) => {
    const { values, validationErrors } =
      this.props.CustomerStore.customerFormObj;
    return (
      <InputField
        {...config}
        values={values}
        error={validationErrors[config.attributeName]}
      />
    );
  };

  render() {
    const { CustomerStore } = this.props;
    const { validationErrors, errorMessage, isSubmitting, values } =
      CustomerStore.customerFormObj;

    const male = 'male';
    const female = 'female';

    return (
      <div className="add-people__form">
        <form onSubmit={this.handleSubmitForm}>
          {Object.keys(validationErrors).length === 0 && errorMessage && (
            <h3>{errorMessage}</h3>
          )}
          {CUSTOMER_ATTRIBUTES.name && (
            <div className="add-people__form-input-split">
              {this.renderTextInput({
                attributeName: 'firstName',
                inputType: 'text',
                placeholder: 'first name',
                changeHandler: this.attributeChangeHandler('firstName'),
              })}
              {this.renderTextInput({
                attributeName: 'lastName',
                inputType: 'text',
                placeholder: 'last name',
                changeHandler: this.attributeChangeHandler('lastName'),
              })}
            </div>
          )}

          {CUSTOMER_ATTRIBUTES.gender && (
            <ul className="add-people__nav">
              <li className={values.sex === male ? 'is-active' : ''}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.CustomerStore.setAttribute('sex', male);
                  }}
                >
                  Male
                </a>
              </li>
              <li className={values.sex === female ? 'is-active' : ''}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.CustomerStore.setAttribute('sex', female);
                  }}
                >
                  Female
                </a>
              </li>
            </ul>
          )}
          {CUSTOMER_ATTRIBUTES.phone &&
            this.renderTextInput({
              attributeName: 'phone',
              inputType: 'phone',
              placeholder: 'phone number',
              changeHandler: this.attributeChangeHandler('phone'),
            })}

          {CUSTOMER_ATTRIBUTES.birthday &&
            this.renderTextInput({
              attributeName: 'birthday',
              inputType: 'date-picker',
              placeholder: 'birthday',
              maxDate: new Date(),
              changeHandler: this.attributeChangeHandler('birthday'),
            })}

          <div className="add-people__tags is-active">
            <div className="add-people__tags-top">
              <h3 className="add-people__tags-title">groups</h3>
              <div className="add-people__tags-text">
                Used for internal reference
              </div>
            </div>

            <div className="tags tags--size-m">
              <Tags
                tags={values.tags || []}
                onChange={(tags) => {
                  CustomerStore.setAttribute(
                    'tags',
                    tags.map((tag) => tag.value)
                  );
                }}
                suggestions={
                  CustomerStore.tags.isLoading ? [] : CustomerStore.tags.allTags
                }
              />
            </div>
          </div>

          <label className="check check--size-m check--color-java check--style-label">
            <input
              type="checkbox"
              className="check__helper"
              onChange={this.toggleConsent}
              checked={this.state.consentGiven}
            />
            <span className="check__icon">
              <CheckIcon />
            </span>
            <span className="check__label">
              I certify that these are not purchased contacts and that I have
              received their permission.
            </span>
          </label>
          <Button type="submit" name="add contacts" disabled={isSubmitting} />
        </form>
      </div>
    );
  }
}

export default inject('CustomerStore')(observer(AddCustomerForm));
