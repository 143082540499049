import React, { Component } from 'react';
import ReactPropTypes from 'prop-types';
import { inject, observer, PropTypes } from 'mobx-react';
import { confirmYesNo } from 'Components/ConfirmModal';
import { formatDate } from 'Utils/date';

import SidebarLayout from '../SidebarLayout';
import ActionList from 'Components/ActionList';

import { ReactComponent as PencilIcon } from 'Icons/pencil.svg';
import { ReactComponent as CopyIcon } from 'Icons/copy.svg';
import { ReactComponent as TrashIcon } from 'Icons/Buttons/trash.svg';

class Messages extends Component {
  static propTypes = {
    messageType: ReactPropTypes.string.isRequired,
    MyLibraryStore: PropTypes.observableObject,
    MessagesListStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject,
    FavoriteMessagesStore: PropTypes.observableObject
  };

  groups = [
    { title: 'all', count: 2 },
    { title: 'recently added', count: 0 }
  ];

  openBuilder = ({ id }) =>
    this.props.Routing.push(`/builder/from/message/${id}`);

  handlePageChanged = page => {
    this.props.FavoriteMessagesStore.setPage(page);
  };

  handleDelete = async items => {
    if (
      !(await confirmYesNo(
        'Are you sure that you want to delete these messages from "my campaigns"?'
      ))
    ) {
      return;
    }

    this.props.FavoriteMessagesStore.removeFromFavorites(
      items.map(({ id }) => id)
    );
  };

  componentWillMount() {
    const { MyLibraryStore, FavoriteMessagesStore, messageType } = this.props;
    MyLibraryStore.changeOpened('my-library', true);
    MyLibraryStore.setLibraryFolder(
      messageType === 'text' ? 'text-messages' : 'video-messages'
    );
    FavoriteMessagesStore.setType(messageType);
  }

  onSearch = ({ target }) =>
    this.props.FavoriteMessagesStore.setSearchTerm(target.value);

  render() {
    const {
      isLoading,
      messages,
      totalMessages,
      currentPage,
      searchTerm
    } = this.props.FavoriteMessagesStore;

    return (
      <SidebarLayout
        styleType="library"
        searchText="search message"
        searchTerm={searchTerm}
        onSearch={this.onSearch}
      >
        <ActionList
          title={`${this.props.messageType} messages`}
          isLoading={isLoading}
          perPage={10}
          items={messages}
          renderItem={item => (
            <div className="table__video">
              <div className="video-link video-link--size-s">
                <div
                  className="video-link__image"
                  style={
                    item.landingPageImage
                      ? { backgroundImage: `url(${item.landingPageImage})` }
                      : null
                  }
                />
              </div>
              <h3 className="table__video-title">{item.internalTitle}</h3>
              <div
                className="table__video-description"
                style={{ WebkitBoxOrient: 'vertical' }}
              >
                {item.text}
              </div>
            </div>
          )}
          renderItemDate={item => formatDate(item.createdAt)}
          itemsTotal={totalMessages}
          onPageClick={this.handlePageChanged}
          currentPage={currentPage}
          leftColumnName=""
          rightColumnName=""
          actions={[
            {
              onClick: ([message]) => this.openBuilder(message),
              component: (
                <i title="Edit">
                  <PencilIcon />
                </i>
              ),
              onlyPerItem: true
            },
            {
              onClick: items =>
                this.props.MessagesListStore.copySelectedMessages(
                  items[0].id,
                  true
                ),
              component: (
                <i title="Resend">
                  <CopyIcon />
                </i>
              ),
              onlyPerItem: true
            },
            {
              onClick: this.handleDelete,
              component: (
                <i title="Delete from my campaigns">
                  <TrashIcon />
                </i>
              )
            }
          ]}
        />
      </SidebarLayout>
    );
  }
}

export default inject(
  'MyLibraryStore',
  'MessagesListStore',
  'Routing',
  'FavoriteMessagesStore'
)(observer(Messages));
