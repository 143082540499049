import request from './request';

import { dataUriToBlob } from 'Utils/binary';

const generate = (directory, fileName) =>
  request.post('/upload-storage/generate-upload-url', { directory, fileName });

const noop = () => {};

const upload = (url, file, returnUrl, onProgress = noop) =>
  request.raw
    .put(url)
    .send(file)
    .on('progress', onProgress)
    .then(() => returnUrl);

const getUploader = folder => (file, onProgress = noop, fileName) =>
  generate(folder, fileName).then(({ url, cdnUrl }) =>
    upload(url, file, cdnUrl, onProgress)
  );

const uploadImage = getUploader('image');

export default {
  uploadDataUriImage: (dataUri, onProgress = noop, fileName = null) =>
    uploadImage(dataUriToBlob(dataUri), onProgress, fileName),
  uploadVideo: getUploader('video'),
  uploadImage
};
