import React from 'react';
import Button from 'Components/Button';
import { ResponsiveBarChart } from '../Charts';
import { secondaryBarColor } from '../Charts/colors';

const PERFORMANCE_COUNTERS = [
  { parameter: 'viewsCount', label: 'total views' }
  /*{ parameter: 'thumbnailViewsCount', label: 'total impressions' },
  { parameter: 'playsCount', label: 'total plays' }*/
];

const COUNTERS = [
  { parameter: 'location', label: 'top location' },
  { parameter: 'referrers', label: 'top referrer' }
];

class Analytic extends React.Component {
  state = { activeParameter: 'viewsCount' };
  getCountersData() {
    return COUNTERS.map(({ parameter, label }) => {
      const groupData = this.groupParameterData(parameter);
      const topParameter =
        groupData.length > 0 ? groupData[0] : { name: '', value: 0 };
      return { value: topParameter, parameter, label };
    }).filter(record => record.value.value > 0);
  }
  getPerformanceData() {
    return PERFORMANCE_COUNTERS.map(({ parameter, label }) => {
      const value = this.props.report.performance.reduce((result, record) => {
        return result + record[parameter];
      }, 0);
      return { value: { name: value }, parameter, label };
    });
  }
  get counters() {
    return this.getPerformanceData().concat(this.getCountersData());
  }
  groupParameterData = parameter => {
    const rawData = this.props.report[parameter];
    return Object.values(
      rawData.reduce((result, record) => {
        const { viewsCount, uniqueViewsCount, performanceMetric } = record;
        if (!result[performanceMetric]) {
          result[performanceMetric] = {
            name: performanceMetric,
            value: 0,
            uniqueValue: 0
          };
        }
        result[performanceMetric].value += viewsCount;
        result[performanceMetric].uniqueValue += uniqueViewsCount;

        return result;
      }, {})
    )
      .filter(record => record.value > 0)
      .sort((a, b) => {
        if (a.value > b.value) {
          return -1;
        } else if (a.value < b.value) {
          return 1;
        }
        return 0;
      });
  };
  formatImpressionsChartData() {
    return Object.values(
      this.props.report.referrers.reduce((result, record) => {
        const { thumbnailViewsCount, performanceMetric, date } = record;
        if (thumbnailViewsCount === 0) {
          return result;
        }
        if (!result[date]) {
          result[date] = {
            name: date,
            email: 0,
            website: 0
          };
        }
        if (performanceMetric === 'Email, Direct') {
          result[date].email += thumbnailViewsCount;
        } else {
          result[date].website += thumbnailViewsCount;
        }

        return result;
      }, {})
    );
  }
  get chartData() {
    const { activeParameter } = this.state;
    switch (activeParameter) {
      case 'viewsCount':
        return this.props.report.performance
          .map(record => ({
            name: record.date,
            value: record.viewsCount,
            uniqueValue: record.uniqueViewsCount
          }))
          .filter(({ value }) => !!value);

      case 'thumbnailViewsCount':
        return this.formatImpressionsChartData();

      case 'playsCount':
        return this.props.report.performance
          .map(record => ({
            name: record.date,
            value: record.playsCount,
            uniqueValue: record.uniquePlaysCount
          }))
          .filter(({ value }) => !!value);

      case 'location':
      case 'referrers':
        return this.groupParameterData(activeParameter);

      default:
        return [];
    }
  }
  get chartName() {
    return 'Views';
  }
  get groupMode() {
    const { activeParameter } = this.state;
    return ['location', 'referrers'].indexOf(activeParameter) !== -1;
  }
  get barsConfig() {
    const { activeParameter } = this.state;
    if (activeParameter === 'thumbnailViewsCount') {
      return [
        { yAxisKey: 'email', name: 'email' },
        {
          yAxisKey: 'website',
          name: 'website',
          color: secondaryBarColor
        }
      ];
    }
    return [
      { yAxisKey: 'value', name: this.chartName },
      {
        yAxisKey: 'uniqueValue',
        name: 'Unique views',
        color: secondaryBarColor
      }
    ];
  }
  render() {
    const { activeParameter } = this.state;
    return (
      <div className="general-stats">
        <div className="toolbar toolbar--style-reports-stats">
          <div className="cnt">
            <div className="toolbar__content">
              <nav className="toolbar__navigation">
                <ul>
                  {this.counters.map(item => (
                    <li
                      className={`toolbar__stats ${
                        activeParameter === item.parameter ? 'is-active' : ''
                      }`}
                      onClick={() =>
                        this.setState({ activeParameter: item.parameter })
                      }
                      key={item.parameter}
                    >
                      <h3 className="toolbar__stats-title">{item.label}</h3>
                      <div className="toolbar__stats-data">
                        <div
                          className="toolbar__stats-count"
                          title={item.value.name}
                        >
                          {item.parameter === 'ageGroup'
                            ? item.value.name.replace('Age ', '')
                            : item.value.name}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        {this.chartData.length > 0 && (
          <ResponsiveBarChart
            data={this.chartData}
            xAxisKey="name"
            bars={this.barsConfig}
            name={this.chartName}
            groupMode={this.groupMode}
          />
        )}
        <div style={{ textAlign: 'right', marginBottom: 10 }}>
          <Button
            link={this.props.fullReportLink}
            name="Additional Reporting"
          />
        </div>
      </div>
    );
  }
}

export default Analytic;
