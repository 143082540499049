import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import ChatApp from 'Components/ChatApp';

class Chat extends React.Component {
  render() {
    const {
      selectedRespondent,
      sms,
      smsCount,
      loadMoreSms,
      isLoadingSms,
      smsListScrolledDown
    } = this.props.SmsStore;

    return (
      <ChatApp
        respondent={{
          firstName: selectedRespondent.firstName,
          lastName: selectedRespondent.lastName,
          linkedToNumber: selectedRespondent.linkedToNumber
        }}
        messages={sms}
        messagesCount={smsCount}
        loadMore={loadMoreSms}
        isLoading={isLoadingSms}
        sendMessage={text => this.props.SmsStore.sendSms(text)}
        smsListScrolledDown={smsListScrolledDown}
      />
    );
  }
}

Chat.propTypes = {
  SmsStore: PropTypes.observableObject
};

export default inject('SmsStore')(observer(Chat));
