/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { VideoSettings } from 'react-cam-recorder';

class Settings extends Component {
  render() {
    return (
      <VideoSettings
        onClose={() => {}}
        videoSrc={[
          'https://cdn.digitalcare100.info/admin/videos/1d1ce9b2-0f92-49b6-9c35-cf20babdbb74',
          'https://cdn.digitalcare100.info/admin/videos/1d1ce9b2-0f92-49b6-9c35-cf20babdbb74.mp4'
        ]}
        videoTitle="Video title"
        videoCaption="Video caption"
        //videoThumbnail="https://video-react.js.org/assets/poster.png"
        thumbnailMaxSize={1200}
        thumbnailQuality={0.7}
        onSave={data => console.log(data)}
        decoration={{
          logo: {
            url:
              'https://cdn.snapkare-stage.2amigos.us/5cafa532-dc7e-4897-b940-2f4c2ee8756e/image/26e1c162-2ff1-4e98-8abc-6983934ee1f3'
          },
          playButton: true
        }}
      />
    );
  }
}

export default Settings;
