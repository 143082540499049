import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PencilIcon } from 'Icons/pencil.svg';
import { ReactComponent as CopyIcon } from 'Icons/copy.svg';
import { ReactComponent as TrashIcon } from 'Icons/Buttons/trash.svg';
import { ReactComponent as LinkIcon } from 'Icons/get-link.svg';
import { ReactComponent as StarIcon } from 'Icons/star.svg';
import ConfirmModal from 'Components/ConfirmModal';

class Actions extends React.Component {
  state = {};
  executeWithConfirmation = (callback, actionText, confirmationTitle) => {
    return () =>
      this.setState({
        confirmationCallback: () => {
          callback();
          this.closeConfirmationModal();
        },
        actionText,
        confirmationTitle
      });
  };
  closeConfirmationModal = () =>
    this.setState({ confirmationCallback: null, actionText: null });
  render() {
    const {
      selectedMessageId,
      selectedMessageType,
      selectedMessagesCount,
      toggleFavorite,
      onEdit,
      onCopy,
      onDelete,
      resendInsteadOfCopy,
      isFavorite
    } = this.props;
    const { confirmationCallback, actionText, confirmationTitle } = this.state;

    return (
      <React.Fragment>
        <ConfirmModal
          text={
            actionText === false
              ? ''
              : `Are you sure you want to ${actionText}?`
          }
          isActive={!!confirmationCallback}
          onConfirm={confirmationCallback}
          confirmButton="submit"
          title={confirmationTitle}
          onCancel={this.closeConfirmationModal}
        />
        <div className="care__header-actions is-active">
          <div className="toolbar__actions-content">
            {!selectedMessageId && (
              <div className="toolbar__actions-selected">
                <span>{selectedMessagesCount}</span> message(s) selected
              </div>
            )}

            <ul className="actions actions--style-delimeter">
              {(selectedMessagesCount === 1 || selectedMessageId) && (
                <React.Fragment>
                  {selectedMessageId && toggleFavorite && (
                    <li style={isFavorite ? { color: '#12b2d9' } : null}>
                      <button
                        onClick={toggleFavorite}
                        title={
                          isFavorite
                            ? 'Remove this message from "my campaigns"'
                            : 'Add this message to "my campaigns"'
                        }
                      >
                        <StarIcon />
                      </button>
                    </li>
                  )}
                  {onEdit && (
                    <li>
                      <button onClick={onEdit} title="Edit">
                        <PencilIcon />
                      </button>
                    </li>
                  )}
                  {selectedMessageType === 'video' && (
                    <li>
                      <button
                        onClick={() => {
                          const win = window.open(
                            `/message/${selectedMessageId}/preview`,
                            '_blank'
                          );
                          win.focus();
                        }}
                        title="Preview"
                      >
                        <LinkIcon />
                      </button>
                    </li>
                  )}
                </React.Fragment>
              )}
              {(!resendInsteadOfCopy ||
                selectedMessagesCount === 1 ||
                selectedMessageId) && (
                <li>
                  <button
                    onClick={this.executeWithConfirmation(
                      onCopy,
                      resendInsteadOfCopy ? false : 'copy message(s)',
                      resendInsteadOfCopy ? 'choose recipients' : undefined
                    )}
                    title={resendInsteadOfCopy ? 'Resend' : 'Copy'}
                  >
                    <CopyIcon />
                  </button>
                </li>
              )}
              <li>
                <button
                  onClick={this.executeWithConfirmation(
                    onDelete,
                    'delete message(s)'
                  )}
                  title="Delete"
                >
                  <TrashIcon />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Actions.propTypes = {
  selectedMessageId: PropTypes.string,
  selectedMessageType: PropTypes.string,
  selectedMessagesCount: PropTypes.number,
  onEdit: PropTypes.func,
  onCopy: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  resendInsteadOfCopy: PropTypes.bool
};

export default Actions;
