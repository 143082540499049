import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import TimePicker from 'rc-time-picker';
import DatePicker from 'react-datepicker';

import './DateTimePick.scss';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';

class DateTimePick extends Component {
  static propTypes = {
    startTime: PropTypes.any,
    onPick: PropTypes.func
  };

  validationInterval = null;

  state = {
    pickedDate: moment().toDate(),
    pickedTime: this.nearest15MinutesNow,
    timePickerOpened: false
  };

  componentWillMount() {
    this.checkValidationInterval();

    const { startTime } = this.props;

    if (startTime) {
      this.setState({
        pickedDate: startTime.clone().toDate(),
        pickedTime: this.momentToNeareset15Minutes(startTime.clone())
      });
    }
  }

  componentWillUnmount() {
    this.stopCheckingValidationInterval();
  }

  checkValidationInterval() {
    this.stopCheckingValidationInterval();
    this.validationInterval = setInterval(this.validateDateTime, 1000);
  }

  stopCheckingValidationInterval() {
    clearInterval(this.validationInterval);
  }

  get nearest15MinutesNow() {
    return this.momentToNeareset15Minutes(moment());
  }

  momentToNeareset15Minutes(time) {
    const minute = time.minute();
    time.minute(Math.ceil(minute / 15) * 15);
    return time;
  }

  handleDateChanged = pickedDate => {
    this.setState({ pickedDate }, () => this.validateDateTime());
  };

  handleTimePickerOpened = () => {
    this.setState({ timePickerOpened: true });
    this.stopCheckingValidationInterval();
  };

  handleTimePickerClosed = () => {
    this.validateDateTime();
    this.checkValidationInterval();
  };

  get combinedMoment() {
    const { pickedDate } = this.state;
    let { pickedTime } = this.state;

    return moment(pickedDate)
      .clone()
      .hour(pickedTime.hour())
      .minute(pickedTime.minute());
  }

  validateDateTime = () => {
    const { onPick } = this.props;
    let { pickedTime } = this.state;

    const combinedMoment = this.combinedMoment;

    const now = this.nearest15MinutesNow;

    if (!now.isSameOrBefore(combinedMoment)) {
      pickedTime = now;
      combinedMoment.hour(now.hour()).minute(now.minute());
    }

    this.setState(
      {
        timePickerOpened: false,
        pickedTime
      },
      () => onPick && onPick(combinedMoment)
    );
  };

  render() {
    const { timePickerOpened, pickedDate, pickedTime } = this.state;
    return (
      <div className="builder__schedule-form-schedule form form--style-account schedule-date-time-pick">
        <label className="form__label">schedule options</label>
        <div className="form__container date-time-text">
          <DatePicker
            customInput={
              <span className="date-picker-text">
                {moment(pickedDate).format('MMM DD, YYYY')}
              </span>
            }
            minDate={new Date()}
            onChange={this.handleDateChanged}
            selected={pickedDate}
          />
          <span className={timePickerOpened ? 'picker-opened' : ''}>
            <TimePicker
              showSecond={false}
              onOpen={this.handleTimePickerOpened}
              onClose={this.handleTimePickerClosed}
              onChange={pickedTime => this.setState({ pickedTime })}
              popupClassName="schedule-date-time-pick"
              allowEmpty={false}
              minuteStep={15}
              value={pickedTime}
              format="h:mm a"
              use12Hours
            />
          </span>
        </div>
      </div>
    );
  }
}

export default DateTimePick;
