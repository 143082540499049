import React from 'react';
import PropTypes from 'prop-types';
import LineLoader from 'Components/Loader/LineLoader';
import { formatDate } from 'Utils/date';
import { ResponsiveBarChart } from '../Charts';
import { secondaryBarColor } from '../Charts/colors';

const PERFORMANCE_COUNTERS = [
  { parameter: 'viewsCount', label: 'total views' },
  { parameter: 'deliveriesCount', label: 'total sends' }
];

const COUNTERS = [
  { parameter: 'location', label: 'top location' },
  { parameter: 'ageGroup', label: 'top age group' }
];

class GeneralStats extends React.PureComponent {
  state = { activeParameter: 'viewsCount' };
  groupParameterData = parameter => {
    const rawData = this.props.data[parameter];
    return Object.values(
      rawData.reduce((result, record) => {
        const {
          viewsCount,
          uniqueViewsCount,
          externalLinkViewsCount,
          uniqueExternalLinkViewsCount,
          performanceMetric
        } = record;
        if (!result[performanceMetric]) {
          result[performanceMetric] = {
            name: performanceMetric,
            value: 0,
            uniqueValue: 0
          };
        }
        result[performanceMetric].value += viewsCount + externalLinkViewsCount;
        result[performanceMetric].uniqueValue +=
          uniqueViewsCount + uniqueExternalLinkViewsCount;

        return result;
      }, {})
    )
      .filter(record => record.value > 0)
      .sort((a, b) => {
        if (a.value > b.value) {
          return -1;
        } else if (a.value < b.value) {
          return 1;
        }
        return 0;
      });
  };
  getCountersData() {
    return COUNTERS.map(({ parameter, label }) => {
      const groupData = this.groupParameterData(parameter);
      const topParameter =
        groupData.length > 0 ? groupData[0] : { name: '', value: 0 };
      return { value: topParameter, parameter, label };
    }).filter(record => record.value.value > 0);
  }
  getPerformanceData() {
    return PERFORMANCE_COUNTERS.map(({ parameter, label }) => {
      const value = this.props.data.performance.reduce((result, record) => {
        if (parameter !== 'viewsCount') {
          return result + record[parameter];
        }
        return result + record.viewsCount + record.externalLinkViewsCount;
      }, 0);
      return { value: { name: value }, parameter, label };
    });
  }
  getChartData() {
    const { activeParameter } = this.state;
    switch (activeParameter) {
      case 'viewsCount':
        return this.props.data.performance.map(record => ({
          name: formatDate(record.datetime),
          value: record.viewsCount + record.externalLinkViewsCount,
          uniqueValue:
            record.uniqueViewsCount + record.uniqueExternalLinkViewsCount
        }));

      case 'deliveriesCount':
        return this.props.data.performance.map(record => ({
          name: formatDate(record.datetime),
          value: record[activeParameter]
        }));

      case 'ageGroup':
      case 'location':
        return this.groupParameterData(activeParameter);

      default:
        return [];
    }
  }
  getChartName() {
    const { activeParameter } = this.state;
    switch (activeParameter) {
      case 'deliveriesCount':
        return 'Sends';
      default:
        return 'Views';
    }
  }
  getGroupMode() {
    const { activeParameter } = this.state;
    return ['ageGroup', 'location'].indexOf(activeParameter) !== -1;
  }
  getBarsConfig() {
    const bars = [{ yAxisKey: 'value', name: this.getChartName() }];
    const { activeParameter } = this.state;
    if (['deliveriesCount'].indexOf(activeParameter) !== -1) {
      return bars;
    }
    return bars.concat([
      {
        yAxisKey: 'uniqueValue',
        name: 'Unique views',
        color: secondaryBarColor
      }
    ]);
  }
  render() {
    const { isLoading } = this.props.data;
    const { activeParameter } = this.state;
    if (isLoading) {
      return <LineLoader />;
    }
    const counters = this.getPerformanceData().concat(this.getCountersData());
    return (
      <div className="general-stats">
        <div className="toolbar toolbar--style-reports-stats">
          <div className="cnt">
            <div className="toolbar__content">
              <nav className="toolbar__navigation">
                <ul>
                  {counters.map(item => (
                    <li
                      className={`toolbar__stats ${
                        activeParameter === item.parameter ? 'is-active' : ''
                      }`}
                      onClick={() =>
                        this.setState({ activeParameter: item.parameter })
                      }
                      key={item.parameter}
                    >
                      <h3 className="toolbar__stats-title">{item.label}</h3>
                      <div className="toolbar__stats-data">
                        <div
                          className="toolbar__stats-count"
                          title={item.value.name}
                        >
                          {item.parameter === 'ageGroup'
                            ? item.value.name.replace('Age ', '')
                            : item.value.name}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <ResponsiveBarChart
          data={this.getChartData()}
          xAxisKey="name"
          bars={this.getBarsConfig()}
          name={this.getChartName()}
          groupMode={this.getGroupMode()}
        />
      </div>
    );
  }
}

GeneralStats.propTypes = {
  data: PropTypes.object.isRequired
};

export default GeneralStats;
