export const isEmptyObject = object => {
  return Object.keys(object).length === 0 && object.constructor === Object;
};

export const removeEmptyValues = object => {
  return Object.keys(object)
    .filter(f => object[f] && object[f].length > 0)
    .reduce((r, i) => {
      r[i] = object[i];
      return r;
    }, {});
};

export const diff = (obj1, obj2) => {
  const result = {};
  for (let i in obj1) {
    if (obj2[i] !== obj1[i]) {
      result[i] = obj1[i];
    }
  }
  return result;
};
