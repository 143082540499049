import { observable, action, decorate } from 'mobx';

class MyLibraryStore {
  currentFolder = null;
  openedSections = {};

  setLibraryFolder(folderName) {
    if (folderName === null) {
      this.isOpened = false;
      this.currentFolder = null;
      return;
    }

    this.isOpened = true;
    this.currentFolder = folderName;
  }

  changeOpened(sectionName, isOpened) {
    this.openedSections = {
      ...this.openedSections,
      [sectionName]: isOpened
    };
  }
}

const MobxMyLibraryStore = decorate(MyLibraryStore, {
  openedSections: observable,
  currentFolder: observable,
  setLibraryFolder: action,
  changeOpened: action
});

export default new MobxMyLibraryStore();
