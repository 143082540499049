import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import './DatePickerInput.css';
import './ReactDatepicker.css';

import DatePickerInput from './DatePickerInput';
class PickDate extends Component {
  static propTypes = {
    error: PropTypes.string,
    placeholder: PropTypes.string,
    onChangeDate: PropTypes.func,
    inline: PropTypes.bool,
    label: PropTypes.string,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    startDate: PropTypes.string,
    maxDate: PropTypes.object
  };

  constructor(props) {
    super();
    this.state = { startDate: props.startDate };
  }

  handleChange(date) {
    this.setState({
      startDate: new Date(date)
    });
  }

  render() {
    const {
      onChangeDate,
      placeholder,
      error,
      inline,
      label,
      defaultValue,
      disabled,
      maxDate,
      ...rest
    } = this.props;

    return (
      <DatePicker
        className={`date-picker-width ${error ? 'form__container__error' : ''}`}
        onChange={e => {
          onChangeDate(e);
          this.handleChange(e);
        }}
        selected={this.state.startDate}
        showYearDropdown
        showMonthDropdown
        scrollableYearDropdown
        placeholderText={placeholder}
        disabled={disabled}
        yearDropdownItemNumber={80}
        maxDate={maxDate}
        customInput={
          <DatePickerInput
            placeholder={placeholder}
            inline={inline}
            label={label}
            defaultValue={defaultValue}
            error={error}
            disabled={disabled}
          />
        }
        {...rest}
      />
    );
  }
}
export default PickDate;
