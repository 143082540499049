import React, { Component } from 'react';

import { stripHtml } from 'Utils/string';

import { inject, observer, PropTypes } from 'mobx-react';

import Recorder from 'Components/Recorder';

import { MagicLinkApi, MessageApi, VideoRecorderApi } from 'Api';

import { info } from 'Utils/alert';

class Recording extends Component {
  static propTypes = {
    BuilderStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  videoIdChecker = null;
  state = {};

  componentWillMount() {
    this.props.BuilderStore.setStep('videoRecording', true);
    this.videoIdChecker = setInterval(this.checkIfVideoSet, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.videoIdChecker);
  }

  checkIfVideoSet = async () => {
    if (this.isCheckingVideoSet) {
      return;
    }

    this.isCheckingVideoSet = true;

    const messageId = this.props.BuilderStore.getMessageAttribute('id');
    const { recordedVideoUrl } = this.state;

    if (!recordedVideoUrl) {
      const existingVideoId = this.props.BuilderStore.getMessageAttribute(
        'videoId'
      );
      const { videoId, videoUrl } = await MessageApi.getById(messageId);

      if (videoId !== null && videoId !== existingVideoId) {
        await this.props.BuilderStore.setVideo(videoId);
        this.setState({ recordedVideoUrl: videoUrl });
      }
    }

    if (recordedVideoUrl) {
      const processed = await this.checkVideoProcessed(recordedVideoUrl);

      if (processed) {
        info('Video was recorded by another device. Switching to editor.');
        this.goToEditor();
      }
    }

    this.isCheckingVideoSet = false;
  };

  checkVideoProcessed = async url => {
    const path = url
      .split('/')
      .filter(part => !!part)
      .slice(2)
      .join('/');
    return await VideoRecorderApi.checkVideoProcessed(path);
  };

  goToEditor() {
    this.props.Routing.push('/builder/recording/editor');
  }

  uploadVideo = async (video, selectedFrame) => {
    clearInterval(this.videoIdChecker);
    await this.props.BuilderStore.uploadVideo(video, selectedFrame);
    this.goToEditor();
  };

  showClosingDialog = () => this.props.Routing.push('/builder/close-editor');

  sendMagicLink = async phone => {
    const { id: messageId } = this.props.BuilderStore.message;

    MagicLinkApi.sendSMS(
      phone,
      'Use this link for video recording.',
      'record-video-mobile',
      { messageId },
      'recorder'
    );
  };

  getQrCodeData = async () => {
    const { id: messageId } = this.props.BuilderStore.message;
    return await MagicLinkApi.getLink(
      'record-video-mobile',
      { messageId },
      'recorder'
    );
  };

  updateMessageScript = script => {
    this.props.BuilderStore.setMessageAttribute('scriptText', script);
    this.props.BuilderStore.saveMessageData();
  };

  get title() {
    return stripHtml(this.props.BuilderStore.getMessageAttribute('title', ''));
  }

  formatScript = script => script.replace(/\[.+?\]/g, '');

  render() {
    const {
      sendMagicLink: sendLink,
      uploadVideo,
      title,
      getQrCodeData,
      updateMessageScript,
      showClosingDialog,
      formatScript: formatter
    } = this;

    const text = this.props.BuilderStore.getMessageAttribute('scriptText', '');

    return (
      <Recorder
        videoMimeType="video/webm;codecs=vp8,opus"
        script={{ text, title, formatter }}
        onScriptTextChange={updateMessageScript}
        qrCodeData={getQrCodeData}
        onVideoReady={uploadVideo}
        onClose={showClosingDialog}
        mobileRecordSettings={{ sendLink }}
        startTimeout={10}
        autoStopTimeout={1}
      />
    );
  }
}

export default inject('BuilderStore', 'Routing')(observer(Recording));
