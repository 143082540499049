import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import { apiError } from 'Utils/alert';
import { SequencesApi } from 'Api';
import Button from 'Components/Button';
import { inject, observer } from 'mobx-react';
import LineLoader from 'Components/Loader/LineLoader';
import SidebarLayout from '../SidebarLayout';
import Steps from './Steps';
import './index.css';

const ATTRIBUTE_NAME = 'name';
const ATTRIBUTE_STEPS = 'steps';

function SequenceEditor({
  MyLibraryStore,
  Routing,
  match: {
    params: { sequenceId }
  }
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [sequence, setSequence] = useState(null);

  const isLoading = sequenceId && !sequence;

  const loadSequence = async () => {
    const result = await SequencesApi.getById(sequenceId);
    setSequence(result);
  };

  const initialValues = () => {
    if (!sequence) {
      return { [ATTRIBUTE_NAME]: '', [ATTRIBUTE_STEPS]: [] };
    }

    return {
      [ATTRIBUTE_NAME]: sequence.name,
      [ATTRIBUTE_STEPS]: sequence.messages.map(
        ({ interval, step, message }) => {
          return {
            messageId: {
              value: message.id,
              label: message.internalTitle,
              type: message.type,
              landingPageImage: message.landingPageImage
            },
            messageType: message.type,
            interval,
            step
          };
        }
      )
    };
  };
  useEffect(function() {
    MyLibraryStore.setLibraryFolder('');
    MyLibraryStore.changeOpened('sequences', true);
    if (sequenceId) {
      loadSequence();
    }
  }, []);
  return (
    <SidebarLayout
      styleType="categories"
      searchText="search sequence"
      searchTerm={searchTerm}
      onSearch={({ target }) => setSearchTerm(target.value)}
    >
      {isLoading && <LineLoader />}
      {!isLoading && (
        <Formik
          initialValues={initialValues()}
          validate={values => {
            const errors = {};
            if (!values[ATTRIBUTE_NAME]) {
              errors[ATTRIBUTE_NAME] = 'Name is required';
            }
            if (values[ATTRIBUTE_STEPS].length === 0) {
              errors[ATTRIBUTE_STEPS] = 'At least one step is necessary';
            } else {
              values[ATTRIBUTE_STEPS].forEach((step, stepId) => {
                if (errors[ATTRIBUTE_STEPS]) {
                  return;
                }
                if (
                  !step.messageId ||
                  !step.interval ||
                  !step.step ||
                  step.step <= 0
                ) {
                  errors[ATTRIBUTE_STEPS] = `Step ${stepId +
                    1} is not fully configured`;
                }
              });
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const { name, steps } = values;
            const sequenceData = {
              steps: steps.map(({ messageId, interval, step }) => ({
                interval,
                step,
                messageId: messageId.value
              })),
              name
            };
            try {
              await (sequenceId
                ? SequencesApi.update(sequenceId, sequenceData)
                : SequencesApi.create(sequenceData));
              setTimeout(() => Routing.push('/library/sequences'), 100);
            } catch (e) {
              apiError(e);
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldTouched,
            setFieldValue,
            handleSubmit,
            isSubmitting
          }) => {
            const nameError =
              errors[ATTRIBUTE_NAME] &&
              touched[ATTRIBUTE_NAME] &&
              errors[ATTRIBUTE_NAME];
            const stepsError =
              errors[ATTRIBUTE_STEPS] &&
              touched[ATTRIBUTE_STEPS] &&
              errors[ATTRIBUTE_STEPS];
            return (
              <form
                onSubmit={function() {
                  setFieldTouched(ATTRIBUTE_STEPS, true);
                  handleSubmit(...arguments);
                }}
              >
                <div className="library__categories">
                  <div className="library__categories-title">
                    create new sequence
                  </div>

                  <div className="form form--style-account">
                    <label className="form__label">sequence name</label>
                    <div
                      className={`form__container ${
                        nameError ? 'form__container__error' : ''
                      }`}
                    >
                      <input
                        className="form__input"
                        type="text"
                        name={ATTRIBUTE_NAME}
                        placeholder="enter sequence name"
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      {nameError && (
                        <div className="form__note">{nameError}</div>
                      )}
                    </div>
                  </div>

                  <Steps
                    error={stepsError}
                    steps={values[ATTRIBUTE_STEPS]}
                    onChange={function(steps) {
                      setFieldValue(ATTRIBUTE_STEPS, steps);
                    }}
                  />

                  <div>
                    <Button
                      name="Save Sequence"
                      type="submit"
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      )}
    </SidebarLayout>
  );
}

export default withRouter(
  inject(
    'MyLibraryStore',
    'ProfileStore',
    'Routing'
  )(
    observer(function SequencesWrapper(props) {
      return <SequenceEditor {...props} />;
    })
  )
);
