import React, { Component } from 'react';
import Modal from 'Components/Modal';
import InputField from 'Components/InputField';
import Button from 'Components/Button';
import PropTypes from 'prop-types';

class EnterPhoneModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    onSend: PropTypes.func,
    onCancel: PropTypes.func
  };

  state = {
    label: 'Please enter your phone number',
    phoneNumber: '',
    sending: false
  };

  phoneNumberField = {
    attributeName: 'phoneNumber',
    inputType: 'number',
    placeholder: 'phone number',
    changeHandler: ({ target }) => this.setState({ phoneNumber: target.value })
  };

  handlePhoneSend = async () => {
    this.setState({
      sending: true,
      label: 'sending message to this phone number'
    });
    await this.props.onSend(this.state.phoneNumber);
    this.setState({ sending: false, label: 'message sent.' });
  };

  render() {
    const { phoneNumber, sending, label } = this.state;
    const { visible, onCancel } = this.props;
    return (
      <Modal visible={visible} style={{ minHeight: 100 }}>
        <InputField
          {...this.phoneNumberField}
          disabled={sending}
          label={label}
          values={{ phoneNumber }}
        />
        <p>&nbsp;</p>
        <Button
          onClick={this.handlePhoneSend}
          disabled={sending || !phoneNumber}
          name="send test message"
        />
        &nbsp;&nbsp;&nbsp;&nbsp;
        {!sending && (
          <button
            onClick={onCancel}
            className="button button--reset button--link"
          >
            Close
          </button>
        )}
      </Modal>
    );
  }
}

export default EnterPhoneModal;
