import React, { Component } from 'react';
import Modal from 'Components/Modal';
import Button from 'Components/Button';
import PropTypes from 'prop-types';

const legend = [['{{firstName}}', 'Will be replaced with contacts first name']];

class LegendModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func
  };

  render() {
    const { visible, onClose } = this.props;
    return (
      <Modal
        className="legend-modal"
        visible={visible}
        maxWidth={600}
        style={{ minHeight: 100 }}
      >
        Following templates are available which you can use in this text:
        <ul>
          {legend.map(([template, description], key) => (
            <li key={key}>
              <strong>{template}</strong> - {description}
            </li>
          ))}
        </ul>
        <Button name="Close" onClick={onClose} />
      </Modal>
    );
  }
}

export default LegendModal;
