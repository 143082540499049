/* eslint-disable */
const BROWSER_ID_KEY = 'BROWSER__UUID';

const getCookie = name => {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts.length === 2)
    return parts
      .pop()
      .split(';')
      .shift();
};

const setCookie = (name, value) => {
  const [_, ...rest] = location.hostname.split('.');
  const domain =
    location.hostname.toLowerCase() === 'localhost'
      ? location.hostname
      : '.' + rest.join('.');
  document.cookie = name + '=' + (value || '') + `; domain=${domain}; path=/`;
};

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const generateBrowserIdentity = () => {
  if (getCookie(BROWSER_ID_KEY)) {
    return;
  }
  setCookie(BROWSER_ID_KEY, uuidv4());
};

export const getBrowserIdentity = () => getCookie(BROWSER_ID_KEY);
