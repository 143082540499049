/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as FacebookIcon } from 'Icons/Social/facebook.svg';
import { ReactComponent as TwitterIcon } from 'Icons/Social/twitter.svg';
import { ReactComponent as LinkedinIcon } from 'Icons/Social/linkedin.svg';
import { ReactComponent as EmailIcon } from 'Icons/Social/email.svg';
import { ReactComponent as EmbedIcon } from 'Icons/Social/embed.svg';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share';
import EmbedVideoModal from 'Components/EmbedVideoModal';
import { FACEBOOK, TWITTER, LINKEDIN } from 'Consts/socialShare';
import { addQueryParamsToUrl } from 'Utils/url';

class SocialShare extends React.Component {
  state = { showEmbedModal: false };
  get shareOptions() {
    const {
      video: { landingPageUrl: url, title },
      merchantMode
    } = this.props;

    return [
      {
        element: (
          <FacebookShareButton
            url={addQueryParamsToUrl(url, { referrer: FACEBOOK })}
          >
            <FacebookIcon />
          </FacebookShareButton>
        ),
        key: 'facebook'
      },
      {
        element: (
          <TwitterShareButton
            url={addQueryParamsToUrl(url, { referrer: TWITTER })}
            title={title}
          >
            <TwitterIcon />
          </TwitterShareButton>
        ),
        key: 'twitter'
      },
      {
        element: (
          <LinkedinShareButton
            url={addQueryParamsToUrl(url, { referrer: LINKEDIN })}
            title={title}
          >
            <LinkedinIcon />
          </LinkedinShareButton>
        ),
        key: 'linkedin'
      },
      {
        element: (
          <EmailShareButton url={url} subject={title}>
            <EmailIcon />
          </EmailShareButton>
        ),
        key: 'email'
      },
      merchantMode
        ? {
            element: (
              <i title="embed" onClick={this.showEmbedModal}>
                <EmbedIcon />
              </i>
            ),
            key: 'embed'
          }
        : null
    ].filter(val => !!val);
  }

  showEmbedModal = e => {
    e.preventDefault();
    this.setState({ showEmbedModal: true });
  };

  closeEmbedModal = () => this.setState({ showEmbedModal: false });

  render() {
    const { showEmbedModal } = this.state;
    const { video } = this.props;

    return (
      <React.Fragment>
        {showEmbedModal && (
          <EmbedVideoModal
            videoId={video.id}
            videoRecordedBy={video.recordedBy}
            title={video.title}
            embeddableUrl={video.embeddableUrl}
            landingPageUrl={video.landingPageUrl}
            src={[`${video.url}.mp4`, video.url]}
            poster={
              video.thumbnailUrl ? `${video.thumbnailUrl}-decorated` : null
            }
            overlay={video.overlay}
            logo={
              video.merchant.profileImage
                ? { bottom: 50, right: 50, url: video.merchant.profileImage }
                : null
            }
            frameImageSource={video.frameImageSource}
            onClose={this.closeEmbedModal}
          />
        )}
        <div className="message__social">
          {this.shareOptions.map(({ element, key }) => (
            <a key={key} href="#" className="message__social-icon">
              {element}
            </a>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

SocialShare.propTypes = {
  video: PropTypes.object.isRequired
};

export default SocialShare;
