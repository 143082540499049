import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

class Modal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.object
  };

  static defaultProps = {
    visible: true,
    maxWidth: 500,
    style: {}
  };

  render() {
    const { children, visible, maxWidth, style, className } = this.props;

    if (!visible) {
      return null;
    }

    return (
      <div className={`backdrop ${className}`}>
        <div
          className="modalWindow"
          style={{
            maxWidth,
            ...style
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default Modal;
