import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formatDate } from 'Utils/date';
import Button from 'Components/Button';
import LineLoader from 'Components/Loader/LineLoader';

const Blank = () => (
  <div className="settings__billing-history">
    <div className="settings__billing-history-blank" />
    <div className="settings__billing-history-blank" />
    <div className="settings__billing-history-no-data">
      no data currently available
    </div>
  </div>
);

const Payments = ({ isLoading, hasMore, loadMore, payments }) => {
  return (
    <div className="settings__section">
      <div className="settings__billing-history">
        <div className="settings__billing-history-title">
          <div className="settings__billing-history-row">date</div>
          <div className="settings__billing-history-row">invoice</div>
          <div className="settings__billing-history-row">amount</div>
          <div className="settings__billing-history-row">status</div>
        </div>

        {payments
          .filter(payment => payment.amount - payment.amount_refunded > 0)
          .map(payment => (
            <div className="settings__billing-history-item" key={payment.id}>
              <div className="settings__billing-history-row">
                {formatDate(payment.created)}
              </div>
              <div className="settings__billing-history-row">
                <a
                  href={payment.receipt_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {payment.description
                    ? payment.description.replace('Payment for invoice', '')
                    : 'no description'}
                </a>
              </div>
              <div className="settings__billing-history-row">
                ${((payment.amount - payment.amount_refunded) / 100).toFixed(2)}
              </div>
              <div className="settings__billing-history-row">
                {payment.status}
              </div>
            </div>
          ))}
      </div>
      <br />
      {isLoading && <LineLoader />}
      {!isLoading && hasMore && (
        <div>
          <Button name="load more" onClick={loadMore} />
        </div>
      )}
    </div>
  );
};

Payments.propTypes = {
  payments: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func.isRequired
};

const History = ({ isLoading, payments, hasMore, loadMore }) => {
  return (
    <div className="settings__section">
      <h3 className="settings__subtitle" id="billing-history">
        billing history (<Link to="/charges">see charges</Link>)
      </h3>
      {payments.length === 0 ? (
        <Blank />
      ) : (
        <Payments {...{ isLoading, payments, hasMore, loadMore }} />
      )}
    </div>
  );
};

History.propTypes = {
  isLoading: PropTypes.bool,
  payments: PropTypes.array.isRequired,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func.isRequired
};

export default History;
