import React from 'react';
import { PropTypes } from 'prop-types';

import { ReactComponent as VideoFileIcon } from 'Icons/video-file.svg';
import { ReactComponent as TextFileIcon } from 'Icons/text-file.svg';

import './MessageItem.css';

const MessageItem = ({ message, onClick }) => (
  <li className="library__messages-item" onClick={() => onClick(message)}>
    <div
      className="library__messages-item-thumbnail"
      style={{
        backgroundImage: `url('${message.exampleVideoThumbnailUrl ||
          message.imageUrl}')`
      }}
    >
      {message.scriptText ? <VideoFileIcon /> : <TextFileIcon />}
    </div>
    <div className="library__messages-item-text">
      <div className="library__messages-item-title">{message.title}</div>
      <div className="library__messages-item-excerpt">
        {message.description}
      </div>
    </div>
  </li>
);

MessageItem.propTypes = {
  message: PropTypes.object,
  onClick: PropTypes.func
};

export default MessageItem;
