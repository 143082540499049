/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'react-linkify';
import VideoPlayer from 'Components/VideoPlayer';
import { BRAND, PORTAL_HOST } from 'Utils/whitelabeling';
import OldMessage from './OldMessage';
import SocialShare from './SocialShare';
import './index.css';

class LandingPage extends React.Component {
  state = { width: window.innerWidth, height: window.innerHeight };
  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize, false);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }
  handleWindowResize = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };
  handleVideoResize = width => {
    this.setState({ currentVideoWidth: width });
  };
  handleVideoPropsReceived = ({ width, height, ...rest }) => {
    this.setState({ videoFormat: width / height });
  };
  getShareUrl() {
    return `${window.location.origin}/message/${this.props.message.id}`;
  }
  renderVideo(plainVideoMode) {
    const { message, onPlay, onPause } = this.props;
    const {
      videoUrl,
      videoRecordedBy,
      videoThumbnailUrl,
      videoFrameImageSource,
      videoOverlay
    } = message;

    const { currentVideoWidth, videoFormat } = this.state;
    const videoHeight =
      currentVideoWidth && videoFormat ? currentVideoWidth / videoFormat : null;

    const videoContainerStyle = plainVideoMode
      ? {
          width: this.state.width,
          height: this.state.height,
          margin: 0,
          overflow: 'hidden'
        }
      : {
          maxWidth: 560,
          width: '100',
          height: videoHeight || 315
        };
    return videoUrl ? (
      <div className="message__element" style={videoContainerStyle}>
        <VideoPlayer
          recordedBy={videoRecordedBy}
          overlay={videoOverlay ? JSON.parse(videoOverlay) : null}
          videoSrc={[`${videoUrl}.mp4`, videoUrl]}
          onVideoPropsReceived={this.handleVideoPropsReceived}
          onResize={this.handleVideoResize}
          onPlay={onPlay}
          onPause={onPause}
          frameSource={videoFrameImageSource}
          poster={videoThumbnailUrl ? `${videoThumbnailUrl}-decorated` : null}
        />
      </div>
    ) : null;
  }
  render() {
    const { message, history, plainVideoMode } = this.props;
    const { title, landingPageText, merchant = {} } = message;

    const previousMessages = history.filter(
      record => record.messageId !== message.id
    );

    if (plainVideoMode) {
      return this.renderVideo(true);
    }

    return (
      <div className="message">
        <div className="cnt">
          <div className="message__limit">
            <div className="message__element">
              <img
                src={merchant.profileImage}
                className="landing-page-logo"
                alt={merchant.officeName}
              />
            </div>

            <div className="message__element">
              <div className="message__title">
                <Linkify>{title}</Linkify>
              </div>
            </div>

            {this.renderVideo()}

            <div className="message__element">
              <div
                className="message__intro display-linebreak"
                style={{ wordBreak: 'break-word' }}
              >
                <Linkify>{landingPageText}</Linkify>
              </div>
            </div>

            <div className="message__element">
              <SocialShare
                url={this.getShareUrl()}
                title={title}
                onShare={source => this.props.onShare(source)}
              />
            </div>
          </div>

          {previousMessages.length > 0 ? (
            <div className="message__more">
              <div className="message__more-title">more videos</div>

              <div className="message__more-container">
                {previousMessages.map((previousMessage, i) => {
                  return (
                    <React.Fragment key={previousMessage.messageId}>
                      <OldMessage
                        message={previousMessage.message}
                        customerId={previousMessage.customerId}
                      />
                      {i === 1 && previousMessages.length > 4 && (
                        <div className="flex-break" />
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="message__more" />
          )}

          <div className="message__footer">
            <div className="message__footer-left">
              <div>
                © {merchant.officeName} | All Rights Reserved,{' '}
                {new Date().getFullYear()}
              </div>
              <a href="">Privacy Policy</a>
              <a href="">Terms and Conditions</a>
            </div>
            <div className="message__footer-right">
              <a href={PORTAL_HOST} style={{ color: '#bdc3cf' }}>
                powered by {BRAND}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LandingPage.propTypes = {
  message: PropTypes.object,
  history: PropTypes.array,
  plainVideoMode: PropTypes.bool,
  onShare: PropTypes.func,
  onPlay: PropTypes.func,
  onPause: PropTypes.func
};

export default LandingPage;
