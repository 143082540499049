// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Logo from 'Components/layout/Logo';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.any
  };
  render() {
    return (
      <div className="account account--style-sign-in">
        <div className="cnt">
          <div className="account__header">
            <h1 className="account__header-logo">
              <Link to="/">
                <Logo />
              </Link>
            </h1>

            <div className="account__header-menu">
              <Link to="/">Plans &amp; Pricing</Link>
            </div>

            <div className="account__header-alternative">
              Don't have an account?
              <Link to="/">Sign up</Link>
            </div>
          </div>
        </div>
        <div className="account__content">{this.props.children}</div>
      </div>
    );
  }
}

export default Layout;
