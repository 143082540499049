const moment = require('moment');

export const formatDate = dateTime => {
  const time = initializeMoment(dateTime).local();
  return time.format('L');
};

export const formatCalendar = dateTime => {
  const time = initializeMoment(dateTime).local();
  return time.calendar();
};

export const formatDateTime = (dateTime, offsetMode = true) => {
  const time = initializeMoment(dateTime).local();
  const duration = moment.duration(new moment().diff(time));
  return duration.asDays() < 7 && offsetMode
    ? time.startOf('second').fromNow()
    : time.format('lll');
};

const initializeMoment = dateTime => {
  return typeof dateTime === 'number'
    ? moment.unix(dateTime)
    : moment.utc(dateTime);
};
