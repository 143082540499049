import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class Button extends Component {
  static propTypes = {
    name: PropTypes.string,
    width: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    link: PropTypes.string
  };

  static defaultProps = {
    type: 'button',
    size: 'm',
    color: 'blue',
    width: 'm',
    disabled: false
  };

  render() {
    const {
      type,
      onClick,
      size,
      color,
      width,
      disabled,
      link,
      ...other
    } = this.props;
    const props = {
      className: `button button--style-default button--size-${size} button--color-${color}-ribbon button--width-${width} ${disabled &&
        'is-disabled'}`,
      type,
      onClick,
      disabled,
      ...other
    };
    return link ? (
      <Link to={link} {...props}>
        {this.props.name}
      </Link>
    ) : (
      <button {...props}>{this.props.name}</button>
    );
  }
}
