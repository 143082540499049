import React from 'react';
import ContentLoader from 'react-content-loader';

export default () => (
  <div className="library__video">
    <div className="library__video-container">
      <div className="video-link video-link--size-m">
        <div className="video-link__check" />
        <div>
          <ContentLoader
            height={250}
            speed={1}
            primaryColor={'#888'}
            secondaryColor={'#EEE'}
          >
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="250" />
          </ContentLoader>
        </div>

        <div className="video-link__title">
          <span className="video-link__name">-</span>

          <div className="video-link__actions">
            <ul className="actions actions--style-delimeter" />
          </div>
        </div>
      </div>
    </div>
  </div>
);
