import React from 'react';
import { withRouter } from 'react-router-dom';
import Loader from 'Components/Loader/FacebookLoader';
import { VideosApi } from 'Api';
import './CompiledVideoStylesheet.css';
import Hourglass from 'Images/hourglass.gif';

class CompiledVideo extends React.Component {
  state = { isLoading: true };
  tryToRedirectToCompiledVideo = async id => {
    const { url } = await VideosApi.getCompiledVideoUrl(id);
    if (url) {
      window.localStorage.removeItem(`vc-started-${id}`);
      window.location.href = url;
      return true;
    }

    return false;
  };
  initiateVideoCompilation = async id => {
    const asyncStorageKey = `vc-started-${id}`;
    const value = window.localStorage.getItem(asyncStorageKey);
    if (value) {
      return;
    }
    await VideosApi.compile(id);
    window.localStorage.setItem(asyncStorageKey, true);
  };
  async componentWillMount() {
    const {
      match: {
        params: { videoId }
      }
    } = this.props;
    const isVideoAlreadyCompiled = await this.tryToRedirectToCompiledVideo(
      videoId
    );
    if (isVideoAlreadyCompiled) {
      return;
    }

    this.setState({ isLoading: false });

    let isTrying = false;
    setInterval(async () => {
      if (isTrying) {
        return;
      }
      isTrying = true;
      await this.tryToRedirectToCompiledVideo(videoId);
      isTrying = false;
    }, 2000);

    await this.initiateVideoCompilation(videoId);
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return <Loader />;
    }
    return (
      <div className="container">
        <div className="content">
          Please wait while your video is being downloaded. It may take up to 5
          minutes to compile your video with graphics, text, and the video
          frame.
          <div
            className="image"
            style={{ backgroundImage: `url(${Hourglass})` }}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(CompiledVideo);
