export function partitionBy(items, byCheck) {
  return items.reduce(
    (acc, item) => {
      const [listOne, listTwo] = acc;

      if (byCheck(item)) {
        listOne.push(item);
      } else {
        listTwo.push(item);
      }

      return acc;
    },
    [[], []]
  );
}

export function differenceBy(byCheck, ...allArrays) {
  return allArrays
    .map((array, arrayIndex) => {
      const otherArrays = allArrays.filter(arr => arr !== array);

      return array.filter(item => {
        for (let i = 0; i < otherArrays.length; i++) {
          if (!byCheck(otherArrays[i], item, arrayIndex)) {
            return false;
          }
        }

        return true;
      });
    })
    .reduce((finalArray, array) => finalArray.concat(array), []);
}

export function differenceByItem(...allArrays) {
  return differenceBy((array, item) => !array.includes(item), ...allArrays);
}

export const toggleArrayItem = (items, item) => {
  if (items.indexOf(item) !== -1) {
    return items.filter(i => i !== item);
  }

  return items.concat([item]);
};

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const unique = arr => arr.filter(onlyUnique);
