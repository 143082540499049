import React from 'react';
import Recorder from './Recorder';
import Editor from './Editor';
import Settings from './Settings';

const MobileRecorder = props => <Recorder {...{ ...props, mobile: true }} />;

export default {
  base: '/video',
  merge: {
    activePage: 'video-recorder',
    exact: true
  },
  items: [
    { path: '/record', component: Recorder },
    { path: '/record/:templateId', component: Recorder },
    { path: '/record-mobile', component: MobileRecorder },
    { path: '/record-mobile/:templateId', component: MobileRecorder },
    { path: '/editor/:videoId', component: Editor },
    { path: '/settings/:videoId', component: Settings }
  ]
};
