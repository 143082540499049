/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer, PropTypes } from 'mobx-react';
import { ReactComponent as CloseIcon } from 'Icons/close.svg';
import { ReactComponent as TagIcon } from 'Icons/Buttons/tags.svg';
import { ReactComponent as TrashIcon } from 'Icons/Buttons/trash.svg';
import { ReactComponent as SearchIcon } from 'Icons/Buttons/search.svg';
import { ReactComponent as NewIcon } from 'Icons/new.svg';
import { CustomerApi } from 'Api';
import { confirmYesNo } from 'Components/ConfirmModal';
import { TAB_INVALID_CONTACTS } from './const';
import Modal from 'Components/Modal';

class CustomerToolbar extends Component {
  static propTypes = {
    CustomerStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  state = {};

  handleTabChange = tab => this.props.CustomerStore.setCurrentTab(tab);

  get customers() {
    const {
      currentTab,
      customers,
      invalidCustomers: { allInvalidCustomers: invalidCustomers }
    } = this.props.CustomerStore;

    switch (currentTab) {
      case TAB_INVALID_CONTACTS:
        return invalidCustomers;
      default:
        return customers;
    }
  }

  viewSelectedCustomer = () => {
    const { selectedCustomers, setSelectedCustomer } = this.props.CustomerStore;
    if (selectedCustomers.length !== 1) {
      return;
    }
    const selectedCustomer = this.customers.find(
      customer => customer.id === selectedCustomers[0]
    );
    setSelectedCustomer(Object.assign({}, selectedCustomer));
  };

  deleteSelectedCustomers = async () => {
    if (
      !(await confirmYesNo(
        'Are you sure that you want to delete selected contacts?'
      ))
    ) {
      return;
    }
    this.props.CustomerStore.deleteSelectedCustomers();
  };

  truncateCustomersList = async () => {
    if (
      !(await confirmYesNo(
        'By clicking submit, you will be permanently deleting all your contacts.  Are you sure you want to proceed?'
      ))
    ) {
      return;
    }

    this.setState({ customerListTruncated: true });
    return CustomerApi.truncate();
  };

  render() {
    const { CustomerStore } = this.props;
    const {
      totalCustomers,
      currentTab,
      importId,
      searchTerm,
      filterTag,
      selectedCustomers,
      toggleBatchTaggingToolbar,
      invalidCustomers: { total: totalInvalidCustomers },
      tags: { total: totalTags }
    } = CustomerStore;

    const tabName = searchTerm ? `search result for "${searchTerm}"` : 'all';
    return (
      <>
        <div className="toolbar toolbar--style-people">
          <div className="cnt">
            <div className="toolbar__content">
              <Link to="/people/add" className="toolbar__new">
                <NewIcon />
                <span>add contacts</span>
              </Link>

              <nav className="toolbar__navigation">
                <ul>
                  {importId !== null && (
                    <li>
                      <a
                        href="javascript:void(0)"
                        onClick={() => this.props.Routing.push('/people')}
                      >
                        show all contacts
                      </a>
                    </li>
                  )}
                  {importId === null && (
                    <li className={currentTab === 'all' ? 'is-active' : ''}>
                      <a
                        href="javascript:void(0)"
                        onClick={() => this.handleTabChange('all')}
                      >
                        {tabName} ({totalCustomers})
                      </a>
                    </li>
                  )}
                  {importId === null && (
                    <li
                      className={currentTab === 'segments' ? 'is-active' : ''}
                    >
                      <a
                        href="javascript:void(0)"
                        onClick={() => this.handleTabChange('segments')}
                      >
                        groups ({totalTags})
                      </a>
                    </li>
                  )}
                  {importId === null && (
                    <li
                      className={
                        currentTab === TAB_INVALID_CONTACTS ? 'is-active' : ''
                      }
                    >
                      <a
                        href="javascript:void(0)"
                        onClick={() =>
                          this.handleTabChange(TAB_INVALID_CONTACTS)
                        }
                      >
                        invalid phone ({totalInvalidCustomers})
                      </a>
                    </li>
                  )}
                </ul>
              </nav>

              {selectedCustomers.length > 0 && (
                <div className="toolbar__actions is-active">
                  <span
                    style={{
                      display: 'inline-block',
                      marginRight: 10,
                      cursor: 'pointer'
                    }}
                  >
                    <CloseIcon onClick={CustomerStore.resetSelectedCustomers} />
                  </span>
                  <div className="toolbar__actions-selected">
                    <span>{selectedCustomers.length}</span> selected
                  </div>
                  <ul className="actions actions--style-delimeter">
                    {selectedCustomers.length === 1 && (
                      <li>
                        <button
                          onClick={this.viewSelectedCustomer}
                          title="Profile"
                        >
                          <SearchIcon />
                        </button>
                      </li>
                    )}
                    {currentTab !== TAB_INVALID_CONTACTS && (
                      <li>
                        <button
                          onClick={toggleBatchTaggingToolbar}
                          title="Add to group"
                        >
                          <TagIcon />
                        </button>
                      </li>
                    )}
                    <li>
                      <button
                        onClick={this.deleteSelectedCustomers}
                        title="Delete"
                      >
                        <TrashIcon />
                      </button>
                    </li>
                  </ul>
                </div>
              )}

              {filterTag && (
                <div className="toolbar__actions is-active">
                  <div className="toolbar__actions-selected">
                    <span>{filterTag}</span> <CloseIcon />
                  </div>

                  <ul className="actions actions--style-delimeter">
                    <li>
                      <button
                        style={{ fontSize: 14 }}
                        onClick={() => this.props.Routing.push('/people')}
                      >
                        <CloseIcon />
                      </button>
                    </li>
                  </ul>
                </div>
              )}

              {!filterTag && selectedCustomers.length === 0 && (
                <button
                  className="button button--style-default button--size-m button--color-blaze-orange button--width-m"
                  type="button"
                  name="DELETE ALL CONTACTS"
                  onClick={this.truncateCustomersList}
                >
                  DELETE ALL CONTACTS
                </button>
              )}
            </div>
          </div>
        </div>
        {this.state.customerListTruncated && (
          <Modal visible={true} className="modal--type-why-mobile-number">
            <div className="modal__text">
              <p>
                Please wait a bit. The time depends on the number of customers.
              </p>
            </div>
          </Modal>
        )}
      </>
    );
  }
}

export default inject('CustomerStore', 'Routing')(observer(CustomerToolbar));
