/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import VideoEditorComponent from 'Components/VideoEditor';
import { GraphicAssetsApi, MerchantAssetsApi, UploadApi } from 'Api';

class Settings extends Component {
  loadUploads = async (offset, pageSize) => {
    const page = Math.floor(offset / pageSize);
    const { data } = await MerchantAssetsApi.list(page, pageSize);
    return data.map(record => ({ title: '', url: record.url }));
  };
  uploadAssets = async assets => {
    const url = await UploadApi.uploadImage(assets[0]);
    await MerchantAssetsApi.create(url);
  };
  deleteAsset = async asset => await MerchantAssetsApi.delete(asset.url);
  loadAssets = async (offset, pageSize, filters) => {
    if (filters && filters.find(filter => filter.filter === 'uploads')) {
      return this.loadUploads(offset, pageSize);
    }
    const resolvedFilters = (filters || [])
      .map(({ filter, value }) => {
        return [filter, value];
      })
      .reduce(
        (prev, [filter, value]) => {
          prev[filter] = value;
          return prev;
        },
        { type: 'overlay' }
      );

    const page = Math.floor(offset / pageSize);

    const { data } = await GraphicAssetsApi.find(
      resolvedFilters,
      page,
      pageSize
    );
    return data.map(record => ({ title: '', url: record.url }));
  };
  render() {
    return (
      <VideoEditorComponent
        onClose={() => {}}
        videoSrc={[
          'https://cdn.digitalcare100.info/fc14d4e7-01d5-4447-83ea-37ddefc73554/videos/a694671d-5ff6-46c9-af1c-5af36563bd26.mp4',
          'https://cdn.digitalcare100.info/fc14d4e7-01d5-4447-83ea-37ddefc73554/videos/a694671d-5ff6-46c9-af1c-5af36563bd26'
        ]}
        scriptText="test script"
        assetsSource={this.loadAssets}
        assetsFilters={[
          {
            title: 'treatment',
            filter: 'type',
            value: 'treatment'
          },
          {
            title: 'my uploads',
            filter: 'uploads',
            value: 1,
            uploadAssets: this.uploadAssets,
            deleteAsset: this.deleteAsset
          }
        ]}
        availableFonts={[
          'coming soon',
          'arial',
          'times new roman',
          'Oranienbaum',
          'Butler',
          'Bitter',
          'koliko',
          '20 db',
          'De La Fuente'
          /*'montserrat',
          'oswald',
          'short stack',
          'passion one',
          'comfortaa',
          'courier new',
          'sans serif',
          'avenir',
          'railway'*/
        ]}
        overlay={{ version: '2.7.0', objects: [] }}
        onSave={() => {}}
        enableWindowsChromeWorkaround={true}
      />
    );
  }
}

export default Settings;
