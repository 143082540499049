import React, { Component } from 'react';
import Header from '../Header';
import { inject, observer, PropTypes } from 'mobx-react';

import { ReactComponent as WebBrowserIcon } from 'Icons/web-browser.svg';
import { ReactComponent as PhoneIcon } from 'Icons/phone.svg';

import BrowserPreview from './BrowserPreview';
import PhonePreview from './PhonePreview';
import LandingPage from 'Components/LandingPage';

class Landing extends Component {
  static propTypes = {
    BuilderStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject,
    ProfileStore: PropTypes.observableObject
  };

  state = {
    Preview: BrowserPreview,
    title: '',
    text: ''
  };

  handleTextChange = text => {
    this.setState({ text });
    this.props.BuilderStore.setMessageAttribute('landingPageText', text);
  };

  handleTitleChange = title => {
    this.setState({ title });
    this.props.BuilderStore.setMessageAttribute('title', title);
  };

  goToEditorStep = () => this.props.Routing.push('/builder/recording/editor');

  goToRecordingStep = () =>
    this.props.Routing.push('/builder/recording/record');

  componentWillMount() {
    this.props.BuilderStore.setStep('landing', true);
    this.setState({
      title: this.props.BuilderStore.getMessageAttribute('title', ''),
      text: this.props.BuilderStore.getMessageAttribute('landingPageText', '')
    });
  }

  get videoUrl() {
    return this.props.BuilderStore.getVideoAttribute('url', '');
  }

  get videoThumbnailUrl() {
    const url = this.props.BuilderStore.getVideoAttribute('thumbnailUrl', '');
    return url ? `${url}-decorated` : null;
  }

  get videoOverlay() {
    return this.props.BuilderStore.getVideoAttribute('overlay', '');
  }

  get caption() {
    return this.props.BuilderStore.getVideoAttribute('caption', '');
  }

  get frameImageSource() {
    return this.props.BuilderStore.getVideoAttribute('frameImageSource', '');
  }

  get isVideoInCuratedList() {
    const value = parseInt(
      this.props.BuilderStore.getVideoAttribute('isInCuratedList', '0')
    );
    return value > 0;
  }

  get videoRecordedBy() {
    return this.props.BuilderStore.getVideoAttribute('recordedBy', '');
  }

  render() {
    const { profile_image } = this.props.ProfileStore.currentProfile;
    const {
      videoUrl,
      videoOverlay,
      videoThumbnailUrl,
      frameImageSource
    } = this;
    const { title, text, Preview } = this.state;

    return (
      <div className="builder builder--style-preview">
        <Header />
        <div className="cnt">
          <div className="builder__content">
            <div className="builder__preview">
              <Preview>
                <LandingPage
                  logo={profile_image}
                  onTitleChange={this.handleTitleChange}
                  onTextChange={this.handleTextChange}
                  title={title}
                  landingText={text}
                  videoRecordedBy={this.videoRecordedBy}
                  videoUrl={videoUrl}
                  videoThumbnailUrl={videoThumbnailUrl}
                  videoOverlay={videoOverlay}
                  frameSource={frameImageSource}
                  onEdit={this.goToEditorStep}
                  onReRecord={this.goToRecordingStep}
                  videoFromCuratedList={this.isVideoInCuratedList}
                />
              </Preview>
            </div>

            <div className="builder__sidebar">
              <div className="builder__sidebar-wrapper">
                Preview device
                <div className="builder__sidebar-devices">
                  <button
                    onClick={() => this.setState({ Preview: BrowserPreview })}
                    className={`builder__sidebar-device builder__sidebar-device--desktop button button--reset ${
                      Preview === BrowserPreview ? 'is-active' : ''
                    }`}
                  >
                    <WebBrowserIcon />
                  </button>
                  <button
                    onClick={() => this.setState({ Preview: PhonePreview })}
                    className={`builder__sidebar-device builder__sidebar-device--mobile button button--reset  ${
                      Preview === PhonePreview ? 'is-active' : ''
                    }`}
                  >
                    <PhoneIcon />
                  </button>
                </div>
              </div>

              <div className="builder__sidebar-info">
                You’re editing the landing page your audience will receive.
                Click on the text to edit the content.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject(
  'BuilderStore',
  'Routing',
  'ProfileStore'
)(observer(Landing));
