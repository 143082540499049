export default [
  '#75d0e6',
  '#50d4d9',
  '#41d6be',
  '#59d59a',
  '#7fd16f',
  '#a8c843',
  '#d3bb16',
  '#ffa600'
];

export const mainBarColor = '#75D0E6';
export const secondaryBarColor = '#0B59F0';
