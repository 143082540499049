import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'Utils/date';
import { ResponsiveBarChart, PieChart } from '../Charts';
import Customers from './Customers';
import Toolbar from './VideoStatsToolbar';
import { ReactComponent as VideoFileIcon } from 'Icons/video-file.svg';
import { secondaryBarColor } from '../Charts/colors';

const STATS_TAB = 'stats';
const CUSTOMERS_TAB = 'customers';

const TABS = [
  { name: 'stats', value: STATS_TAB },
  { name: 'contacts', value: CUSTOMERS_TAB },
];

class VideoStats extends React.PureComponent {
  state = { activeTab: 'stats' };
  videoPerformanceChartData() {
    return this.props.video.performance
      .map((record) => {
        const views = record.viewsCount;
        return views > 0 ? { ...record, date: formatDate(record.datetime) } : 0;
      })
      .filter((val) => !!val);
  }
  videoSendsChartData() {
    return this.props.video.performance
      .map((record) => {
        return record.sendsCount
          ? {
              date: formatDate(record.datetime),
              sends: record.sendsCount,
            }
          : null;
      })
      .filter((val) => !!val);
  }
  videoOpensChartData() {
    return this.props.video.performance
      .map((record) => {
        return record.thumbnailViewsCount
          ? {
              date: formatDate(record.datetime),
              opens: record.thumbnailViewsCount,
            }
          : null;
      })
      .filter((val) => !!val);
  }
  makePieChartData = (parameter) => {
    const rawData = this.props.video[parameter];
    return Object.values(
      rawData.reduce((result, record) => {
        const { viewsCount, performanceMetric } = record;
        if (!result[performanceMetric]) {
          result[performanceMetric] = { name: performanceMetric, value: 0 };
        }
        result[performanceMetric].value += viewsCount;

        return result;
      }, {})
    ).filter((record) => record.value > 0);
  };
  render() {
    const { video: selectedVideo } = this.props;
    const { activeTab } = this.state;
    const videoPerformanceData = this.videoPerformanceChartData();

    return (
      <section>
        <div className="library__preview-title">
          <VideoFileIcon />
          &nbsp;
          {[
            selectedVideo.video.title,
            formatDate(selectedVideo.video.datetime),
          ].join(' - ')}
        </div>
        <Toolbar
          tabs={TABS}
          activeTab={activeTab}
          selectTab={(tab) => this.setState({ activeTab: tab })}
        />
        <div style={{ display: activeTab === STATS_TAB ? 'block' : 'none' }}>
          {videoPerformanceData.length > 0 && (
            <ResponsiveBarChart
              data={videoPerformanceData}
              xAxisKey="date"
              bars={[
                { yAxisKey: 'viewsCount', name: 'Views' },
                {
                  yAxisKey: 'uniqueViewsCount',
                  name: 'Unique views',
                  color: secondaryBarColor,
                },
              ]}
              name="Views"
            />
          )}
          {[
            { value: 'gender', title: 'Gender' },
            { value: 'ageGroup', title: 'Age' },
            { value: 'location', title: 'Location' },
          ].map(({ value, title }) => {
            const data = this.makePieChartData(value);
            return (
              data.length > 0 && (
                <PieChart key={value} data={data} title={title} />
              )
            );
          })}
        </div>
        <div
          style={{ display: activeTab === CUSTOMERS_TAB ? 'block' : 'none' }}
        >
          <Customers customers={selectedVideo.customers} />
        </div>
      </section>
    );
  }
}

VideoStats.propTypes = {
  video: PropTypes.object.isRequired,
};

export default VideoStats;
