import React from 'react';
import PropTypes from 'prop-types';
import { BRAND } from 'Utils/whitelabeling';
import LineLoader from 'Components/Loader/LineLoader';

class Balance extends React.Component {
  constructor() {
    super();
    this.state = { amountToAdd: 50 };
  }
  addFunds = e => {
    e.preventDefault();
    const { addFunds } = this.props;
    const { amountToAdd } = this.state;
    if (amountToAdd > 0) {
      addFunds(amountToAdd);
    }
  };
  render() {
    const { addFunds, balance, isAddingFunds, pricingSettings } = this.props;
    const { amountToAdd } = this.state;

    return (
      <div className="settings__section" style={{ fontSize: '1.1em' }}>
        <p>Your current balance: ${(balance / 100).toFixed(2)}</p>
        <br />
        {pricingSettings ? (
          <section>
            {BRAND} charges $0.025 per text message.
            {`If the balance falls below $${pricingSettings.minimumBalance.toFixed(
              2
            )}, your account will be re-charged to $${pricingSettings.fullBalance.toFixed(
              2
            )}`}
          </section>
        ) : (
          <LineLoader />
        )}
        {addFunds && (
          <form
            className="form form--style-account"
            onSubmit={this.addFunds}
            style={{ marginTop: 20 }}
          >
            <label className="form__label">Add funds</label>
            <div className="form__container">
              <input
                type="number"
                className="form__input"
                placeholder="amount"
                value={amountToAdd}
                disabled={isAddingFunds}
                onChange={e => this.setState({ amountToAdd: e.target.value })}
              />
              <button
                type="submit"
                disabled={isAddingFunds}
                className="form__button button button--style-default button--color-blue-ribbon button--size-s"
              >
                add funds
              </button>
            </div>
          </form>
        )}
      </div>
    );
  }
}

Balance.propTypes = {
  addFunds: PropTypes.func,
  isAddingFunds: PropTypes.bool,
  pricingSettings: PropTypes.object,
  balance: PropTypes.number.isRequired
};

export default Balance;
