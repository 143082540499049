import Messages from './Messages';

export default {
  base: '/testing',
  merge: {
    activePage: 'library',
    exact: true
  },
  items: [{ path: '/messages', component: Messages }]
};
