import React from 'react';
import FacebookLoader from 'Components/Loader/FacebookLoader';

export default () => (
  <div className="care">
    <div className="cnt">
      <div className="care__list">
        <FacebookLoader />
      </div>
    </div>
  </div>
);
