import { observable, action, flow, decorate, reaction } from 'mobx';
import { apiError } from 'Utils/alert';
import { CustomerApi } from 'Api';

class AudienceStore {
  isLoadingCustomers = false;
  isLoadingSegments = false;
  loadMoreCustomers = true;
  customerPage = 0;
  segmentsPage = 0;
  peopleCount = 0;
  totalPeopleCount = 0;
  search = '';
  segments = [];
  selectedCustomers = [];
  selectedSegments = [];
  customers = [];

  constructor() {
    reaction(
      () => this.search,
      () => {
        this.customers = [];
        this.customerPage = 0;
        this.loadCustomers();
      },
      { delay: 500 }
    );
    reaction(
      () => this.selectedCustomers.length,
      () => {
        this.peopleCount = this.selectedCustomers.length;
      }
    );
  }

  reset() {
    this.customerPage = 0;
    this.segmentsPage = 0;
    this.peopleCount = 0;
    this.search = '';
    this.selectedCustomers = [];
    this.selectedSegments = [];
    this.customers = [];
    this.segments = [];
  }

  reloadData = flow(function*() {
    this.loadMoreCustomers = false;
    this.customerPage = 0;
    this.segmentsPage = 0;
    this.search = '';
    this.customers = [];
    this.segments = [];

    try {
      yield Promise.all([
        this.loadCustomers(),
        this.loadSegments(),
        this.loadTotalCustomersCount()
      ]);
      this.loadMoreCustomers = true;
    } catch (e) {
      apiError(e);
    }
  });

  setupSelected = (selectedCustomers, selectedSegments) => {
    this.reset();
    this.selectedCustomers = selectedCustomers;
    this.selectedSegments = selectedSegments;
    //await this.recalculatePeopleCount();
  };

  loadCustomers = flow(function*() {
    this.isLoadingCustomers = true;
    try {
      const { data } = yield CustomerApi.get(
        this.customerPage,
        20,
        null,
        this.search
      );

      this.customers = this.customers.concat(data);

      this.loadMoreCustomers = data.length > 0;
    } catch (e) {
      apiError(e);
    }

    this.isLoadingCustomers = false;
  });

  loadTotalCustomersCount = flow(function*() {
    try {
      const { count } = yield CustomerApi.get(0, 0);

      this.totalPeopleCount = count;
    } catch (e) {
      apiError(e);
    }
  });

  loadMore = flow(function*() {
    this.customerPage++;
    yield this.loadCustomers();
  });

  loadSegments = flow(function*() {
    this.isLoadingSegments = true;
    this.segments = [];
    try {
      const { data } = yield CustomerApi.getTags(
        this.segmentsPage,
        20,
        null,
        this.search
      );

      this.segments = data;
    } catch (e) {
      apiError(e);
    }

    this.isLoadingSegments = false;
  });

  recalculatePeopleCount = flow(function*() {
    this.peopleCount = '-';
    try {
      const selectedCustomers = yield CustomerApi.calculateSelected(
        this.selectedCustomers.map(({ id }) => id),
        this.selectedSegments.map(({ tag }) => tag)
      );
      this.selectedCustomers.replace(selectedCustomers);
      this.peopleCount = this.selectedCustomers.length;
    } catch (e) {
      apiError(e);
    }

    this.isLoadingSegments = false;
  });

  toggleSelectedSegment(segment) {
    const segmentIndex = this.selectedSegments.findIndex(
      vsSegment => vsSegment.tag === segment.tag
    );

    if (segmentIndex === -1) {
      this.selectedSegments.push(segment);
    } else {
      //this.selectedSegments.splice(segmentIndex, 1);
      if (segment.tag === 'All') {
        this.selectedSegments.clear();
        this.selectedCustomers.clear();
      } else {
        const unselectedTags = this.selectedCustomers.reduce(
          (result, customer) => {
            if (!customer.tags.includes(segment.tag)) {
              return result;
            }

            return result.concat(customer.tags);
          },
          []
        );
        this.selectedSegments = this.selectedSegments.filter(
          segment =>
            segment.tag !== 'All' && !unselectedTags.includes(segment.tag)
        );
        this.selectedCustomers = this.selectedCustomers.filter(
          customer => !customer.tags.includes(segment.tag)
        );
      }
    }

    this.recalculatePeopleCount();
  }

  toggleSelectedCustomer(customer) {
    const customerIndex = this.selectedCustomers.findIndex(
      vsCustomer => vsCustomer.id === customer.id
    );

    if (customerIndex === -1) {
      this.selectedCustomers.push(customer);
    } else {
      this.selectedCustomers.splice(customerIndex, 1);

      this.selectedSegments = this.selectedSegments.filter(segment => {
        if (segment.tag === 'All') {
          return false;
        }

        return !customer.tags.includes(segment.tag);
      });
    }

    //this.recalculatePeopleCount();
  }

  isSegmentSelected({ tag: vsTag }) {
    return this.selectedSegments.find(({ tag }) => vsTag === tag) !== undefined;
  }

  isCustomerSelected({ id: vsId }) {
    return this.selectedCustomers.find(({ id }) => vsId === id) !== undefined;
  }

  setSearch(search) {
    this.search = search;
  }
}

const MobxAudienceStore = decorate(AudienceStore, {
  isLoadingCustomers: observable,
  isLoadingSegments: observable,
  loadMoreCustomers: observable,
  customerPage: observable,
  segmentsPage: observable,
  peopleCount: observable,
  totalPeopleCount: observable,
  search: observable,
  segments: observable,
  selectedCustomers: observable,
  selectedSegments: observable,
  customers: observable,
  load: action,
  recalculatePeopleCount: action,
  loadMore: action,
  toggleSelectedSegment: action,
  reloadData: action,
  toggleSelectedCustomer: action,
  reset: action,
  setSearch: action,
  setupSelected: action
});

export default new MobxAudienceStore();
