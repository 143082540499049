import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import ReportListItem from './ReportListItem';
import VideoStats from './VideoStats';
import CustomerStats from './CustomerStats';
import GeneralStats from './GeneralStats';
import LineLoader from 'Components/Loader/LineLoader';
import Toolbar from './Toolbar';
import ReportTypeToolbar from './ReportTypeToolbar';
import { ReactComponent as BackArrowIcon } from 'Icons/back-arrow.svg';
import { Link } from 'react-router-dom';
import Header from 'Components/Shared/Analytic/Header';

const REPORT_TYPE_VIDEOS = 'videos';
const REPORT_TYPE_CUSTOMERS = 'customers';

class Analytic extends React.Component {
  selectedItemId = () => {
    const { reportType, selectedItem } = this.props;
    switch (reportType) {
      case REPORT_TYPE_VIDEOS:
        return selectedItem ? selectedItem.video.videoId : null;
      case REPORT_TYPE_CUSTOMERS:
        return selectedItem ? selectedItem.customer.customerId : null;
      default:
        return;
    }
  };

  reportItems = () => {
    const { reportType, videos, customers } = this.props;
    switch (reportType) {
      case REPORT_TYPE_VIDEOS:
        return {
          items: videos.videos || [],
          isLoading: videos.isLoading,
        };
      case REPORT_TYPE_CUSTOMERS:
        return {
          items: customers.customers || [],
          isLoading: customers.isLoading,
        };
      default:
        return { items: [], isLoading: true };
    }
  };

  render() {
    const {
      reportType,
      general,
      selectedItem,
      selectDateInterval,
      selectedInterval,
      startDate,
      endDate,
      setDateRange,
      searchTerm,
      setSearch,
    } = this.props;
    const selectedItemId = this.selectedItemId();

    const { items, isLoading } = this.reportItems();

    return (
      <div className="library library--style-category">
        <Header active="video-views" />

        <div className="analytic cnt">
          <GeneralStats data={general} />
          <div
            className={`library__split ${selectedItem ? 'is-active' : null}`}
          >
            <div className="library__split-left">
              <Toolbar
                {...{
                  selectDateInterval,
                  selectedInterval,
                  startDate,
                  endDate,
                  setDateRange,
                  searchTerm,
                  setSearch,
                }}
              />
              <ReportTypeToolbar />
              {isLoading && <LineLoader />}

              <div className="library__messages">
                <ul className="library__messages-list">
                  {items
                    .map((item) => {
                      const { viewsCount, uniqueViewsCount } = item;
                      switch (reportType) {
                        case REPORT_TYPE_VIDEOS:
                          return (
                            <ReportListItem
                              key={item.videoId}
                              title={item.title}
                              stats={`unique views ${uniqueViewsCount}, total views ${viewsCount}`}
                              date={item.datetime}
                              link={`/video-analytic/videos/${item.videoId}`}
                              isActive={item.videoId === selectedItemId}
                              icon={true}
                            />
                          );
                        case REPORT_TYPE_CUSTOMERS:
                          return (
                            <ReportListItem
                              key={item.customerId}
                              title={item.customerName}
                              date={item.datetime}
                              link={`/video-analytic/customers/${item.customerId}`}
                              isActive={item.customerId === selectedItemId}
                            />
                          );
                        default:
                          return null;
                      }
                    })
                    .filter((val) => !!val)}
                </ul>
              </div>
            </div>
            <div className="library__split-right">
              <div className="library__preview">
                <div className="library__preview-header">
                  <Link
                    to="/video-analytic/videos"
                    className="library__preview-header-back"
                  >
                    <BackArrowIcon />
                  </Link>
                </div>
                {selectedItem &&
                  (reportType === 'videos' ? (
                    <VideoStats video={selectedItem} />
                  ) : (
                    <CustomerStats customer={selectedItem} />
                  ))}
                {isLoading && <LineLoader />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Analytic.propTypes = {
  reportType: PropTypes.string.isRequired,
  videos: PropTypes.object.isRequired,
  customers: PropTypes.object.isRequired,
  general: PropTypes.object.isRequired,
  selectedItem: PropTypes.object,
  selectDateInterval: PropTypes.func.isRequired,
  selectedInterval: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  setDateRange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
};

export default Analytic;
