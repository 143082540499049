import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { observe } from 'mobx';
import { Formik } from 'formik';
import { apiError } from 'Utils/alert';
import { SequencesApi } from 'Api';
import Button from 'Components/Button';
import DatePicker from 'Components/DatePicker';
import LineLoader from 'Components/Loader/LineLoader';
import Header from './Header';
import Steps from './Steps';
import Audience from './Audience';
import { formatDate, addStepToDate, toUTC } from './utils';
import './index.css';

const ATTRIBUTE_START_DATE = 'startDate';
const ATTRIBUTE_STEPS = 'steps';
const ATTRIBUTE_AUDIENCE = 'audience';

const makeSteps = (messages, startDate) => {
  return messages.map(({ interval, message, step }, index) => ({
    messageId: message.id,
    landingPageImage: message.landingPageImage,
    messageTitle: message.internalTitle,
    messageType: message.type,
    defaultStartDate: startDate
      ? formatDate(addStepToDate(startDate, interval, index > 0 ? step : 0))
      : '',
    interval,
    step
  }));
};

let dispose;

function EnrollSequence({
  Routing,
  AudienceStore,
  match: {
    params: { sequenceId }
  }
}) {
  const [sequence, setSequence] = useState(null);
  const loadSequence = async () => {
    const result = await SequencesApi.getById(sequenceId);
    setSequence(result);
  };

  useEffect(function() {
    loadSequence();
  }, []);
  if (!sequence) {
    return <LineLoader />;
  }
  return (
    <React.Fragment>
      <Header sequence={sequence} />
      <div className="cnt" style={{ paddingBottom: 40 }}>
        <Formik
          initialValues={{
            [ATTRIBUTE_START_DATE]: '',
            [ATTRIBUTE_STEPS]: makeSteps(sequence.messages),
            [ATTRIBUTE_AUDIENCE]: []
          }}
          validate={values => {
            const errors = {};
            if (!values[ATTRIBUTE_START_DATE]) {
              errors[ATTRIBUTE_START_DATE] = 'Start date is required.';
            }
            if (values[ATTRIBUTE_STEPS].length === 0) {
              errors[ATTRIBUTE_STEPS] =
                'It is not possible to remove all sequence steps.';
            }
            if (values[ATTRIBUTE_AUDIENCE].length === 0) {
              errors[ATTRIBUTE_AUDIENCE] =
                'At least one recipient must be selected.';
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const data = {
              ...values,
              [ATTRIBUTE_START_DATE]: toUTC(
                new Date(values[ATTRIBUTE_START_DATE])
              ),
              [ATTRIBUTE_STEPS]: values[ATTRIBUTE_STEPS].map(
                ({ messageId, startDate }) => ({
                  messageId,
                  startDate: startDate ? toUTC(new Date(startDate)) : null
                })
              )
            };
            try {
              await SequencesApi.enroll(sequence.id, data);
              setTimeout(() => {
                Routing.push('/care/scheduled');
                setSubmitting(false);
              }, 1000);
            } catch (e) {
              apiError(e);
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldTouched,
            setFieldValue,
            setValues,
            handleSubmit,
            isSubmitting
          }) => {
            if (dispose) {
              dispose();
            }

            dispose = observe(AudienceStore.selectedCustomers, change => {
              setFieldValue(
                ATTRIBUTE_AUDIENCE,
                change.object.map(t => t.id)
              );
              setTimeout(() => setFieldTouched(ATTRIBUTE_AUDIENCE, true), 100);
            });

            const startDateError =
              errors[ATTRIBUTE_START_DATE] &&
              touched[ATTRIBUTE_START_DATE] &&
              errors[ATTRIBUTE_START_DATE];
            const stepsError =
              errors[ATTRIBUTE_STEPS] &&
              touched[ATTRIBUTE_STEPS] &&
              errors[ATTRIBUTE_STEPS];
            const audienceError =
              errors[ATTRIBUTE_AUDIENCE] &&
              touched[ATTRIBUTE_AUDIENCE] &&
              errors[ATTRIBUTE_AUDIENCE];

            return (
              <form onSubmit={handleSubmit}>
                <div className="form form--style-account">
                  <label className="form__label">start date</label>
                  <div
                    className={`form__container ${
                      startDateError ? 'form__container__error' : ''
                    }`}
                  >
                    <DatePicker
                      name={ATTRIBUTE_START_DATE}
                      showTimeSelect
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="MM/dd/y h:mm a"
                      onBlur={() => setFieldTouched(ATTRIBUTE_START_DATE, true)}
                      onChangeDate={value => {
                        setValues({
                          ...values,
                          [ATTRIBUTE_START_DATE]: formatDate(value),
                          [ATTRIBUTE_STEPS]: makeSteps(sequence.messages, value)
                        });
                        setTimeout(
                          () => setFieldTouched(ATTRIBUTE_START_DATE, true),
                          100
                        );
                      }}
                      placeholder="start date"
                      value={values[ATTRIBUTE_START_DATE]}
                    />
                    {startDateError && (
                      <div className="form__note">{startDateError}</div>
                    )}
                  </div>
                </div>

                <Steps
                  error={stepsError}
                  steps={values[ATTRIBUTE_STEPS]}
                  reset={() => {
                    setFieldValue(
                      ATTRIBUTE_STEPS,
                      makeSteps(sequence.messages, values[ATTRIBUTE_START_DATE])
                    );
                  }}
                  onChange={newSteps => {
                    setFieldValue(ATTRIBUTE_STEPS, newSteps);
                    setTimeout(
                      () => setFieldTouched(ATTRIBUTE_STEPS, true),
                      100
                    );
                  }}
                />

                <Audience error={audienceError} />

                <div>
                  <Button
                    name="Confirm"
                    type="submit"
                    disabled={isSubmitting}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </React.Fragment>
  );
}

export default withRouter(
  inject(
    'Routing',
    'AudienceStore'
  )(
    observer(function SequencesWrapper(props) {
      return <EnrollSequence {...props} />;
    })
  )
);
