import React, { Component } from 'react';
import { confirmYesNo } from 'Components/ConfirmModal';
import { inject, observer, PropTypes } from 'mobx-react';

import { ReactComponent as CloseIcon } from 'Icons/close.svg';

class ClosingDialog extends Component {
  static propTypes = {
    BuilderStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  state = {
    internalTitle: ''
  };

  componentWillMount() {
    this.setState({
      internalTitle: this.props.BuilderStore.getMessageAttribute(
        'internalTitle',
        ''
      )
    });
  }

  setInternalTitle = ({ target }) => {
    this.setState({ internalTitle: target.value });
    this.props.BuilderStore.setMessageAttribute('internalTitle', target.value);
  };

  saveChanges = () => {
    this.props.BuilderStore.saveMessageData().then(() => {
      this.props.Routing.push('/care/draft');
    });
  };

  discardChanges = async () => {
    const { isNewMessage } = this.props.BuilderStore;

    if (
      !isNewMessage &&
      !(await confirmYesNo(
        'This message will be deleted. Do you want to proceed?'
      ))
    ) {
      return;
    }

    this.props.BuilderStore.discardMessage();
    this.props.Routing.push('/care');
  };

  returnToCurrentStep = () => {
    this.props.Routing.push(this.props.BuilderStore.currentRoute);
  };

  render() {
    const { internalTitle } = this.state;

    return (
      <div className="builder builder--style-select-type">
        <div className="cnt">
          <button
            onClick={this.returnToCurrentStep}
            className="button button--reset button--link builder__close"
          >
            <CloseIcon />
          </button>

          <div className="builder__title">save message before closing</div>

          <div className="builder__save">
            <div className="builder__save-form form form--style-account">
              <label className="form__label">internal message title</label>
              <div className="form__container">
                <input
                  type="text"
                  className="form__input"
                  placeholder="enter message title"
                  onChange={this.setInternalTitle}
                  value={internalTitle}
                />
              </div>
            </div>

            <div className="builder__save-buttons">
              <button
                onClick={this.saveChanges}
                className="button button--style-default button--color-blue-ribbon button--size-m button--width-m"
              >
                Save message
              </button>
              <button
                onClick={this.discardChanges}
                className="button button--reset button--link"
              >
                Discard message
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('BuilderStore', 'Routing')(observer(ClosingDialog));
