import React from 'react';
import PropTypes from 'prop-types';
import { SUPPORT_EMAIL } from 'Utils/whitelabeling';

const ActiveSubscription = ({ billingPlan }) => {
  const { title, price, period } = billingPlan;
  return (
    <div className="settings__subscription-plan">
      <div className="settings__subscription-plan-card">
        <div className="settings__subscription-plan-name">{title}</div>
        <div className="settings__subscription-plan-price">
          ${price} <span>/ {period}</span>
        </div>
      </div>
      <div className="settings__subscription-plan-cancel">
        To cancel this subscription please send email to&nbsp;
        <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
      </div>
    </div>
  );
};

ActiveSubscription.propTypes = {
  billingPlan: PropTypes.object.isRequired
};

export default ActiveSubscription;
