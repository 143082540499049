import React, { Fragment, Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';

import { ReactComponent as CloseIcon } from 'Icons/close.svg';
import { ReactComponent as RightArrowIcon } from 'Icons/right-arrow-short.svg';

import './Header.css';

class Header extends Component {
  static propTypes = {
    BuilderStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  videoMessageSteps = [
    {
      name: 'landing',
      title: 'landing page',
      subtitle: 'Setup landing page'
    },
    {
      name: 'message',
      title: 'message',
      subtitle: 'Write a mobile message'
    },
    {
      name: 'schedule',
      title: 'schedule',
      subtitle: 'Schedule and send'
    }
  ];

  textMessageSteps = [
    {
      name: 'message',
      title: 'message',
      subtitle: 'Write a mobile message'
    },
    {
      name: 'schedule',
      title: 'schedule',
      subtitle: 'Schedule and send'
    }
  ];

  steps = null;

  componentWillMount() {
    if (this.props.BuilderStore.getMessageAttribute('type', '') === 'text') {
      this.steps = this.textMessageSteps;
    } else {
      this.steps = this.videoMessageSteps;
    }
  }

  showClosingDialog = () => this.props.Routing.push('/builder/close-editor');

  goToNextStep = () => {
    const { Routing, BuilderStore } = this.props;

    if (!BuilderStore.isValidStep) {
      return;
    }

    Routing.push(BuilderStore.nextRoute);
  };

  getStepSwitcher = toStep => () => {
    const currentStepIndex = this.steps.findIndex(
      step => step.name === this.props.BuilderStore.stepName
    );
    const toStepIndex = this.steps.findIndex(step => step.name === toStep.name);
    const { steps, isValidStep } = this.props.BuilderStore;

    if (
      currentStepIndex >= toStepIndex ||
      (isValidStep && currentStepIndex + 1 === toStepIndex)
    ) {
      this.props.Routing.push(`/builder/${steps[toStep.name].route}`);
    }
  };

  render() {
    const { steps } = this;
    const { isValidStep, stepName } = this.props.BuilderStore;
    return (
      <div className="builder__header">
        <div className="cnt">
          <div className="builder__header-content">
            <button
              className="builder__close button button--reset button--link"
              onClick={this.showClosingDialog}
            >
              <CloseIcon />
            </button>
            <div className="builder__header-steps">
              {steps.map((step, key) => (
                <Fragment key={key}>
                  <div
                    className={`builder__header-steps-item ${
                      step.name === stepName ? 'is-active' : ''
                    }`}
                  >
                    <div
                      onClick={this.getStepSwitcher(step)}
                      className="builder__header-steps-content"
                    >
                      <div className="builder__header-steps-title">
                        {step.title}
                      </div>
                      <div className="builder__header-steps-description">
                        {step.subtitle}
                      </div>
                    </div>
                  </div>
                  {key !== steps.length - 1 && (
                    <div className="builder__header-steps-arrow">
                      <RightArrowIcon />
                    </div>
                  )}
                </Fragment>
              ))}
            </div>

            <div className="builder__header-buttons">
              <button
                onClick={this.showClosingDialog}
                className="builder__header-button builder__header-button--cancel button button--reset button--button"
              >
                finish later
              </button>
              <button
                onClick={this.goToNextStep}
                disabled={!isValidStep}
                className={`builder__header-button builder__header-button--next button button--reset button--button ${
                  isValidStep ? '' : 'is-disabled'
                }`}
              >
                next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('BuilderStore', 'Routing')(observer(Header));
