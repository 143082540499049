import superagent from 'superagent';

export function getQueryParams(search) {
  const hashes = search
    .slice(search.indexOf('?') + 1)
    .split('&')
    .filter(val => !!val);
  return hashes.reduce((params, hash) => {
    let [key, val] = hash.split('=');
    return { ...params, [key]: val };
  }, {});
}

export function paramsToQueryString(params) {
  const result = [];
  for (let p in params)
    if (params.hasOwnProperty(p)) {
      result.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]));
    }
  return result.join('&');
}

export const addQueryParamsToUrl = (url, params) => {
  const { host, pathname, protocol, search } = new URL(url);
  const newSearch = paramsToQueryString({
    ...getQueryParams(search),
    ...params
  });
  return `${protocol}//${host}${pathname}?${newSearch}`;
};

export const getTrackingReferrer = () => {
  if (window.document.referrer) {
    return window.document.referrer;
  }

  const { referrer } = getQueryParams(window.location.search);
  return referrer || null;
};

export const isAvailable = async url => {
  try {
    await superagent.head(url);
    return true;
  } catch (e) {
    return false;
  }
};
