import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';

import SidebarLayout from '../SidebarLayout';
import EmptyFavorites from './EmptyFavorites';
import StatefulPagination from 'Components/Pagination/StatefulPagination';

import MessageItem from '../MessageItem';
import { MESSAGE_SENDER } from '../../../roles';

class Favorites extends Component {
  static propTypes = {
    ProfileStore: PropTypes.observableObject,
    FavoritesStore: PropTypes.observableObject,
    MyLibraryStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  static perPageLimit = 10;

  get canSendMessages() {
    return this.props.ProfileStore.roles.indexOf(MESSAGE_SENDER) !== -1;
  }

  getSwitchTabHandler = tabName => () =>
    this.props.FavoritesStore.goToTab(tabName);

  handlePageClick = page => {
    this.props.FavoritesStore.goToPage(page - 1);
  };

  onSearchhandleSearch = ({ target }) =>
    this.props.FavoritesStore.search(target.value || '');

  openBuilder = ({ id }) =>
    this.canSendMessages
      ? this.props.Routing.push(`/builder/from/template/${id}`)
      : this.props.Routing.push(`/video/record/${id}`);

  componentWillMount() {
    this.props.MyLibraryStore.setLibraryFolder(null);
    this.props.FavoritesStore.loadFavorites();
  }

  renderFavoritesList() {
    const {
      currentTabItems,
      isLoading,
      currentTab,
      page,
      allTemplatesCount,
      videoTemplatesCount,
      messageTemplatesCount,
      searchTerm
    } = this.props.FavoritesStore;

    if (isLoading) {
      return null;
    }

    const offset = page * Favorites.perPageLimit;
    const pagedItems = currentTabItems.slice(
      offset,
      offset + Favorites.perPageLimit
    );

    return (
      <React.Fragment>
        <div className="toolbar toolbar--style-library">
          <div className="toolbar__content">
            <div className="toolbar__title">my favorites</div>

            {this.canSendMessages && (
              <nav className="toolbar__navigation">
                <ul>
                  <li className={currentTab === 'all' ? 'is-active' : ''}>
                    <a
                      href="#message-templates-all"
                      onClick={this.getSwitchTabHandler('all')}
                    >
                      all ({allTemplatesCount})
                    </a>
                  </li>
                  <li className={currentTab === 'video' ? 'is-active' : ''}>
                    <a
                      href="#message-templates-video"
                      onClick={this.getSwitchTabHandler('video')}
                    >
                      video ({videoTemplatesCount})
                    </a>
                  </li>
                  <li className={currentTab === 'text' ? 'is-active' : ''}>
                    <a
                      href="#message-templates-text"
                      onClick={this.getSwitchTabHandler('text')}
                    >
                      text ({messageTemplatesCount})
                    </a>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
        <div className="library__messages">
          <ul className="library__messages-list">
            {pagedItems.map((message, key) => (
              <MessageItem
                key={key}
                onClick={this.openBuilder}
                message={message}
              />
            ))}
            {pagedItems.length === 0 && searchTerm && <li>Nothing found.</li>}
          </ul>

          <StatefulPagination
            total={currentTabItems.length}
            limit={Favorites.perPageLimit}
            onPageClick={this.handlePageClick}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {
      isLoading,
      searchTerm,
      allTemplatesCount
    } = this.props.FavoritesStore;

    if (isLoading) {
      return null;
    }

    return (
      <SidebarLayout
        styleType="favorites"
        searchText="search topic"
        searchTerm={searchTerm}
        onSearch={this.onSearchhandleSearch}
      >
        {!searchTerm && allTemplatesCount === 0 && <EmptyFavorites />}
        {(allTemplatesCount > 0 || searchTerm) && this.renderFavoritesList()}
      </SidebarLayout>
    );
  }
}

export default inject(
  'FavoritesStore',
  'MyLibraryStore',
  'Routing',
  'ProfileStore'
)(observer(Favorites));
