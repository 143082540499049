import React from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { MerchantApi } from 'Api';
import SELECT_STYLES from './ReactSelectStyles';

function MessageSelector({ onChange, value, type: messageType }) {
  const loadOptions = async inputValue => {
    const { data } = await MerchantApi.listFavouriteMessages(
      0,
      10,
      messageType,
      inputValue
    );
    return data.map(({ id, internalTitle, type, landingPageImage }) => ({
      value: id,
      label: internalTitle,
      type,
      landingPageImage
    }));
  };

  return (
    <AsyncSelect
      key={messageType}
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions
      onChange={onChange}
      styles={SELECT_STYLES}
      value={value}
    />
  );
}

export default MessageSelector;
