import React from 'react';
import { Link } from 'react-router-dom';
import { BRAND, DISABLE_BILLING } from 'Utils/whitelabeling';
import { observable, action, flow, decorate, computed } from 'mobx';

import { isEmptyObject, removeEmptyValues } from 'Utils/objectHelper';
import { success, info, error } from 'Utils/alert';

import FavoritesStore from './FavoritesStore';
import CommonStore from './CommonStore';
import RoutingStore from './RoutingStore';

import { AuthApi, MerchantApi, PhoneApi, UploadApi } from 'Api';
import { stickyWarning } from '../Utils/alert';
import { BillingApi } from 'Api';

const NOT_SUFFICIENT_FUNDS_MESSAGE_ID = 'not-sufficient-funds';

class ProfileStore {
  currentProfile = {};
  takingPhone = false;
  savingFavoriteChange = false;
  profileLoadingStarted = false;
  isProfileValueChanged = false;

  emptySettingsFormObj = {
    values: {
      name: '',
      businessPhone: '',
      password: '',
      confirmedPassword: '',
      office_name: '',
    },
    submitting: false,
    validationErrors: [],
    errorMessage: undefined,
    passwordMatchError: undefined,
  };

  settingsFormData = { ...this.emptySettingsFormObj };

  setCurrentProfile = async (item) => {
    // prepare favorites structure
    if (!item.favorites) {
      item.favorites = {};
    }
    ['templates', 'messages'].forEach((favoriteType) => {
      if (!item.favorites[favoriteType]) {
        item.favorites[favoriteType] = {};
      }
    });

    this.currentProfile = item;
    this.isProfileValueChanged = false;
    const hasActiveSubscription =
      item.freePortalAccess ||
      (item.serviceSubscriptionId &&
        item.serviceSubscriptionState === 'active');

    if (hasActiveSubscription) {
      FavoritesStore.setTemplateFavorites(this.templateFavorites);
      if (
        !DISABLE_BILLING &&
        this.currentProfile.balance === 0 &&
        !this.currentProfile.isTrialActive
      ) {
        const { messagePrice } = await BillingApi.pricingSettings();
        if (messagePrice > 0) {
          stickyWarning(
            <p>
              You can not send messages because your balance is $0. To view
              payment history or edit your payment method, please visit{' '}
              <Link to="/billing">billing section</Link>, located in the
              {BRAND} portal under account > profile > subscription.
            </p>,
            NOT_SUFFICIENT_FUNDS_MESSAGE_ID
          );
        }
      }
    }
  };

  setAttribute(attName, value) {
    this.settingsFormData.values[attName] = value;
    this.isProfileValueChanged = true;
  }

  clearProfileData() {
    this.currentProfile = {};
    this.settingsFormData = { ...this.emptySettingsFormObj };
    this.isProfileValueChanged = false;
  }

  addTemplateToFavorites = flow(function* (templateId) {
    const { templates = {} } = this.currentProfile.favorites || {};

    try {
      if (templates[templateId]) {
        return;
      }

      this.savingFavoriteChange = true;
      yield MerchantApi.addTemplateToFavorites(templateId);
      templates[templateId] = true;

      this.currentProfile.favorites.templates = templates;
      FavoritesStore.setTemplateFavorites(this.templateFavorites);

      this.savingFavoriteChange = false;
    } catch (e) {
      this.handleErrors(e);
      this.savingFavoriteChange = false;
    }
  });

  removeTemplateFromFavorites = flow(function* (templateId) {
    const { templates = {} } = this.currentProfile.favorites || {};

    try {
      if (!templates[templateId]) {
        return;
      }

      this.savingFavoriteChange = true;
      yield MerchantApi.removeTemplateFromFavorites(templateId);
      delete templates[templateId];

      this.currentProfile.favorites.templates = templates;
      FavoritesStore.setTemplateFavorites(this.templateFavorites);

      this.savingFavoriteChange = false;
    } catch (e) {
      this.handleErrors(e);
      this.savingFavoriteChange = false;
    }
  });

  addMessageToFavorites = flow(function* (messageId) {
    const { messages = {} } = this.currentProfile.favorites;

    try {
      if (messages[messageId]) {
        return;
      }

      this.savingFavoriteChange = true;
      yield MerchantApi.addMessageToFavorites(messageId);
      messages[messageId] = true;

      this.currentProfile.favorites.messages = messages;

      this.savingFavoriteChange = false;
    } catch (e) {
      this.handleErrors(e);
      this.savingFavoriteChange = false;
    }
  });

  removeMessageFromFavorites = flow(function* (messageId) {
    const { messages = {} } = this.currentProfile.favorites;

    try {
      if (!messages[messageId]) {
        return;
      }

      this.savingFavoriteChange = true;
      yield MerchantApi.removeMessageFromFavorites(messageId);
      delete messages[messageId];

      this.currentProfile.favorites.messages = messages;

      this.savingFavoriteChange = false;
    } catch (e) {
      this.handleErrors(e);
      this.savingFavoriteChange = false;
    }
  });

  get templateFavorites() {
    if (this.currentProfile && this.currentProfile.favorites) {
      return this.currentProfile.favorites.templates;
    }

    return {};
  }

  get messageFavorites() {
    if (this.currentProfile && this.currentProfile.favorites) {
      return this.currentProfile.favorites.messages;
    }

    return {};
  }

  get roles() {
    return this.currentProfile.roles || [];
  }

  loadProfile = flow(function* () {
    if (this.profileLoadingStarted) {
      return;
    }
    try {
      this.profileLoadingStarted = true;
      const { ...response } = yield AuthApi.current();
      this.setAttribute('name', response.name);
      this.setCurrentProfile(response);
    } catch (e) {
      this.handleErrors(e);
    }
    this.profileLoadingStarted = false;
  });

  updateProfile = flow(function* () {
    const { values } = this.settingsFormData;

    if (values.password !== values.confirmedPassword) {
      this.settingsFormData.passwordMatchError =
        'Password and Confirm password must be same';
      return;
    }

    const newValueObject = {
      name: values.name === this.currentProfile.name ? null : values.name,
      businessPhone: values.businessPhone,
      password: values.confirmedPassword,
      currentPassword: values.currentPassword,
      officeName: values.officeName,
    };

    const objectToSend = removeEmptyValues(newValueObject);
    if (isEmptyObject(objectToSend)) {
      return;
    }

    try {
      this.settingsFormData.submitting = true;
      yield MerchantApi.updatePersonalInfo(objectToSend);
      this.settingsFormData.submitting = false;
      this.loadProfile();
      info('Profile updated');
    } catch (e) {
      this.handleErrors(e, false);
    }
  });

  uploadLogo = flow(function* (image) {
    const profileImage = yield UploadApi.uploadImage(image);
    yield MerchantApi.updatePersonalInfo({ profileImage });

    this.currentProfile.profile_image = profileImage;
  });

  takeNumber = flow(function* (phone) {
    try {
      this.takingPhone = true;
      yield PhoneApi.takePhone(phone);
      this.takingPhone = false;
      success('Phone number is taken');
    } catch (e) {
      this.handleErrors(e);
      this.takingPhone = false;
    }
  });

  handleErrors(err, showErrorMessage = true) {
    if (err.status === 404) {
      CommonStore.setToken(undefined);
      RoutingStore.push('/login');
    }
    const { validationErrors = [], message = undefined } = err.response.body;
    this.settingsFormData.validationErrors = validationErrors;
    this.settingsFormData.errorMessage = message;
    this.settingsFormData.submitting = false;
    if (message && showErrorMessage) return error(message);
  }
}

const MobxProfileStore = decorate(ProfileStore, {
  currentProfile: observable,
  takingPhone: observable,
  settingsFormData: observable,
  updateProfile: action,
  setCurrentProfile: action,
  loadProfile: action,
  uploadLogo: action,
  isProfileValueChanged: observable,
  templateFavorites: computed,
  messageFavorites: computed,
  setAttribute: action,
  roles: computed,
});

export default new MobxProfileStore();
