import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import './Recurrence.scss';

const recurrences = [
  {
    name: 'timed recurrence',
    singleItemOnly: true,
    types: [
      { name: 'Weekly', type: 'weekly' },
      { name: 'Monthly', type: 'monthly' }
    ]
  },
  {
    name: 'events and promotion recurrence',
    types: [{ name: 'On Birthday', type: 'onBirthday' }]
  }
];

class Recurrence extends Component {
  static propTypes = {
    recurrences: PropTypes.array,
    onChange: PropTypes.func
  };

  isSelected({ type }) {
    const { recurrences } = this.props;
    return recurrences.includes(type);
  }

  toggleRecurrenceType = (recurrence, allRecurrences, singleItemOnly) => () => {
    const { onChange } = this.props;
    let recurrences = [...this.props.recurrences];
    const index = recurrences.indexOf(recurrence.type);

    if (index !== -1) {
      recurrences.splice(index, 1);
    } else {
      if (singleItemOnly) {
        recurrences = recurrences.filter(
          recurrence =>
            allRecurrences.find(({ type }) => type === recurrence) === undefined
        );
      }

      recurrences.push(recurrence.type);
    }

    onChange && onChange(recurrences);
  };

  renderCustomRecurrence() {
    return (
      <div className="custom-recurrence">
        {recurrences.map(({ name, types, singleItemOnly = false }, key) => (
          <div key={key} className="builder--style-audience">
            <div className="builder__audience-subtitle">{name}</div>
            <div className="builder__audience-tags">
              <div className="tags tags--size-m">
                {types.map((type, key) => (
                  <div
                    onClick={this.toggleRecurrenceType(
                      type,
                      types,
                      singleItemOnly
                    )}
                    key={key}
                    className={`tags__item ${
                      this.isSelected(type) ? 'is-active' : ''
                    }`}
                  >
                    {type.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    return (
      <div className="builder-schedule-recurrence">
        {this.renderCustomRecurrence()}
      </div>
    );
  }
}

export default Recurrence;
