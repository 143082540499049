import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class CustomerStats extends React.Component {
  render() {
    const { customer: selectedCustomer } = this.props;
    return (
      <section>
        <div className="library__preview-title">
          {selectedCustomer.customer.customerName}
        </div>

        <div className="table table--style-reports-shares">
          <div className="table__wrapper" style={{ margin: 10 }}>
            <table className="table__table table-recipients">
              <thead className="table__head">
                <tr>
                  <th title="name">message</th>
                  <th title="sends">sends</th>
                  <th title="views">views</th>
                  <th title="unique views">unique views</th>
                </tr>
              </thead>
              <tbody className="table__body">
                {selectedCustomer.messages.map(record => {
                  return (
                    <tr key={record.groupId}>
                      <td title={record.title}>
                        <Link to={`/analytic/messages/${record.groupId}`}>
                          {record.title}
                        </Link>
                      </td>
                      <td>{record.deliveriesCount}</td>
                      <td>
                        {record.viewsCount + record.externalLinkViewsCount}
                      </td>
                      <td>
                        {record.uniqueViewsCount +
                          record.uniqueExternalLinkViewsCount}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    );
  }
}

CustomerStats.propTypes = {
  customer: PropTypes.object.isRequired
};

export default CustomerStats;
