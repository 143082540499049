import { observable, action, computed, flow, decorate } from 'mobx';
import { error } from 'Utils/alert';
import { SmsApi, CustomerApi } from 'Api';
import moment from 'moment';

class SmsStore {
  notReadMessagesCount = undefined;
  respondentsCount = null;
  respondents = [];
  isLoadingRespondents = false;
  respondentsOffset = 0;
  respondentsPageSize = 10;

  smsOffset = 0;
  smsPageSize = 10;
  isLoadingSms = false;
  isSendingSms = false;
  sms = [];
  smsCount = 0;
  smsListScrolledDown = true;

  selectedRespondentId = null;

  reset = () => {
    this.selectedRespondentId = null;
    this.respondentsOffset = 0;
    this.sms = [];
    this.respondents = [];
    this.respondentsCount = null;
  };

  loadRespondents = flow(function*() {
    this.allRespondentsLoaded =
      this.respondentsCount === 0 ||
      (this.respondentsCount > 0 &&
        this.respondentsOffset >= this.respondentsCount);

    if (this.isLoadingRespondents || this.allRespondentsLoaded) {
      return;
    }
    this.isLoadingRespondents = true;
    try {
      const { count, data } = yield SmsApi.getRespondents(
        this.respondentsOffset,
        this.respondentsPageSize
      );
      this.respondentsCount = count;
      data.forEach(record => this.addRespondent(record, true));
      this.respondentsOffset += data.length;
      this.isLoadingRespondents = false;

      /**
       * When browser opens link to chat with selected customer, we know customer id before
       * his data be loaded. So we need to load customer data and make sure that we attempt to
       * load sms when have customers data(phone number)
       */
      if (
        this.selectedRespondentId &&
        this.selectedRespondent &&
        !this.selectedRespondent.lastMessageDate
      ) {
        this.isLoadingRespondents = false;
        return this.loadRespondents();
      }
    } catch (error) {
      this.handleErrors(error);
      this.isLoadingRespondents = false;
    }
  });

  calculateNotReadMessages = flow(function*() {
    if (this.notReadMessagesCount !== undefined) {
      return;
    }
    try {
      const { count } = yield SmsApi.calculateNotReadMessages();
      this.notReadMessagesCount = count;
    } catch (error) {
      this.handleErrors(error);
    }
  });

  setSelectedRespondent = flow(function*(id) {
    if (id === this.selectedRespondentId) {
      return;
    }

    this.selectedRespondentId = id;

    if (!this.selectedRespondent) {
      try {
        const { data } = yield CustomerApi.getMultiple([id]);
        if (data.length === 0) {
          return;
        }
        const { firstName, lastName, phone, linkedToNumber } = data[0];
        this.addRespondent({
          customerId: id,
          customerPhone: phone,
          lastMessage: null,
          lastMessageDate: null,
          notReadMessagesCount: 0,
          firstName,
          lastName,
          linkedToNumber
        });
      } catch (e) {
        return;
      }
    }

    this.sms = [];
    this.smsCount = 0;
    this.smsOffset = 0;
    this.loadRespondentsSms();
  });

  addRespondent = (respondent, override = false) => {
    let added = false;
    this.respondents = this.respondents.map(record => {
      if (respondent.customerId === record.customerId) {
        added = true;
      }
      return respondent.customerId === record.customerId && override
        ? respondent
        : record;
    });

    if (!added) {
      this.respondents = this.respondents.concat([respondent]);
    }
  };

  get selectedRespondent() {
    return this.selectedRespondentId
      ? this.respondents.find(
          respondent => respondent.customerId === this.selectedRespondentId
        )
      : null;
  }

  loadRespondentsSms = flow(function*() {
    if (this.isLoadingSms) {
      return;
    }
    try {
      const selectedRespondent = this.selectedRespondent;
      if (!selectedRespondent) {
        return;
      }

      this.isLoadingSms = true;
      this.markRespondentsMessagesAsRead();
      const { count, data } = yield SmsApi.listSms(
        selectedRespondent.customerPhone,
        this.smsOffset,
        this.smsPageSize
      );
      this.smsCount = count;
      this.smsOffset += data.length;
      this.sms = this.sms.concat(data);
    } catch (error) {
      this.handleErrors(error);
    }
    this.isLoadingSms = false;
  });

  loadMoreSms = () => {
    if (!this.isLoadingSms) {
      this.smsListScrolledDown = false;
      this.loadRespondentsSms();
    }
  };

  markRespondentsMessagesAsRead = flow(function*() {
    const selectedRespondent = this.selectedRespondent;
    if (!selectedRespondent || selectedRespondent.notReadMessagesCount === 0) {
      return;
    }

    yield SmsApi.markSmsRead(selectedRespondent.customerId);

    this.notReadMessagesCount -= selectedRespondent.notReadMessagesCount;

    selectedRespondent.notReadMessagesCount = 0;
    this.respondents = this.respondents.map(respondent =>
      respondent.customerId === selectedRespondent.customerId
        ? selectedRespondent
        : respondent
    );
  });

  sendSms = flow(function*(text) {
    const selectedRespondent = this.selectedRespondent;
    if (this.isSendingSms || !selectedRespondent) {
      return;
    }
    try {
      this.isSendingSms = true;
      yield CustomerApi.sendSms(selectedRespondent.customerId, text);
      this.smsListScrolledDown = true;
      this.sms = [
        {
          createdAt: new moment().format(),
          direction: 'out',
          id: new moment().format(),
          isRead: '1',
          message: text
        }
      ].concat(this.sms);
      this.smsCount += 1;
      this.smsOffset += 1;
    } catch (error) {
      this.handleErrors(error);
    }
    this.isSendingSms = false;
  });

  handleErrors(err) {
    const { message = undefined } = err.response.body;
    if (message) {
      error(message);
    }
    console.log(err);
  }
}

const MobxSmsStore = decorate(SmsStore, {
  notReadMessagesCount: observable,
  respondentsCount: observable,
  respondents: observable,
  selectedRespondentId: observable,
  sms: observable,
  smsListScrolledDown: observable,
  isLoadingSms: observable,
  isLoadingRespondents: observable,

  selectedRespondent: computed,

  reset: action,
  calculateNotReadMessages: action,
  loadRespondents: action,
  setSelectedRespondent: action,
  loadRespondentsSms: action,
  loadMoreSms: action,
  sendSms: action
});

export default new MobxSmsStore();
