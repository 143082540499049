import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import FacebookLoader from 'Components/Loader/FacebookLoader';
import Pagination from 'Components/Pagination';
import { Link } from 'react-router-dom';
import { ReactComponent as CloseIcon } from 'Icons/close.svg';
import AccountMenu from '../AccountMenu';
import ChargesList from './ChargesList';

class Charges extends React.Component {
  componentWillMount() {
    this.props.ChargesStore.load();
  }
  componentDidMount() {
    this.props.CommonStore.setActivePage('charges');
  }
  addBillingMethod = token => {
    this.props.BillingStore.addBillingMethod(token);
  };
  render() {
    const {
      charges,
      isLoading,
      currentPage,
      pageSize,
      totalCharges
    } = this.props.ChargesStore;

    return (
      <div className="settings">
        <div className="cnt">
          <div className="settings__wrapper">
            <AccountMenu />
            <div className="settings__content">
              <div className="settings__header">
                <Link to="/billing" className="settings__header-close">
                  <CloseIcon />
                </Link>
                <h2 className="settings__header-title">Charges</h2>
              </div>
              {isLoading && <FacebookLoader />}
              {!isLoading && (
                <React.Fragment>
                  <ChargesList charges={charges} />
                  {totalCharges > pageSize && (
                    <Pagination
                      total={totalCharges}
                      limit={pageSize}
                      page={currentPage + 1}
                      onPageClick={page =>
                        this.props.ChargesStore.setPage(page - 1)
                      }
                    />
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Charges.propTypes = {
  CommonStore: PropTypes.observableObject,
  ChargesStore: PropTypes.observableObject
};

export default inject('CommonStore', 'ChargesStore')(observer(Charges));
