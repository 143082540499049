import { observable, action, flow, decorate } from 'mobx';

import { AuthApi } from 'Api';
import CommonStore from 'Stores/CommonStore';
import ProfileStore from './ProfileStore';
import Routing from './RoutingStore';

const MERCHANT_TYPE_KEY = 'merchant-type';

class AuthStore {
  inProgress = false;
  validationErrors = [];
  errorMessage = undefined;
  loginHasError = false;

  values = {
    name: '',
    email: '',
    password: '',
    confirmedPassword: '',
    businessPhone: '',
    merchantTypeId: window.localStorage.getItem(MERCHANT_TYPE_KEY)
  };

  setMerchantType(id) {
    this.values.merchantTypeId = id;
  }

  setUsername(username) {
    this.values.name = username;
  }

  setEmail(email) {
    this.values.email = email;
  }

  setPassword(password) {
    this.values.password = password;
  }

  setConfirmedPassword(confirmedPassword) {
    this.values.confirmedPassword = confirmedPassword;
  }

  setBusinessPhone(businessPhone) {
    this.values.businessPhone = businessPhone;
  }

  setSelectedMerchantType = typeId => {
    this.values.merchantTypeId = typeId;
    window.localStorage.setItem(MERCHANT_TYPE_KEY, typeId);
  };

  tryLoginAgain() {
    this.loginHasError = false;
  }

  reset() {
    this.values.name = '';
    this.values.email = '';
    this.values.password = '';
    this.values.confirmedPassword = '';
    this.values.businessPhone = '';
    this.validationErrors = [];
    this.errorMessage = undefined;
    this.loginHasError = false;
    this.inProgress = false;
  }

  register = flow(function*() {
    if (this.values.password !== this.values.confirmedPassword) {
      this.errorMessage = 'Password and confirmed password should be same';
      return;
    }
    this.inProgress = true;
    try {
      const { jwt } = yield AuthApi.register(
        this.values.merchantTypeId,
        this.values.name,
        this.values.email,
        this.values.businessPhone,
        this.values.password
      );
      CommonStore.setToken(jwt);
      this.inProgress = false;
      Routing.push('/welcome');
    } catch (e) {
      this.handleErrors(e);
    }
  });

  login = flow(function*() {
    this.inProgress = true;
    try {
      const { jwt } = yield AuthApi.login(
        this.values.email,
        this.values.password
      );
      CommonStore.setToken(jwt);
      ProfileStore.loadProfile();
      this.inProgress = false;
    } catch (e) {
      this.loginHasError = true;
    }
  });

  resetPassword = flow(function*() {
    this.inProgress = true;
    try {
      yield AuthApi.passwordRecover(this.values.email);
      this.inProgress = false;
    } catch (e) {
      this.handleErrors(e);
    }
  });

  logout() {
    CommonStore.setToken(undefined);
    ProfileStore.clearProfileData();
  }

  handleErrors(err) {
    const { validationErrors = [], message = undefined } = err.response.body;
    this.validationErrors = validationErrors;
    this.errorMessage = message;
    this.inProgress = false;
    window.scrollTo(0, 0);
  }
}

const MobxAuthStore = decorate(AuthStore, {
  inProgress: observable,
  loginHasError: observable,
  validationErrors: observable,
  errorMessage: observable,
  values: observable,
  setMerchantType: action,
  setUsername: action,
  setBusinessPhone: action,
  setEmail: action,
  setPassword: action,
  setConfirmedPassword: action,
  reset: action,
  register: action,
  login: action,
  logout: action,
  tryLoginAgain: action,
  setSelectedMerchantType: action
});

export default new MobxAuthStore();
