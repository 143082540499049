import React from 'react';
import Waypoint from 'react-waypoint';
import { ReactComponent as SearchIcon } from '../../Icons/search.svg';
import Button from '../Button';
import { debounce } from 'Utils/decorators';
import { CustomerApi, VideosApi } from 'Api';
import { getNameInitals, userToColor } from '../../Utils/string';
import Loader from '../../Pages/Builder/Schedule/Audience/Loader';
import { selectText } from './utils';

const PAGE_SIZE = 40;

const VIDEO_THUMBNAIL_DOMAIN =
  process.env.VIDEO_THUMBNAIL_DOMAIN ||
  process.env.REACT_APP_API_URL ||
  'http://localhost:8080';

class IndividualThumbnailLinks extends React.Component {
  state = {
    customerSearch: {
      searchTerm: '',
      customers: [],
      isLoading: 0,
      page: 0,
      hasMore: true
    }
  };

  setCustomerSearch = value => this.setState({ customerSearch: value });

  handleSearchTermChange = e => {
    const searchTerm = e.target.value;
    this.setCustomerSearch({ ...this.state.customerSearch, searchTerm });
    this.searchCustomers();
  };

  searchCustomers = debounce(async () => {
    if (this.state.customerSearch.isLoading) {
      return;
    }
    this.setCustomerSearch({
      ...this.state.customerSearch,
      page: 0,
      customers: [],
      hasMore: true
    });
    this.loadCustomers();
  }, 1000);

  loadCustomers = async () => {
    this.setCustomerSearch({ ...this.state.customerSearch, isLoading: true });
    const { data: customers } = await CustomerApi.get(
      this.state.customerSearch.page,
      PAGE_SIZE,
      null,
      this.state.customerSearch.searchTerm
    );
    this.setCustomerSearch({
      ...this.state.customerSearch,
      page: this.state.customerSearch.page + 1,
      isLoading: false,
      customers: this.state.customerSearch.customers.concat(customers),
      hasMore: customers.length === PAGE_SIZE
    });
  };

  copyThumbnailLink = () => {
    selectText(this.thumbnailSnippetInput);
    window.document.execCommand('copy');
    window.getSelection().removeAllRanges();
  };

  render() {
    const { onClose, videoId } = this.props;
    const {
      isLoading,
      searchTerm,
      customers,
      hasMore
    } = this.state.customerSearch;

    return (
      <div className="builder builder--style-audience">
        <h3 style={{ marginTop: 30, textAlign: 'center' }}>
          Copy individual thumbnail link
        </h3>
        <div className="builder__contacts">
          <div className="builder__contacts-search form form--style-account">
            <div className="form__container">
              <input
                type="text"
                className="form__input"
                placeholder="search contacts"
                onChange={this.handleSearchTermChange}
                value={searchTerm}
              />
              <SearchIcon />
            </div>
          </div>

          <div
            ref={node => (this.thumbnailSnippetInput = node)}
            style={{ marginLeft: -10000, height: 1 }}
          >
            <a href={this.state.shortLink}>
              <img
                src={`${VIDEO_THUMBNAIL_DOMAIN}/thumbnail/${videoId}/${this.state.customerId}`}
                title={`${VIDEO_THUMBNAIL_DOMAIN}/thumbnail/${videoId}/${this.state.customerId}`}
                style={{ maxWidth: 600 }}
                loading="lazy"
                alt={`${VIDEO_THUMBNAIL_DOMAIN}/thumbnail/${videoId}/${this.state.customerId}`}
              />
            </a>
          </div>

          <div className="builder__contacts-list" style={{ maxHeight: 400 }}>
            {!isLoading && customers.length === 0 && (
              <div>No contacts found.</div>
            )}
            {customers.map((customer, key) => (
              <div key={key} className="builder__contacts-item">
                <div
                  className="user-icon user-icon--size-m"
                  style={{
                    backgroundColor: userToColor(
                      customer.firstName,
                      customer.lastName
                    )
                  }}
                >
                  {getNameInitals(customer.firstName, customer.lastName)}
                </div>
                <div className="builder__contacts-item-name">
                  {customer.firstName} {customer.lastName}
                </div>
                {
                  <Button
                    name="Copy thumbnail link"
                    onClick={async () => {
                      const {
                        urls
                      } = await VideosApi.shareWithCustomers(videoId, [
                        customer.id
                      ]);
                      this.setState(
                        {
                          customerId: customer.id,
                          shortLink: urls[customer.id]
                        },
                        () => {
                          this.copyThumbnailLink();
                        }
                      );
                    }}
                    disabled={this.state.customerId === customer.id}
                  />
                }
              </div>
            ))}
            {isLoading && <Loader />}
            {hasMore && (
              <Waypoint
                onEnter={() => {
                  if (this.state.customerSearch.isLoading) {
                    return;
                  }
                  this.setCustomerSearch({
                    ...this.state.customerSearch,
                    page: this.state.customerSearch.page + 1
                  });
                  this.loadCustomers();
                }}
              />
            )}
          </div>
        </div>
        <br />
        <div style={{ textAlign: 'right' }}>
          <Button name="Close" onClick={onClose} />
        </div>
      </div>
    );
  }
}

export default IndividualThumbnailLinks;
