import React from 'react';
import PropTypes from 'prop-types';
import BillingMethodForm from './BillingMethodForm';

import Amex from 'Icons/CreditCards/amex.svg';
import Default from 'Icons/CreditCards/default.svg';
import Dinners from 'Icons/CreditCards/diners.svg';
import Discover from 'Icons/CreditCards/discover.svg';
import Jcb from 'Icons/CreditCards/jcb.svg';
import Mastercard from 'Icons/CreditCards/mastercard.svg';
import Unionpay from 'Icons/CreditCards/unionpay.svg';
import Visa from 'Icons/CreditCards/visa.svg';

const CARDS = {
  'American Express': Amex,
  'Diners Club': Dinners,
  Discover: Discover,
  JCB: Jcb,
  MasterCard: Mastercard,
  UnionPay: Unionpay,
  Visa: Visa,
  Unknown: Default
};

class BillingMethods extends React.Component {
  state = { showNewMethodForm: false };
  toggleNewMethodForm = () => {
    this.setState({ showNewMethodForm: !this.state.showNewMethodForm });
  };
  getMonth = number => {
    return [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ][number - 1];
  };
  renderBillingMethod = billingMethod => {
    const { actionsDisabled, onMethodDefault } = this.props;

    return (
      <div className="settings__card" key={billingMethod.id}>
        <img
          style={{ width: 70 }}
          src={CARDS[billingMethod.brand]}
          alt="card brand"
        />
        <div className="settings__card-name">
          <span>**** *** *** {billingMethod.last4}</span>
        </div>
        <div className="settings__card-info">
          expires on: {this.getMonth(billingMethod.exp_month)}&nbsp;
          {billingMethod.exp_year}
        </div>
        <div style={{ marginLeft: 'auto' }}>
          {!billingMethod.default && (
            <button
              className="button button--style-default button--color-blue-ribbon button--size-s"
              disabled={actionsDisabled}
              onClick={() => onMethodDefault(billingMethod.id)}
            >
              make default
            </button>
          )}
        </div>
      </div>
    );
  };
  render() {
    const { showNewMethodForm } = this.state;
    const { billingMethods, actionsDisabled } = this.props;
    return (
      <div className="settings__section">
        {billingMethods.map(this.renderBillingMethod)}

        {showNewMethodForm ? (
          <BillingMethodForm
            onClose={this.toggleNewMethodForm}
            onTokenReady={this.props.onAddNewMethod}
          />
        ) : (
          <div className="settings__card settings__card--blank">
            <div className="settings__card-name">
              <div className="icon" />
              <span />
            </div>
            <button
              className="button button--style-default button--color-blue-ribbon button--size-m"
              disabled={actionsDisabled}
              onClick={this.toggleNewMethodForm}
            >
              add new payment method
            </button>
          </div>
        )}
      </div>
    );
  }
}

BillingMethods.propTypes = {
  onAddNewMethod: PropTypes.func.isRequired,
  billingMethods: PropTypes.array.isRequired,
  onMethodDefault: PropTypes.func.isRequired,
  onMethodDelete: PropTypes.func.isRequired,
  actionsDisabled: PropTypes.bool
};

export default BillingMethods;
