const mapParamsToQuery = (keys, paramObject) =>
  keys.map(key => `${key}=${valueToQuery(paramObject[key])}`).join('&');

export const valueToQuery = value => {
  if (Array.isArray(value)) {
    return value.map(valueToQuery).join(',');
  }

  if (typeof value === 'boolean') {
    return value ? '1' : '0';
  }

  return encodeURIComponent(value);
};

export const toQuery = params => mapParamsToQuery(Object.keys(params), params);

export const toSearchQuery = params =>
  mapParamsToQuery(
    Object.keys(params).filter(key => params[key]),
    params
  );

export const limit = (limit, page) =>
  toQuery({ limit, offset: page ? page * limit : 0 });

export const merge = (...params) =>
  params.filter(stringParam => stringParam.length > 0).join('&');
