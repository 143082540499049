import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'Utils/date';
import { ResponsiveBarChart, PieChart } from '../Charts';
import Customers from './Customers';
import Resends from './Resends';
import Toolbar from './MessageStatsToolbar';
import { ReactComponent as VideoFileIcon } from 'Icons/video-file.svg';
import { ReactComponent as TextFileIcon } from 'Icons/text-file.svg';
import { secondaryBarColor } from '../Charts/colors';

const STATS_TAB = 'stats';
const CUSTOMERS_TAB = 'customers';
const RESENDS_TAB = 'resends';

const TABS = [
  { name: 'stats', value: STATS_TAB },
  { name: 'contacts', value: CUSTOMERS_TAB },
  { name: 'resends', value: RESENDS_TAB }
];

class MessageStats extends React.PureComponent {
  state = { activeTab: 'stats' };
  getTabs() {
    const hasResends = this.props.message.childMessages.length > 0;
    return hasResends ? TABS : TABS.filter(tab => tab.value !== RESENDS_TAB);
  }
  messagePerformanceChartData() {
    return this.props.message.performance
      .map(record => {
        const views = record.viewsCount + record.externalLinkViewsCount;
        return views
          ? {
              date: formatDate(record.datetime),
              views: record.viewsCount + record.externalLinkViewsCount,
              uniqueViews:
                record.uniqueViewsCount + record.uniqueExternalLinkViewsCount
            }
          : null;
      })
      .filter(val => !!val);
  }
  messageSendsChartData() {
    return this.props.message.performance
      .map(record => {
        return record.deliveriesCount
          ? {
              date: formatDate(record.datetime),
              sends: record.deliveriesCount
            }
          : null;
      })
      .filter(val => !!val);
  }
  makePieChartData = parameter => {
    const rawData = this.props.message[parameter];
    return Object.values(
      rawData.reduce((result, record) => {
        const {
          viewsCount,
          externalLinkViewsCount,
          performanceMetric
        } = record;
        if (!result[performanceMetric]) {
          result[performanceMetric] = { name: performanceMetric, value: 0 };
        }
        result[performanceMetric].value += viewsCount + externalLinkViewsCount;

        return result;
      }, {})
    ).filter(record => record.value > 0);
  };
  render() {
    const { message: selectedMessage } = this.props;
    const { activeTab } = this.state;

    const messagePerfomanceData = this.messagePerformanceChartData();
    const messageSendsData = this.messageSendsChartData();

    return (
      <section>
        <div className="library__preview-title">
          {selectedMessage.message.messageType === 'video' ? (
            <VideoFileIcon />
          ) : (
            <TextFileIcon />
          )}
          &nbsp;
          {[
            selectedMessage.message.title,
            formatDate(selectedMessage.message.datetime)
          ].join(' - ')}
        </div>
        <Toolbar
          tabs={this.getTabs()}
          activeTab={activeTab}
          selectTab={tab => this.setState({ activeTab: tab })}
        />
        <div style={{ display: activeTab === STATS_TAB ? 'block' : 'none' }}>
          {messagePerfomanceData.length > 0 && (
            <ResponsiveBarChart
              data={messagePerfomanceData}
              xAxisKey="date"
              bars={[
                { yAxisKey: 'views', name: 'Views' },
                {
                  yAxisKey: 'uniqueViews',
                  name: 'Unique views',
                  color: secondaryBarColor
                }
              ]}
              name="Views"
            />
          )}
          {messageSendsData.length > 0 && (
            <ResponsiveBarChart
              data={messageSendsData}
              xAxisKey="date"
              bars={[{ yAxisKey: 'sends', name: 'Sends' }]}
              name="Sends"
            />
          )}
          {[
            { value: 'gender', title: 'Gender' },
            { value: 'ageGroup', title: 'Age' },
            //{ value: 'referrers', title: 'Referrer' },
            { value: 'location', title: 'Location' }
          ].map(({ value, title }) => {
            const data = this.makePieChartData(value);
            return (
              data.length > 0 && (
                <PieChart key={value} data={data} title={title} />
              )
            );
          })}
        </div>
        <div
          style={{ display: activeTab === CUSTOMERS_TAB ? 'block' : 'none' }}
        >
          <Customers customers={selectedMessage.customers} />
        </div>
        <div style={{ display: activeTab === RESENDS_TAB ? 'block' : 'none' }}>
          <Resends copies={selectedMessage.childMessages} />
        </div>
      </section>
    );
  }
}

MessageStats.propTypes = {
  message: PropTypes.object.isRequired
};

export default MessageStats;
