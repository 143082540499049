import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell
} from 'recharts';

import COLORS, { mainBarColor } from './colors';
import { applyDataLimit } from './utils';

class ResponsiveBarChart extends React.PureComponent {
  render() {
    const { bars, xAxisKey, name, data, groupMode } = this.props;
    const chartData = groupMode
      ? applyDataLimit(data, COLORS.length - 1)
      : data;
    return (
      <React.Fragment>
        <h4 className="message-stats-title">{name}</h4>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
            onClick={(data, index) => {
              //do nothing, somehow it makes bars clickable on ios devices
            }}
          >
            <XAxis type="category" dataKey={xAxisKey} />
            <YAxis type="number" hide={true} />
            <Tooltip />
            {bars &&
              bars.map(barConfig => (
                <Bar
                  key={barConfig.yAxisKey}
                  dataKey={barConfig.yAxisKey}
                  fill={barConfig.color || mainBarColor}
                  name={barConfig.name}
                >
                  {groupMode &&
                    chartData.map((entry, index) => {
                      return (
                        <Cell
                          key={entry[barConfig.yAxisKey]}
                          fill={COLORS[index]}
                        />
                      );
                    })}
                </Bar>
              ))}
          </BarChart>
        </ResponsiveContainer>
      </React.Fragment>
    );
  }
}

ResponsiveBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  xAxisKey: PropTypes.string.isRequired,
  bars: PropTypes.array,
  name: PropTypes.string.isRequired,
  groupMode: PropTypes.bool
};

export default ResponsiveBarChart;
