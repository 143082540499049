import React from 'react';
import PropTypes from 'prop-types';
import Respondent from './Respondent';
import RespondentPlaceholder from './RespondentPlaceholder';
import Waypoint from 'react-waypoint';
import LineLoader from 'Components/Loader/LineLoader';

class Respondents extends React.Component {
  componentDidUpdate(prevProps) {
    const newRespondentSelected =
      prevProps.selectedRespondentId !== this.props.selectedRespondentId;
    if (this.activeRespondentItem && newRespondentSelected) {
      this.scrollContainer.scrollTop = this.activeRespondentItem.offsetTop;
    }
  }

  render() {
    const {
      respondents,
      selectedRespondentId,
      respondentsCount,
      loadMore,
      isLoading,
      children
    } = this.props;
    return (
      <div className="cnt">
        <div className="chat__split chat__split--info">
          <div className="chat__split-left">
            {respondents.length > 0
              ? 'Responses to your care messages.'
              : 'New clients responses will appear here.'}
          </div>
          <div className="chat__split-right">
            {children && 'Replies are sent as SMS text.'}
          </div>
        </div>
        <div className="chat__split chat__split--main">
          <div className="chat__split-left">
            <div className="chat__list">
              <div
                className="scroll scroll--vertical scroll--absolute"
                ref={node => (this.scrollContainer = node)}
              >
                {respondents.length > 0 &&
                  respondents.map(respondent => {
                    const isActive =
                      selectedRespondentId === respondent.customerId;
                    return (
                      <Respondent
                        refCallback={ref => {
                          if (isActive) {
                            this.activeRespondentItem = ref;
                          }
                        }}
                        key={respondent.customerId}
                        isActive={isActive}
                        respondent={respondent}
                      />
                    );
                  })}
                {respondents.length < respondentsCount && !isLoading && (
                  <Waypoint onEnter={loadMore} bottomOffset="-50%" />
                )}
                {isLoading && <LineLoader />}
                {respondents.length === 0 &&
                  [0, 1, 2].map(key => <RespondentPlaceholder key={key} />)}
              </div>
            </div>
          </div>
          <div className="chat__split-right">{children}</div>
        </div>
      </div>
    );
  }
}

Respondents.propTypes = {
  respondents: PropTypes.array.isRequired,
  selectedRespondentId: PropTypes.string,
  respondentsCount: PropTypes.number.isRequired,
  loadMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  children: PropTypes.any
};

export default Respondents;
