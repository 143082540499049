import { wrap } from 'Components/layout';
import Analytic from '.';
import { MESSAGE_SENDER } from '../../roles';

export default {
  base: '/analytic',
  merge: {
    activePage: 'analytic',
    roles: [MESSAGE_SENDER]
  },
  items: [
    { path: '/messages', component: wrap(Analytic), exact: true },
    { path: '/messages/:itemId', component: wrap(Analytic) },
    { path: '/customers', component: wrap(Analytic), exact: true },
    { path: '/customers/:itemId', component: wrap(Analytic) }
  ]
};
