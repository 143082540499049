/* eslint-disable jsx-a11y/heading-has-content */

import React from 'react';

export default () => (
  <div className="chat__list-item chat__list-item--style-blank">
    <div className="user-icon user-icon--size-l" />
    <div className="chat__list-item-row">
      <div className="chat__list-item-col">
        <h3 className="chat__list-item-sender" />
        <div className="chat__list-item-date" />
      </div>
      <div className="chat__list-item-col">
        <div className="chat__list-item-intro" />
      </div>
    </div>
  </div>
);
