//keeps trying to execute async request
export const attemptToDoRequest = async (
  request,
  intervalBetweenAttempts = 1000,
  numberOfAttempts = null
) => {
  let isBusy = false;
  let attemptsCount = 0;

  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      try {
        if (isBusy) {
          return;
        }
        isBusy = true;
        attemptsCount += 1;

        const response = await request();

        isBusy = false;
        clearInterval(interval);

        resolve(response);
      } catch (e) {
        isBusy = false;
        if (attemptsCount === numberOfAttempts) {
          clearInterval(interval);
          reject(e.message);
        }
      }
    }, intervalBetweenAttempts);
  });
};
