import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import SidebarLayout from '../SidebarLayout';
import CategoryList from './CategoryList';
import MessageTemplateSearch from './MessageTemplateSearch';
import { MESSAGE_SENDER } from '../../../roles';

class Categories extends Component {
  static propTypes = {
    ProfileStore: PropTypes.observableObject,
    MyLibraryStore: PropTypes.observableObject,
    MessageTemplateStore: PropTypes.observableObject,
  };

  componentWillMount() {
    this.props.MyLibraryStore.setLibraryFolder(null);
  }

  onSearchhandleSearch = ({ target }) =>
    this.props.MessageTemplateStore.search(target.value || '');

  get pageTitle() {
    const {
      ProfileStore: { roles },
    } = this.props;
    return roles.indexOf(MESSAGE_SENDER) !== -1
      ? 'create a video message'
      : 'record new video';
  }

  render() {
    const { searchTerm } = this.props.MessageTemplateStore;

    return (
      <SidebarLayout
        styleType="categories"
        searchText="search topic"
        searchTerm={searchTerm}
        onSearch={this.onSearchhandleSearch}
      >
        {searchTerm.length === 0 ? (
          <div className="library__categories">
            <div className="library__categories-title">{this.pageTitle}</div>
            <CategoryList />
          </div>
        ) : (
          <React.Fragment>
            <MessageTemplateSearch />
          </React.Fragment>
        )}
      </SidebarLayout>
    );
  }
}

export default inject(
  'MessageTemplateStore',
  'MyLibraryStore',
  'ProfileStore'
)(observer(Categories));
