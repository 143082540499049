import { inject, observer, PropTypes } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { error } from 'Utils/alert';
import React, { Component } from 'react';
import ReactPropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

import './builder.css';

class BuilderRouter extends Component {
  static propTypes = {
    BuilderStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject,
    match: ReactPropTypes.any
  };

  get builderType() {
    const { match } = this.props;
    return decodeURIComponent(match.params.type);
  }

  get builderId() {
    const { match } = this.props;
    return decodeURIComponent(match.params.id);
  }

  componentWillMount() {
    const { BuilderStore, Routing } = this.props;
    BuilderStore.setupFromType(this.builderType, this.builderId).then(
      () => Routing.push(BuilderStore.currentRoute),
      ({ message }) => {
        Routing.push('');
        error(message);
      }
    );
  }

  render() {
    return (
      <div className="builder builder--style-message loading-screen">
        <div className="cnt">
          <div className="builder__split">
            <div className="builder__message">
              <div className="builder__message-title">
                <span>Loading message editor</span>
              </div>
              <div>Please wait...</div>
              <ContentLoader
                height={5}
                speed={1}
                primaryColor={'#888'}
                secondaryColor={'#EEE'}
              >
                <rect x="0" y="0" rx="4" ry="4" width="100%" height="5" />
              </ContentLoader>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject(
  'BuilderStore',
  'Routing'
)(withRouter(observer(BuilderRouter)));
