import React from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from 'Components/VideoPlayer';
import './VideoPreview.css';

class VideoPreview extends React.Component {
  state = { playbackStarted: false };
  onPlaybackStarted = () => {
    if (!this.state.playbackStarted) {
      this.setState({ playbackStarted: true });
    }
  };
  onPlaybackStopped = () => {
    if (this.state.playbackStarted) {
      this.setState({ playbackStarted: false });
    }
  };
  render() {
    const {
      videoRecordedBy,
      videoTitle,
      videoOverlay,
      videoUrl,
      posterUrl,
      frameSource,
      onVideoPropsReceived
    } = this.props;
    const { playbackStarted } = this.state;
    return (
      <div className="video-preview-container">
        {videoTitle && !playbackStarted && (
          <div className="video-preview-title">{videoTitle}</div>
        )}
        <VideoPlayer
          recordedBy={videoRecordedBy}
          onTimeChanged={this.onPlaybackStarted}
          onPause={this.onPlaybackStopped}
          overlay={videoOverlay}
          videoSrc={[`${videoUrl}.mp4`, videoUrl]}
          frameSource={frameSource}
          onVideoPropsReceived={onVideoPropsReceived}
          poster={posterUrl}
        />
      </div>
    );
  }
}

VideoPreview.propTypes = {
  videoTitle: PropTypes.string,
  videoOverlay: PropTypes.object,
  videoUrl: PropTypes.string.isRequired,
  posterUrl: PropTypes.string,
  showFrame: PropTypes.bool,
  onVideoPropsReceived: PropTypes.func,
  frameSource: PropTypes.string
};

export default VideoPreview;
