import React from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer, PropTypes } from 'mobx-react';
import ThankYou from 'Images/thank-you.png';
import {
  BRAND,
  SHORT_BRAND,
  SUPPORT_EMAIL,
  PORTAL_HOST,
  VIDEO_RESOURCES_LINK
} from 'Utils/whitelabeling';
import Logo from 'Components/layout/Logo';
import './index.css';

class Welcome extends React.Component {
  state = {};
  handleVideoResize = width => {
    this.setState({ currentVideoWidth: width });
  };
  handleVideoResize = width => {
    this.setState({ currentVideoWidth: width });
  };
  handleVideoPropsReceived = ({ width, height, ...rest }) => {
    this.setState({ videoFormat: width / height });
  };
  render() {
    const { Routing, CommonStore } = this.props;
    const { from } = Routing.location.state || {
      from: { pathname: '/login' }
    };
    if (!CommonStore.token) {
      return <Redirect to={from} />;
    }

    return (
      <div className="account account--style-sign-up">
        <div className="cnt">
          <div className="account__header">
            <h1 className="account__header-logo">
              <a href="/">
                <Logo />
                <span>digital:care</span>
              </a>
            </h1>
          </div>

          <div className="account__content account__content--style-thank-you">
            <div className="account__intro">
              <h2 className="account__title">Welcome to {BRAND}!</h2>
            </div>
          </div>

          <div className="account__content account__content--style-important-notes">
            <h3 className="account__title">
              Thank you for signing up. We’re excited to have you join us!
            </h3>

            <div className="account__text">
              Getting started is easy. Here are a few simple tips to assist your
              on-boarding process:
            </div>
            <ul className="account__list">
              <li>
                <p>
                  1.{' '}
                  <a href="https://www.youtube.com/watch?v=0cIIWhqH_4Y&t=4s">
                    Watch this 3-minute overview video
                  </a>
                </p>
              </li>
              <li>
                <p>
                  2. Download our recording app on iOS or Android by searching
                  {BRAND}
                </p>
              </li>
              <li>
                <p>
                  3. Access your account and get started at&nbsp;
                  <a href="/login">{PORTAL_HOST}/login</a>
                </p>
              </li>
            </ul>

            <div className="account__cta" style={{ color: '#66748E' }}>
              Please note you can access additional{' '}
              <a href={VIDEO_RESOURCES_LINK}>tutorial videos here</a>.
            </div>
            <br />
            <div className="account__cta" style={{ color: '#66748E' }}>
              Lastly, if you have any questions or to schedule a complimentary
              training and on-boarding call with our customer support team,
              please email{' '}
              <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
            </div>
            <br />

            <h3 className="account__text" style={{ color: '#66748E' }}>
              Sincerely,
              <br />
              <br />
              The {SHORT_BRAND} Team
            </h3>

            <img
              className="account__background"
              src={ThankYou}
              alt="thank you"
            />
          </div>
        </div>
      </div>
    );
  }
}

Welcome.propTypes = {
  CommonStore: PropTypes.observableObject,
  Routing: PropTypes.observableObject
};

export default inject('Routing', 'CommonStore')(observer(Welcome));
