import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { VideoSettings } from 'react-cam-recorder';

class Settings extends Component {
  static propTypes = {
    BuilderStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  componentWillMount() {
    this.props.BuilderStore.setStep('videoSettings', true);
  }

  showClosingDialog = () => this.props.Routing.push('/builder/close-editor');

  getChangeHandler = stateProp => ({ target }) =>
    this.setState({ [stateProp]: target.value });

  setActiveThumbnail = activeThumbnail => this.setState({ activeThumbnail });

  saveSettings = async ({
    videoTitle,
    videoCaption,
    thumbnail,
    decoratedThumbnail,
    decoratedThumbnailWithPlayButton
  }) => {
    await this.props.BuilderStore.updateVideoData(
      videoTitle,
      videoCaption,
      thumbnail,
      decoratedThumbnail,
      decoratedThumbnailWithPlayButton
    );

    this.props.Routing.push(
      `/builder/${this.props.BuilderStore.nextStepData.route}`
    );
  };

  render() {
    const { BuilderStore } = this.props;
    const { videoReady } = BuilderStore;

    const title = BuilderStore.getVideoAttribute('title', '');
    const caption = BuilderStore.getVideoAttribute('caption', '');
    const url = BuilderStore.getVideoAttribute('url', '');
    const thumbnailUrl = BuilderStore.getVideoAttribute('thumbnailUrl', '');
    const overlay = BuilderStore.getVideoAttribute('overlay', null);
    const frameImageSource = BuilderStore.getVideoAttribute(
      'frameImageSource',
      null
    );

    if (!videoReady) {
      return null;
    }

    const timestamp = new Date().getTime();

    return (
      <VideoSettings
        videoTitle={title}
        videoCaption={caption}
        videoSrc={[`${url}.mp4?${timestamp}`, `${url}?${timestamp}`]}
        videoThumbnail={thumbnailUrl ? `${thumbnailUrl}-decorated` : null}
        thumbnailMaxSize={1200}
        thumbnailQuality={0.7}
        decoration={{
          overlay: JSON.parse(JSON.stringify(overlay)),
          playButton: false,
          customFrameSource: frameImageSource
        }}
        onClose={this.showClosingDialog}
        onSave={this.saveSettings}
      />
    );
  }
}

export default inject('BuilderStore', 'Routing')(observer(Settings));
