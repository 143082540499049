import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { MessagesList } from 'Components/Messages';
import Pagination from 'Components/Pagination';

class MessagesListPage extends Component {
  static propTypes = {
    MessagesListStore: PropTypes.observableObject
  };

  render() {
    const { MessagesListStore } = this.props;
    const {
      messages,
      messagesCount,
      page,
      pageSize,
      selectedMessages,
      activeFilters,
      tabs,
      setPreviewMessage,
      activeTab
    } = MessagesListStore;

    const filtersNotActive = Object.keys(activeFilters).length === 0;
    const doesNotHaveMessages = tabs
      ? tabs.reduce((result, tab) => result && tab.count === 0, true)
      : false;

    return (
      <React.Fragment>
        <MessagesList
          toggleMessageSelection={MessagesListStore.toggleMessageSelection}
          showOnboarding={filtersNotActive && doesNotHaveMessages}
          messagesCount={messagesCount}
          messages={messages.map(message => {
            switch (activeTab) {
              case 'sent':
                return { ...message, date: message.lastDeliveredAt };
              case 'scheduled':
                return { ...message, date: message.startingFrom };
              default:
                return { ...message, date: message.createdAt };
            }
          })}
          selectedMessages={selectedMessages}
          setPreviewMessage={setPreviewMessage}
        />
        {pageSize < messagesCount && (
          <Pagination
            total={messagesCount}
            limit={pageSize}
            onPageClick={MessagesListStore.setPage}
            page={page}
          />
        )}
      </React.Fragment>
    );
  }
}

export default inject('MessagesListStore')(observer(MessagesListPage));
