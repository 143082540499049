import React from 'react';
import { withRouter } from 'react-router-dom';
import SignInPage from './SignInPage';
import PropTypes from 'prop-types';
import PersonalizedTextEditor from './PersonalizedTextEditor';
import VideoSettings from './VideoSettings';
import VideoEditor from './VideoEditor';
import EmbedVideoModal from './EmbedVideoModal';
import BuilderSending from './BuilderSending';

const KitchenSink = ({ match }) => {
  switch (match.params.component) {
    case 'personalized-text-editor':
      return <PersonalizedTextEditor />;
    case 'video-settings':
      return <VideoSettings />;
    case 'video-editor':
      return <VideoEditor />;
    case 'embed-video':
      return <EmbedVideoModal />;
    case 'builder-sending':
      return <BuilderSending />;
    default:
      return <SignInPage />;
  }
};

KitchenSink.propTypes = {
  match: PropTypes.object
};

export default withRouter(KitchenSink);
