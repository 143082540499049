/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './uploadbutton.css';

class UploadButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    buttonText: PropTypes.string,
    uploadText: PropTypes.string,
    accept: PropTypes.string,
    disabled: PropTypes.bool,
    disableOnSelect: PropTypes.bool,
    onUpload: PropTypes.func
  };

  static defaultProps = {
    className:
      'button button--style-default button--color-blue-ribbon button--size-s',
    disableOnSelect: true,
    disabled: false,
    buttonText: 'upload',
    uploadText: 'uploading...',
    accept: 'image/jpeg,image/png'
  };

  state = {
    isUploading: false
  };

  fileInput = {};

  handleFileInput() {
    const { files } = this.fileInput;
    const { onUpload } = this.props;

    if (!files || files.length === 0) {
      return;
    }

    let [file] = files;
    const resetUpload = () => this.setState({ isUploading: false });

    this.setState({ isUploading: true }, () =>
      onUpload(file).then(resetUpload, resetUpload)
    );
  }

  render() {
    const { isUploading } = this.state;
    const {
      accept,
      buttonText,
      uploadText,
      className,
      disableOnSelect,
      disabled
    } = this.props;

    let allowInput = !isUploading;

    if (!disableOnSelect) {
      allowInput = true;
    }

    if (disabled) {
      allowInput = false;
    }

    return (
      <button
        className={`upload-button-component ${className} ${
          (isUploading && disableOnSelect) || disabled ? 'is-disabled' : ''
        }`}
      >
        {allowInput && (
          <input
            ref={ref => (this.fileInput = ref)}
            onChange={() => this.handleFileInput()}
            className="file-input"
            type="file"
            accept={accept}
          />
        )}
        {isUploading ? uploadText : buttonText}
      </button>
    );
  }
}

export default UploadButton;
