import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'Components/Button';
import TextareaAutosize from 'react-autosize-textarea';

class SendMessageForm extends Component {
  state = {
    message: ''
  };

  handleSubmit = e => {
    e.preventDefault();
    if (!this.state.message) return;
    this.props.sendMessage(this.state.message);
    this.setState({
      message: ''
    });
  };

  handleChange = e => {
    this.setState({ message: e.target.value });
  };

  render() {
    return (
      <div className="chat__preview-form">
        <form onSubmit={this.handleSubmit}>
          <div className="chat__preview-form-container">
            <TextareaAutosize
              onChange={this.handleChange}
              value={this.state.message}
              className="chat__preview-form-input"
              placeholder="Type message and press Enter to submit..."
              style={{ maxHeight: 100, boxSizing: 'border-box' }}
            />
            <Button name="send" size="m" type="submit" />
          </div>
        </form>
      </div>
    );
  }
}

SendMessageForm.propTypes = {
  sendMessage: PropTypes.func
};

export default SendMessageForm;
