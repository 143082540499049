import request from './request';
import { limit } from './helpers';

export default {
  create: (url) => request.post(`/merchant-asset`, { url }),
  list: (page, pageSize = 10) =>
    request.get(`/merchant-asset?${limit(pageSize, page)}`),
  delete: (url) =>
    new Promise(async (r) => {
      await request.post(`/merchant-asset/delete`, { url });
      setTimeout(() => {
        r();
      }, 2000); //dirty hack, wait 2 seconds in order to make sure projection has been completed
    }),
};
