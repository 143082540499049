import React from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import PropTypes from 'prop-types';
import COLORS from './colors';
import { applyDataLimit } from './utils';

const renderActiveShape = props => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={-16} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {`${value} views`}
      </text>
      <text x={cx} y={cy} dy={32} textAnchor="middle" fill={fill}>
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

renderActiveShape.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  startAngle: PropTypes.number,
  endAngle: PropTypes.number,
  fill: PropTypes.string,
  payload: PropTypes.object,
  percent: PropTypes.number,
  value: PropTypes.number
};

class PieChartComponent extends React.PureComponent {
  state = {
    activeIndex: 0
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index
    });
  };

  render() {
    const data = applyDataLimit(this.props.data, COLORS.length - 1);
    return (
      <React.Fragment>
        <h4 className="message-stats-title">{this.props.title}</h4>
        <ResponsiveContainer width="100%" height={350}>
          <PieChart height={350}>
            <Pie
              activeIndex={this.state.activeIndex}
              activeShape={renderActiveShape}
              data={data}
              cx="50%"
              cy={150}
              innerRadius={80}
              outerRadius={100}
              fill="#43A0F7"
              dataKey="value"
              onMouseEnter={this.onPieEnter}
              onClick={this.onPieEnter}
            >
              {data.map((entry, index) => {
                return <Cell key={entry.name} fill={COLORS[index]} />;
              })}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </React.Fragment>
    );
  }
}

PieChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

export default PieChartComponent;
