import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class Customers extends React.PureComponent {
  prepareCustomersData = () => {
    return Object.values(
      this.props.customers.reduce((result, record) => {
        if (!result[record.customerId]) {
          result[record.customerId] = {
            id: record.customerId,
            name: record.customerName,
            gender: record.gender,
            ageGroup: record.ageGroup,
            deliveriesCount: 0,
            deliveryFailsCount: 0,
            viewsCount: 0,
            uniqueViewsCount: 0
          };
        }
        result[record.customerId].deliveriesCount += record.deliveriesCount;
        result[record.customerId].deliveryFailsCount +=
          record.deliveryFailsCount;
        result[record.customerId].viewsCount +=
          record.viewsCount + record.externalLinkViewsCount;
        result[record.customerId].uniqueViewsCount +=
          record.uniqueViewsCount + record.uniqueExternalLinkViewsCount;
        return result;
      }, {})
    );
  };
  render() {
    const data = this.prepareCustomersData();

    return (
      <div className="table table--style-reports-shares">
        <div className="table__wrapper" style={{ margin: 10 }}>
          <table className="table__table table-recipients">
            <thead className="table__head">
              <tr>
                <th title="name">name</th>
                <th title="gender">gender</th>
                <th title="age">age</th>
                <th title="sends">sends</th>
                <th title="views">views</th>
                <th title="unique views">unique views</th>
              </tr>
            </thead>
            <tbody className="table__body">
              {data.map(record => {
                const ageGroup = record.ageGroup
                  ? record.ageGroup.split('Age').join('')
                  : '-';
                return (
                  <tr key={record.id}>
                    <td title={record.name}>
                      {record.id ? (
                        <Link to={`/analytic/customers/${record.id}`}>
                          {record.name}
                        </Link>
                      ) : (
                        'anonymous'
                      )}
                    </td>
                    <td>{record.gender || '-'}</td>
                    <td>
                      <span title={ageGroup}>{ageGroup || '-'}</span>
                    </td>
                    <td>{record.deliveriesCount}</td>
                    <td>{record.viewsCount}</td>
                    <td>{record.uniqueViewsCount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

Customers.propTypes = {
  customers: PropTypes.array.isRequired
};

export default Customers;
