import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import CommonStore from 'Stores/CommonStore';
import AuthStore from 'Stores/AuthStore';
import { getBrowserIdentity } from 'Utils/browserIdentity';

const superagent = superagentPromise(_superagent, global.Promise);

function makeAppOrigin() {
  const result = window.location.origin;
  // quick and dirty hack
  if (result.indexOf('portal.digitalcare100.info') !== -1) {
    return 'https://portal.digitalcare100.com';
  }

  return result;
}

export const APP_ORIGIN = makeAppOrigin();

export const API_ROOT =
  process.env.REACT_APP_API_URL || 'http://localhost:8080';

const handleErrors = err => {
  if (err && err.response) {
    if (err.response.status === 401) {
      AuthStore.logout();
      err.response = { body: { message: 'Your login has expired.' } };
      window.location.href = window.location.origin;
    } else if (err.response.status === 403) {
      window.location.href = '/billing';
    }
  }

  if (err && err.response && err.response.text) {
    err.response.body = JSON.parse(err.response.text);
  }

  return err;
};

const mapResponseBody = res => res.body;

const requestTokenPlugin = req => {
  if (CommonStore.token) {
    req.set('Authorization', `Bearer ${CommonStore.token}`);
  }
};

const browserIdentityPlugin = req => {
  req.set('BrowserIdentity', getBrowserIdentity());
};

const jsonRequestPlugin = req => {
  req.set('Accept', 'application/json');
  req.set('Content-Type', 'application/json');
};

const applyPlugins = request =>
  request
    .use(requestTokenPlugin)
    .use(jsonRequestPlugin)
    .use(browserIdentityPlugin);

const setupRequest = request =>
  applyPlugins(request)
    .end(handleErrors)
    .then(mapResponseBody);

export default {
  raw: superagent,
  formUpload: (url, file, fields = {}, fileField = 'file', method = 'post') => {
    const agent = superagent[method](`${API_ROOT}${url}`)
      .set('Accept', 'application/json')
      .use(requestTokenPlugin)
      .field(fileField, file);

    for (const [fieldName, fieldValue] of Object.entries(fields)) {
      agent.field(fieldName, fieldValue);
    }

    return agent.end(handleErrors).then(mapResponseBody);
  },
  delWithBody: (url, body) =>
    setupRequest(superagent.del(`${API_ROOT}${url}`).send(body)),
  del: url => setupRequest(superagent.del(`${API_ROOT}${url}`)),
  get: url => setupRequest(superagent.get(`${API_ROOT}${url}`)),
  getWithHeaders: async url => {
    const { body, header } = await applyPlugins(
      superagent.get(`${API_ROOT}${url}`)
    ).end(handleErrors);

    return { body, header };
  },
  put: (url, body) => setupRequest(superagent.put(`${API_ROOT}${url}`, body)),
  post: (url, body) => setupRequest(superagent.post(`${API_ROOT}${url}`, body)),
  sendBeacon: (url, data, sync = false) => {
    const isBeaconApiSupported =
      'navigator' in window && 'sendBeacon' in window.navigator;
    if (isBeaconApiSupported) {
      const formData = new FormData();
      formData.set('BrowserIdentity', getBrowserIdentity());
      Object.keys(data).forEach(
        key => data[key] && formData.append(key, data[key])
      );
      navigator.sendBeacon(`${API_ROOT}${url}`, formData);
      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${API_ROOT}${url}`, !sync);
    const contentType = 'application/json';
    xhr.setRequestHeader('Accept', contentType);
    xhr.setRequestHeader('Content-Type', contentType);
    xhr.responseType = 'json';

    try {
      xhr.send(JSON.stringify(data));
    } catch (error) {
      return false;
    }
  }
};
