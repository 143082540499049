import React from 'react';
import EmbedVideoModal from 'Components/EmbedVideoModal';
import { wrap } from 'Components/layout';

const videoSrc =
  'https://cdn.digitalcare100.info/9d0ed97f-e47b-4b1a-8b3f-ac5659866e68/videos/d157f8c1-a311-4380-961d-ee8a73400ff1';
const posterUrl =
  'https://cdn.digitalcare100.info/9d0ed97f-e47b-4b1a-8b3f-ac5659866e68/image/89f2b581-192b-45a6-9d40-7449dfbc7bd9';
const logoConfig = {
  url:
    'https://cdn.digitalcare100.info/9d0ed97f-e47b-4b1a-8b3f-ac5659866e68/image/03676dd2-39dc-437a-b331-2665e2e05a19',
  bottom: 50,
  right: 50
};
const embeddableUrl =
  'https://portal.digitalcare100.info/message/28280712-d968-4055-897a-e105ed5e3f98';

export default wrap(() => (
  <div className="cnt">
    <EmbedVideoModal
      videoId="28280712-d968-4055-897a-e105ed5e3f98"
      title="Sample video title"
      landingPageUrl="http://test.com/video"
      embeddableUrl={embeddableUrl}
      src={[`${videoSrc}.mp4`, videoSrc]}
      poster={posterUrl}
      logo={logoConfig}
      onClose={() => alert('close')}
    />
  </div>
));
