import { observable, action, decorate, flow, reaction, computed } from 'mobx';
import { VideoAnalyticApi } from 'Api';
import { apiError } from 'Utils/alert';
import RoutingStore from './RoutingStore';
import { formatDate } from 'Utils/date';

const moment = require('moment');

function dateToLocal(dateStr) {
  const currentDate = new Date();
  const date = new Date(dateStr);
  date.setHours(
    currentDate.getHours(),
    currentDate.getMinutes(),
    currentDate.getSeconds()
  );
  return date;
}

class VideoAnalyticStore {
  interval = 'month';
  isLoadingVideos = false;
  isLoadingCustomers = false;
  isLoadingGeneral = false;
  general = {};
  videosMap = [];
  customersMap = [];
  detailedVideos = observable.map({});
  detailedCustomers = observable.map({});
  reportType = null;
  data = [];
  startDate = null;
  endDate = null;
  searchTerm = '';

  selectedItemId = null;

  videosInProcess = {};
  customersInProcess = {};

  constructor() {
    this.applyPredefinedInterval(this.interval);
    reaction(
      () => this.startDate,
      async () => {
        this.reload();
      },
      {
        delay: 100
      }
    );
    reaction(
      () => this.endDate,
      async () => {
        this.reload();
      },
      {
        delay: 100
      }
    );
    reaction(
      () => this.selectedItemId,
      async () => this.loadSelectedItem()
    );
    reaction(
      () => this.videosMap,
      () => {
        if (this.reportType !== 'videos') {
          return;
        }
        this.ensureSelectedReportItem(
          this.videosMap,
          video => video.videoId,
          (id, videos) => {
            return videos.length > 0 ? videos[0].videoId : null;
          }
        );
      },
      {
        delay: 100
      }
    );
    reaction(
      () => this.customersMap,
      () => {
        if (this.reportType !== 'customers') {
          return;
        }
        this.ensureSelectedReportItem(
          this.customersMap,
          customer => customer.customerId
        );
      },
      {
        delay: 100
      }
    );
  }

  ensureSelectedReportItem = (items, idGetter, redirectIdGetter = null) => {
    if (items.length === 0) {
      return;
    }
    const selectedItemStillExists = !!items.find(
      item => idGetter(item) === this.selectedItemId
    );
    if (selectedItemStillExists) {
      return;
    }

    if (window.innerWidth < 768) {
      return;
    }
    RoutingStore.push(
      `/video-analytic/${this.reportType}/${
        redirectIdGetter
          ? redirectIdGetter(this.selectedItemId, items)
          : idGetter(items[0])
      }`
    );
  };

  getStartDate() {
    return moment.utc(dateToLocal(this.startDate)).format('L');
  }

  getEndDate() {
    return moment.utc(dateToLocal(this.endDate)).format('L');
  }

  applyPredefinedInterval = interval => {
    this.endDate = formatDate(new Date());
    const startDate = new Date();
    switch (interval) {
      case 'week':
        startDate.setDate(startDate.getDate() - 7);
        break;
      case 'month':
        startDate.setDate(startDate.getDate() - 31);
        break;
      case 'year':
        startDate.setDate(startDate.getDate() - 365);
        break;
      default:
        startDate.setDate(startDate.getDate() - 7);
        break;
    }
    this.startDate = formatDate(startDate);
  };

  setReportType = type => {
    if (this.reportType !== type) {
      this.reportType = type;
      this.searchTerm = '';
    }
  };

  setDateRange = (startDate, endDate) => {
    if (new Date(startDate).getTime() >= new Date(endDate).getTime()) {
      return;
    }
    if (this.startDate !== startDate) {
      this.startDate = startDate;
    }
    if (this.endDate !== endDate) {
      this.endDate = endDate;
    }
  };

  changeInterval = interval => {
    this.applyPredefinedInterval(interval);
    if (this.interval !== interval && !this.isLoadingVideos) {
      this.interval = interval;
    }
  };

  selectItem = id => {
    if (this.selectedItemId !== id) {
      this.selectedItemId = id;
    }
  };

  setSearch = searchTerm => {
    this.searchTerm = searchTerm;
  };

  reload = () => {
    this.loadGeneralData();
    this.reloadCustomers();
    this.reloadVideos();
    this.loadSelectedItem();
  };

  reloadVideos() {
    this.videosMap = [];
    this.detailedVideos.clear();
    this.loadVideos();
  }

  reloadCustomers() {
    this.customersMap = [];
    this.detailedCustomers.clear();
    this.loadCustomers();
  }

  loadSelectedItem() {
    if (!this.selectedItemId) {
      return;
    }
    switch (this.reportType) {
      case 'videos':
        this.loadVideo(this.selectedItemId);
        break;
      case 'customers':
        this.loadCustomer(this.selectedItemId);
        break;
      default:
        break;
    }
  }

  loadVideos = flow(function*() {
    if (this.isLoadingVideos) {
      return;
    }
    try {
      this.isLoadingVideos = true;
      this.videosMap = yield VideoAnalyticApi.videos(
        this.getStartDate(),
        this.getEndDate()
      );
    } catch (e) {
      apiError(e);
    }
    this.isLoadingVideos = false;
  });

  loadCustomers = flow(function*() {
    if (this.isLoadingCustomers) {
      return;
    }
    try {
      this.isLoadingCustomers = true;
      this.customersMap = yield VideoAnalyticApi.customers(
        this.getStartDate(),
        this.getEndDate()
      );
    } catch (e) {
      apiError(e);
    }
    this.isLoadingCustomers = false;
  });

  loadGeneralData = flow(function*() {
    if (this.isLoadingGeneral) {
      return;
    }
    try {
      this.isLoadingGeneral = true;
      this.general = yield VideoAnalyticApi.general(
        this.getStartDate(),
        this.getEndDate()
      );
    } catch (e) {
      apiError(e);
    }
    this.isLoadingGeneral = false;
  });

  loadVideo = flow(function*(id) {
    if (this.videosInProcess[id]) {
      return;
    }
    this.videosInProcess[id] = true;
    try {
      const video = yield VideoAnalyticApi.video(
        id,
        this.getStartDate(),
        this.getEndDate()
      );
      this.detailedVideos.set(id, video);
    } catch (e) {
      apiError(e);
    }
    delete this.videosInProcess[id];
  });

  loadCustomer = flow(function*(id) {
    if (this.customersInProcess[id]) {
      return;
    }
    this.customersInProcess[id] = true;
    try {
      const customer = yield VideoAnalyticApi.customer(
        id,
        this.getStartDate(),
        this.getEndDate()
      );
      this.detailedCustomers.set(id, customer);
    } catch (e) {
      apiError(e);
    }
    delete this.customersInProcess[id];
  });

  get selectedItem() {
    switch (this.reportType) {
      case 'videos': {
        const detailedVideo = this.detailedVideos.get(this.selectedItemId);
        if (!detailedVideo) {
          return null;
        }

        const video = this.videosMap.find(
          video => video.videoId === this.selectedItemId
        );

        return video ? { ...detailedVideo, video } : null;
      }
      case 'customers': {
        const detailedCustomerReport = this.detailedCustomers.get(
          this.selectedItemId
        );
        if (!detailedCustomerReport) {
          return null;
        }
        const customer = this.customers.find(
          customer => customer.customerId === this.selectedItemId
        );

        return customer ? { ...detailedCustomerReport, customer } : null;
      }
      default:
        return null;
    }
  }

  get videos() {
    const searchTerm = this.searchTerm ? this.searchTerm.toLowerCase() : null;
    return searchTerm
      ? this.videosMap.filter(video => {
          return video.title.toLowerCase().indexOf(searchTerm) !== -1;
        })
      : this.videosMap;
  }

  get customers() {
    const searchTerm = this.searchTerm ? this.searchTerm.toLowerCase() : null;
    return searchTerm
      ? this.customersMap.filter(customer => {
          return customer.customerName.toLowerCase().indexOf(searchTerm) !== -1;
        })
      : this.customersMap;
  }
}

const MobxVideoAnalyticStore = decorate(VideoAnalyticStore, {
  isLoadingVideos: observable,
  isLoadingGeneral: observable,
  selectedItemId: observable,
  videosMap: observable,
  videos: computed,
  customersMap: observable,
  customers: computed,
  general: observable,
  reportType: observable,
  interval: observable,
  startDate: observable,
  endDate: observable,
  changeInterval: action,
  reload: action,
  setDateRange: action,
  selectedItem: computed,
  setReportType: action,
  selectItem: action,
  searchTerm: observable,
  setSearch: action
});

export default new MobxVideoAnalyticStore();
