import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'Utils/date';
import { unique } from 'Utils/arrayHelper';

class Resends extends React.PureComponent {
  prepareResendsData = () => {
    return Object.values(
      this.props.copies.reduce((result, record) => {
        if (!result[record.messageId]) {
          result[record.messageId] = {
            sentAt: formatDate(record.resentAt || record.copiedAt),
            id: record.messageId,
            deliveriesCount: 0,
            deliveryFailsCount: 0,
            viewsCount: 0,
            uniqueViewsCount: 0,
            segments: []
          };
        }
        result[record.messageId].deliveriesCount += record.deliveriesCount;
        result[record.messageId].deliveryFailsCount +=
          record.deliveryFailsCount;
        result[record.messageId].viewsCount +=
          record.viewsCount + record.externalLinkViewsCount;
        result[record.messageId].uniqueViewsCount +=
          record.uniqueViewsCount + record.uniqueExternalLinkViewsCount;
        result[record.messageId].segments = unique(
          result[record.messageId].segments.concat(record.segments)
        );
        return result;
      }, {})
    );
  };
  render() {
    const data = this.prepareResendsData();
    return (
      <div className="table table--style-reports-shares">
        <div className="table__wrapper" style={{ margin: 10 }}>
          <table className="table__table table-recipients">
            <thead className="table__head">
              <tr>
                <th>groups</th>
                <th>sent at</th>
                <th>sends</th>
                <th>views</th>
                <th>unique views</th>
              </tr>
            </thead>
            <tbody className="table__body">
              {data.map(record => (
                <tr key={record.id}>
                  <th>
                    {record.segments.length > 0
                      ? record.segments.join(', ')
                      : 'Custom selection'}
                  </th>
                  <th>{record.sentAt}</th>
                  <th>{record.deliveriesCount}</th>
                  <th>{record.viewsCount}</th>
                  <th>{record.uniqueViewsCount}</th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

Resends.propTypes = {
  copies: PropTypes.array.isRequired
};

export default Resends;
