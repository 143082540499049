import request from './request';
import { limit, toSearchQuery, merge } from './helpers';

export default {
  getById: id => request.get(`/sequence/${id}`),
  get: (page, limitBy = 10, search = null, options = {}) =>
    request.get(
      `/sequence?${merge(
        limit(limitBy, page),
        toSearchQuery({ ...options, search })
      )}`
    ),
  create: sequence => request.post('/sequence', sequence),
  copy: id => request.post(`/sequence/${id}/copy`),
  enroll: (id, data) => request.post(`/sequence/${id}/enroll`, data),
  update: (id, data) => request.put(`/sequence/${id}`, data),
  delete: id => request.del(`/sequence/${id}`)
};
