/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { PropTypes as ReactPropTypes } from 'prop-types';
import { inject, observer, PropTypes } from 'mobx-react';

import { ReactComponent as CloseIcon } from 'Icons/close.svg';
import { ReactComponent as TrashIcon } from 'Icons/Buttons/trash.svg';
import { ReactComponent as PencilIcon } from 'Icons/pencil.svg';
import { ReactComponent as CheckIcon } from 'Icons/check.svg';
import InputField from 'Components/InputField';
import Tags from 'Components/Tags';
import ConfirmModal from 'Components/ConfirmModal';
import { textToColor } from 'Utils/string';
import { CUSTOMER_ATTRIBUTES } from 'Utils/whitelabeling';

class CustomerDetail extends Component {
  static propTypes = {
    CustomerStore: PropTypes.observableObject,
    onDeleteCustomer: ReactPropTypes.func,
    onCloseClick: ReactPropTypes.func,
  };

  state = {
    confirmModalShow: false,
    editMode: false,
  };

  handleUpdateCustomer = async (e) => {
    e.preventDefault();
    try {
      await this.props.CustomerStore.updateCustomer();
      this.setState({ editMode: false });
    } catch (e) {
      //console.log(e);
    }
  };

  handleDeleteCustomer = (customer) => {
    this.props.CustomerStore.deleteCustomer(customer.id);
    this.props.onDeleteCustomer();
  };

  handleTagsOnChange = (tags) => {
    const { selectedCustomer } = this.props.CustomerStore;
    const customerTags = selectedCustomer.tags.map((tag) =>
      tag.value ? tag.value : tag
    );
    const currentTagsValue = tags.map((tag) => tag.value);
    this.props.CustomerStore.updateSelectedCustomer('tags', tags);

    if (customerTags.length > currentTagsValue.length) {
      const tagsToRemove = customerTags.filter(
        (x) => !currentTagsValue.includes(x)
      );
      return this.props.CustomerStore.removeTagsFromCustomer(tagsToRemove);
    }
    const tagsToAdd = currentTagsValue.filter((u) => !customerTags.includes(u));
    return tagsToAdd.length > 0
      ? this.props.CustomerStore.addTagsToCustomer(tagsToAdd[0])
      : null;
  };

  attributeChangeHandler = (attributeName) => {
    return (e) => {
      if (!e) return;
      if (!e.target) {
        return this.props.CustomerStore.updateSelectedCustomer(
          attributeName,
          e
        );
      }
      this.props.CustomerStore.updateSelectedCustomer(
        attributeName,
        e.target.value
      );
    };
  };

  handelToggleConfirmModal = () => {
    this.setState({
      confirmModalShow: !this.state.confirmModalShow,
    });
  };

  enableEditMode = () => {
    this.setState({ editMode: true }, () => this.firstNameInput.focus());
  };

  render() {
    const {
      selectedCustomer,
      isCustomerValueChanged,
      updateInProgress,
      customerFormObj,
    } = this.props.CustomerStore;
    const { validationErrors } = customerFormObj;
    const { editMode } = this.state;
    const { onCloseClick } = this.props;
    const { allTags } = this.props.CustomerStore.tags;
    const fullName = [selectedCustomer.firstName, selectedCustomer.lastName];

    const male = 'male';
    const female = 'female';

    const inputs = [
      CUSTOMER_ATTRIBUTES.name
        ? {
            attributeName: 'firstName',
            inputType: 'text',
            label: 'first name',
            placeholder: 'empty',
            changeHandler: this.attributeChangeHandler('firstName'),
            refCallback: (ref) => (this.firstNameInput = ref),
          }
        : null,
      CUSTOMER_ATTRIBUTES.name
        ? {
            attributeName: 'lastName',
            inputType: 'text',
            label: 'last name',
            placeholder: 'empty',
            changeHandler: this.attributeChangeHandler('lastName'),
          }
        : null,
      CUSTOMER_ATTRIBUTES.phone
        ? {
            attributeName: 'phone',
            inputType: 'phone',
            label: 'phone',
            placeholder: 'empty',
            changeHandler: this.attributeChangeHandler('phone'),
          }
        : null,
    ].filter((val) => !!val);

    const eventsInputs = [
      /*{
        attributeName: 'nextVisitDate',
        label: 'upcoming visit',
        inputType: 'date-picker',
        placeholder: 'empty',
        changeHandler: this.attributeChangeHandler('nextVisitDate')
      },
      {
        attributeName: 'lastVisitedAt',
        label: 'last visit',
        inputType: 'date-picker',
        placeholder: 'empty',
        changeHandler: this.attributeChangeHandler('lastVisitedAt')
      },*/
      CUSTOMER_ATTRIBUTES.birthday
        ? {
            attributeName: 'birthday',
            label: 'birthday',
            inputType: 'date-picker',
            placeholder: 'empty',
            changeHandler: this.attributeChangeHandler('birthday'),
          }
        : null,
    ].filter((val) => !!val);
    if (this.state.confirmModalShow) {
      return (
        <ConfirmModal
          onConfirm={() => this.handleDeleteCustomer(selectedCustomer)}
          onCancel={this.handelToggleConfirmModal}
        />
      );
    }
    return (
      <div className="modal modal--style-pull-right modal--type-edit-customer is-active">
        <div className="modal__outside-close" />
        <div className="modal__window">
          <div className="profile">
            <div className="profile__top">
              <a
                href="javascript:void(0)"
                className="profile__top-close"
                onClick={onCloseClick}
              >
                <CloseIcon />
              </a>
              <div className="profile__top-actions">
                <ul className="actions actions--style-delimeter">
                  <li>
                    {editMode ? (
                      <button type="submit" onClick={this.handleUpdateCustomer}>
                        <CheckIcon
                          disabled={!isCustomerValueChanged || updateInProgress}
                        />
                      </button>
                    ) : (
                      <button onClick={this.enableEditMode}>
                        <PencilIcon />
                      </button>
                    )}
                  </li>
                  {!editMode && (
                    <li>
                      <button onClick={this.handelToggleConfirmModal}>
                        <TrashIcon />
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div className="profile__name">
              <div
                className="user-icon user-icon--size-l"
                style={{ backgroundColor: `${textToColor(fullName.join())}` }}
              >
                {fullName.map((word) => word[0])}
              </div>
              <h2>{fullName.join(' ')}</h2>
            </div>

            {CUSTOMER_ATTRIBUTES.phone && (
              <div className="profile__title">
                <h3>
                  contact
                  {selectedCustomer.linkedToNumber
                    ? ` (assigned number ${selectedCustomer.linkedToNumber})`
                    : ''}
                </h3>
              </div>
            )}

            {inputs.map((input) => (
              <InputField
                key={input.attributeName}
                inline={true}
                disabled={!editMode}
                {...input}
                values={selectedCustomer}
                error={validationErrors[input.attributeName]}
              />
            ))}

            {CUSTOMER_ATTRIBUTES.gender && (
              <ul className="add-people__nav">
                <li
                  className={selectedCustomer.sex === male ? 'is-active' : ''}
                >
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      editMode &&
                        this.props.CustomerStore.updateSelectedCustomer(
                          'sex',
                          male
                        );
                    }}
                  >
                    Male
                  </a>
                </li>
                <li
                  className={selectedCustomer.sex === female ? 'is-active' : ''}
                >
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      editMode &&
                        this.props.CustomerStore.updateSelectedCustomer(
                          'sex',
                          female
                        );
                    }}
                  >
                    Female
                  </a>
                </li>
              </ul>
            )}

            {eventsInputs.length > 0 && (
              <div className="profile__title">
                <h3>events</h3>
              </div>
            )}
            {eventsInputs.map((event) => (
              <InputField
                key={event.attributeName}
                inline={true}
                disabled={!editMode}
                {...event}
                values={selectedCustomer}
                error={validationErrors[event.attributeName]}
              />
            ))}

            <div className="profile__title">
              <h3>notes</h3>
            </div>

            <div className="form form--style-inline">
              <label className="form__label" />
              <div className="form__container">
                <textarea
                  className="form__textarea"
                  placeholder="empty"
                  disabled={!editMode}
                  value={selectedCustomer.note || ''}
                  onChange={this.attributeChangeHandler('note')}
                />
              </div>
            </div>

            <div className="profile__title">
              <h3>groups</h3>
            </div>
            <div className="tags tags--size-m">
              <Tags
                tags={selectedCustomer.tags}
                onChange={this.handleTagsOnChange}
                suggestions={allTags}
                disabled={!editMode}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('CustomerStore')(observer(CustomerDetail));
