/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { VideoAnalyticApi } from 'Api';
import LineLoader from 'Components/Loader/LineLoader';
import Modal from '../Modal';
import { ReactComponent as CloseIcon } from 'Icons/close.svg';
import Toolbar from './Toolbar';
import Analytic from './Analytic';
import { formatDate } from '../../Utils/date';

class VideoAnalyticModal extends React.Component {
  state = { interval: null, startDate: null, endDate: null };
  applyPredefinedInterval = interval => {
    const { interval: currentInterval, isLoading } = this.state;
    if (isLoading || currentInterval === interval) {
      return;
    }
    const endDate = new Date();
    const startDate = new Date();

    switch (interval) {
      case 'week':
        startDate.setDate(startDate.getDate() - 7);
        break;
      case 'month':
        startDate.setDate(startDate.getDate() - 31);
        break;
      case 'year':
        startDate.setDate(startDate.getDate() - 365);
        break;
      default:
        startDate.setDate(startDate.getDate() - 7);
        break;
    }

    this.setState({
      interval,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    });
    setTimeout(this.loadReport);
  };
  setDateRange = (startDate, endDate) => {
    if (this.state.isLoading) {
      return;
    }

    if (new Date(startDate).getTime() >= new Date(endDate).getTime()) {
      return;
    }
    this.setState({ startDate, endDate, interval: null });
    setTimeout(this.loadReport);
  };
  loadReport = async () => {
    if (this.state.isLoading) {
      return;
    }
    this.setState({ isLoading: true });
    const { videoId } = this.props;
    const { startDate, endDate } = this.state;
    const report = await VideoAnalyticApi.video(videoId, startDate, endDate);
    this.setState({ report, isLoading: false });
  };
  componentDidMount() {
    this.applyPredefinedInterval('month');
  }
  render() {
    const { onClose } = this.props;
    const { isLoading, interval, startDate, endDate, report } = this.state;
    return (
      <Modal maxWidth="80%">
        {onClose && (
          <div style={{ textAlign: 'right', marginBottom: 10 }}>
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                onClose();
              }}
              style={{ color: '#000' }}
            >
              <CloseIcon
                style={{ display: 'inline-block', height: '1em', width: '1em' }}
              />
            </a>
          </div>
        )}
        {this.state.startDate && (
          <Toolbar
            selectDateInterval={this.applyPredefinedInterval}
            setDateRange={this.setDateRange}
            selectedInterval={interval}
            startDate={startDate}
            endDate={endDate}
            disabled={isLoading}
          />
        )}
        {isLoading && <LineLoader />}
        {!isLoading && report && (
          <Analytic
            report={report}
            fullReportLink={`/video-analytic/videos/${this.props.videoId}`}
          />
        )}
      </Modal>
    );
  }
}

export default VideoAnalyticModal;
