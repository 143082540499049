import request from './request';

export default {
  checkVideoProcessed: async path => {
    const { result } = await request.post(
      `/video-recorder/check-processed?path=${path}.mp4`
    );
    return result;
  }
};
