/* eslint-disable no-unused-vars */
import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import ReactPropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { DISABLE_BILLING } from 'Utils/whitelabeling';

const UNSUBSCRIBED_ACTIVE_LOCATIONS = DISABLE_BILLING
  ? ['/profile']
  : ['/billing', '/profile', '/subscription'];

class PrivateRoute extends React.Component {
  static propTypes = {
    CommonStore: PropTypes.observableObject.isRequired,
    component: ReactPropTypes.func.isRequired,
    activePage: ReactPropTypes.string,
    ProfileStore: PropTypes.observableObject.isRequired,
  };

  renderComponent = (props) => {
    const { component: Component, ProfileStore } = this.props;
    const {
      id,
      serviceSubscriptionId,
      serviceSubscriptionState,
      freePortalAccess,
    } = ProfileStore.currentProfile;
    if (!id) {
      // do not render component till we load merchant profile
      return null;
    }

    const hasActiveSubscription =
      freePortalAccess ||
      (serviceSubscriptionId && serviceSubscriptionState === 'active');
    const mustRender =
      hasActiveSubscription ||
      UNSUBSCRIBED_ACTIVE_LOCATIONS.indexOf(
        window.location.pathname.toLowerCase()
      ) !== -1;

    return mustRender ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/billing',
          state: { from: props.location },
        }}
      />
    );
  };

  render() {
    const {
      CommonStore,
      ProfileStore,
      activePage,
      component: _,
      roles = [],
      properties = {},
      ...rest
    } = this.props;
    const { token } = CommonStore;

    if (activePage) {
      setTimeout(() => CommonStore.setActivePage(activePage));
    }

    const isProfileLoaded = ProfileStore.currentProfile.id;

    token && !isProfileLoaded && ProfileStore.loadProfile();

    if (roles.length > 0) {
      if (!isProfileLoaded) {
        return null;
      }
      const hasAccess = roles.reduce(
        (result, role) =>
          ProfileStore.roles.indexOf(role) !== -1 ? true : result,
        false
      );
      if (!hasAccess) {
        return (
          <Route
            {...rest}
            render={(props) => (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: props.location },
                }}
              />
            )}
          />
        );
      }
    }

    return (
      <Route
        {...rest}
        render={(props) =>
          token ? (
            this.renderComponent({ ...props, ...properties })
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
}

export default inject('CommonStore', 'ProfileStore')(observer(PrivateRoute));
