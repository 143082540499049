import { wrap } from 'Components/layout';
import CustomerManagement from '.';
import AddCustomer from './Add/AddCustomer';
import ImportSuccess from './Add/ImportSuccess';
import { MESSAGE_SENDER } from '../../roles';

export default {
  base: '/people',
  merge: {
    exact: true,
    activePage: 'people',
    roles: [MESSAGE_SENDER]
  },
  items: [
    { path: '/', component: wrap(CustomerManagement) },
    { path: '/list-imported/:importId', component: wrap(CustomerManagement) },
    { path: '/tag/:tag', component: wrap(CustomerManagement) },
    { path: '/search/:term', component: wrap(CustomerManagement) },
    { path: '/add', component: wrap(AddCustomer) },
    { path: '/add/batch', component: wrap(AddCustomer) },
    { path: '/imported/:importId/:importCount', component: ImportSuccess },
    { path: '*', component: wrap(CustomerManagement) }
  ]
};
