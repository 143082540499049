/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as FacebookIcon } from 'Icons/Social/facebook.svg';
import { ReactComponent as TwitterIcon } from 'Icons/Social/twitter.svg';
import { ReactComponent as LinkedinIcon } from 'Icons/Social/linkedin.svg';
import { ReactComponent as EmailIcon } from 'Icons/Social/email.svg';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share';
import { FACEBOOK, TWITTER, LINKEDIN } from 'Consts/socialShare';
import { addQueryParamsToUrl } from 'Utils/url';

const SocialShare = ({ url, title, onShare }) => {
  const shareOptions = [
    {
      element: (
        <FacebookShareButton
          url={addQueryParamsToUrl(url, { referrer: FACEBOOK })}
          beforeOnClick={() => onShare('facebook')}
        >
          <FacebookIcon />
        </FacebookShareButton>
      ),
      key: 'facebook'
    },
    {
      element: (
        <TwitterShareButton
          url={addQueryParamsToUrl(url, { referrer: TWITTER })}
          title={title}
          beforeOnClick={() => onShare('twitter')}
        >
          <TwitterIcon />
        </TwitterShareButton>
      ),
      key: 'twitter'
    },
    {
      element: (
        <LinkedinShareButton
          url={addQueryParamsToUrl(url, { referrer: LINKEDIN })}
          title={title}
          beforeOnClick={() => onShare('linkedin')}
        >
          <LinkedinIcon />
        </LinkedinShareButton>
      ),
      key: 'linkedin'
    },
    {
      element: (
        <EmailShareButton
          url={url}
          subject={title}
          beforeOnClick={() => onShare('email')}
        >
          <EmailIcon />
        </EmailShareButton>
      ),
      key: 'email'
    }
  ];
  return (
    <div className="message__social">
      {shareOptions.map(({ element, key }) => (
        <a key={key} href="#" className="message__social-icon">
          {element}
        </a>
      ))}
    </div>
  );
};

SocialShare.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  onShare: PropTypes.func.isRequired
};

export default SocialShare;
