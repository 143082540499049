/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as FacebookIcon } from 'Icons/Social/facebook.svg';
import { ReactComponent as TwitterIcon } from 'Icons/Social/twitter.svg';
import { ReactComponent as LinkedinIcon } from 'Icons/Social/linkedin.svg';
import { ReactComponent as EmailIcon } from 'Icons/Social/email.svg';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share';
import { FACEBOOK, TWITTER, LINKEDIN } from 'Consts/socialShare';
import { addQueryParamsToUrl } from 'Utils/url';

class SocialShare extends React.Component {
  get shareOptions() {
    const { url, title } = this.props;
    return [
      {
        element: (
          <FacebookShareButton
            url={addQueryParamsToUrl(url, { referrer: FACEBOOK })}
          >
            <FacebookIcon />
          </FacebookShareButton>
        ),
        key: 'facebook'
      },
      {
        element: (
          <TwitterShareButton
            url={addQueryParamsToUrl(url, { referrer: TWITTER })}
            title={title}
          >
            <TwitterIcon />
          </TwitterShareButton>
        ),
        key: 'twitter'
      },
      {
        element: (
          <LinkedinShareButton
            url={addQueryParamsToUrl(url, { referrer: LINKEDIN, compiled: true })}
            title={title}
          >
            <LinkedinIcon />
          </LinkedinShareButton>
        ),
        key: 'linkedin'
      },
      {
        element: (
          <EmailShareButton url={url} subject={title}>
            <EmailIcon />
          </EmailShareButton>
        ),
        key: 'email'
      }
    ].filter(val => !!val);
  }

  render() {
    return (
      <div className="message__social">
        {this.shareOptions.map(({ element, key }) => (
          <a key={key} href="#" className="message__social-icon">
            {element}
          </a>
        ))}
      </div>
    );
  }
}

SocialShare.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default SocialShare;
