import request, { APP_ORIGIN } from './request';

export default {
  sendSMS: (toNumber, message, to, params, prefix) =>
    request.post('/magic-link/send', {
      origin: APP_ORIGIN,
      to: toNumber,
      message,
      parameters: { to, ...params },
      prefix
    }),
  getLink: (to, params, prefix) =>
    request
      .post('/magic-link', {
        origin: APP_ORIGIN,
        parameters: { to, ...params },
        prefix
      })
      .then(({ link }) => link)
};
