import React from 'react';
import ReactPropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { inject, observer, PropTypes } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import LandingPageComponent from 'Components/CustomerLandingPage';
import Loader from 'Components/Loader/FacebookLoader';
import { ReactComponent as SunIcon } from 'Icons/sun.svg';
import { ReactComponent as MoonIcon } from 'Icons/moon.svg';
import './index.css';

let playedSeconds = 0;
let playbackStartedAt;

class LandingPage extends React.Component {
  state = {};
  componentWillUnmount() {
    delete window.onbeforeunload;
    delete window.onunload;
    window.removeEventListener('visibilitychange', this.reportPlayedSeconds);
    this.reportPlayedSeconds();
    document.body.classList.toggle('body');
    document.body.classList.toggle('body--responsive');
    document.body.classList.remove('dark-mode');
  }
  isPreviewMode = () => {
    const { customerId } = this.props.match.params;
    return customerId === 'preview';
  };
  isPlainVideoMode = () => {
    const { customerId } = this.props.match.params;
    return !customerId;
  };
  onStartPlayback = () => {
    playbackStartedAt = new Date();
  };
  onPausePlayback = () => {
    this.updatePlayedSecondsCounter();
  };
  updatePlayedSecondsCounter = () => {
    if (!playbackStartedAt) {
      return;
    }
    playedSeconds +=
      (new Date().getTime() - playbackStartedAt.getTime()) / 1000;
    playbackStartedAt = null;
  };
  reportPlayedSeconds = () => {
    this.updatePlayedSecondsCounter();
    if (playedSeconds >= 1 && !this.isPreviewMode()) {
      this.props.LandingPageStore.reportPlayedSeconds(playedSeconds);
    }
    playedSeconds = 0;
  };

  componentDidMount() {
    const self = this;
    window.onbeforeunload = window.onunload = function() {
      self.reportPlayedSeconds();
    };
    document.addEventListener('visibilitychange', function() {
      if (document.visibilityState === 'visible') {
        // do nothing
      } else {
        self.reportPlayedSeconds();
      }
    });

    document.body.classList.toggle('body', true);
    document.body.classList.toggle('body--responsive', true);

    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      // dark mode
      document.body.classList.add('dark-mode');
      this.setState({ darkMode: true });
    }
    const { LandingPageStore, match } = this.props;
    const { messageId, customerId } = match.params;
    const previewMode = this.isPreviewMode();
    LandingPageStore.customizeMessage(
      messageId,
      previewMode ? null : customerId,
      previewMode
    );
    if (customerId && !previewMode) {
      LandingPageStore.loadMessagesHistory(customerId);
    }
  }
  componentWillReceiveProps(nextProps) {
    const { LandingPageStore, match } = nextProps;
    const { messageId, customerId } = match.params;
    const currentMatch = this.props.match;
    if (
      currentMatch.messageId !== messageId ||
      currentMatch.customerId !== customerId
    ) {
      this.reportPlayedSeconds();
      LandingPageStore.customizeMessage(messageId, customerId);
      if (currentMatch.customerId !== customerId) {
        LandingPageStore.loadMessagesHistory(customerId);
      }
    }
  }

  render() {
    const { darkMode } = this.state;
    const {
      isLoadingMessage,
      isLoadingHistory,
      customizedMessage,
      messagesHistory
    } = this.props.LandingPageStore;

    if (isLoadingMessage || isLoadingHistory) {
      return (
        <div className="message">
          <div className="cnt">
            <div className="message__limit">
              <Loader />
            </div>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{customizedMessage.title}</title>
          <meta property="og:title" content={customizedMessage.title} />
          <meta
            property="og:description"
            content={customizedMessage.landingPageText}
          />
          <meta
            property="og:image"
            content={`${customizedMessage.videoThumbnailUrl}-decorated`}
          />
        </Helmet>
        <i
          className="dark-mode-control"
          onClick={() => {
            this.setState({ darkMode: !darkMode });
            document.body.classList.toggle('dark-mode');
          }}
        >
          {darkMode ? <SunIcon /> : <MoonIcon />}
        </i>
        <LandingPageComponent
          message={customizedMessage}
          history={messagesHistory}
          plainVideoMode={this.isPlainVideoMode()}
          onShare={source => this.props.LandingPageStore.trackShare(source)}
          onPlay={this.onStartPlayback}
          onPause={this.onPausePlayback}
        />
      </React.Fragment>
    );
  }
}

LandingPage.propTypes = {
  match: ReactPropTypes.object,
  LandingPageStore: PropTypes.observableObject
};

export default inject('LandingPageStore')(withRouter(observer(LandingPage)));
