// @flow

import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './AuthLayout';
import InputField from 'Components/InputField';
import Button from 'Components/Button';

class RecoverPass extends React.Component {
  static propTypes = {
    AuthStore: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  handleEmailChange = e => this.props.AuthStore.setEmail(e.target.value);
  handleSubmitForm = e => {
    e.preventDefault();
    this.props.AuthStore.resetPassword();
  };

  componentWillMount() {
    this.props.AuthStore.reset();
    const token = this.props.Routing.history.location.search.split('=')[1];
    if (token) {
      this.props.CommonStore.setToken(token);
    }
  }

  render() {
    if (this.props.CommonStore.token) {
      return <Redirect to="/profile#password" />;
    }
    const {
      values,
      validationErrors,
      errorMessage,
      inProgress
    } = this.props.AuthStore;
    const emailField = {
      label: 'enter your email',
      attributeName: 'email',
      inputType: 'email',
      placeholder: 'email address',
      changeHandler: this.handleEmailChange
    };
    return (
      <React.Fragment>
        <div className="account__form">
          <form onSubmit={this.handleSubmitForm}>
            <h2 className="account__title">reset password</h2>

            {Object.keys(validationErrors).length === 0 && errorMessage && (
              <h3>{errorMessage}</h3>
            )}
            <InputField
              key={emailField.attributeName}
              {...emailField}
              values={values}
              error={validationErrors[emailField.attributeName]}
            />

            <div className="account__form-buttons">
              <Button
                type="submit"
                name="reset password"
                disabled={inProgress}
              />
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const ObserverRecoverPass = inject(
  'AuthStore',
  'Routing',
  'CommonStore'
)(observer(RecoverPass));

export default () => (
  <AuthLayout>
    <ObserverRecoverPass />
  </AuthLayout>
);
