import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from 'Components/PrivateRoute';
import KitchenSink from './KitchenSink';

import routes from './Pages/routes';

const mergeChildRoutes = routeArray =>
  [].concat.apply(
    [],
    routeArray.map(({ items, base = '', merge = {} }) =>
      items.map(item => {
        item.path = base + item.path;
        return { ...merge, ...item };
      })
    )
  );

const privateRoutes = mergeChildRoutes(routes.private);
const publicRoutes = mergeChildRoutes(routes.public);
const defaultRoute = routes.default;

export default () => (
  <Switch>
    <Redirect from="/" exact to={defaultRoute} />
    {privateRoutes.map((route, key) => (
      <PrivateRoute key={key} {...route} />
    ))}
    {publicRoutes.map((route, key) => (
      <Route key={key} {...route} />
    ))}
    <Route path="/kitchensink/:component" component={KitchenSink} />
  </Switch>
);
