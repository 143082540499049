import React, { Component } from 'react';

export default class EmptyFavorites extends Component {
  render() {
    return (
      <div className="empty empty--style-library">
        <div className="cnt">
          <div className="empty__title">
            quick access to your favorite content
          </div>

          <div className="empty__intro">
            Your favorite content from Library will appear here. Favorites gives
            you access to most used content from our Library.
          </div>
        </div>
      </div>
    );
  }
}
