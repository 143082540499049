import { observable, action, flow, decorate, computed } from 'mobx';
import { apiError } from 'Utils/alert';
import { MessageTemplateApi } from 'Api';
import { partitionBy } from 'Utils/arrayHelper';
import ProfileStore from './ProfileStore';
import { MESSAGE_SENDER } from '../roles';

class FavoritesStore {
  isLoading = false;
  currentTab = 'all';
  page = 0;
  searchTerm = '';
  favoriteIds = [];
  allTemplates = [];
  videoTemplates = [];
  messageTemplates = [];

  loadFavorites = flow(function*() {
    try {
      this.videoTemplates = [];
      this.messageTemplates = [];
      const { favoriteIds } = this;

      if (favoriteIds.length === 0) {
        return;
      }

      this.isLoading = true;

      const { data } = yield MessageTemplateApi.getMessageTemplates(
        favoriteIds
      );

      [this.videoTemplates, this.messageTemplates] = partitionBy(
        data,
        item => item.scriptText
      );

      const canSendMessages = ProfileStore.roles.indexOf(MESSAGE_SENDER) !== -1;

      this.allTemplates = data.filter(
        ({ scriptText }) => canSendMessages || !!scriptText
      );
    } catch (err) {
      apiError(err);
    }

    this.isLoading = false;
  });

  setTemplateFavorites(favorites) {
    this.favoriteIds = Object.keys(favorites || {});
    this.page = 0;
    this.loadFavorites();
  }

  goToTab(tabName) {
    this.currentTab = tabName;
    this.page = 0;
  }

  goToPage(page) {
    this.page = page;
  }

  search(name) {
    this.searchTerm = name;
    this.page = 0;
  }

  get currentTabItems() {
    switch (this.currentTab) {
      case 'all':
        return this.filterTemplatesBySearchTerm(this.allTemplates);
      case 'video':
        return this.filterTemplatesBySearchTerm(this.videoTemplates);
      case 'text':
        return this.filterTemplatesBySearchTerm(this.messageTemplates);
      default:
        return [];
    }
  }

  get allTemplatesCount() {
    return this.filterTemplatesBySearchTerm(this.allTemplates).length;
  }

  get videoTemplatesCount() {
    return this.filterTemplatesBySearchTerm(this.videoTemplates).length;
  }

  get messageTemplatesCount() {
    return this.filterTemplatesBySearchTerm(this.messageTemplates).length;
  }

  filterTemplatesBySearchTerm(templates) {
    const term = this.searchTerm.toLocaleLowerCase();

    if (!term) {
      return [...templates];
    }

    return templates.filter(
      ({ title, scriptText, messageText }) =>
        title.toLocaleLowerCase().includes(term) ||
        (scriptText && scriptText.toLocaleLowerCase().includes(term)) ||
        (messageText && messageText.toLocaleLowerCase().includes(term))
    );
  }
}

const MobxFavoritesStore = decorate(FavoritesStore, {
  isLoading: observable,
  videoTemplates: observable,
  messageTemplates: observable,
  allTemplates: observable,
  searchTerm: observable,
  page: observable,
  allTemplatesCount: computed,
  videoTemplatesCount: computed,
  messageTemplatesCount: computed,
  favoriteIds: observable,
  currentTab: observable,
  currentTabItems: computed,
  search: action,
  loadFavorites: action,
  setFavorites: action,
  goToTab: action,
  goToPage: action
});

export default new MobxFavoritesStore();
