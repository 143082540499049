import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatDateTime } from 'Utils/date';

export default class SingleMsg extends Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['in', 'out']).isRequired
  };

  render() {
    const { message, direction, createdAt } = this.props;
    const className = `chat__conversation-item ${
      direction === 'out' ? 'chat__conversation-item--response' : ''
    }`;
    return (
      <div className={className}>
        <div className="chat__conversation-timestamp">
          {formatDateTime(createdAt, false)}
        </div>
        <div className="chat__conversation-text">
          <p>{message}</p>
          <div className="chat__conversation-text-bubble" />
        </div>
      </div>
    );
  }
}
