import React from 'react';
import { Redirect } from 'react-router';
import { wrap } from 'Components/layout';
import wrapLayout from './Layout';
import PassthroughStep from './Passthrough';

import Recording from './Recording';
import RecordingEditor from './Recording/Editor';
import RecordingSettings from './Recording/Settings';
import Message from './Message';
import Landing from './Landing';
import BuilderRouter from '.';
import ClosingDialog from './ClosingDialog';
import Schedule from './Schedule';
import ScheduleAudience from './Schedule/Audience';
import ScheduleSetup from './Schedule/Setup';
import Sending from './Sending';
import { MESSAGE_SENDER } from '../../roles';

const protectedRoutes = [
  { path: '/recording/pending', component: PassthroughStep },
  { path: '/recording/record', component: Recording },
  { path: '/recording/editor', component: RecordingEditor },
  { path: '/recording/settings', component: RecordingSettings },
  { path: '/landing', component: Landing },
  { path: '/message', component: Message },
  { path: '/schedule', component: Schedule },
  { path: '/schedule/setup', component: ScheduleSetup },
  { path: '/schedule/audience', component: ScheduleAudience },
  { path: '/sending', component: Sending },
  { path: '/close-editor', component: ClosingDialog }
];

export default {
  base: '/builder',
  merge: {
    activePage: 'care',
    roles: [MESSAGE_SENDER],
    exact: true
  },
  items: [
    {
      path: '/from/:type/:id',
      component: wrap(BuilderRouter, { hideNavigation: true })
    },
    {
      path: '',
      component: () => <Redirect to="/" />
    },
    ...protectedRoutes.map(({ component, ...rest }) => ({
      component: wrapLayout(component),
      ...rest
    }))
  ]
};
