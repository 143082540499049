import request from './request';
import { toSearchQuery } from './helpers';

export default {
  videos: (startDate, endDate) =>
    request.get(
      `/videos-analytic?${toSearchQuery({
        startDate,
        endDate
      })}`
    ),
  customers: (startDate, endDate) =>
    request.get(
      `/videos-analytic/customers?${toSearchQuery({
        startDate,
        endDate
      })}`
    ),
  general: (startDate, endDate) =>
    request.get(
      `/videos-analytic/general?${toSearchQuery({
        startDate,
        endDate
      })}`
    ),
  video: (id, startDate, endDate) =>
    request.get(
      `/videos-analytic/${id}?${toSearchQuery({
        startDate,
        endDate
      })}`
    ),
  customer: (id, startDate, endDate) =>
    request.get(
      `/videos-analytic/customers/${id}?${toSearchQuery({
        startDate,
        endDate
      })}`
    )
};
