/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import DateRangeSelector from '../DateRangeSelector';

const INTERVALS = [
  { title: 'last week', value: 'week' },
  { title: 'last month', value: 'month' },
  { title: 'last year', value: 'year' }
];

const Toolbar = ({
  selectDateInterval,
  selectedInterval,
  startDate,
  endDate,
  setDateRange,
  disabled
}) => {
  return (
    <div
      className="toolbar toolbar--style-reports-time"
      style={{ backgroundColor: 'transparent' }}
    >
      <div className="cnt">
        <div className="toolbar__content">
          <nav className="toolbar__navigation">
            <ul>
              {INTERVALS.map(({ title, value }) => (
                <li
                  key={value}
                  className={value === selectedInterval ? 'is-active' : null}
                >
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      selectDateInterval(value);
                    }}
                  >
                    {title}
                  </a>
                </li>
              ))}
              <li className="toolbar__navigation-pull-right">
                <a>
                  <DateRangeSelector
                    {...{
                      startDate,
                      endDate,
                      setDateRange,
                      disabled
                    }}
                  />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  selectDateInterval: PropTypes.func.isRequired,
  selectedInterval: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  setDateRange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default Toolbar;
