import { apiError, error } from './alert';
import { UploadApi, VideosApi } from 'Api';

const MAX_VIDEO_DURATION =
  process.env.REACT_APP_MAX_UPLOADED_VIDEO_DURATION || 0;
const MAX_VIDEO_SIZE = process.env.REACT_APP_MAX_UPLOADED_VIDEO_SIZE || 0;

const getVideoLength = function (file) {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);
    // wait for duration to change from NaN to the actual duration
    video.ondurationchange = function () {
      resolve(this.duration);
    };
  });
};

const createVideoFromSource = async (videoFile) => {
  const cdnUrl = await UploadApi.uploadVideo(videoFile);
  const { id: videoId } = await VideosApi.record(videoFile.name, cdnUrl, {});
  return videoId;
};

export const createVideoFromFile = async (file) => {
  if (file.type.substring(0, 5) !== 'video') {
    error('This is not a video file');
    return;
  }
  const videoLength = await getVideoLength(file);
  // file size in megabytes
  const videoSize = file.size / 1024 / 1024;

  if (MAX_VIDEO_SIZE > 0 && videoSize > MAX_VIDEO_SIZE) {
    return error(`The video size exceeds maximum value of ${MAX_VIDEO_SIZE}Mb`);
  }
  if (MAX_VIDEO_DURATION > 0 && videoLength > MAX_VIDEO_DURATION) {
    return error(
      `The video duration exceeds maximum value ${MAX_VIDEO_DURATION} seconds`
    );
  }

  try {
    return await createVideoFromSource(file);
  } catch (e) {
    apiError(e);
  }
};
