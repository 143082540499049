// @flow

import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import ReactPropTypes from 'prop-types';

import AuthLayout from 'Pages/Auth/AuthLayout';
import InputField from 'Components/InputField';
import Button from 'Components/Button';
import { Link } from 'react-router-dom';
import { PRIVACY_LINK, TERMS_LINK, BRAND } from '../../../Utils/whitelabeling';

class SignUpForm extends React.Component {
  static propTypes = {
    AuthStore: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject,
    merchantTypes: ReactPropTypes.array
  };

  componentWillMount() {
    this.props.AuthStore.reset();
  }

  handleMerchantTypeChange = e =>
    this.props.AuthStore.setMerchantType(e.target.value);
  handleNameChange = e => this.props.AuthStore.setUsername(e.target.value);
  handleEmailChange = e => this.props.AuthStore.setEmail(e.target.value);
  handleBusinessPhoneChange = e =>
    this.props.AuthStore.setBusinessPhone(e.target.rawValue);
  handlePasswordChange = e => this.props.AuthStore.setPassword(e.target.value);
  handleConfirmPasswordChange = e =>
    this.props.AuthStore.setConfirmedPassword(e.target.value);
  handleSubmitForm = e => {
    e.preventDefault();
    this.props.AuthStore.register();
  };

  render() {
    const { merchantTypes } = this.props;
    const {
      values,
      validationErrors,
      errorMessage,
      inProgress
    } = this.props.AuthStore;
    const inputs = [
      {
        label: 'what is your name?',
        attributeName: 'name',
        inputType: 'text',
        placeholder: 'enter your full name',
        changeHandler: this.handleNameChange
      },
      {
        label: 'email address',
        attributeName: 'email',
        inputType: 'email',
        placeholder: 'enter your email address',
        changeHandler: this.handleEmailChange
      },
      {
        label: 'business phone',
        attributeName: 'businessPhone',
        inputType: 'phone',
        placeholder: 'enter business phone',
        changeHandler: this.handleBusinessPhoneChange
      },
      {
        label: 'password',
        attributeName: 'password',
        inputType: 'password',
        placeholder: 'enter your password',
        changeHandler: this.handlePasswordChange
      },
      {
        label: 'confirm password',
        attributeName: 'confirmedPassword',
        inputType: 'password',
        placeholder: 'confirm your password',
        changeHandler: this.handleConfirmPasswordChange
      }
    ];
    return (
      <React.Fragment>
        <div className="account__intro">
          <h2 className="account__title">get started with {BRAND.toUpperCase()}</h2>

          <div className="account__text">
            Supercharge your sales with the first-ever ChatGPT teleprompter.
            Ignite sales growth with AI-powered video messages.
          </div>
        </div>
        <div className="account__form">
          <form onSubmit={this.handleSubmitForm}>
            {Object.keys(validationErrors).length === 0 && errorMessage && (
              <React.Fragment>
                <h3>{errorMessage}</h3>
                {errorMessage.search('account exists') !== false && (
                  <h3>
                    <Link to="/recovery">reset password</Link>
                  </h3>
                )}
              </React.Fragment>
            )}
            <div>
              <div className="form form--style-account">
                <label className="form__label">account type?</label>
                <div className="form__container ">
                  <select
                    value={values.merchantTypeId || ''}
                    className="form__input"
                    onChange={this.handleMerchantTypeChange}
                  >
                    {merchantTypes.map(type => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {inputs.map(config => (
              <div key={config.attributeName}>
                <InputField
                  {...config}
                  values={values}
                  error={validationErrors[config.attributeName]}
                />
              </div>
            ))}
            <Button type="submit" name="get started" disabled={inProgress} />
            <div className="account__form-agree-policy">
              By clicking this button, you agree to Digital Care 100&nbsp;
              <a href={PRIVACY_LINK} target="_blank" rel="noopener noreferrer">
                Anti-Spam Policy
              </a>
              &nbsp;&amp;&nbsp;
              <a href={TERMS_LINK} target="_blank" rel="noopener noreferrer">
                Terms of Use
              </a>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const ObserverSignUpForm = inject('AuthStore')(observer(SignUpForm));

export default props => (
  <AuthLayout page="sign-up">
    <ObserverSignUpForm {...props} />
  </AuthLayout>
);
