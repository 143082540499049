import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer, PropTypes } from 'mobx-react';
import { ReactComponent as CloseIcon } from 'Icons/close.svg';
import Button from 'Components/Button';
import AccountMenu from '../AccountMenu';
import AccountBasicInfo from './AccountBasicInfo';
import ChangePassword from './ChangePassword';
import './index.css';

class Profile extends Component {
  static propTypes = {
    ProfileStore: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject
  };

  componentDidMount() {
    this.props.CommonStore.setActivePage('profile');
  }

  saveChanges = () => {
    this.props.ProfileStore.updateProfile();
  };

  render() {
    const { isProfileValueChanged } = this.props.ProfileStore;
    return (
      <div className="settings">
        <div className="cnt">
          <div className="settings__wrapper">
            <AccountMenu />
            <div className="settings__content">
              <div className="settings__header">
                <Link to="/" className="settings__header-close">
                  <CloseIcon />
                </Link>
                <h2 className="settings__header-title">profile</h2>
                <Button
                  style={{ marginLeft: 'auto' }}
                  name="save changes"
                  disabled={!isProfileValueChanged}
                  onClick={this.saveChanges}
                />
              </div>
              <AccountBasicInfo />
              <ChangePassword />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('ProfileStore', 'CommonStore')(observer(Profile));
