import { toast } from 'react-toastify';

export const success = message => {
  toast.success(message);
};

export const warning = message => {
  toast.warn(message);
};
export const info = message => {
  toast.info(message);
};

export const error = message => {
  toast.error(message);
};

export const stickyWarning = (message, id) => {
  if (toast.isActive(id)) {
    return;
  }

  toast.warn(message, {
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: false,
    toastId: id
  });
};

export const apiError = err => {
  const body =
    typeof err === 'object' && err.response
      ? err.response.body
      : 'Unknown error';

  const { message = undefined } = body;
  if (message) {
    error(message);
  }
};
