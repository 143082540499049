import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowIcon } from 'Icons/back-arrow.svg';

export default class Pagination extends Component {
  static propTypes = {
    total: PropTypes.number,
    page: PropTypes.number,
    limit: PropTypes.number,
    onPageClick: PropTypes.func
  };

  triggerPageChanged = page => {
    const { total, limit, onPageClick } = this.props;
    const totalPages = Math.ceil(total / limit);
    const currentPage = Math.min(isNaN(page) ? 1 : page, totalPages);

    if (currentPage < 1) {
      return;
    }

    onPageClick && onPageClick(currentPage);
  };

  render() {
    const { total, limit, page } = this.props;
    const totalPages = Math.ceil(total / limit);

    return (
      <div className="pagination">
        <button
          className={`pagination__prev ${page === 1 ? 'is-disabled' : ''}`}
          onClick={() => this.triggerPageChanged(page - 1)}
        >
          <ArrowIcon />
        </button>

        <div className="pagination__info">
          Page
          <input
            type="number"
            value={page}
            min="1"
            max={totalPages}
            style={{ width: '25px' }}
            onChange={({ target }) =>
              this.triggerPageChanged(parseInt(target.value))
            }
          />
          <span> of {totalPages}</span>
        </div>

        <button
          className={`pagination__next ${
            page === totalPages ? 'is-disabled' : ''
          }`}
          onClick={() => this.triggerPageChanged(page + 1)}
        >
          <ArrowIcon />
        </button>
      </div>
    );
  }
}
