/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { DISABLE_BILLING } from 'Utils/whitelabeling';

import './Account.css';
import Link from 'react-router-dom/Link';

class AccountMenu extends Component {
  static propTypes = {
    ProfileStore: PropTypes.observableObject,
    AuthStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject,
    CommonStore: PropTypes.observableObject,
  };

  handleLogout = () => {
    this.props.AuthStore.logout();
    this.props.Routing.push('/login');
  };

  render() {
    const { currentProfile } = this.props.ProfileStore;
    const { activePage } = this.props.CommonStore;

    return (
      <div className="settings__menu">
        <div className="settings__menu-name">{currentProfile.name}</div>
        <div className="settings__menu-proffesion">
          {currentProfile.office_name}
        </div>
        <ul className="settings__navigation">
          <li className={activePage === 'profile' ? 'is-active' : ''}>
            <Link to="/profile">profile</Link>
            <ul>
              <li>
                <a href="#basic-information">Basic information</a>
                <br />
              </li>
              <li>
                <a href="#password">Password</a>
              </li>
            </ul>
          </li>

          {!DISABLE_BILLING && (
            <li className="is-active">
              <Link to="/billing">subscription</Link>
            </li>
          )}
        </ul>
        <a
          // eslint-disable-next-line no-script-url
          href="javascript:void(0)"
          className="settings__navigation-sign-out"
          onClick={this.handleLogout}
        >
          sign out
        </a>
      </div>
    );
  }
}

export default inject(
  'ProfileStore',
  'AuthStore',
  'Routing',
  'CommonStore'
)(observer(AccountMenu));
