import { observable, action, flow, decorate } from 'mobx';
import { apiError } from 'Utils/alert';
import { BillingApi } from 'Api';
import ProfileStore from './ProfileStore';

class BillingStore {
  billingMethods = [];
  billingPlans = [];
  pricingSettings = null;
  payments = [];
  hasMorePayments = true;
  isLoading = false;
  isLoadingPayments = false;
  isLoadingBillingPlans = false;
  isAddingFunds = false;
  isLoadingPricingSettings = false;
  changeInProgress = false;
  processingSubscribe = false;

  paymentsPage = 0;

  addBillingMethod = flow(function*(token) {
    if (this.changeInProgress) {
      return;
    }
    this.changeInProgress = true;

    try {
      yield BillingApi.addBillingMethod(token);
    } catch (e) {
      apiError(e);
    }

    this.changeInProgress = false;
    this.loadBillingMethods();
  });

  makeBillingMethodDefault = flow(function*(id) {
    if (this.changeInProgress) {
      return;
    }
    this.changeInProgress = true;

    try {
      yield BillingApi.makeBillingMethodDefault(id);
    } catch (e) {
      apiError(e);
    }

    this.changeInProgress = false;
    this.loadBillingMethods();
  });

  deleteBillingMethod = flow(function*(id) {
    if (this.changeInProgress) {
      return;
    }
    this.changeInProgress = true;

    try {
      yield BillingApi.deleteBillingMethod(id);
    } catch (e) {
      apiError(e);
    }

    this.changeInProgress = false;
    this.loadBillingMethods();
  });

  loadBillingMethods = flow(function*() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    try {
      this.billingMethods = yield BillingApi.listBillingMethods();
    } catch (e) {
      apiError(e);
    }

    this.isLoading = false;
  });

  loadNextPaymentsPage = flow(function*() {
    if (this.isLoadingPayments) {
      return;
    }
    this.isLoadingPayments = true;

    const pageSize = 10;

    try {
      const payments = yield BillingApi.listPayments(
        pageSize,
        this.paymentsPage
      );
      this.payments = this.payments.concat(payments);
      this.paymentsPage++;
      this.hasMorePayments = payments.length === pageSize;
    } catch (e) {
      apiError(e);
    }

    this.isLoadingPayments = false;
  });

  loadBillingPlans = flow(function*() {
    if (this.isLoadingBillingPlans) {
      return;
    }
    this.isLoadingBillingPlans = true;

    try {
      this.billingPlans = yield BillingApi.listBillingPlans();
    } catch (e) {
      apiError(e);
    }

    this.isLoadingBillingPlans = false;
  });

  loadPricingSettings = flow(function*() {
    if (this.isLoadingPricingSettings) {
      return;
    }
    this.isLoadingPricingSettings = true;

    try {
      this.pricingSettings = yield BillingApi.pricingSettings();
    } catch (e) {
      apiError(e);
    }

    this.isLoadingPricingSettings = false;
  });

  subscribe = flow(function*(planId, token) {
    if (this.processingSubscribe) {
      return;
    }
    this.processingSubscribe = true;

    try {
      yield BillingApi.subscribe(planId, token);
      window.location.href = '/';
      return;
    } catch (e) {
      apiError(e);
    }

    this.processingSubscribe = false;
  });

  addFunds = flow(function*(amount) {
    if (this.isAddingFunds) {
      return;
    }
    this.isAddingFunds = true;

    try {
      yield BillingApi.addFunds(amount);
    } catch (e) {
      apiError(e);
    }

    this.isAddingFunds = false;

    ProfileStore.loadProfile();

    this.payments = [];
    this.paymentsPage = 0;
    this.loadNextPaymentsPage();
  });
}

const MobxBillingStore = decorate(BillingStore, {
  billingMethods: observable,
  billingPlans: observable,
  isLoading: observable,
  isLoadingBillingPlans: observable,
  isAddingFunds: observable,
  changeInProgress: observable,
  processingSubscribe: observable,
  payments: observable,
  isLoadingPayments: observable,
  hasMorePayments: observable,
  pricingSettings: observable,
  isLoadingPricingSettings: observable,

  addBillingMethod: action,
  loadBillingMethods: action,
  makeBillingMethodDefault: action,
  deleteBillingMethod: action,
  subscribe: action,
  loadNextPaymentsPage: action
});

export default new MobxBillingStore();
