import { observable, action, flow, decorate, reaction } from 'mobx';
import { apiError } from 'Utils/alert';
import { MerchantApi } from 'Api';

class ChargesStore {
  isLoading = false;
  charges = [];
  totalCharges = 0;
  currentPage = 0;
  pageSize = 10;

  constructor() {
    reaction(
      () => this.currentPage,
      () => this.load()
    );
  }

  load = flow(function*() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    try {
      const { data, count } = yield MerchantApi.getPayments(
        this.currentPage,
        this.pageSize
      );
      this.totalCharges = count;
      this.charges = data;
    } catch (e) {
      apiError(e);
    }

    this.isLoading = false;
  });

  setPage = page => {
    if (page !== this.currentPage) {
      this.currentPage = page;
    }
  };
}

const MobxChargesStore = decorate(ChargesStore, {
  isLoading: observable,
  charges: observable,
  totalCharges: observable,
  currentPage: observable,
  pageSize: observable,

  setPage: action
});

export default new MobxChargesStore();
