import React from 'react';
import UploadButton from 'Components/UploadButton';
import { createVideoFromFile } from 'Utils/video';
import { inject } from 'mobx-react';

function UploadVideoButton({ Routing }) {
  return (
    <UploadButton
      accept="video/*"
      onUpload={async (file) => {
        const videoId = await createVideoFromFile(file);
        videoId &&
          setTimeout(() => Routing.push(`/video/editor/${videoId}`), 100);
      }}
      buttonText="Upload Video"
    />
  );
}

export default inject('Routing')(UploadVideoButton);
