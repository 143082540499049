export const dataUriToBlob = dataUri => {
  const [, mimeType, data] = dataUri.match(/data:([^;]+);base64,(.+)/);
  return base64ToBlob(data, mimeType);
};

export const base64ToBlob = (base64, mime = '') => {
  const sliceSize = 1024;
  const byteChars = window.atob(base64);
  const byteArrays = [];

  for (
    let offset = 0, len = byteChars.length;
    offset < len;
    offset += sliceSize
  ) {
    const slice = byteChars.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    byteArrays.push(new Uint8Array(byteNumbers));
  }

  return new Blob(byteArrays, { type: mime });
};
