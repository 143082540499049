const moment = require('moment');

const DATE_FORMAT = 'MM/DD/YY h:mm a';

export const formatDate = date =>
  moment
    .utc(date)
    .local()
    .format(DATE_FORMAT);

export const toUTC = date =>
  moment(date)
    .utc()
    .toISOString();

export const addStepToDate = (date, interval, step) => {
  const m = moment(date);
  switch (interval) {
    case 'week':
      return m.add(step, 'w').toDate();
    case 'month':
      return m.add(step, 'M').toDate();
    default:
      return m.add(step, 'd').toDate();
  }
};
