import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as NewIcon } from 'Icons/new.svg';
import { ReactComponent as ScriptsIcon } from 'Icons/scripts.svg';
import Checkbox from 'Components/Checkbox';
import ToolbarFilters from './ToolbarFilters';
import Actions from './Actions';

const LIBRARY_LINK = '/library/categories';

const Toolbar = ({
  selectedMessageId,
  selectedMessageType,
  messagesCount,
  selectedMessagesCount,
  toggleAllMessagesSelection,
  allMessagesSelected,
  onEdit,
  onCopy,
  onDelete,
  activeFilters,
  setFilter,
  setActiveTab,
  availableTabs,
  resendInsteadOfCopy
}) => {
  const filtersActivated = Object.keys(activeFilters).length > 0;
  const hasMessages = availableTabs
    ? availableTabs.reduce((result, tab) => result || tab.count > 0, false)
    : true;
  return (
    <div className="toolbar toolbar--style-care">
      <div className="cnt">
        <div className="toolbar__content">
          <Link to={LIBRARY_LINK} className="toolbar__new">
            <NewIcon />
            <span>create new</span>
          </Link>

          <div className="toolbar__extra">
            <Link to={LIBRARY_LINK} className="toolbar__extra-button">
              <ScriptsIcon />
              Explore our pre-written scripts
            </Link>
          </div>
        </div>
        {(messagesCount > 0 || filtersActivated || hasMessages) && (
          <div className="toolbar__content">
            <nav className="toolbar__navigation">
              <ul>
                {(availableTabs || []).map(config => (
                  <li
                    key={config.value}
                    className={config.active ? 'is-active' : null}
                  >
                    <a
                      href="/care"
                      onClick={e => {
                        e.preventDefault();
                        setActiveTab(config.value);
                      }}
                    >
                      {config.value}({config.count})
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            {selectedMessagesCount === 0 && (
              <ToolbarFilters
                {...{
                  activeFilters,
                  setFilter
                }}
              />
            )}
            {selectedMessagesCount > 0 && (
              <Actions
                {...{
                  resendInsteadOfCopy,
                  selectedMessageId,
                  selectedMessageType,
                  selectedMessagesCount,
                  onEdit,
                  onCopy,
                  onDelete
                }}
              />
            )}
            <div className="toolbar__check-all">
              <Checkbox
                checked={allMessagesSelected}
                onChange={toggleAllMessagesSelection}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  selectedMessageId: PropTypes.string,
  selectedMessageType: PropTypes.string,
  messagesCount: PropTypes.number.isRequired,
  selectedMessagesCount: PropTypes.number.isRequired,
  toggleAllMessagesSelection: PropTypes.func.isRequired,
  allMessagesSelected: PropTypes.bool,
  onEdit: PropTypes.func,
  onCopy: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  activeFilters: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  availableTabs: PropTypes.array,
  resendInsteadOfCopy: PropTypes.bool
};

export default Toolbar;
