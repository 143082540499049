import React from 'react';
import { Player } from 'react-cam-recorder';
import { VideosApi } from 'Api';

const cachedLogos = {};

async function getVideoLogo(recordedBy) {
  if (recordedBy === 'demo' || recordedBy === null) {
    return '/transparent-logo.png';
  }
  if (cachedLogos[recordedBy]) {
    return cachedLogos[recordedBy];
  }

  try {
    const { logo } = await VideosApi.getLogo(recordedBy);
    cachedLogos[recordedBy] = logo;

    return logo;
  } catch (e) {
    return null;
  }
}

class VideoPlayer extends React.Component {
  state = {};
  componentDidMount() {
    //this.loadVideoLogo();
  }
  loadVideoLogo = async () => {
    const { recordedBy, frameSource } = this.props;
    if (frameSource) {
      // do not show logo with custom frames
      return;
    }
    const videoLogo = await getVideoLogo(recordedBy);
    this.setState({ videoLogo });
  };
  render() {
    // eslint-disable-next-line
    const { recordedBy, ...props } = this.props;
    const { videoLogo } = this.state;
    return (
      <Player
        {...props}
        logo={videoLogo ? { bottom: 50, right: 50, url: videoLogo } : null}
      />
    );
  }
}

export default VideoPlayer;
