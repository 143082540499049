import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { PropTypes as ReactPropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { FileDrop } from 'react-file-drop';
import { ReactComponent as SearchIcon } from 'Icons/search.svg';
import { VideosApi } from 'Api';
import { createVideoFromFile } from 'Utils/video';
import MyLibraryFolder from './MyLibrary/MyLibraryFolder';
import MySequencesFolder from './MyLibrary/MySequencesFolder';
import CuratedVideosFolder from './MyLibrary/CuratedVideosFolder';
import CategorySidebarList from './Categories/CategorySidebarList';
import { info } from 'Utils/alert';

import { MESSAGE_SENDER } from '../../roles';

class SidebarLayout extends Component {
  static propTypes = {
    ProfileStore: PropTypes.observableObject,
    children: ReactPropTypes.any,
    styleType: ReactPropTypes.string,
    searchText: ReactPropTypes.string,
    searchTerm: ReactPropTypes.string,
    onSearch: ReactPropTypes.func,
  };

  state = { curatedVideosAvailable: false };

  checkCuratedVideosAvailability = async () => {
    const { count: totalVideosCount } = await VideosApi.getCurated(null, 0, 0);
    this.setState({ curatedVideosAvailable: totalVideosCount > 0 });
  };

  componentDidMount() {
    this.checkCuratedVideosAvailability();
  }

  canSendMessages = () =>
    this.props.ProfileStore.roles.indexOf(MESSAGE_SENDER) !== -1;

  render() {
    const { Routing } = this.props;
    const { styleType, searchText, searchTerm, onSearch } = this.props;
    const { curatedVideosAvailable, isUploadingVideo } = this.state;

    return (
      <FileDrop
        onDrop={async (files) => {
          if (isUploadingVideo) {
            return;
          }
          this.setState({ isUploadingVideo: true });
          info('Uploading video...');

          const videoId = await createVideoFromFile(files[0]);

          if (!videoId) {
            this.setState({ isUploadingVideo: false });
            return;
          }

          setTimeout(() => Routing.push(`/video/editor/${videoId}`), 100);
        }}
      >
        <div className={`library library--style-${styleType}`}>
          <div className="cnt">
            <div className="library__split">
              <div className="library__split-left">
                <div className="library__sidebar">
                  {onSearch && (
                    <div className="library__sidebar-search">
                      <SearchIcon />
                      <input
                        name="text"
                        placeholder={searchText}
                        value={searchTerm}
                        onChange={onSearch}
                      />
                    </div>
                  )}

                  <div className="library__sidebar-buttons">
                    <MyLibraryFolder />
                    <MySequencesFolder />
                    {curatedVideosAvailable && <CuratedVideosFolder />}
                  </div>

                  <CategorySidebarList />

                  {this.canSendMessages() && (
                    <Link
                      to="/library/create-custom"
                      className="library__sidebar-custom"
                    >
                      create custom message
                    </Link>
                  )}
                </div>
              </div>
              <div className="library__split-right">{this.props.children}</div>
            </div>
          </div>
        </div>
      </FileDrop>
    );
  }
}

export default inject('ProfileStore', 'Routing')(observer(SidebarLayout));
