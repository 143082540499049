import { observable, action, flow, decorate, reaction } from 'mobx';
import { apiError, success } from 'Utils/alert';
import { MessageApi } from 'Api';

class MyMessagesStore {
  isLoading = false;
  isReloading = false;
  searchTerm = '';
  allMessagesPage = 1;
  recentMessagesPage = 1;
  allMessages = [];
  recentMessages = [];

  messageGroups = [
    { title: 'all', count: 0 },
    { title: 'recently added', count: 0 }
  ];

  allMessagesGroup = this.messageGroups[0];
  recentMessagesGroup = this.messageGroups[1];
  activeMessageGroup = this.messageGroups[0];

  activeFilters = {};

  constructor() {
    reaction(
      () => this.searchTerm,
      () => this.reloadAll(),
      { delay: 500 }
    );
    reaction(
      () => this.activeFilters,
      () => {
        this.reloadAll();
      },
      {
        delay: 500
      }
    );
  }

  setActiveMessageGroup(group) {
    this.activeMessageGroup = group;
  }

  reloadAll = flow(function*() {
    this.isReloading = true;
    yield Promise.all([
      this.setAllMessagesPage(1),
      this.setRecentMessagesPage(1)
    ]);
    this.isReloading = false;
  });

  loadAllMessages = flow(function*() {
    try {
      this.isLoading = true;
      const { count, data } = yield MessageApi.get(
        this.searchTerm,
        Math.max(this.allMessagesPage - 1, 0),
        10,
        { type: this.activeFilters.type }
      );
      this.allMessagesGroup.count = count;
      this.allMessages = data;
    } catch (err) {
      apiError(err);
    }

    this.isLoading = false;
  });

  loadRecentMessages = flow(function*() {
    try {
      this.isLoading = true;
      const { count, data } = yield MessageApi.getRecent(
        this.searchTerm,
        Math.max(this.recentMessagesPage - 1, 0),
        10,
        { type: this.activeFilters.type }
      );
      this.recentMessagesGroup.count = count;
      this.recentMessages = data;
    } catch (err) {
      apiError(err);
    }

    this.isLoading = false;
  });

  copyMessages = flow(function*(messages) {
    try {
      yield MessageApi.copy(messages.map(({ id }) => id));
      success(`Successfully copied ${messages.length} message(s)`);
    } catch (err) {
      apiError(err);
    }

    yield this.reloadAll();
  });

  deleteMessages = flow(function*(messages) {
    try {
      yield MessageApi.delete(messages.map(({ id }) => id));
      success(`Successfully deleted ${messages.length} message(s)`);
    } catch (err) {
      apiError(err);
    }

    yield this.reloadAll();
  });

  setRecentMessagesPage(page) {
    this.recentMessagesPage = page;
    return this.loadRecentMessages();
  }

  setAllMessagesPage(page) {
    this.allMessagesPage = page;
    return this.loadAllMessages();
  }

  search(term) {
    this.searchTerm = term;
  }

  setFilter = (filterName, filterValue) => {
    if (!filterValue) {
      const filters = { ...this.activeFilters };
      delete filters[filterName];
      this.activeFilters = filters;
      return;
    }

    this.activeFilters = {
      ...this.activeFilters,
      ...{ [filterName]: filterValue }
    };
  };
}

const MobxMyMessagesStore = decorate(MyMessagesStore, {
  isLoading: observable,
  isReloading: observable,
  allMessages: observable,
  recentMessages: observable,
  allMessagesPage: observable,
  recentMessagesPage: observable,
  searchTerm: observable,
  messageGroups: observable,
  activeMessageGroup: observable,
  setActiveMessageGroup: action,
  setAllMessagesPage: action,
  setRecentMessagesPage: action,
  search: action,
  loadAllMessages: action,
  copyMessages: action,
  loadRecentMessages: action,
  activeFilters: observable,
  setFilter: action
});

export default new MobxMyMessagesStore();
