import { observable, action, computed, flow, decorate, reaction } from 'mobx';
import { success, info, error } from 'Utils/alert';
import { diff } from 'Utils/objectHelper';
import Routing from './RoutingStore';
import { CustomerApi } from 'Api';

const TAB_INVALID_CONTACTS = 'invalid-contacts';

class CustomerStore {
  isLoading = false;
  currentTab = 'all';
  importId = null;
  customers = [];
  selectedCustomers = [];
  totalCustomers = 0;
  offset = 0;
  limit = 50;
  filterTag = undefined;
  selectedCustomer = {};
  isCustomerValueChanged = false;
  updateInProgress = false;
  isBatchTaggingActive = false;
  batchOperationActive = false;

  tags = {
    allTags: [],
    total: 0,
    offset: 0,
    limit: 50,
    isLoading: false,
    customerTagsUpdated: false
  };

  invalidCustomers = {
    allInvalidCustomers: [],
    total: 0,
    offset: 0,
    limit: 50,
    isLoading: false
  };

  emptyCustomerFormObj = {
    values: {
      name: '',
      phone: '',
      birthday: '',
      sex: 'male'
    },
    validationErrors: {},
    errorMessage: undefined,
    isSubmitting: false,
    isAdded: false
  };

  customerFormObj = this.emptyCustomerFormObj;

  setImportId(importId) {
    this.importId = importId;
  }

  constructor() {
    reaction(
      () => this.searchTerm,
      async () => {
        this.loadCustomers();
      }
    );
  }

  setSelectedCustomer = customer => {
    this.selectedCustomer = customer;
    this.isCustomerValueChanged = false;
    this.tags.customerTagsUpdated = false;
    this.clearValidationErrors();
  };

  resetSelectedCustomers = () => {
    this.selectedCustomers = [];
  };

  toggleCustomerSelection = customerId => {
    if (this.selectedCustomers.indexOf(customerId) !== -1) {
      this.selectedCustomers = this.selectedCustomers.filter(
        id => id !== customerId
      );
      return;
    }
    this.selectedCustomers = this.selectedCustomers.concat([customerId]);
  };

  getCurrentTabCustomers = () => {
    return this.currentTab === TAB_INVALID_CONTACTS
      ? this.invalidCustomers.allInvalidCustomers
      : this.customers;
  };

  toggleAllCustomersSelection = () => {
    if (
      this.getCurrentTabCustomers().length === this.selectedCustomers.length
    ) {
      this.resetSelectedCustomers();
      return;
    }
    this.selectedCustomers = this.getCurrentTabCustomers().map(({ id }) => id);
  };

  updateSelectedCustomer(attributeToUpdate, value) {
    this.isCustomerValueChanged = true;
    this.selectedCustomer[attributeToUpdate] = value;
  }

  resetFormObj() {
    this.customerFormObj = { ...this.emptyCustomerFormObj };
  }

  setAttribute(attName, value) {
    this.customerFormObj.values[attName] = value;
  }

  setCurrentTab(tab) {
    this.currentTab = tab;
    this.setSelectedCustomer({});
    this.resetSelectedCustomers();
  }

  setLimit(newLimit) {
    if (this.limit !== newLimit) {
      this.limit = newLimit;
      this.resetSelectedCustomers();
    }
  }

  setOffset(offset) {
    if (this.offset !== offset) {
      this.offset = offset;
      this.resetSelectedCustomers();
      this.loadCustomers();
    }
  }

  setFilterTag(filterTag) {
    this.setOffset(0);
    this.filterTag = filterTag;
  }

  setTagLimit(newLimit) {
    this.tags.limit = newLimit;
  }

  setTagsOffset(offset) {
    if (this.tags.offset !== offset) {
      this.tags.offset = offset;
      this.loadTags();
    }
  }

  setInvalidCustomersOffset(offset) {
    if (this.invalidCustomers.offset !== offset) {
      this.invalidCustomers.offset = offset;
      this.loadInvalidCustomers();
    }
  }

  get searchTerm() {
    if (!Routing.location) {
      return null;
    }
    const { pathname } = Routing.location;
    const parts = pathname.split('/search/').filter(val => !!val);
    return parts.length > 1 ? parts[1] : null;
  }

  loadCustomers = flow(function*() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    try {
      const { ...response } = yield CustomerApi.get(
        this.offset,
        this.limit,
        this.filterTag,
        this.searchTerm,
        this.importId
      );
      this.totalCustomers = response.count;
      this.customers = response.data;
    } catch (error) {
      this.handleErrors(error);
    }
    this.isLoading = false;
  });

  addCustomer = flow(function*() {
    this.customerFormObj.isSubmitting = true;
    this.customerFormObj.isAdded = false;
    try {
      const saveValues = { ...this.customerFormObj.values };

      if (saveValues.birthday === '') {
        saveValues.birthday = null;
      }

      const { id } = yield CustomerApi.add(saveValues);
      (this.customerFormObj.values.tags || []).map(async tag => {
        await CustomerApi.addTag(id, tag);
      });
      this.customerFormObj.isSubmitting = false;
      this.customerFormObj.isAdded = true;
      this.tags.customerTagsUpdated = true;
    } catch (error) {
      this.customerFormObj.isSubmitting = false;
      this.customerFormObj.isAdded = false;
      this.handleErrors(error);
    }
  });

  loadTags = flow(function*() {
    this.tags.isLoading = true;
    try {
      const { ...response } = yield CustomerApi.getTags(
        this.tags.offset,
        this.tags.limit
      );
      this.tags.allTags = response.data;
      this.tags.total = response.count;
      this.tags.isLoading = false;
    } catch (error) {
      this.handleErrors(error);
      this.tags.isLoading = false;
    }
  });

  loadInvalidCustomers = flow(function*() {
    this.invalidCustomers.isLoading = true;
    try {
      const { ...response } = yield CustomerApi.getInvalid(
        this.invalidCustomers.offset,
        this.invalidCustomers.limit
      );
      this.invalidCustomers.allInvalidCustomers = response.data;
      this.invalidCustomers.total = response.count;
      this.invalidCustomers.isLoading = false;
    } catch (error) {
      this.handleErrors(error);
      this.invalidCustomers.isLoading = false;
    }
  });

  getSelectedCustomer = () =>
    this.getCurrentTabCustomers().find(
      customer => customer.id === this.selectedCustomer.id
    );

  // eslint-disable-next-line require-yield
  updateCustomer = flow(function*() {
    if (this.updateInProgress) {
      return;
    }
    this.updateInProgress = true;
    try {
      const selectedCustomer = this.getSelectedCustomer();
      let toUpdate = diff(this.selectedCustomer, selectedCustomer);
      if (toUpdate.firstName || toUpdate.lastName) {
        //when update name it is necessary to send both parts to the server, even if only one has been updated
        toUpdate = {
          ...{
            firstName: selectedCustomer.firstName,
            lastName: selectedCustomer.lastName
          },
          ...toUpdate
        };
      }
      if (Object.keys(toUpdate).length > 0) {
        yield CustomerApi.update(this.selectedCustomer.id, toUpdate);
        yield Promise.all([this.loadCustomers(), this.loadInvalidCustomers()]);
        if (!this.getSelectedCustomer()) {
          this.setSelectedCustomer({});
          this.resetSelectedCustomers();
        }
      }
      this.updateInProgress = false;
      this.isCustomerValueChanged = false;
      this.clearValidationErrors();
    } catch (err) {
      this.handleErrors(err);
      this.updateInProgress = false;
      throw err;
    }
  });

  deleteCustomer = flow(function*(customerId) {
    try {
      yield CustomerApi.delete(customerId);
      info('Client is deleted');

      if (this.currentTab === TAB_INVALID_CONTACTS) {
        this.invalidCustomers.allInvalidCustomers = this.invalidCustomers.allInvalidCustomers.filter(
          customer => customer.id !== customerId
        );
        this.invalidCustomers.total--;
        return;
      }

      this.tags.customerTagsUpdated = true;
      this.customers = this.customers.filter(
        customer => customer.id !== customerId
      );
      this.totalCustomers--;
    } catch (err) {
      this.handleErrors(err);
    }
  });

  addTagsToCustomer = flow(function*(tag) {
    try {
      yield CustomerApi.addTag(
        this.selectedCustomer.id,
        tag.value ? tag.value : tag
      );
      this.tags.customerTagsUpdated = true;
      success('Client added to group');
    } catch (err) {
      this.handleErrors(err);
    }
  });

  removeTagsFromCustomer = flow(function*(tags) {
    const customerTags = tags.map(tag =>
      tag.value || tag.value === '' ? { tag: tag.value } : { tag }
    );
    try {
      for (let i = 0; i < customerTags.length; i++) {
        yield CustomerApi.removeTag(this.selectedCustomer.id, customerTags[i]);
      }
      this.tags.customerTagsUpdated = true;
      info('Client removed from group');
    } catch (err) {
      this.handleErrors(err);
    }
  });

  handleErrors(err) {
    const { validationErrors = [], message = undefined } = err.response.body;
    this.customerFormObj.validationErrors = validationErrors;
    this.customerFormObj.errorMessage = message;
    if (message) return error(message);
  }

  clearValidationErrors() {
    this.customerFormObj.validationErrors = {};
    this.customerFormObj.errorMessage = null;
  }

  toggleBatchTaggingToolbar = () =>
    (this.isBatchTaggingActive = !this.isBatchTaggingActive);

  tagSelectedCustomers = flow(function*(tags) {
    if (this.batchOperationActive) {
      return;
    }
    this.batchOperationActive = true;
    try {
      yield CustomerApi.batchTagging(this.selectedCustomers, tags);
      setTimeout(() => {
        this.toggleBatchTaggingToolbar();
        this.loadCustomers();
        this.loadTags();
      }, 1000);
    } catch (err) {
      this.handleErrors(err);
    }
    this.batchOperationActive = false;
  });

  deleteSelectedCustomers = flow(function*(tags) {
    if (this.batchOperationActive) {
      return;
    }
    this.batchOperationActive = true;
    try {
      yield CustomerApi.batchDelete(this.selectedCustomers);
      this.selectedCustomers = [];
      setTimeout(() => {
        if (this.currentTab === TAB_INVALID_CONTACTS) {
          this.loadInvalidCustomers();
          return;
        }
        this.loadCustomers();
        this.loadTags();
      }, 1000);
    } catch (err) {
      this.handleErrors(err);
    }
    this.batchOperationActive = false;
  });
}

const MobxCustomerStore = decorate(CustomerStore, {
  currentTab: observable,
  isCustomerValueChanged: observable,
  updateInProgress: observable,
  customers: observable,
  isLoading: observable,
  customerFormObj: observable,
  totalCustomers: observable,
  importId: observable,
  tags: observable,
  invalidCustomers: observable,
  selectedCustomer: observable,
  selectedCustomers: observable,
  isBatchTaggingActive: observable,
  batchOperationActive: observable,
  toggleCustomerSelection: action,
  toggleAllCustomersSelection: action,
  setSelectedCustomer: action,
  updateSelectedCustomer: action,
  setCurrentTab: action,
  loadCustomers: action,
  loadInvalidCustomers: action,
  setAttribute: action,
  addCustomer: action,
  resetFormObj: action,
  updateCustomer: action,
  setFilterTag: action,
  setLimit: action,
  setOffset: action,
  loadTags: action,
  limit: observable,
  offset: observable,
  deleteCustomer: action,
  addTagsToCustomer: action,
  setImportId: action,
  removeTagsFromCustomer: action,
  setTagsOffset: action,
  setInvalidCustomersOffset: action,
  searchTerm: computed,
  toggleBatchTaggingToolbar: action,
  tagSelectedCustomers: action,
  deleteSelectedCustomers: action,
  resetSelectedCustomers: action
});

export default new MobxCustomerStore();
