import React from 'react';
import Select from 'react-select';
import SELECT_STYLES from './ReactSelectStyles';

const OPTIONS = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' }
];

function IntervalSelector({ onChange, value = null }) {
  return (
    <Select
      options={OPTIONS}
      onChange={onChange}
      styles={SELECT_STYLES}
      value={OPTIONS.find(option => option.value === value)}
    />
  );
}

export default IntervalSelector;
