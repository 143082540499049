import React, { Component } from 'react';
import PropTypes from 'prop-types';

export * from './confirm';

export default class ConfirmModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    confirmButton: PropTypes.string,
    cancelButton: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    isActive: PropTypes.bool
  };

  static defaultProps = {
    confirmButton: 'confirm',
    cancelButton: 'cancel',
    title: 'please confirm'
  };

  render() {
    const {
      text,
      onCancel,
      onConfirm,
      confirmButton,
      cancelButton,
      title,
      isActive = true
    } = this.props;

    return (
      <div
        className={`modal modal--style-default modal--type-confirm ${isActive &&
          'is-active'}`}
      >
        <div className="modal__outside-close" />

        <div className="modal__window">
          <h3 className="modal__title">{title}</h3>

          <div className="modal__info">{text}</div>

          <div className="modal__buttons">
            <button
              onClick={onCancel}
              className="button button--style-default button--size-m button--color-bali-hai button--width-s"
            >
              {cancelButton}
            </button>

            <button
              onClick={onConfirm}
              className="button button--style-default button--size-m button--color-blue-ribbon button--width-s"
            >
              {confirmButton}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
