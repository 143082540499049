import { observable, action, decorate, computed, flow, reaction } from 'mobx';
import { apiError } from 'Utils/alert';
import { MessageApi, VideosApi, UploadApi, CustomerApi } from 'Api';

import AudienceStore from './AudienceStore';
import ProfileStore from './ProfileStore';

import steps from 'Pages/Builder/steps';
import { attemptToDoRequest } from 'Utils/api';
import MessagesApi from '../Api/MessagesApi';

const UNTITLED_VIDEO = 'Untitled video';

export const getInitialVideoOverlay = () => ({
  version: '2.4.4',
  objects: [],
});

export const getInitialVideo = () => ({
  script: '',
  title: 'Untitled video',
  caption: '',
  overlay: getInitialVideoOverlay(),
  thumbnail: '',
  thumbnails: {},
});

class BuilderStore {
  video = getInitialVideo();

  stepName = '';

  selectedCustomers = [];
  selectedSegments = [];

  message = {};
  isNewMessage = false;
  messageReady = false;
  videoReady = false;

  isValidStep = false;

  constructor() {
    reaction(
      () => this.stepName,
      async () => {
        if (!this.messageReady) {
          return;
        }

        const stepData = this.steps[this.message.stage];

        if (stepData.next === this.stepName) {
          await this.updateMessageStage(this.stepName);
        }
      },
      { delay: 500 }
    );
  }

  get steps() {
    return steps[this.getMessageAttribute('type', 'text')];
  }

  get currentStepData() {
    return this.steps[this.stepName];
  }

  get nextStepData() {
    return this.steps[this.currentStepData.next];
  }

  get previousStepData() {
    return this.steps[this.currentStepData.previous];
  }

  get currentRoute() {
    return `/builder/${this.currentStepData.route}`;
  }

  get nextRoute() {
    return `/builder/${this.nextStepData.route}`;
  }

  get previousRoute() {
    return `/builder/${this.previousStepData.route}`;
  }

  get isTextMessage() {
    return this.message.type === 'text';
  }

  get landingPageUrl() {
    return `dc100.me/abcdefghij`;
  }

  setMessageAttribute(attribute, value) {
    this.message[attribute] = value;
  }

  getMessageAttribute(attribute, defaultValue) {
    return this.message[attribute] || defaultValue;
  }

  setVideoAttribute(attribute, value) {
    this.video[attribute] = value;
  }

  getVideoAttribute(attribute, defaultValue) {
    return this.video[attribute] || defaultValue;
  }

  setSelectedAudience(selectedCustomers, selectedSegments) {
    this.selectedCustomers = selectedCustomers;
    this.selectedSegments = selectedSegments;
    this.message.audience = {
      contacts: selectedCustomers.map(({ id }) => id),
      segments: selectedSegments.map(({ tag }) => tag),
    };
  }

  setStep(stepName, valid) {
    this.stepName = stepName;
    this.isValidStep = valid;
  }

  setStepValid(valid) {
    this.isValidStep = valid;
  }

  reset() {
    this.stepIndex = 1;
    this.isNewMessage = false;
    this.selectedCustomers = [];
    this.selectedSegments = [];
    this.message = {};
    this.messageReady = false;
    this.video = getInitialVideo();
    AudienceStore.reset();
  }

  sendMessageNow = flow(function* () {
    try {
      this.message.startingFrom = '';
      this.message.recurrence = [];
      yield this.saveMessageData();
      yield MessagesApi.sendNow(this.message.id);
    } catch (e) {
      apiError(e);
    }
  });

  testPhoneNumber = flow(function* (phone) {
    try {
      yield this.saveMessageData();
      yield MessagesApi.testSMS(this.message.id, phone);
    } catch (e) {
      apiError(e);
    }
  });

  setupFromType = flow(function* (type, id) {
    this.reset();
    switch (type) {
      case 'template':
        yield this.createFromTemplate(id);
        break;
      case 'message':
        yield this.openMessage(id);
        break;
      case 'video':
        yield this.createFromVideo(id);
        break;
      case 'empty':
        yield this.createEmpty(id);
        break;
      default:
        throw new Error(`Invalid type: ${type}`);
    }
  });

  updateMessageStage = flow(function* (stage) {
    try {
      yield MessageApi.update(this.message.id, { stage });
      this.message.stage = stage;
    } catch (e) {
      apiError(e);
    }
  });

  setVideo = flow(function* (videoId, useVideoTitle) {
    yield this.openVideo(videoId);
    const messageParams = { videoId };
    if (useVideoTitle && this.video.title !== UNTITLED_VIDEO) {
      this.message.title = messageParams.title = this.video.title;
      this.message.internalTitle = messageParams.internalTitle =
        this.video.title;
    }
    yield MessageApi.update(this.message.id, messageParams);
    this.message.videoId = videoId;
  });

  openVideo = flow(function* (videoId) {
    this.videoReady = false;
    //because of backend asynchronous nature we need to make sure that we keep trying
    //to load video. First attempt may fail because projection was not ready.
    this.video = yield attemptToDoRequest(
      async () => await VideosApi.getById(videoId),
      1000
    );

    this.message.videoThumbnailUrl = ProfileStore.currentProfile.profile_image;
    if (
      !this.message.landingPageImage ||
      this.message.landingPageImage === this.video.thumbnailUrl
    ) {
      this.message.landingPageImage = this.message.videoThumbnailUrl;
    }
    this.video.overlay = this.video.overlay
      ? JSON.parse(this.video.overlay)
      : getInitialVideoOverlay();
    this.videoReady = true;
  });

  uploadVideo = flow(function* (video, selectedFrame) {
    try {
      const { title } = this.video;
      const cdnUrl = yield UploadApi.uploadVideo(video);
      const { id: videoId } = yield VideosApi.record(
        title,
        cdnUrl,
        {
          scriptText: this.message.scriptText,
          templateId: this.message.templateId,
        },
        selectedFrame ? selectedFrame.id : null
      );
      yield this.setVideo(videoId);
    } catch (e) {
      apiError(e);
    }
  });

  discardMessage = flow(function* () {
    try {
      yield MessageApi.delete([this.message.id]);
      this.reset();
    } catch (e) {
      apiError(e);
    }
  });

  saveMessageData = flow(function* () {
    try {
      if ((this.message.landingPageImage || '').indexOf('data:image') === 0) {
        const newThumbnailUrl = yield UploadApi.uploadDataUriImage(
          this.message.landingPageImage
        );
        this.setMessageAttribute('landingPageImage', newThumbnailUrl);
      }
      yield MessageApi.update(this.message.id, {
        ...this.message,
      });
    } catch (e) {
      apiError(e);
    }
  });

  updateOverlay = flow(function* (overlay, selectedFrame) {
    try {
      yield VideosApi.updateOverlay(
        this.video.id,
        overlay,
        selectedFrame ? selectedFrame.id : null
      );
      this.video.overlay = overlay;
      this.video.frameId = selectedFrame ? selectedFrame.id : null;
      this.video.frameImageSource = selectedFrame ? selectedFrame.url : null;
    } catch (e) {
      apiError(e);
    }
  });

  updateVideoData = flow(function* (
    title,
    caption,
    thumbnailUrl,
    decoratedThumbnail,
    decoratedThumbnailWithPlayButton
  ) {
    try {
      if (thumbnailUrl && thumbnailUrl.indexOf('data:') === 0) {
        thumbnailUrl = yield UploadApi.uploadDataUriImage(thumbnailUrl);
        const thumbnailFileName = thumbnailUrl.split('/').pop();
        const decoratedThumbnailFileName = thumbnailFileName + '-decorated';
        const decoratedThumbnailWithPlayButtonFileName =
          thumbnailFileName + '-decorated-email';
        yield Promise.all([
          UploadApi.uploadDataUriImage(
            decoratedThumbnail,
            () => {},
            decoratedThumbnailFileName
          ),
          UploadApi.uploadDataUriImage(
            decoratedThumbnailWithPlayButton,
            () => {},
            decoratedThumbnailWithPlayButtonFileName
          ),
        ]);
      } else {
        thumbnailUrl = this.video.thumbnailUrl;
      }

      yield VideosApi.updateVideo(this.video.id, title, caption, thumbnailUrl);
      this.video = { ...this.video, title, caption, thumbnailUrl };

      yield MessageApi.update(this.message.id, {
        internalTitle: title,
        title,
      });
      this.message = {
        ...this.message,
        stage: 'videoSettings',
        videoThumbnailUrl: thumbnailUrl,
      };
    } catch (e) {
      apiError(e);
    }
  });

  createFromVideo = flow(function* (videoId) {
    try {
      this.messageReady = false;
      this.isNewMessage = true;
      yield this.createEmpty('video');
      yield this.setVideo(videoId, true);
      yield this.updateMessageStage('landing');
      this.setStep('landing', false);
    } catch (e) {
      apiError(e);
    }
  });

  createFromTemplate = flow(function* (templateId) {
    try {
      this.messageReady = false;
      this.isNewMessage = true;
      const { id } = yield MessageApi.createFromTemplate(templateId);
      yield this.openMessage(id);
    } catch (e) {
      apiError(e);
    }
  });

  createEmpty = flow(function* (type) {
    try {
      this.messageReady = false;
      this.isNewMessage = true;
      const { id } = yield MessageApi.create({
        title: 'Untitled',
        text: '',
        type,
      });
      yield this.openMessage(id);
    } catch (e) {
      apiError(e);
    }
  });

  reloadAudience = flow(function* () {
    if (this.selectedCustomers.length > 0 || this.selectedSegments.length > 0) {
      yield AudienceStore.setupSelected(
        this.selectedCustomers,
        this.selectedSegments
      );
    }
  });

  openMessage = flow(function* (messageId) {
    try {
      this.messageReady = false;
      this.message = yield this.attemptToLoadMessage(messageId);

      if (this.message.videoId) {
        yield this.openVideo(this.message.videoId);
      }

      let { segments = [], contacts = [] } = this.message.audience;

      [this.selectedSegments, this.selectedCustomers] = yield Promise.all([
        segments.length > 0
          ? CustomerApi.getMultipleTags(segments).then(({ data }) => data)
          : Promise.resolve([]),
        contacts.length > 0
          ? CustomerApi.getMultiple(contacts).then(({ data }) => data)
          : Promise.resolve([]),
      ]);

      yield this.reloadAudience();

      this.setStep(this.message.stage, false);
      this.messageReady = true;
    } catch (e) {
      apiError(e);
    }
  });

  attemptToLoadMessage = (messageId) => {
    //because of backend asynchronous nature we need to make sure that we keep trying
    //to load message. First attempt may fail because projection was not ready.
    return attemptToDoRequest(
      async () => await MessageApi.getById(messageId),
      1000
    );
  };
}

const MobxBuilderStore = decorate(BuilderStore, {
  video: observable,
  returnRoute: observable,
  isNewMessage: observable,
  videoReady: observable,
  stepName: observable,
  message: observable,
  messageReady: observable,
  isValidStep: observable,
  selectedCustomers: observable,
  selectedSegments: observable,
  currentStepData: computed,
  steps: computed,
  isTextMessage: computed,
  landingPageUrl: computed,
  nextStepData: computed,
  previousStepData: computed,
  nextRoute: computed,
  previousRoute: computed,
  currentRoute: computed,
  setMessageAttribute: action,
  getMessageAttribute: action,
  setVideoAttribute: action,
  getVideoAttribute: action,
  setStep: action,
  setStepValid: action,
  reset: action,
  updateMessageStage: action,
  setVideo: action,
  openVideo: action,
  uploadVideo: action,
  saveMessageData: action,
  setSelectedAudience: action,
  testPhoneNumber: action,
  sendMessageNow: action,
  updateOverlay: action,
  updateVideoData: action,
  createFromVideo: action,
  createFromTemplate: action,
  createEmpty: action,
  openMessage: action,
});

export default new MobxBuilderStore();
