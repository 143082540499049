import React from 'react';
import './NotSupportedBrowserNotification.css';
import mobilecheck from 'Utils/mobilecheck';
import { BRAND } from 'Utils/whitelabeling';

const DESKTOP_MESSAGE = `This browser is currently not fully supported by ${BRAND}. Please use the Chrome or Firefox internet browser for full functionality.`;
const MOBILE_MESSAGE = `This browser is currently not fully supported by ${BRAND}. To record videos, please download the ${BRAND} companion app or click “record on your mobile phone."`;

const BODY_CLASS = 'not-supported-browser';

const STORAGE_KEY = 'NOT_SUPPORTED_BROWSER_MESSAGE';
const LIBRARY_STORAGE_KEY = 'NOT_SUPPORTED_BROWSER_MESSAGE_LIBRARY';

function isTouchDevice() {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
}

class NotSupportedBrowser extends React.Component {
  state = { active: false };
  currentHref = null;
  componentDidMount() {
    if (this.isNotificationVisible()) {
      document.body.classList.add(BODY_CLASS);
      this.setState({ active: true });
      this.markAsNotified();
    }
  }

  componentDidUpdate() {
    if (this.currentHref === window.location.href) {
      return;
    }
    this.currentHref = window.location.href;

    setTimeout(() => {
      const isNotificationVisible = this.isNotificationVisible();
      this.setState({ active: isNotificationVisible });
      isNotificationVisible
        ? document.body.classList.add(BODY_CLASS)
        : document.body.classList.remove(BODY_CLASS);
      isNotificationVisible && this.markAsNotified();
    });
  }

  componentWillUnmount() {
    document.body.classList.remove(BODY_CLASS);
  }

  isNotificationVisible() {
    if (mobilecheck()) {
      return false; //do not show on mobile device
    }
    if (this.isBrowserSupported() || this.isPageWhitelisted()) {
      return false;
    }
    return !this.isNotified();
  }

  getStorageKey() {
    const { pathname } = window.location;
    if (
      pathname.indexOf('builder') !== -1 ||
      pathname.indexOf('library') !== -1
    ) {
      return LIBRARY_STORAGE_KEY;
    }

    return STORAGE_KEY;
  }

  isNotified = () => {
    return !!window.localStorage.getItem(this.getStorageKey());
  };

  markAsNotified = () => {
    window.localStorage.setItem(this.getStorageKey(), true);
  };

  isBrowserSupported = () => {
    return window.MediaRecorder !== undefined;
  };

  isPageWhitelisted = () => {
    return window.location.pathname.substr(0, 8) === '/message';
  };

  render() {
    return this.state.active ? (
      <div className="not-supported-browser-notification">
        {isTouchDevice() ? MOBILE_MESSAGE : DESKTOP_MESSAGE}
      </div>
    ) : null;
  }
}

export default NotSupportedBrowser;
