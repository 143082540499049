import request from './request';

export default {
  generate: (instruction, history) => {
    const config = Object.fromEntries(
      Object.entries({
        model: process.env['REACT_APP_CHAT_GPT_MODEL'] || null,
        maxTokens: process.env['REACT_APP_CHAT_GPT_MAX_TOKENS'] || null,
        temperature: process.env['REACT_APP_CHAT_GPT_TEMPERATURE'] || null,
        frequencyPenalty:
          process.env['REACT_APP_CHAT_GPT_FREQUENCY_PENALTY'] || null,
        presencePenalty:
          process.env['REACT_APP_CHAT_GPT_PRESENCE_PENALTY'] || null,
      }).filter(([_, v]) => v != null)
    );

    return request.post('/scripts/generate', {
      instruction,
      history,
      config,
    });
  },
};
