import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';

import InputField from 'Components/InputField';
import Button from 'Components/Button';
import RouterLink from 'Components/RouterLink';

class LoginForm extends React.Component {
  static propTypes = {
    AuthStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  componentWillMount() {
    this.props.AuthStore.reset();
  }

  handleEmailChange = e => this.props.AuthStore.setEmail(e.target.value);
  handlePasswordChange = e => this.props.AuthStore.setPassword(e.target.value);
  handleSubmitForm = e => {
    e.preventDefault();
    this.props.AuthStore.login().then(() => this.props.Routing.push('/'));
  };
  render() {
    const {
      values,
      validationErrors,
      errorMessage,
      inProgress
    } = this.props.AuthStore;
    const fields = [
      {
        label: 'email',
        attributeName: 'email',
        inputType: 'email',
        placeholder: 'email',
        changeHandler: this.handleEmailChange
      },
      {
        label: 'password',
        attributeName: 'password',
        inputType: 'password',
        placeholder: 'password',
        changeHandler: this.handlePasswordChange
      }
    ];
    return (
      <React.Fragment>
        <div className="account__form">
          <h2 className="account__title">sign in</h2>

          <form onSubmit={this.handleSubmitForm}>
            {Object.keys(validationErrors).length === 0 && errorMessage && (
              <h3>{errorMessage}</h3>
            )}
            {fields.map(field => (
              <InputField
                key={field.attributeName}
                {...field}
                values={values}
                error={validationErrors[field.attributeName]}
              />
            ))}
            <div className="account__form-buttons">
              <Button type="submit" name="sign in" disabled={inProgress} />
              <RouterLink
                path="/recovery"
                name="reset password"
                color="ghost"
                removeRibbon={true}
              />
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default inject('AuthStore', 'Routing')(observer(LoginForm));
