import React from 'react';
import { formatDate } from 'Utils/date';

export default ({ charges }) => (
  <div className="settings__section">
    <div className="settings__billing-history">
      <div className="settings__billing-history-title">
        <div className="settings__billing-history-row">date</div>
        <div className="settings__billing-history-row">description</div>
        <div className="settings__billing-history-row">amount</div>
      </div>

      {charges.map(({ id, amount, description, paidAt }) => (
        <div className="settings__billing-history-item" key={id}>
          <div className="settings__billing-history-row">
            {formatDate(paidAt)}
          </div>
          <div className="settings__billing-history-row">{description}</div>
          <div className="settings__billing-history-row">
            ${(amount / 100).toFixed(2)}
          </div>
        </div>
      ))}
    </div>
  </div>
);
