/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactPropTypes from 'prop-types';
import { inject, observer, PropTypes } from 'mobx-react';
import AnalyticComponent from 'Components/MessageAnalytic';
class Analytic extends Component {
  static propTypes = {
    MessageAnalyticStore: PropTypes.observableObject,
    match: ReactPropTypes.object
  };

  componentWillMount() {
    this.setReportType();
    this.selectItem();
    this.props.MessageAnalyticStore.reload();
  }

  componentDidUpdate(nextProps) {
    this.setReportType();
    this.selectItem();
  }

  setReportType = () => {
    this.props.MessageAnalyticStore.setReportType(
      document.location.pathname.split('/')[2]
    );
  };

  selectItem = () => {
    const { MessageAnalyticStore } = this.props;
    const { itemId = null } = this.props.match.params || {};
    MessageAnalyticStore.selectItem(itemId);
  };

  render() {
    const {
      isLoadingMessages,
      isLoadingCustomers,
      isLoadingGeneral,
      reportType,
      messages,
      customers,
      general,
      selectedItem,
      changeInterval,
      interval,
      startDate,
      endDate,
      setDateRange,
      searchTerm,
      setSearch
    } = this.props.MessageAnalyticStore;

    return (
      <AnalyticComponent
        reportType={reportType}
        selectedItem={selectedItem}
        messages={{ isLoading: isLoadingMessages, messages }}
        customers={{ isLoading: isLoadingCustomers, customers }}
        general={{ isLoading: isLoadingGeneral, ...general }}
        selectDateInterval={changeInterval}
        selectedInterval={interval}
        startDate={startDate}
        endDate={endDate}
        setDateRange={setDateRange}
        searchTerm={searchTerm}
        setSearch={setSearch}
      />
    );
  }
}

export default inject('MessageAnalyticStore')(withRouter(observer(Analytic)));
