import React from 'react';
import ContentLoader from 'react-content-loader';

export default () => (
  <ContentLoader
    height={35}
    width={600}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="15" y="10" rx="5" ry="5" width="570" height="15" />
  </ContentLoader>
);
