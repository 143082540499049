import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as VideoFileIcon } from 'Icons/video-file.svg';
import { ReactComponent as RightArrowIcon } from 'Icons/right-arrow.svg';
import { formatDate } from 'Utils/date';

class ReportListItem extends React.Component {
  render() {
    const {
      title,
      date,
      stats,
      icon,
      link,
      isActive,
      refCallback
    } = this.props;

    return (
      <li className={`library__messages-item ${isActive ? 'is-active' : ''}`}>
        <Link innerRef={refCallback} to={link}>
          {icon && (
            <div className="care__item-icon">
              <VideoFileIcon />
            </div>
          )}
          <div className="library__messages-item-title">{title}</div>
          <div className="library__messages-item-excerpt">
            {formatDate(date)}
          </div>
          {stats && (
            <div className="library__messages-item-excerpt">{stats}</div>
          )}
          <div className="library__messages-item-arrow">
            <RightArrowIcon />
          </div>
        </Link>
      </li>
    );
  }
}

ReportListItem.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  icon: PropTypes.bool,
  link: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  refCallback: PropTypes.func
};

export default ReportListItem;
