/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ReactComponent as CloseIcon } from 'Icons/close.svg';
import DatePicker from 'Components/DatePicker';
import Button from 'Components/Button';
import { formatDate } from './utils';

function Steps({ onChange, steps = [], error, reset }) {
  const onStepDelete = stepId => {
    const newSteps = [...steps];
    newSteps[stepId] = null;

    onChange(newSteps.filter(val => !!val));
  };

  return (
    <div className="table table--style-reports-shares enroll-sequence-steps">
      <div className="reset-steps">
        <Button name="Reset steps" onClick={reset} size="s" width="s" />
      </div>
      <div className="table__wrapper">
        <table className="table__table">
          <thead className="table__head">
            <tr>
              <th></th>
              <th>message</th>
              <th>type</th>
              <th>interval</th>
              <th>date</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="table__body">
            {steps.map((step, stepId) => {
              const startDate = step.startDate || step.defaultStartDate;
              return (
                <tr key={stepId}>
                  <td>
                    {step.landingPageImage && (
                      <img
                        className="thumbnail"
                        src={step.landingPageImage}
                        alt={step.messageTitle}
                      />
                    )}
                  </td>
                  <td>{step.messageTitle}</td>
                  <td>{step.messageType}</td>
                  <td>
                    {step.step} {step.interval}
                  </td>
                  <td className="datepicker">
                    {startDate && (
                      <DatePicker
                        showTimeSelect
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="MM/dd/y h:mm a"
                        onChangeDate={value => {
                          const newSteps = [...steps];
                          newSteps[stepId] = {
                            ...newSteps[stepId],
                            startDate: formatDate(value)
                          };
                          onChange(newSteps);
                        }}
                        value={startDate}
                      />
                    )}
                  </td>
                  <td className="buttons">
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        onStepDelete(stepId);
                      }}
                    >
                      <CloseIcon
                        style={{
                          display: 'inline-block',
                          height: '0.5em',
                          width: '0.5em'
                        }}
                      />
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {error && <div className="error form__note">{error}</div>}
    </div>
  );
}

export default Steps;
