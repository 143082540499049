import React from 'react';
import ContentLoader from 'react-content-loader';

export default () => (
  <span
    className="library__categories-item library__categories-item--align-left library__categories-item--size-double"
    style={{ position: 'relative' }}
  >
    <ContentLoader
      height={227}
      speed={1}
      style={{ position: 'absolute', left: 0, right: 0, bottom: 0, top: 0 }}
      primaryColor={'#888'}
      secondaryColor={'#EEE'}
    >
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
    </ContentLoader>
  </span>
);
