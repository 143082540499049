import LandingPage from '.';

export default {
  base: '/message/:messageId',
  merge: {
    exact: true
  },
  items: [
    { path: '', component: LandingPage },
    { path: '/:customerId', component: LandingPage }
  ]
};
