import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Recorder from 'Components/Recorder';
import { MessageApi, UploadApi, VideosApi } from 'Api';
import Loader from 'Components/Loader/LineLoader';
import { apiError } from 'Utils/alert';

class MobileVideoRecorder extends React.Component {
  state = {};
  uploadVideo = async (video, selectedFrame) => {
    try {
      const title = 'Untitled video';
      const cdnUrl = await UploadApi.uploadVideo(video);
      const {
        message: { scriptText, templateId }
      } = this.state;

      const { id: videoId } = await VideosApi.record(
        title,
        cdnUrl,
        {
          scriptText,
          templateId
        },
        selectedFrame ? selectedFrame.id : null
      );
      await MessageApi.update(this.state.message.id, { videoId });
      this.close();
    } catch (e) {
      console.log(e);
      apiError(e);
    }
  };
  componentWillMount = async () => {
    document.body.classList.toggle('body--responsive', true);
    const { messageId } = this.props.match.params;
    try {
      const message = await MessageApi.getById(messageId);
      this.setState({ message });
    } catch (e) {
      this.close();
    }
  };

  close = () => {
    window.close();
  };

  formatScript = script => script.replace(/\[.+?\]/g, '');

  updateMessageScript = async scriptText => {
    const { message } = this.state;
    const newMessage = { ...message, scriptText };
    this.setState({ message: newMessage });
    try {
      await MessageApi.update(newMessage.id, {
        ...newMessage
      });
    } catch (e) {
      //do nothing
    }
  };

  render() {
    const { message } = this.state;
    if (!message) {
      return <Loader />;
    }
    const { scriptText, title } = message;
    return (
      <Recorder
        script={{ text: scriptText || '', formatter: this.formatScript, title }}
        onVideoReady={this.uploadVideo}
        onScriptTextChange={this.updateMessageScript}
        onClose={this.close}
        startTimeout={10}
        autoStopTimeout={1}
      />
    );
  }
}

MobileVideoRecorder.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(MobileVideoRecorder);
