import React from 'react';
import ContentLoader from 'react-content-loader';

export default () => (
  <div className="builder__contacts-item">
    <div
      className="user-icon user-icon--size-m"
      style={{
        backgroundColor: '#888'
      }}
    />
    <div className="builder__contacts-item-name">
      <ContentLoader
        height={10}
        speed={1}
        primaryColor={'#888'}
        secondaryColor={'#EEE'}
      >
        <rect x="0" y="0" rx="4" ry="4" width="60%" height="10" />
      </ContentLoader>
    </div>
    <div className="builder__contacts-item-phone">-------</div>
  </div>
);
