import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';

class Passthrough extends React.Component {
  static propTypes = {
    BuilderStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  componentWillMount() {
    const { BuilderStore, Routing } = this.props;
    const stepData = BuilderStore.currentStepData;

    BuilderStore.updateMessageStage(stepData.next).then(() =>
      Routing.push(BuilderStore.nextRoute)
    );
  }

  render() {
    return null;
  }
}

export default inject('BuilderStore', 'Routing')(observer(Passthrough));
