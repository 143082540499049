import React, { useEffect, useState } from 'react';
import { VideoFramesApi } from 'Api';
import { EDITOR_TUTORIAL } from 'Utils/whitelabeling';
import { Editor as EditorComponent } from 'react-cam-recorder';

const AUTO_SAVE_DELAY = process.env.REACT_APP_VIDEO_EDITOR_AUTOSAVE_DELAY || 5;

function makeFrameSource(selectedFrameId) {
  let selectedFrameSource = undefined;
  async function loadSelectedFrame() {
    if (!selectedFrameId) {
      return null;
    }
    if (selectedFrameSource !== undefined) {
      return selectedFrameSource;
    }
    try {
      const { id, title, imageSource } = await VideoFramesApi.byId(
        selectedFrameId
      );
      selectedFrameSource = {
        id,
        url: imageSource,
        title,
      };
    } catch (e) {
      selectedFrameSource = null;
    }

    return selectedFrameSource;
  }

  return async (offset, limit) => {
    const [selectedFrame, { data }] = await Promise.all([
      loadSelectedFrame(),
      VideoFramesApi.list(offset, limit),
    ]);
    const frames = data
      .map(({ id, title, imageSource }) => ({
        id,
        url: imageSource,
        title,
      }))
      .map((record) =>
        !selectedFrame || record.id !== selectedFrame.id ? record : null
      );

    return selectedFrame && offset === 0
      ? [selectedFrame].concat(frames)
      : frames;
  };
}

function Editor(props) {
  const [hasFrames, setHasFrames] = useState(null);

  async function detectHasFrames() {
    const { count } = await VideoFramesApi.list(0, 0);
    setHasFrames({ hasFrames: count > 0 });
  }

  useEffect(function () {
    detectHasFrames();
  }, []);

  const framesSource = React.useMemo(
    function () {
      return hasFrames ? makeFrameSource(props.selectedFrame) : null;
    },
    [hasFrames]
  );

  if (hasFrames === null) {
    return null;
  }

  return (
    <EditorComponent
      {...{
        ...props,
        autoSaveDelay: AUTO_SAVE_DELAY,
        availableFonts: [
          'coming soon',
          'arial',
          'times new roman',
          'Oranienbaum',
          'Butler',
          'Bitter',
          'koliko',
          '20 db',
          'De La Fuente',
          /*'montserrat',
            'oswald',
            'short stack',
            'passion one',
            'comfortaa',
            'courier new',
            'sans serif',
            'avenir',
            'railway'*/
        ],
        tutorialLink: EDITOR_TUTORIAL,
        framesSource,
      }}
    />
  );
}

export default Editor;
