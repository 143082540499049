import ReactDOMServer from 'react-dom/server';
import Snippet from './Snippet';
import React from 'react';

const renderSnippet = ({ snippet, title }) =>
  ReactDOMServer.renderToStaticMarkup(
    <Snippet snippet={snippet} title={title} />
  );

const stringToHtmlStream = str => str.split('');

export const renderText = (text, snippets) => {
  let mayBeSnippet = '';
  let snippet = '';
  return text
    .split('')
    .reduce((result, letter) => {
      if (mayBeSnippet.length >= 2 && mayBeSnippet.slice(0, 2) !== '{{') {
        //it is not snippet, add previously recorded string as stream of letters
        const newItems = stringToHtmlStream(mayBeSnippet + letter);
        mayBeSnippet = '';
        return result.concat(newItems);
      }
      switch (letter) {
        case '\n':
          return result.concat(['<br/>']);
        case '{':
          mayBeSnippet += letter;
          if (mayBeSnippet.length >= 2 && mayBeSnippet !== '{{') {
            //it is not snippet, add previously recorded string as stream of letters
            const newItems = stringToHtmlStream(mayBeSnippet);
            mayBeSnippet = '';
            return result.concat(newItems);
          }
          return result;
        case '}':
          if (!mayBeSnippet) {
            return result.concat([letter]);
          }
          mayBeSnippet += letter;
          if (mayBeSnippet.substr(-2) !== '}}') {
            return result;
          }

          snippet = snippets.find(
            ({ snippet }) => snippet === mayBeSnippet
          ) || { title: mayBeSnippet, snippet: mayBeSnippet };
          mayBeSnippet = '';

          return result.concat([renderSnippet(snippet)]);
        default:
          if (mayBeSnippet) {
            mayBeSnippet += letter;
          }
          return mayBeSnippet ? result : result.concat([letter]);
      }
    }, [])
    .concat(mayBeSnippet ? stringToHtmlStream(mayBeSnippet) : [])
    .join('');
};

export const getCaretCharacterOffsetWithin = element => {
  const doc = element.ownerDocument || element.document;
  const win = doc.defaultView || doc.parentWindow;
  if (win.getSelection().rangeCount > 0) {
    let range = win.getSelection().getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    return [...preCaretRange.cloneContents().childNodes].length;
  }
};

export const setCaretToPosition = (node, position) => {
  const pos = Math.min(position, node.childNodes.length);
  const range = document.createRange();
  const selection = window.getSelection();
  range.setStart(node, pos);
  range.collapse(true);
  selection.removeAllRanges();
  selection.addRange(range);
};

export const getTextFromNode = node => nodesToText(...node.childNodes);

const nodesToText = (...nodes) => {
  return nodes
    .map(child => {
      const childName = child.nodeName;
      if (child.nodeType === Node.TEXT_NODE) {
        return child.nodeValue;
      } else if (childName === 'DIV') {
        return `\n${getTextFromNode(child)}`;
      } else if (childName === 'BR') {
        return '\n';
      } else if (child.className !== 'attribute') {
        return child.innerText;
      }
      return child.dataset.snippet;
    })
    .join('');
};
