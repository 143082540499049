import React from 'react';

export default function Header({ sequence }) {
  return (
    <div className="toolbar toolbar--style-care">
      <div className="cnt">
        <div className="toolbar__content toolbar__new">
          <span>Enroll sequence "{sequence.name}"</span>
        </div>
      </div>
    </div>
  );
}
