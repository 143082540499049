/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { ReactComponent as CloseIcon } from 'Icons/close.svg';
import Tags from 'Components/Tags';
import Button from 'Components/Button';

class BatchTaggingToolbar extends React.Component {
  state = { tags: [] };
  handleTagsOnChange = tags => {
    this.setState({ tags });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.CustomerStore.tagSelectedCustomers(
      this.state.tags.map(tag => tag.value)
    );
  };
  render() {
    const {
      batchOperationActive,
      toggleBatchTaggingToolbar
    } = this.props.CustomerStore;
    const { allTags } = this.props.CustomerStore.tags;
    const { tags } = this.state;
    return (
      <div className="modal modal--style-pull-right modal--type-edit-customer is-active">
        <div className="modal__outside-close" />
        <div className="modal__window">
          <form className="profile" onSubmit={this.handleSubmit}>
            <div className="profile__top">
              <a
                href="javascript:void(0)"
                className="profile__top-close"
                onClick={toggleBatchTaggingToolbar}
              >
                <CloseIcon />
              </a>
            </div>
            <div className="profile__name" />
            <div className="profile__title">
              <h3>Select groups</h3>
            </div>
            <div className="tags tags--size-m">
              <Tags
                suggestions={allTags}
                tags={tags}
                onChange={this.handleTagsOnChange}
                disabled={batchOperationActive}
              />
            </div>
            <div className="profile__title">
              <Button
                name="Save"
                type="submit"
                disabled={batchOperationActive}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

BatchTaggingToolbar.propTypes = {
  CustomerStore: PropTypes.observableObject
};

export default inject('CustomerStore')(observer(BatchTaggingToolbar));
