// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Logo from 'Components/layout/Logo';

class AuthLayout extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    page: PropTypes.string
  };
  render() {
    const page = this.props.page;
    const headerMsg =
      page === 'sign-in'
        ? `Don't have an account?`
        : 'Already have an account?';
    const headerBtn =
      page === 'sign-in' ? (
        <Link to="/sign-up">Sign up</Link>
      ) : (
        <Link to="/login">Sign in</Link>
      );
    return (
      <div className={`account account--style-${this.props.page || 'sign-in'}`}>
        <div className="cnt">
          <div className="account__header">
            <h1 className="account__header-logo">
              <Link to="/">
                <Logo />
              </Link>
            </h1>

            {page !== 'welcome' && (
              <div className="account__header-alternative">
                {headerMsg}
                {headerBtn}
              </div>
            )}
          </div>
          <div className="account__content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default AuthLayout;
