import React, { Component } from 'react';
import { error } from 'Utils/alert';
import { inject, observer, PropTypes } from 'mobx-react';
import Checkbox from 'Components/Checkbox';
import Tags from 'Components/Tags';
import Button from 'Components/Button';
import UploadButton from 'Components/UploadButton';
import { CustomerApi } from 'Api';
import { storeImportDetails } from './utils';
import { BRAND, SHORT_BRAND } from 'Utils/whitelabeling';

import './ImportFromFileForm.scss';

class ImportFromFileForm extends Component {
  static propTypes = {
    Routing: PropTypes.observableObject
  };

  resolveUploaded = null;

  state = {
    isUploading: false,
    consentGiven: false,
    selectedFile: null,
    suggested: [],
    tags: []
  };

  handleTagsOnChange = tags => this.setState({ tags });

  handleUploadButton = selectedFile => {
    this.setState({ selectedFile });
    return new Promise(resolve => (this.resolveUploaded = resolve));
  };

  handleConsent = consentGiven => this.setState({ consentGiven });

  handleSubmitForm = async e => {
    e.preventDefault();

    const { tags, consentGiven, selectedFile } = this.state;
    if (!consentGiven) {
      error(
        'Please certify that this is not purchased contact and that You have received their permission'
      );
      return;
    }

    this.setState({ isUploading: true });

    try {
      const {
        customerCount,
        importId,
        invalidCustomers
      } = await CustomerApi.batchImport(
        selectedFile,
        tags.map(tag => tag.value)
      );
      storeImportDetails(importId, { invalidCustomers });
      this.resolveUploaded();
      this.setState({
        isUploading: false,
        consentGiven: false,
        selectedFile: null,
        tags: []
      });

      this.props.Routing.push(`/people/imported/${importId}/${customerCount}`);
    } catch (e) {
      const { body } = e.response || {};
      const messageParts = [body.message].concat(
        Object.values(body.validationErrors || {})
      );
      error(messageParts.join('\n'));
    }

    this.setState({ isUploading: false });
  };

  componentWillMount() {
    CustomerApi.getTags(0, 20).then(({ data }) =>
      this.setState({ suggested: data })
    );
  }

  render() {
    const {
      tags,
      suggested,
      consentGiven,
      selectedFile,
      isUploading
    } = this.state;
    return (
      <React.Fragment>
        <ol className="import-instruction">
          <li>Click “Download CSV template</li>
          <li>Open template titled import_example once downloaded</li>
          <li>
            Copy & paste columns from your CSV contact list into the
            import_example CSV file.
            <br />
            *Note: Make sure to copy first and last names into the appropriate
            columns. First name, last name, and phone number are required.
            Birthday and gender are optional
          </li>
          <li>Save import_example CSV with new information</li>
          <li>Click “upload CSV” in {BRAND}</li>
          <li>Select file</li>
          <li>
            If desired, tag contacts by selecting a pre-existing tag or typing a
            new tag into the field
          </li>
          <li>
            Click checkbox to certify that you have not purchased your contacts
          </li>
          <li>Click “add contacts”</li>
          <li>
            Review uploads. {SHORT_BRAND} will produce an error report CSV for
            contacts that have not uploaded
          </li>
        </ol>
        <div className="import-customers-form add-people__form-step add-people__form-step--1 is-active">
          <div className="add-people__form-buttons">
            <a
              href="/import_example.csv"
              className="upload-button button button--style-outline button--size-m button--width-m"
              style={{ color: '#bdc3cf', marginRight: 30 }}
            >
              Download CSV template
            </a>
            <UploadButton
              className="upload-button button button--style-outline button--size-m button--color-java button--width-m"
              onUpload={this.handleUploadButton}
              disabled={isUploading}
              disableOnSelect={false}
              accept=".csv"
              buttonText="upload CSV"
              uploadText="CSV selected"
            />
          </div>

          <div className="form form--style-account">
            <label className="form__label">
              add imported contacts to groups
            </label>
            <div className="form__container">
              <div className="form__dropdown tags">
                <Tags
                  tags={tags}
                  onChange={this.handleTagsOnChange}
                  suggestions={suggested}
                  disabled={isUploading}
                />
              </div>
            </div>
            <p className="contacts-certified">
              <Checkbox
                onChange={this.handleConsent}
                checked={consentGiven}
                name="I certify that these are not purchased contacts and that I have received their permission."
              />
            </p>
            <p className="perform-upload">
              <Button
                name="add contacts"
                onClick={this.handleSubmitForm}
                disabled={selectedFile === null || isUploading}
              />
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default inject('Routing')(observer(ImportFromFileForm));
