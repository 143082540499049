const hashCode = str => {
  let hash = 0;
  for (let char of str) {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  }
  return hash;
};

const intToRGB = i => {
  const c = (i & 0xffffff).toString(16).toUpperCase();
  return '00000'.substring(0, 6 - c.length) + c;
};

export function stripHtml(input) {
  return input.replace(/<.*?>/g, '');
}

export function extractQueryObject(queryString) {
  return decodeURIComponent(queryString)
    .substring(queryString[0] === '?' ? 1 : 0)
    .split('&')
    .map(i => {
      let [leftSide] = i.match(/[^=]+/);
      return [leftSide, i.substring(leftSide.length + 1)];
    })
    .reduce((ob, [key, value]) => {
      ob[key] = value;
      return ob;
    }, {});
}

export function textToColor(text) {
  return '#' + intToRGB(hashCode(text));
}

export function userToColor(...nameParts) {
  return textToColor(nameParts.map(part => part || '').join(' '));
}

export function getNameInitals(...nameParts) {
  return nameParts
    .filter(part => part.length > 0)
    .map(part => part[0].toUpperCase())
    .join(' ');
}
