import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';

import { ReactComponent as BackArrowIcon } from 'Icons/back-arrow.svg';
import { ReactComponent as SearchIcon } from 'Icons/search.svg';
import Checkbox from 'Components/Checkbox';
import Loader from './Loader';
import { userToColor, getNameInitals } from 'Utils/string';

import Waypoint from 'react-waypoint';

import './audience.css';

class Audience extends Component {
  static propTypes = {
    BuilderStore: PropTypes.observableObject,
    AudienceStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  componentWillMount() {
    this.props.BuilderStore.setStep('schedule', false);
    this.props.AudienceStore.reloadData();
  }

  revertChanges = () => {
    this.props.AudienceStore.reset();
    this.props.BuilderStore.reloadAudience();
    this.goBackward();
  };

  goBackward = () =>
    this.props.Routing.push(this.props.BuilderStore.currentRoute);

  handleSearch = ({ target }) =>
    this.props.AudienceStore.setSearch(target.value);

  handleSelectedChange = customer => () =>
    this.props.AudienceStore.toggleSelectedCustomer(customer);

  handleSegmentSelection = segment => () =>
    this.props.AudienceStore.toggleSelectedSegment(segment);

  saveDataToBuilderStore() {
    this.props.BuilderStore.setSelectedAudience(
      this.props.AudienceStore.selectedCustomers,
      this.props.AudienceStore.selectedSegments
    );
  }

  saveAndFinish = () => {
    this.saveDataToBuilderStore();
    this.goBackward();
  };

  handlePageChange = () =>
    this.props.AudienceStore.loadMoreCustomers &&
    this.props.AudienceStore.loadMore();

  render() {
    const {
      customers,
      isLoadingCustomers,
      isLoadingSegments,
      segments,
      search,
      peopleCount,
      totalPeopleCount
    } = this.props.AudienceStore;

    return (
      <div className="builder builder--style-audience">
        <div className="cnt">
          <div className="builder__back">
            <button
              onClick={this.revertChanges}
              className="button button--reset"
            >
              <BackArrowIcon />
            </button>
            <button
              disabled={peopleCount === 0 || peopleCount === '-'}
              onClick={this.saveAndFinish}
              className={`button button--style-default button--color-blue-ribbon button--size-m button--width-m ${
                peopleCount === 0 || peopleCount === '-' ? 'is-disabled' : ''
              }`}
            >
              done
            </button>
          </div>

          <div className="builder__split">
            <div className="builder__split-left">
              <div className="builder__audience">
                {peopleCount !== '-' ? (
                  <div className="builder__audience-title">
                    {peopleCount > 0 && <span>{peopleCount}</span>}{' '}
                    {peopleCount === 0
                      ? 'choose your audience'
                      : `${
                          peopleCount === 1 ? 'contact' : 'contacts'
                        } selected`}
                  </div>
                ) : (
                  <div className="builder__audience-title">Calculating...</div>
                )}
                <div className="builder__audience-description">
                  Contact segments
                </div>
                <div className="builder__audience-tags">
                  <div className="tags tags--size-m">
                    {isLoadingSegments && <div>Loading...</div>}
                    {!isLoadingSegments &&
                      [{ tag: 'All', customersCount: totalPeopleCount }]
                        .concat(segments)
                        .map((segment, key) => (
                          <div
                            key={key}
                            onClick={this.handleSegmentSelection(segment)}
                            className={`tags__item ${
                              this.props.AudienceStore.isSegmentSelected(
                                segment
                              )
                                ? 'is-active'
                                : ''
                            }`}
                          >
                            {segment.tag} ({segment.customersCount})
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="builder__split-right">
              <div className="builder__contacts">
                <div className="builder__contacts-search form form--style-account">
                  <div className="form__container">
                    <input
                      type="text"
                      className="form__input"
                      placeholder="search contacts"
                      onChange={this.handleSearch}
                      value={search}
                    />
                    <SearchIcon />
                  </div>
                </div>
                <div className="builder__contacts-list">
                  {!isLoadingCustomers && customers.length === 0 && (
                    <div>No contacts found.</div>
                  )}
                  {customers.map((customer, key) => (
                    <div key={key} className="builder__contacts-item">
                      <div
                        className="user-icon user-icon--size-m"
                        style={{
                          backgroundColor: userToColor(
                            customer.firstName,
                            customer.lastName
                          )
                        }}
                      >
                        {getNameInitals(customer.firstName, customer.lastName)}
                      </div>
                      <div className="builder__contacts-item-name">
                        {customer.firstName} {customer.lastName}
                      </div>
                      <div className="builder__contacts-item-phone">
                        {customer.phone}
                      </div>
                      <Checkbox
                        checked={this.props.AudienceStore.isCustomerSelected(
                          customer
                        )}
                        onChange={this.handleSelectedChange(customer)}
                      />
                    </div>
                  ))}
                  {isLoadingCustomers && <Loader />}
                  <Waypoint onEnter={this.handlePageChange} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject(
  'BuilderStore',
  'Routing',
  'AudienceStore'
)(observer(Audience));
