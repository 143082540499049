/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer, PropTypes } from 'mobx-react';
import { ReactComponent as CloseIcon } from 'Icons/close.svg';
import SuccessImage from 'Images/add-people/add-people-success-individual.png';

class AddCustomerSuccess extends React.Component {
  static propTypes = {
    CustomerStore: PropTypes.observableObject
  };

  componentWillUnmount() {
    this.props.CustomerStore.resetFormObj();
  }

  render() {
    const { values } = this.props.CustomerStore.customerFormObj;
    return (
      <div className="add-people add-people--style-success">
        <div className="cnt">
          <div className="add-people__wrapper">
            <div className="add-people__header">
              <Link to="/people" className="add-people__header-close">
                <CloseIcon />
              </Link>
            </div>

            <div className="add-people__success">
              <img
                className="add-people__success-icon"
                alt=""
                src={SuccessImage}
              />
              <h2 className="add-people__success-title">one but worth it</h2>
              <div className="add-people__success-text">
                You have successfully added <span>{values.firstName}</span> to
                the contacts you care.
              </div>
              <Link
                to="/library/categories"
                className="button button--style-default button--color-blue-ribbon button--size-m"
              >
                send {values.firstName} a care message
              </Link>
              &nbsp;
              <Link
                to="/library/sequences"
                className="button button--style-default button--color-blue-ribbon button--size-m"
              >
                enroll {values.firstName} in a sequence
              </Link>
              <ul className="add-people__success-links">
                <li>
                  <Link to="/people">Back to all contacts</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('CustomerStore')(observer(AddCustomerSuccess));
