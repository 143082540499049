import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { Link } from 'react-router-dom';
import { ReactComponent as FolderOpenOutline } from 'Icons/folder-open-outline.svg';
import { ReactComponent as FolderOutlineIcon } from 'Icons/folder-outline.svg';
import { MESSAGE_SENDER } from '../../../roles';

const CURATED_VIDEOS = 'curated-videos';

class CuratedVideosFolder extends Component {
  static propTypes = {
    MyLibraryStore: PropTypes.observableObject
  };

  canSendMessages = () =>
    this.props.ProfileStore.roles.indexOf(MESSAGE_SENDER) !== -1;

  render() {
    if (!this.canSendMessages()) {
      return null;
    }
    const { MyLibraryStore } = this.props;
    const { currentFolder, openedSections } = MyLibraryStore;
    const isOpened = openedSections[CURATED_VIDEOS];
    return (
      <Link
        to="/library/curated-videos"
        className={`library__sidebar-buttons-button ${
          currentFolder !== null ? 'is-active' : ''
        }`}
        title="Ready to share videos from affiliate partners"
      >
        {isOpened ? <FolderOpenOutline /> : <FolderOutlineIcon />}{' '}
        <span>Premium Partner Videos</span>
      </Link>
    );
  }
}

export default inject(
  'MyLibraryStore',
  'ProfileStore'
)(observer(CuratedVideosFolder));
