import { wrap } from 'Components/layout';
import Profile from './Profile';
import Billing from './Billing';
import Subscription from './Subscription';
import Charges from './Charges';
import { DISABLE_BILLING } from 'Utils/whitelabeling';

export default {
  items: [
    { path: '/profile', component: wrap(Profile), exact: true },
    DISABLE_BILLING
      ? null
      : { path: '/billing', component: wrap(Billing), exact: true },
    DISABLE_BILLING
      ? null
      : { path: '/subscription', component: wrap(Subscription), exact: true },
    DISABLE_BILLING
      ? null
      : { path: '/charges', component: wrap(Charges), exact: true },
  ].filter((val) => !!val),
};
