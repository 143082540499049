import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import { syncHistoryWithStore } from 'mobx-react-router';
import { StripeProvider } from 'react-stripe-elements';
import { Router } from 'react-router';
import { configure } from 'mobx';
import './styles.css';
import './fonts.css';
import './camRecorderStyles.css';
import './icons';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import stores from './Stores';
import './mobileDND';
import { DISABLE_BILLING } from 'Utils/whitelabeling';

const isDev = process.env.NODE_ENV === 'development';

let DevTools;

if (isDev) {
  DevTools = require('mobx-react-devtools').default;
}

configure({
  enforceActions: 'always',
});

const browserHistory = createBrowserHistory();

const history = syncHistoryWithStore(browserHistory, stores.Routing);
const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

ReactDOM.render(
  <Provider {...stores}>
    <Router history={history}>
      <React.Fragment>
        {DISABLE_BILLING ? (
          <App />
        ) : (
          <StripeProvider apiKey={stripeKey}>
            <App />
          </StripeProvider>
        )}
        {isDev && <DevTools />}
      </React.Fragment>
    </Router>
  </Provider>,
  document.getElementById('root')
);
//registerServiceWorker();
