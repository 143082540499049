import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'Utils/date';
import Checkbox from 'Components/Checkbox';
import { ReactComponent as VideoIcon } from 'Icons/snap-video.svg';
import { ReactComponent as TextIcon } from 'Icons/text-file.svg';
import { ReactComponent as EyeIcon } from 'Icons/eye.svg';
import { ReactComponent as PhoneIcon } from 'Icons/phone.svg';

const isCompletelySent = message => {
  if (!message) {
    return true;
  }
  const {
    deliveriesCount,
    audience: { contacts = [] }
  } = message;
  return (
    parseInt(deliveriesCount) === 0 ||
    contacts.length === 0 ||
    parseInt(deliveriesCount) >= contacts.length
  );
};

const Message = ({ onClick, message, selected, toggleMessageSelection }) => {
  return (
    <div
      className={`care__item ${message ? '' : 'care__item--style-blank'}`}
      onClick={onClick ? () => onClick(message.id) : undefined}
    >
      <div className="care__item-icon">
        {message && (message.type === 'text' ? <TextIcon /> : <VideoIcon />)}
      </div>
      <div className="care__item-content">
        <div className="care__item-title">
          {message && message.internalTitle}
          {!isCompletelySent(message) && (
            <span>
              (Sent to {message.deliveriesCount} of{' '}
              {message.audience.contacts.length} contacts.{' '}
              <Link to={`/analytic/messages/${message.id}`}>Details</Link>)
            </span>
          )}
        </div>
        <div className="care__item-intro">{message && message.text}</div>
      </div>
      {message && (
        <React.Fragment>
          <div className="care__item-date">
            {message.date ? formatDateTime(message.date, false) : null}
          </div>
          <div className="care__item-check" onClick={e => e.stopPropagation()}>
            <Checkbox
              checked={selected}
              onChange={() => toggleMessageSelection(message.id)}
            />
          </div>
          {message.type === 'video' && (
            <div className="care__item-actions">
              <ul className="actions actions--style-delimeter actions--style-count">
                <li>
                  <button title="Views">
                    <EyeIcon />
                    <span>{message.viewsCount}</span>
                  </button>
                </li>
                <li>
                  <button title="Sends">
                    <PhoneIcon />
                    <span>{message.deliveriesCount}</span>
                  </button>
                </li>
              </ul>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

Message.propTypes = {
  onClick: PropTypes.func,
  message: PropTypes.object,
  selected: PropTypes.bool,
  toggleMessageSelection: PropTypes.func
};

export default Message;
