import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';

import InputField from 'Components/InputField';

class ChangePassword extends React.Component {
  static propTypes = {
    ProfileStore: PropTypes.observableObject
  };

  attributeChangeHandler = attributeName => {
    return e => {
      if (!e) return;
      if (!e.target) {
        return this.props.ProfileStore.setAttribute(attributeName, e);
      }
      this.props.ProfileStore.setAttribute(attributeName, e.target.value);
    };
  };

  handleSubmitForm = e => {
    e.preventDefault();
    this.props.ProfileStore.updateProfile();
  };

  fields = [
    {
      label: 'verify current password',
      attributeName: 'currentPassword',
      inputType: 'password',
      placeholder: 'enter current password',
      changeHandler: this.attributeChangeHandler('currentPassword')
    },
    {
      label: 'new password',
      attributeName: 'password',
      inputType: 'password',
      placeholder: 'enter new password',
      changeHandler: this.attributeChangeHandler('password')
    },
    {
      label: 'confirm new password',
      attributeName: 'confirmedPassword',
      inputType: 'password',
      placeholder: 're-type new password',
      changeHandler: this.attributeChangeHandler('confirmedPassword')
    }
  ];

  getFields() {
    const { currentProfile } = this.props.ProfileStore;
    const passwordRecoveryMode = !!currentProfile.passwordRecovery;
    return [
      passwordRecoveryMode
        ? null
        : {
            label: 'verify current password',
            attributeName: 'currentPassword',
            inputType: 'password',
            placeholder: 'enter current password',
            changeHandler: this.attributeChangeHandler('currentPassword')
          },
      {
        label: 'new password',
        attributeName: 'password',
        inputType: 'password',
        placeholder: 'enter new password',
        changeHandler: this.attributeChangeHandler('password')
      },
      {
        label: 'confirm new password',
        attributeName: 'confirmedPassword',
        inputType: 'password',
        placeholder: 're-type new password',
        changeHandler: this.attributeChangeHandler('confirmedPassword')
      }
    ].filter(val => !!val);
  }

  render() {
    const {
      values,
      validationErrors,
      passwordMatchError
    } = this.props.ProfileStore.settingsFormData;

    return (
      <React.Fragment>
        <h3 className="settings__subtitle" id="password">
          password
        </h3>
        <form className="settings__section" onSubmit={this.handleSubmitForm}>
          {Object.keys(validationErrors).length === 0 && passwordMatchError && (
            <h3>{passwordMatchError}</h3>
          )}
          {this.getFields().map(field => (
            <InputField
              key={field.attributeName}
              {...field}
              errors={validationErrors}
              values={values}
            />
          ))}
        </form>
      </React.Fragment>
    );
  }
}

export default inject('ProfileStore')(observer(ChangePassword));
