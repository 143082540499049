import { observable, action, flow, decorate, reaction, computed } from 'mobx';
import { toggleArrayItem } from 'Utils/arrayHelper';
import { apiError, success } from 'Utils/alert';
import { MessageApi } from 'Api';
import RoutingStore from './RoutingStore';

const TAB_SENT = 'sent';
const TAB_SCHEDULED = 'scheduled';
//const TAB_ARCHIVED = 'archived';
const TAB_DRAFT = 'draft';

class MyMessagesStore {
  isLoading = false;
  messages = [];
  messagesCount = 0;
  page = 1;
  pageSize = 10;

  selectedMessages = [];
  previewMessageId = null;
  activeFilters = {};
  availableTabs = [
    {
      value: TAB_SENT,
      loaded: false
    },
    {
      value: TAB_SCHEDULED,
      loaded: false
    },
    /*{
      value: TAB_ARCHIVED,
      loaded: false
    },*/
    {
      value: TAB_DRAFT,
      loaded: false
    }
  ];

  constructor() {
    reaction(
      () => this.page,
      () => this.loadMessages()
    );
    reaction(
      () => this.activeFilters,
      () => {
        this.loadMessages();
        this.loadCounters();
      },
      {
        delay: 500
      }
    );
  }

  loadMessages = flow(function*() {
    try {
      this.isLoading = true;
      const { count, data } = yield MessageApi.get(
        null,
        Math.max(this.page - 1, 0),
        this.pageSize,
        { status: this.activeTab, type: this.activeFilters.type }
      );
      this.selectedMessages = [];
      this.messagesCount = count;
      this.messages = data;
    } catch (err) {
      apiError(err);
    }

    this.isLoading = false;
  });

  reset() {
    this.isLoading = false;
    this.messages = [];
    this.messagesCount = 0;
    this.page = 1;
    this.pageSize = 10;
    this.selectedMessages = [];
    this.previewMessageId = null;
    this.activeFilters = {};
  }

  loadCounters = () => {
    this.availableTabs = this.availableTabs.map(tab => {
      this.loadTabCounter(tab.value);
      return {
        ...tab,
        loaded: null
      };
    });
  };

  loadTabCounter = flow(function*(tab) {
    try {
      const { count } = yield MessageApi.get(null, 0, 0, {
        status: tab,
        type: this.activeFilters.type
      });
      this.availableTabs = this.availableTabs.map(availableTab => {
        return availableTab.value === tab
          ? { ...availableTab, loaded: true, count }
          : availableTab;
      });
    } catch (err) {
      apiError(err);
    }
  });

  setPage = page => {
    if (this.isLoading || this.page === page) {
      return;
    }
    this.page = page;
  };

  toggleMessageSelection = messageId => {
    this.selectedMessages = toggleArrayItem(this.selectedMessages, messageId);
  };

  toggleAllMessagesSelection = () => {
    if (this.selectedMessages.length === this.messages.length) {
      this.selectedMessages = [];
      return;
    }

    this.selectedMessages = this.messages.map(message => message.id);
  };

  editSelectedMessage = id => {
    const messageId =
      id ||
      (this.selectedMessages.length === 1 ? this.selectedMessages[0] : null);
    if (messageId) {
      RoutingStore.push(`/builder/from/message/${messageId}`);
    }
  };

  copySelectedMessages = flow(function*(messageId, openInBuilder) {
    try {
      const messageIds = messageId
        ? [messageId]
        : this.getSelectedMessages().map(({ id }) => id);
      if (openInBuilder && messageIds.length > 1) {
        return;
      }
      const { newIds } = yield MessageApi.copy(messageIds);
      if (openInBuilder) {
        return this.editSelectedMessage(newIds[0]);
      }
      success(`Successfully copied ${messageIds.length} message(s)`);
    } catch (err) {
      apiError(err);
    }

    this.loadCounters();
    yield this.loadMessages();
  });

  deleteSelectedMessages = flow(function*(messageId) {
    try {
      const messageIds = messageId
        ? [messageId]
        : this.getSelectedMessages().map(({ id }) => id);
      yield MessageApi.delete(messageIds);
      this.selectedMessages = [];
      success(`Successfully deleted ${messageIds.length} message(s)`);
    } catch (err) {
      apiError(err);
    }

    this.loadCounters();
    yield this.loadMessages();
  });

  getSelectedMessages = () => {
    return this.messages.filter(
      message => this.selectedMessages.indexOf(message.id) !== -1
    );
  };

  setActiveTab = tab => {
    if (this.activeTab === tab) {
      return;
    }
    RoutingStore.push(`/care/${tab}`);
    this.setPage(1);
    this.loadMessages();
  };

  get activeTab() {
    const pathParts = RoutingStore.location.pathname
      .split('/')
      .filter(val => !!val);
    return pathParts.length === 2 ? pathParts[1] : TAB_SENT;
  }

  get tabs() {
    const allTabsLoaded = this.availableTabs.reduce(
      (result, tab) => result && tab.loaded,
      true
    );
    return allTabsLoaded
      ? this.availableTabs.map(tab => ({
          ...tab,
          active: tab.value === this.activeTab
        }))
      : null;
  }

  get previewMessage() {
    return this.messages.find(message => message.id === this.previewMessageId);
  }

  setPreviewMessage = id => (this.previewMessageId = id);

  setFilter = (filterName, filterValue) => {
    if (!filterValue) {
      const filters = { ...this.activeFilters };
      delete filters[filterName];
      this.activeFilters = filters;
      return;
    }

    this.activeFilters = {
      ...this.activeFilters,
      ...{ [filterName]: filterValue }
    };
  };
}

const MobxMyMessagesStore = decorate(MyMessagesStore, {
  availableTabs: observable,
  activeFilters: observable,
  isLoading: observable,
  messages: observable,
  messagesCount: observable,
  page: observable,
  pageSize: observable,
  selectedMessages: observable,
  previewMessageId: observable,

  tabs: computed,
  previewMessage: computed,
  activeTab: computed,

  loadMessages: action,
  reset: action,
  loadCounters: action,
  setPage: action,
  toggleMessageSelection: action,
  toggleAllMessagesSelection: action,
  editSelectedMessage: action,
  copySelectedMessages: action,
  deleteSelectedMessages: action,
  setFilter: action,
  setActiveTab: action,
  setPreviewMessage: action
});

export default new MobxMyMessagesStore();
