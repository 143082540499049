import React, { Component } from 'react';
import { _setConfirmContainer } from './confirm';
import Confirm from '.';

export default class GlobalConfirm extends Component {
  state = {
    title: '',
    text: '',
    override: {},
    isActive: false
  };

  componentDidMount() {
    _setConfirmContainer(this);
  }

  resolver = null;

  open = ({ title, text, ...override }) =>
    new Promise(resolver => {
      this.resolver = resolver;
      this.setState({ title, text, override, isActive: true });
    });

  handleResolver = value => () => {
    if (!this.resolver) {
      return;
    }

    this.setState({ isActive: false }, () => {
      this.resolver(value);

      this.resolver = null;
    });
  };

  render() {
    const { text, title, isActive = true, override } = this.state;

    const config = {
      text,
      title,
      isActive,
      ...override
    };

    return (
      <Confirm
        onCancel={this.handleResolver(false)}
        onConfirm={this.handleResolver(true)}
        {...config}
      />
    );
  }
}
