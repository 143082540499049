import request from './request';
import { toSearchQuery } from './helpers';

export default {
  getRespondents: (offset = 0, limit = 10) =>
    request.get(
      `/merchant/respondents?${toSearchQuery({
        offset,
        limit
      })}`
    ),
  calculateNotReadMessages: () =>
    request.get('/merchant/not-read-messages-count'),
  listSms: (customerPhone = null, offset = 0, limit = 10) =>
    request.get(
      `/merchant/sms?${toSearchQuery({
        customerPhone,
        offset,
        limit
      })}`
    ),
  markSmsRead: customerId => request.post('/merchant/sms', { customerId })
};
