// @flow

import React from 'react';
import { PropTypes } from 'prop-types';
import Header from './Header';

class AppLayout extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    headerProps: PropTypes.any
  };

  render() {
    const { headerProps = {} } = this.props;

    return (
      <React.Fragment>
        <div className="header header--push" />
        <Header {...headerProps} />
        {this.props.children}
      </React.Fragment>
    );
  }
}

export const wrap = (Component, headerProps = {}) => {
  return props => (
    <AppLayout headerProps={headerProps}>
      <Component {...props} />
    </AppLayout>
  );
};
