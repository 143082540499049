export const applyDataLimit = (data, limit) => {
  const otherRecords = [...data];
  const mainRecords = otherRecords.splice(0, limit);
  const otherRecordsStat = otherRecords.reduce(
    (result, record) => {
      Object.keys(record).forEach(key => {
        const value = record[key];
        if (isNaN(value)) {
          return;
        }

        if (!result[key]) {
          result[key] = 0;
        }

        result[key] += value;
      });
      return result;
    },
    { name: 'other' }
  );

  return mainRecords.concat(
    otherRecordsStat.value > 0 ? [otherRecordsStat] : []
  );
};
