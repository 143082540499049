// @flow

import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';

import SignUpForm from './SignUpForm';
import { Redirect, withRouter } from 'react-router-dom';
import { MerchantApi } from 'Api';

class SignUp extends React.Component {
  static propTypes = {
    CommonStore: PropTypes.observableObject,
    AuthStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  loadMerchantTypes = async () => {
    const merchantTypes = await MerchantApi.listTypes();
    this.setState({ merchantTypes });
    if (merchantTypes.length > 0) {
      this.props.AuthStore.setMerchantType(merchantTypes[0].id);
    }
  };

  componentWillMount() {
    const { type: typeId } = this.props.match.params;
    if (typeId) {
      const { AuthStore, Routing } = this.props;
      AuthStore.setSelectedMerchantType(typeId);
      Routing.push('/sign-up');
      return;
    }

    this.loadMerchantTypes();
  }

  render() {
    const { merchantTypes = [] } = this.state || {};
    const { from } = this.props.Routing.location.state || {
      from: { pathname: '/' }
    };
    if (this.props.CommonStore.token) {
      return <Redirect to={from} />;
    }
    return <SignUpForm merchantTypes={merchantTypes} />;
  }
}

export default inject(
  'CommonStore',
  'AuthStore',
  'Routing'
)(withRouter(observer(SignUp)));
