/* eslint-disable no-unused-vars */

import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'Utils/date';

import Cleave from 'cleave.js/react';
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.i18n';
import './index.css';

import DatePicker from 'Components/DatePicker';

class InputField extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    attributeName: PropTypes.string,
    values: PropTypes.object,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    inputType: PropTypes.string,
    placeholder: PropTypes.string,
    changeHandler: PropTypes.func,
    inline: PropTypes.bool,
    refCallback: PropTypes.func,
    maxDate: PropTypes.object
  };

  render() {
    const {
      attributeName,
      label,
      inputType,
      placeholder,
      values,
      changeHandler,
      disabled,
      error,
      inline,
      refCallback
    } = this.props;

    if (inputType === 'date-picker') {
      return (
        <DatePicker
          onChangeDate={time => {
            changeHandler(time ? formatDate(time) : null);
          }}
          defaultValue={values[attributeName]}
          label={label}
          placeholder={placeholder}
          error={error}
          inline={inline}
          disabled={disabled}
          maxDate={this.props.maxDate}
        />
      );
    }
    return (
      <div
        key={attributeName}
        className={`form form--style-${inline ? 'inline' : 'account'}`}
      >
        {label && <label className="form__label">{label}</label>}
        <div
          className={`form__container ${error ? 'form__container__error' : ''}`}
        >
          {inputType === 'phone' ? (
            <Cleave
              placeholder="enter phone number"
              className="form__input"
              options={{ phone: true, phoneRegionCode: 'US' }}
              value={values[attributeName] || ''}
              onChange={changeHandler}
              disabled={disabled || false}
            />
          ) : (
            <input
              className="form__input"
              type={inputType}
              placeholder={placeholder}
              defaultValue={values[attributeName]}
              onChange={changeHandler}
              autoComplete="off"
              disabled={disabled || false}
              ref={refCallback}
            />
          )}
          {error && <div className="form__note">{error}</div>}
        </div>
      </div>
    );
  }
}

export default InputField;
