import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { Link } from 'react-router-dom';

import { ReactComponent as FolderIcon } from 'Icons/folder.svg';
import { ReactComponent as FolderOpenOutline } from 'Icons/folder-open-outline.svg';
import { ReactComponent as FolderOutlineIcon } from 'Icons/folder-outline.svg';
import { MESSAGE_SENDER } from '../../../roles';

const VIDEO_MESSAGES = 'video-messages';
const TEXT_MESSAGES = 'text-messages';
const VIDEOS = 'videos';

const MY_LIBRARY_SECTION = 'my-library';

class MyLibraryFolder extends Component {
  static propTypes = {
    MyLibraryStore: PropTypes.observableObject
  };

  canSendMessages = () =>
    this.props.ProfileStore.roles.indexOf(MESSAGE_SENDER) !== -1;

  render() {
    const { MyLibraryStore } = this.props;
    const { currentFolder, openedSections } = MyLibraryStore;
    const isOpened = openedSections[MY_LIBRARY_SECTION];

    return (
      <React.Fragment>
        <a
          href="#my-library"
          onClick={e => {
            e.preventDefault();
            MyLibraryStore.changeOpened(MY_LIBRARY_SECTION, !isOpened);
          }}
          className={`library__sidebar-buttons-button ${
            currentFolder !== null ? 'is-active' : ''
          }`}
        >
          <FolderIcon /> <span>my campaigns</span>
        </a>
        {isOpened && (
          <div className="library__sidebar-folders">
            <Link
              to="/library/my-library/videos"
              className={`library__sidebar-folders-folder ${
                currentFolder === VIDEOS ? 'is-active' : ''
              }`}
            >
              {currentFolder === VIDEOS ? (
                <FolderOpenOutline />
              ) : (
                <FolderOutlineIcon />
              )}
              <span>videos</span>
            </Link>
            {this.canSendMessages() && (
              <React.Fragment>
                <Link
                  to="/library/my-library/video-messages"
                  className={`library__sidebar-folders-folder ${
                    currentFolder === VIDEO_MESSAGES ? 'is-active' : ''
                  }`}
                  title="Video messages that you have sent to your contacts"
                >
                  {currentFolder === VIDEO_MESSAGES ? (
                    <FolderOpenOutline />
                  ) : (
                    <FolderOutlineIcon />
                  )}
                  <span>video campaigns</span>
                </Link>
                <Link
                  to="/library/my-library/text-messages"
                  className={`library__sidebar-folders-folder ${
                    currentFolder === TEXT_MESSAGES ? 'is-active' : ''
                  }`}
                  title="Text messages that you have sent to your contacts"
                >
                  {currentFolder === TEXT_MESSAGES ? (
                    <FolderOpenOutline />
                  ) : (
                    <FolderOutlineIcon />
                  )}
                  <span>text campaigns</span>
                </Link>
              </React.Fragment>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default inject(
  'MyLibraryStore',
  'ProfileStore'
)(observer(MyLibraryFolder));
