// @flow

import React from 'react';

import { ToastContainer } from 'react-toastify';

import ConfirmContainer from 'Components/ConfirmModal/ConfirmContainer';
import NotSupportedBrowserNotification from 'Components/NotSupportedBrowserNotification';

import { generateBrowserIdentity } from 'Utils/browserIdentity';

import 'react-toastify/dist/ReactToastify.css';

import AppRoutes from './AppRoutes';

generateBrowserIdentity();

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <AppRoutes />
        <ToastContainer autoClose={3000} hideProgressBar={true} pauseOnHover />
        <ConfirmContainer />
        <NotSupportedBrowserNotification />
      </React.Fragment>
    );
  }
}

export default App;
