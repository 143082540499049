import { wrap } from 'Components/layout';
import Analytic from '.';
import { MESSAGE_SENDER } from '../../roles';

export default {
  base: '/video-analytic',
  merge: {
    activePage: 'analytic',
    roles: [MESSAGE_SENDER]
  },
  items: [
    { path: '/videos', component: wrap(Analytic), exact: true },
    { path: '/videos/:itemId', component: wrap(Analytic) },
    { path: '/customers', component: wrap(Analytic), exact: true },
    { path: '/customers/:itemId', component: wrap(Analytic) }
  ]
};
