/** @todo figure out how to store long script texts in the url parameter */
import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import ReactPropTypes from 'prop-types';
import { MessageTemplateApi, MagicLinkApi, UploadApi, VideosApi } from 'Api';
import Recorder from 'Components/Recorder';
import Loader from 'Components/Loader/LineLoader';
import { withRouter } from 'react-router-dom';
import { apiError } from 'Utils/alert';

class VideoRecorder extends React.Component {
  state = {};
  get templateId() {
    const {
      match: {
        params: { templateId },
      },
    } = this.props;
    console.log(templateId);
    return templateId;
  }
  componentDidMount() {
    if (this.templateId) {
      this.loadTemplate(this.templateId);
    }
  }

  loadTemplate = async (id) => {
    this.setState({ hasTemplate: true });
    const { data: templates } = await MessageTemplateApi.getMessageTemplates([
      id,
    ]);
    this.setState({ template: templates[0] });
  };

  formatScript = (script) => script.replace(/\[.+?\]/g, '');

  sendMagicLink = async (phone) => {
    /*const { template } = this.state;
    const { scriptText, isUpdated } = template || {};*/

    await MagicLinkApi.sendSMS(
      phone,
      'Use this link for video recording.',
      `video/record-mobile`,
      {
        templateId: this.templateId,
        //script: isUpdated ? scriptText : null
      },
      'recorder'
    );
    this.startVideoRecordedEventTracking();
  };

  getQrCodeData = async () => {
    /*const { template } = this.state;
    const { scriptText, isUpdated } = template || {};*/

    const link = await MagicLinkApi.getLink(
      `video/record-mobile`,
      {
        templateId: this.templateId,
        //script: isUpdated ? scriptText : null
      },
      'recorder'
    );

    this.startVideoRecordedEventTracking();

    return link;
  };

  uploadVideo = async (video, selectedFrame) => {
    const { template } = this.state;
    const { scriptText } = template || {};
    try {
      const title = 'Untitled video';
      const cdnUrl = await UploadApi.uploadVideo(video);
      const { id: videoId } = await VideosApi.record(
        title,
        cdnUrl,
        {
          scriptText,
          templateId: this.templateId,
        },
        selectedFrame ? selectedFrame.id : null
      );
      this.props.mobile ? window.close() : this.redirectToEditor(videoId);
    } catch (e) {
      apiError(e);
    }
  };

  redirectToEditor = (id) =>
    setTimeout(() => this.props.Routing.push(`/video/editor/${id}`), 100);

  updateScript = (scriptText) => {
    const template = this.state.template || {};
    this.setState({
      template: {
        ...template,
        scriptText,
        isUpdated: template.scriptText !== scriptText,
      },
    });
  };

  close = () => this.props.Routing.push('/');

  startVideoRecordedEventTracking = () => {
    const currentDate = new Date().toISOString();
    let isChecking = false;

    const interval = setInterval(async () => {
      if (isChecking) {
        return;
      }

      isChecking = true;
      const {
        data: [recordedVideo],
      } = await VideosApi.getRecent(null, 0, 1, currentDate);
      isChecking = false;

      if (!recordedVideo) {
        return;
      }

      clearInterval(interval);
      this.redirectToEditor(recordedVideo.id);
    }, 2000);
  };

  render() {
    const { hasTemplate, template } = this.state;
    const { mobile } = this.props;
    if (hasTemplate && !template) {
      return <Loader />;
    }

    const { scriptText } = template || {};

    return (
      <Recorder
        videoMimeType="video/webm;codecs=vp8,opus"
        script={{
          text: scriptText || '',
          formatter: this.formatScript,
          title: '',
        }}
        onVideoReady={this.uploadVideo}
        onScriptTextChange={this.updateScript}
        onClose={this.close}
        startTimeout={10}
        autoStopTimeout={1}
        qrCodeData={mobile ? null : this.getQrCodeData}
        mobileRecordSettings={mobile ? null : { sendLink: this.sendMagicLink }}
      />
    );
  }
}

Recorder.propTypes = {
  match: ReactPropTypes.object,
  mobile: ReactPropTypes.bool,
  Routing: PropTypes.observableObject,
};

export default inject('Routing')(observer(withRouter(VideoRecorder)));
