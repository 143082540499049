import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';

import Pagination from 'Components/Pagination';
import MessageItem from '../MessageItem';
import Loader from './Loader';
import { MESSAGE_SENDER } from '../../../roles';

class MessageTemplateSearch extends Component {
  static propTypes = {
    MessageTemplateStore: PropTypes.observableObject,
    ProfileStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  onPageClick = page => this.props.MessageTemplateStore.setSearchPage(page);

  openBuilder = ({ id }) =>
    this.canSendMessages
      ? this.props.Routing.push(`/builder/from/template/${id}`)
      : this.props.Routing.push(`/video/record/${id}`);

  get canSendMessages() {
    return this.props.ProfileStore.roles.indexOf(MESSAGE_SENDER) !== -1;
  }

  render() {
    const {
      searchPage: page,
      totalFoundCount,
      allTemplates,
      isLoading
    } = this.props.MessageTemplateStore;

    const templatesForRender = allTemplates.filter(
      ({ scriptText }) => !!scriptText
    );

    return (
      <React.Fragment>
        <div className="library__messages">
          <ul className="library__messages-list">
            {isLoading && <Loader />}
            {!isLoading &&
              templatesForRender.map((message, key) => (
                <MessageItem
                  key={key}
                  message={message}
                  onClick={this.openBuilder}
                />
              ))}
            {!isLoading && allTemplates.length === 0 && (
              <li>No topics found.</li>
            )}
          </ul>
        </div>
        {totalFoundCount > 10 && (
          <Pagination
            onPageClick={this.onPageClick}
            limit={10}
            page={page}
            total={totalFoundCount}
          />
        )}
      </React.Fragment>
    );
  }
}

export default inject(
  'MessageTemplateStore',
  'Routing',
  'ProfileStore'
)(observer(MessageTemplateSearch));
