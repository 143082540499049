import { observable, action, flow, decorate, reaction } from 'mobx';
import { apiError } from 'Utils/alert';
import { SequencesApi } from 'Api';

class SequencesStore {
  isLoading = false;
  sequences = [];
  totalSequences = 0;
  currentPage = 0;
  pageSize = 10;
  searchTerm = '';

  lastQueryId = null;

  constructor() {
    reaction(
      () => this.currentPage,
      () => this.load()
    );
    reaction(
      () => this.searchTerm,
      () => this.load(),
      { delay: 1000 }
    );
  }

  load = flow(function*() {
    this.isLoading = true;
    const queryId = new Date().getTime();
    this.lastQueryId = queryId;

    try {
      const [{ data, count }, fetchedQueryId] = yield Promise.all([
        yield SequencesApi.get(
          this.currentPage,
          this.pageSize,
          this.searchTerm
        ),
        (() => queryId)()
      ]);

      if (fetchedQueryId !== this.lastQueryId) {
        return;
      }
      this.totalSequences = count;
      this.sequences = data;
    } catch (e) {
      apiError(e);
    }

    this.isLoading = false;
  });

  deleteSequences = flow(function*(sequences) {
    try {
      yield Promise.all(
        sequences.map(sequence => SequencesApi.delete(sequence.id))
      );
      this.load();
    } catch (e) {
      apiError(e);
    }
  });

  copySequences = flow(function*(sequences) {
    try {
      yield Promise.all(
        sequences.map(sequence => SequencesApi.copy(sequence.id))
      );
      this.load();
    } catch (e) {
      apiError(e);
    }
  });

  setPage = page => {
    if (page !== this.currentPage) {
      this.currentPage = page;
    }
  };

  setSearchTerm = term => {
    this.searchTerm = term;
  };
}

const MobxSequencesStore = decorate(SequencesStore, {
  isLoading: observable,
  sequences: observable,
  totalSequences: observable,
  currentPage: observable,
  pageSize: observable,
  searchTerm: observable,

  setPage: action,
  setSearchTerm: action
});

export default new MobxSequencesStore();
