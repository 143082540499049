import React from 'react';
import { PropTypes } from 'prop-types';

const PhonePreview = ({ children }) => (
  <div className="preview preview--type-phone">
    <div className="preview__top">
      <span />
    </div>
    <div className="preview__bottom" />
    <div className="preview__screen">{children}</div>
  </div>
);

PhonePreview.propTypes = {
  children: PropTypes.any
};

export default PhonePreview;
