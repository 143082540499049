import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox';
import Toolbar from './Toolbar';
import Pagination from '../Pagination';
import Loader from './Loader';

export default class ActionListView extends Component {
  static propTypes = {
    title: PropTypes.string,
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    renderItem: PropTypes.func,
    activeGroup: PropTypes.any,
    groups: PropTypes.array,
    noItemsText: PropTypes.string,
    leftColumnName: PropTypes.string,
    rightColumnName: PropTypes.string,
    itemsTotal: PropTypes.number,
    perPage: PropTypes.number,
    currentPage: PropTypes.number,
    onPageClick: PropTypes.func,
    onGroupChanged: PropTypes.func,
    actions: PropTypes.array,
    renderItemDate: PropTypes.func,
    activeFilters: PropTypes.object,
    setFilter: PropTypes.func
  };

  static defaultProps = {
    isLoading: false,
    groups: [],
    items: [],
    noItemsText: 'No items available.'
  };

  state = {
    selected: []
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.isLoading !== this.props.isLoading ||
      prevProps.items !== this.props.items
    ) {
      this.setState({ selected: [] });
    }
  }

  toggleSelectAll = () => {
    const { items } = this.props;

    if (this.allSelected) {
      this.setState({ selected: [] });
      return;
    }

    this.setState({ selected: [...items] });
  };

  toggleSelectOne(item) {
    const { selected } = this.state;
    const selectedIndex = selected.findIndex(s => s === item);

    if (selectedIndex === -1) {
      selected.push(item);
    } else {
      selected.splice(selectedIndex, 1);
    }

    this.setState({ selected });
  }

  isSelected(item) {
    return this.state.selected.includes(item);
  }

  get allSelected() {
    const { items } = this.props;
    const { selected } = this.state;

    return items.length === selected.length;
  }

  render() {
    const {
      title,
      groups,
      isLoading,
      activeGroup,
      actions,
      leftColumnName,
      rightColumnName,
      items,
      itemsTotal,
      perPage,
      onPageClick,
      renderItem,
      noItemsText,
      currentPage,
      onGroupChanged,
      renderItemDate,
      activeFilters,
      setFilter
    } = this.props;

    const { selected } = this.state;
    return (
      <React.Fragment>
        <Toolbar
          title={title}
          groups={groups}
          activeGroup={activeGroup}
          selectedCount={selected.length}
          onGroupChanged={onGroupChanged}
          actions={actions
            .filter(action => !action.onlyPerItem)
            .map(({ onClick, component }, key) => (
              <li key={key} onClick={() => onClick(selected)}>
                <button>{component}</button>
              </li>
            ))}
          activeFilters={activeFilters}
          setFilter={setFilter}
        />

        <div className="table table--style-library">
          <div className="table__wrapper">
            <table className="table__table">
              <thead className="table__head">
                <tr>
                  <th>{leftColumnName}</th>
                  <th>{items.length > 0 ? rightColumnName : ''}</th>
                  {renderItemDate && <th />}
                  <th>
                    {items.length > 0 && (
                      <Checkbox
                        checked={this.allSelected}
                        onChange={this.toggleSelectAll}
                      />
                    )}
                  </th>
                </tr>
              </thead>
              <tbody className="table__body">
                {isLoading && <Loader />}
                {!isLoading &&
                  items.map((item, key) => (
                    <tr key={key}>
                      <td>{renderItem(item)}</td>
                      <td>{item.checkboxLabel}</td>
                      {renderItemDate && (
                        <td>
                          <div className="care__item-date">
                            {renderItemDate(item)}
                          </div>
                        </td>
                      )}
                      <td>
                        <Checkbox
                          checked={this.isSelected(item)}
                          onChange={() => this.toggleSelectOne(item)}
                        />
                        <div className="table__actions">
                          <ul className="actions actions--style-delimeter">
                            {actions.map(({ onClick, component }, key) => (
                              <li key={key} onClick={() => onClick([item])}>
                                <button>{component}</button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
                {!isLoading && items.length === 0 && (
                  <tr>
                    <td colSpan="3">{noItemsText}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {itemsTotal > perPage && (
          <Pagination
            limit={perPage}
            total={itemsTotal}
            page={currentPage}
            onPageClick={onPageClick}
          />
        )}
      </React.Fragment>
    );
  }
}
