import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const OldMessage = ({ message, customerId }) => {
  const {
    title,
    landingPageText,
    landingPageImage,
    videoThumbnailUrl
  } = message;
  return (
    <div className="message__more-item">
      <div className="message__link">
        <Link to={`/message/${message.id}/${customerId}`}>
          <div className="message__link-title">{title}</div>
          <div
            className="message__link-text"
            style={{ wordBreak: 'break-word' }}
          >
            {(landingPageText || '').split('\n').map((part, i) => (
              <React.Fragment key={i}>
                {part}
                <br />
              </React.Fragment>
            ))}
          </div>
          <div className="message__link-video">
            <div className="video-link">
              <div
                className="video-link__image"
                style={{
                  backgroundImage: `url(${videoThumbnailUrl}-decorated), url(${landingPageImage})`
                }}
              />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

OldMessage.propTypes = {
  message: PropTypes.object.isRequired,
  customerId: PropTypes.string.isRequired
};

export default OldMessage;
