/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { confirmYesNo } from 'Components/ConfirmModal';
import Toolbar from 'Components/ActionList/Toolbar';
import Checkbox from 'Components/Checkbox';
import EmbedVideoModal from 'Components/EmbedVideoModal';
import VideoAnalyticModal from 'Components/VideoAnalyticModal';
import { VideosApi } from 'Api';
import { apiError } from 'Utils/alert';
import SidebarLayout from '../SidebarLayout';

import Pagination from 'Components/Pagination';

import { ReactComponent as TrashIcon } from 'Icons/Buttons/trash.svg';
import { ReactComponent as PieChartIcon } from 'Icons/pie-chart.svg';
import { ReactComponent as GetLinkIcon } from 'Icons/get-link.svg';
import { ReactComponent as AddIcon } from 'Icons/VideoIcons/add.svg';
import { ReactComponent as EditIcon } from 'Icons/pencil.svg';
import { ReactComponent as CopyIcon } from 'Icons/copy.svg';
import { formatDate } from '../../../Utils/date';

import Loader from './Loader';

import './Videos.css';

class Videos extends Component {
  state = {};
  static propTypes = {
    MyLibraryStore: PropTypes.observableObject,
    MyVideosStore: PropTypes.observableObject,
    ProfileStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  static perPage = 10;
  componentWillMount() {
    this.props.MyLibraryStore.setLibraryFolder('videos');
    this.props.MyVideosStore.reloadAll();
  }

  onSearchhandleSearch = ({ target }) =>
    this.props.MyVideosStore.search(target.value);

  handlePageChanged = page => {
    const { activeGroup, allGroup, recentGroup } = this.props.MyVideosStore;

    const nowPage = activeGroup.title === 'all' ? allGroup : recentGroup;

    if (nowPage === page) {
      return;
    }

    if (activeGroup.title === 'all') {
      this.props.MyVideosStore.setAllVideosPage(page);
    } else {
      this.props.MyVideosStore.setRecentVideosPage(page);
    }
  };

  toggleSelectVideo = video =>
    this.props.MyVideosStore.toggleSelectVideo(video);

  setGroup = group => this.props.MyVideosStore.setActiveGroup(group);

  useVideoForNewMessage(video) {
    this.props.Routing.push(`/builder/from/video/${video.id}`);
  }

  handleEdit = items => {
    this.props.Routing.push(`/video/editor/${items[0].id}`);
  };

  handleCopy = async items => {
    if (this.state.isCopying) {
      return;
    }
    this.setState({ isCopying: true });
    try {
      const response = await VideosApi.copy(items[0].id);
      // wait 2 seconds just in case, to make sure projection already processed copied video
      setTimeout(
        () => this.props.Routing.push(`/video/editor/${response.id}`),
        2000
      );
    } catch (err) {
      this.setState({ isCopying: false });
      apiError(err);
    }
  };

  get actions() {
    return [
      {
        onClick: this.handleViewReport,
        component: (
          <a title="Analytics">
            <PieChartIcon />
          </a>
        )
      },
      {
        onClick: this.handleEmbed,
        component: (
          <a title="Share">
            <GetLinkIcon />
          </a>
        )
      },
      {
        onClick: items => this.useVideoForNewMessage(items[0]),
        component: (
          <a title="Use in new message">
            <AddIcon />
          </a>
        )
      },
      {
        onClick: this.handleCopy,
        component: (
          <a title="Copy" style={this.state.isCopying ? { color: 'gray' } : {}}>
            <CopyIcon />
          </a>
        )
      },
      {
        onClick: this.handleEdit,
        component: (
          <a title="Edit">
            <EditIcon />
          </a>
        )
      },
      {
        onClick: this.handleDelete,
        component: (
          <a title="Delete">
            <TrashIcon />
          </a>
        )
      }
    ];
  }

  handleDelete = async items => {
    if (
      !(await confirmYesNo(
        'Are you sure that you want to delete these videos?'
      ))
    ) {
      return;
    }

    await this.props.MyVideosStore.deleteVideos(items);
  };

  handleViewReport = items => {
    this.closeEmbedVideoModal();
    this.setState({ reportVideoId: items[0].id });
  };

  handleEmbed = items => {
    this.closeReportModal();
    this.setState({ videoToEmbed: { ...items[0] } });
  };

  closeEmbedVideoModal = () => {
    this.setState({ videoToEmbed: null });
  };

  closeReportModal = () => {
    this.setState({ reportVideoId: null });
  };

  renderActions(passItems) {
    return this.actions.map(({ onClick, component }, key) => (
      <li key={key} onClick={() => onClick(passItems)}>
        <button>{component}</button>
      </li>
    ));
  }

  isSelected(item) {
    return this.props.MyVideosStore.selectedVideos.includes(item);
  }

  renderEmbedVideoModal = () => {
    const { videoToEmbed } = this.state || {};
    if (!videoToEmbed) {
      return null;
    }

    const { currentProfile } = this.props.ProfileStore;
    if (!currentProfile) {
      return null;
    }

    return (
      <EmbedVideoModal
        videoId={videoToEmbed.id}
        videoRecordedBy={videoToEmbed.recordedBy}
        title={videoToEmbed.title}
        embeddableUrl={videoToEmbed.embeddableUrl}
        landingPageUrl={videoToEmbed.landingPageUrl}
        src={[`${videoToEmbed.url}.mp4`, videoToEmbed.url]}
        poster={
          videoToEmbed.thumbnailUrl
            ? `${videoToEmbed.thumbnailUrl}-decorated`
            : null
        }
        overlay={videoToEmbed.overlay}
        logo={
          currentProfile.profile_image
            ? { bottom: 50, right: 50, url: currentProfile.profile_image }
            : null
        }
        frameImageSource={videoToEmbed.frameImageSource}
        onClose={this.closeEmbedVideoModal}
      />
    );
  };

  renderAnalyticModal = () => {
    const { reportVideoId } = this.state || {};
    if (!reportVideoId) {
      return null;
    }

    return (
      <VideoAnalyticModal
        videoId={reportVideoId}
        onClose={this.closeReportModal}
      />
    );
  };

  render() {
    const {
      activeGroup,
      groups,
      isLoading,
      allVideos,
      recentVideos,
      allVideosPage,
      recentVideosPage,
      selectedVideos,
      searchTerm
    } = this.props.MyVideosStore;

    const items = activeGroup.title === 'all' ? allVideos : recentVideos;

    const page = activeGroup.title === 'all' ? allVideosPage : recentVideosPage;

    return (
      <React.Fragment>
        {this.renderEmbedVideoModal()}
        {this.renderAnalyticModal()}
        <SidebarLayout
          styleType="library"
          searchText="search video"
          searchTerm={searchTerm}
          onSearch={this.onSearchhandleSearch}
        >
          <Toolbar
            title="videos"
            groups={groups}
            activeGroup={activeGroup}
            selectedCount={selectedVideos.length}
            onGroupChanged={this.setGroup}
            actions={this.renderActions(selectedVideos)}
          />
          <div className="library__videos">
            {isLoading && <Loader />}
            {!isLoading && items.length === 0 && (
              <span>No videos created.</span>
            )}
            {!isLoading &&
              items.map((item, key) => (
                <div key={key} className="library__video">
                  <div className="library__video-container">
                    <div className="video-link video-link--size-m">
                      <div className="video-link__check">
                        <Checkbox
                          checked={this.isSelected(item)}
                          onChange={() => this.toggleSelectVideo(item)}
                        />
                      </div>
                      <div
                        className="video-link__image"
                        style={{
                          backgroundImage: `url('${item.thumbnailUrl}-decorated')`
                        }}
                      />
                      <div className="video-link__title">
                        <span className="video-link__name">{item.title}</span>
                        <span className="video-link__duration">
                          {formatDate(item.recordedAt)}
                        </span>

                        <div className="video-link__actions">
                          <ul className="actions actions--style-delimeter">
                            {this.renderActions([item])}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {activeGroup.count > Videos.perPage && (
            <Pagination
              limit={Videos.perPage}
              total={activeGroup.count}
              page={page}
              onPageClick={this.handlePageChanged}
            />
          )}
        </SidebarLayout>
      </React.Fragment>
    );
  }
}

export default inject(
  'MyLibraryStore',
  'MyVideosStore',
  'ProfileStore',
  'Routing'
)(observer(Videos));
