import React from 'react';
import Select from 'react-select';
import SELECT_STYLES from './ReactSelectStyles';

const OPTIONS = [
  { label: 'Text', value: 'text' },
  { label: 'Video', value: 'video' }
];

function TypeSelector({ onChange, value = '' }) {
  return (
    <Select
      options={OPTIONS}
      onChange={onChange}
      styles={SELECT_STYLES}
      value={OPTIONS.find(option => option.value === value)}
    />
  );
}

export default TypeSelector;
