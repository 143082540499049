/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';

const Toolbar = ({ tabs, activeTab, selectTab }) => {
  return (
    <nav className="toolbar__navigation" style={{ margin: '0 20px 20px 20px' }}>
      <ul>
        {tabs.map(({ name, value }) => (
          <li key={value} className={value === activeTab ? 'is-active' : null}>
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                selectTab(value);
              }}
            >
              {name}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

Toolbar.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  selectTab: PropTypes.func.isRequired
};

export default Toolbar;
