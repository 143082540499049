import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as VideoIcon } from 'Icons/snap-video.svg';
import Button from 'Components/Button';
import Message from './Message';

const MessagesList = ({
  messages,
  selectedMessages,
  messagesCount,
  toggleMessageSelection,
  showOnboarding,
  setPreviewMessage
}) => (
  <React.Fragment>
    <div className="care">
      <div className="cnt">
        <div className="care__list">
          {messagesCount === 0 &&
            (showOnboarding ? (
              <div className="care__item care__item--style-new">
                <div className="care__item-icon">
                  <VideoIcon />
                </div>
                <div className="care__item-content">
                  <div className="care__item-title">My first video message</div>
                  <div className="care__item-intro">
                    This is my first video care message sent to my loyal
                    customers.
                  </div>
                </div>
                <div className="care__item-button-new">
                  <Button
                    link="/library/categories"
                    name="create new message"
                  />
                </div>
              </div>
            ) : (
              <p className="empty__intro">No messages</p>
            ))}

          <div className="care__items">
            {messagesCount > 0 ? (
              messages.map(message => (
                <Message
                  key={message.id}
                  {...{
                    onClick: id => setPreviewMessage(id),
                    selected: selectedMessages.indexOf(message.id) !== -1,
                    message,
                    toggleMessageSelection
                  }}
                />
              ))
            ) : showOnboarding ? (
              <React.Fragment>
                <Message />
                <Message />
                <Message />
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

MessagesList.propTypes = {
  messages: PropTypes.array.isRequired,
  selectedMessages: PropTypes.array.isRequired,
  messagesCount: PropTypes.number.isRequired,
  showOnboarding: PropTypes.bool,
  toggleMessageSelection: PropTypes.func.isRequired,
  setPreviewMessage: PropTypes.func.isRequired
};

export default MessagesList;
