import React, { Component, Fragment } from 'react';
import EnterPhoneModal from './EnterPhoneModal';
import { inject, observer, PropTypes } from 'mobx-react';
import { confirmYesNo } from 'Components/ConfirmModal';
import { Link } from 'react-router-dom';

const confirmText = `
    You have scheduled your message for sending. 
    By clicking on 'send now' you will dismiss your schedule configuration. 
    Do you want to proceed?
`;

class AudienceList extends Component {
  static propTypes = {
    AudienceStore: PropTypes.observableObject,
    BuilderStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject
  };

  goToPreviousStep = () => {
    this.props.Routing.push(this.props.BuilderStore.previousRoute);
  };

  showClosingDialog = () => this.props.Routing.push('/builder/close-editor');

  state = {
    sending: false,
    showEnterPhoneModal: false
  };

  componentWillMount() {
    this.setState({
      internalTitle: this.props.BuilderStore.getMessageAttribute(
        'internalTitle',
        ''
      ),
      sending: false,
      showEnterPhoneModal: false
    });
  }

  openTestModal = () => this.setState({ showEnterPhoneModal: true });

  performSend = phone => this.props.BuilderStore.testPhoneNumber(phone);

  hideEnterPhoneModal = () => this.setState({ showEnterPhoneModal: false });

  sendNow = async () => {
    const startingFrom = this.props.BuilderStore.getMessageAttribute(
      'startingFrom',
      null
    );

    if (startingFrom !== null && !(await confirmYesNo(confirmText))) {
      return;
    }

    await this.props.BuilderStore.sendMessageNow();
    this.props.Routing.push('/builder/sending');
  };

  renderBuilderAudience() {
    const items = [
      ...this.props.AudienceStore.selectedCustomers.map(i => ['customer', i]),
      ...this.props.AudienceStore.selectedSegments.map(i => ['segment', i])
    ];

    const top4Items = items.splice(0, 4);

    return (
      <div className="builder__audience-recipients">
        {top4Items.map(([type, item], key) => {
          if (type === 'customer') {
            return (
              <div key={key} className="builder__audience-recipients-item">
                <span>
                  {item.firstName} {item.lastName}
                </span>
                {item.phone}
              </div>
            );
          }

          if (type === 'segment') {
            return (
              <div key={key} className="builder__audience-recipients-item">
                Group: <span>{item.tag}</span>({item.customersCount})
              </div>
            );
          }

          return null;
        })}
        {items.length > 0 && (
          <div className="builder__audience-recipients-more">
            and {this.props.AudienceStore.selectedCustomers.length - 4} more
          </div>
        )}
      </div>
    );
  }

  renderAudience() {
    const { peopleCount } = this.props.AudienceStore;

    if (peopleCount === '-') {
      return <div className="builder__audience-title">Loading...</div>;
    }

    return (
      <Fragment>
        <div className="builder__audience-title">
          <i>
            <img src="/raised-hands.png" width="64" height="64" alt="" />
          </i>{' '}
          {peopleCount === 0 ? 'you’re almost there' : 'you’re all set!'}
        </div>

        {peopleCount === 0 ? (
          <div className="builder__audience-description">
            Choose your audience and continue.
          </div>
        ) : (
          <div className="builder__audience-description">
            You are sending this message to{' '}
            <span>{peopleCount} recipients</span>.
          </div>
        )}
        {peopleCount > 0 && this.renderBuilderAudience()}
      </Fragment>
    );
  }

  render() {
    const { showEnterPhoneModal, sending } = this.state;

    const { peopleCount } = this.props.AudienceStore;

    const deliveriesCount =
      this.props.BuilderStore.getMessageAttribute('deliveriesCount', 0) * 1;
    return (
      <div className="builder__audience">
        <EnterPhoneModal
          visible={showEnterPhoneModal}
          onSend={this.performSend}
          onCancel={this.hideEnterPhoneModal}
        />
        {this.renderAudience()}
        <div className="builder__audience-buttons">
          {peopleCount !== '-' && deliveriesCount === 0 && (
            <Link
              to="/builder/schedule/audience"
              className="builder__audience-buttons-full button button--style-default button--size-m button--color-blue-ribbon"
            >
              choose your audience
            </Link>
          )}
          <button
            onClick={this.openTestModal}
            className="builder__audience-buttons-full button button--style-outline button--size-m button--color-bali-hai"
          >
            {sending ? 'sending...' : 'send yourself a test message'}
          </button>

          <div className="builder__schedule-form-title form form--style-account">
            <label className="form__label">message title</label>
            <div className="form__container">
              <input
                type="text"
                className="form__input"
                placeholder="enter message title"
                onChange={e => {
                  const {
                    target: { value }
                  } = e;
                  this.setState({ internalTitle: value });
                  this.props.BuilderStore.setMessageAttribute(
                    'internalTitle',
                    value
                  );
                }}
                value={this.props.BuilderStore.getMessageAttribute(
                  'internalTitle'
                )}
              />
            </div>
          </div>
          <br />
          <div className="builder__audience-buttons-split">
            <button
              disabled={peopleCount === 0}
              onClick={() => this.props.Routing.push('/builder/schedule/setup')}
              className="builder__audience-buttons-half button button--style-default button--size-m button--color-lynch"
            >
              schedule
            </button>
            <button
              disabled={peopleCount === 0}
              onClick={this.sendNow}
              className={`builder__audience-buttons-half button button--style-default button--size-m button--color-${
                peopleCount === 0 ? 'ghost' : 'blue-ribbon'
              }`}
            >
              send now
            </button>
          </div>
        </div>
        <div className="builder__audience-links">
          <button
            onClick={this.showClosingDialog}
            className="button button--reset button--link"
          >
            Save and close
          </button>
          <span>or</span>
          <button
            onClick={this.goToPreviousStep}
            className="button button--reset button--link"
          >
            Go back to edit
          </button>
        </div>
      </div>
    );
  }
}

export default inject(
  'BuilderStore',
  'AudienceStore',
  'Routing'
)(observer(AudienceList));
