import React from 'react';
import ContentLoader from 'react-content-loader';

export default () => (
  <tr>
    <td colSpan="2">
      <ContentLoader
        height={70}
        speed={1}
        primaryColor={'#888'}
        secondaryColor={'#EEE'}
      >
        <rect x="10" y="10" rx="5" ry="5" width="60" height="40" />
        <rect x="80" y="17" rx="4" ry="4" width="150" height="8" />
        <rect x="80" y="30" rx="3" ry="3" width="125" height="8" />
      </ContentLoader>
    </td>
  </tr>
);
