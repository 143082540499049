/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from 'Components/VideoPlayer';
import Button from 'Components/Button';
import { ReactComponent as CloseIcon } from 'Icons/close.svg';
import Modal from '../Modal';
import SocialShare from './SocialShare';
import IndividualThumbnailLinks from './IndividualThumbnailLinks';
import { selectText } from './utils';
import './index.css';

const VIDEO_THUMBNAIL_DOMAIN =
  process.env.VIDEO_THUMBNAIL_DOMAIN ||
  process.env.REACT_APP_API_URL ||
  'http://localhost:8080';

class EmbedVideoModal extends React.Component {
  state = { videoProps: {}, copyIndividualThumbnailLinks: false };
  handleVideoPropsReceived = videoProps => {
    this.setState({ videoProps });
  };
  calculatePlayerStyle = () => {
    const { width: videoWidth, height: videoHeight } = this.state.videoProps;
    if (!videoHeight) {
      return {};
    }

    const maxPlayerWidth = 740;
    const maxPlayerHeight = 400;
    const playerHeight = Math.min(
      (videoHeight * maxPlayerWidth) / videoWidth,
      maxPlayerHeight
    );

    return {
      maxWidth: (videoWidth * maxPlayerHeight) / videoHeight,
      height: playerHeight,
      margin: '0 auto'
    };
  };
  generateCodeToEmbed = () =>
    `<iframe src="${this.props.embeddableUrl}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

  copyCode = () => {
    this.codeTextarea.select();
    window.document.execCommand('copy');
  };

  copyThumbnailLink = () => {
    selectText(this.thumbnailSnippetInput);
    window.document.execCommand('copy');
    window.getSelection().removeAllRanges();
    this.thumbnailLinkInput.select();
  };

  copyDirectLink = () => {
    this.directLinkInput.select();
    window.document.execCommand('copy');
  };
  render() {
    const {
      videoId,
      videoRecordedBy,
      landingPageUrl,
      title,
      src,
      frameImageSource,
      poster,
      overlay,
      onClose
    } = this.props;
    const { copyIndividualThumbnailLinks } = this.state;

    const thumbnailLink = `${VIDEO_THUMBNAIL_DOMAIN}/thumbnail/${videoId}`;

    return (
      <Modal style={{ width: 800, maxWidth: 800 }}>
        {onClose && (
          <div style={{ textAlign: 'right', marginBottom: 10 }}>
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                onClose();
              }}
              style={{ color: '#000' }}
            >
              <CloseIcon
                style={{ display: 'inline-block', height: '1em', width: '1em' }}
              />
            </a>
          </div>
        )}

        <div style={this.calculatePlayerStyle()}>
          <VideoPlayer
            recordedBy={videoRecordedBy}
            overlay={overlay}
            videoSrc={src}
            frameSource={frameImageSource}
            poster={poster}
            onVideoPropsReceived={this.handleVideoPropsReceived}
          />
        </div>

        {copyIndividualThumbnailLinks && (
          <IndividualThumbnailLinks
            videoId={videoId}
            landingPageUrl={landingPageUrl}
            onClose={() =>
              this.setState({ copyIndividualThumbnailLinks: false })
            }
          />
        )}

        {!copyIndividualThumbnailLinks && (
          <div style={{ margin: '10px 0' }}>
            {poster && (
              <>
                <p>thumbnail link</p>
                <div className="form form--style-inline">
                  <label className="form__label" />
                  <div className="form__container">
                    <div
                      ref={node => (this.thumbnailSnippetInput = node)}
                      style={{ marginLeft: -10000, height: 1 }}
                    >
                      <a href={landingPageUrl}>
                        <img
                          src={`${VIDEO_THUMBNAIL_DOMAIN}/thumbnail/${videoId}`}
                          style={{ maxWidth: 600 }}
                          loading="lazy"
                          title={thumbnailLink}
                          alt={thumbnailLink}
                        />
                      </a>
                    </div>
                    <textarea
                      ref={node => (this.thumbnailLinkInput = node)}
                      className="form__input"
                      value={thumbnailLink}
                      onChange={() => {}}
                      style={{ textAlign: 'left' }}
                    />
                  </div>
                  <div className="embed-video-button-wrapper">
                    <Button
                      name="Copy thumbnail link"
                      onClick={this.copyThumbnailLink}
                    />{' '}
                    <Button
                      name="Individual thumbnails"
                      onClick={() =>
                        this.setState({ copyIndividualThumbnailLinks: true })
                      }
                    />
                  </div>
                </div>
                <br />
              </>
            )}
            <div className="form form--style-inline">
              <p>direct link</p>
              <label className="form__label" />
              <div className="form__container">
                <input
                  ref={node => (this.directLinkInput = node)}
                  className="form__input"
                  style={{ textAlign: 'left' }}
                  value={landingPageUrl}
                  onChange={() => {}}
                />
              </div>
              <div className="embed-video-button-wrapper">
                <Button name="Copy" onClick={this.copyDirectLink} />
                {!this.props.disableDownload && (
                  <React.Fragment>
                    &nbsp;
                    <Button
                      name="Download"
                      onClick={() =>
                        window.open(`/video/${videoId}/compiled`, '_blank')
                      }
                    />
                  </React.Fragment>
                )}
              </div>
              <br />
              <SocialShare url={landingPageUrl} title={title} />
            </div>
            <br/>
            <p>code</p>
            <div className="form form--style-inline">
              <label className="form__label" />
              <div className="form__container">
                <textarea
                  ref={node => (this.codeTextarea = node)}
                  className="form__textarea"
                  value={this.generateCodeToEmbed()}
                  onChange={() => {}}
                />
              </div>
              <div className="embed-video-button-wrapper">
                <Button name="Copy embed code" onClick={this.copyCode} />
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

EmbedVideoModal.propTypes = {
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  landingPageUrl: PropTypes.string.isRequired,
  embeddableUrl: PropTypes.string.isRequired,
  src: PropTypes.array.isRequired,
  poster: PropTypes.string,
  overlay: PropTypes.object,
  onClose: PropTypes.func,
  disableDownload: PropTypes.bool
};

export default EmbedVideoModal;
