import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChatHeader from './ChatHeader';
import MessagesList from './MessagesList';
import SendMessageForm from './SendMessageForm';

class Chat extends Component {
  render() {
    const {
      messages = [],
      messagesCount,
      loadMore,
      isLoading,
      smsListScrolledDown,
      sendMessage,
      respondent
    } = this.props;
    return (
      <div className="chat__preview">
        <ChatHeader respondent={respondent} />
        <MessagesList
          messages={messages}
          messagesCount={messagesCount}
          loadMore={loadMore}
          isLoading={isLoading}
          scrolledDown={smsListScrolledDown}
        />
        {sendMessage && <SendMessageForm sendMessage={sendMessage} />}
      </div>
    );
  }
}

Chat.propTypes = {
  respondent: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  messagesCount: PropTypes.number.isRequired,
  loadMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  sendMessage: PropTypes.func,
  smsListScrolledDown: PropTypes.bool
};

export default Chat;
