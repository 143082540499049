import React from 'react';
import { PropTypes } from 'prop-types';

const BrowserPreview = ({ children }) => (
  <div className="preview preview--type-browser">
    <div className="preview__top">
      <span />
    </div>
    <div className="preview__bottom" />
    <div className="preview__screen">{children}</div>
  </div>
);

BrowserPreview.propTypes = {
  children: PropTypes.any
};

export default BrowserPreview;
