import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DISPLAY_PLAYS_ANALYTICS = !!process.env.REACT_APP_DISPLAY_PLAYS_ANALYTICS;

class CustomerStats extends React.Component {
  render() {
    const { customer: selectedCustomer } = this.props;
    console.log({ ...selectedCustomer.videos });
    return (
      <section>
        <div className="library__preview-title">
          {selectedCustomer.customer.customerName}
        </div>

        <div className="table table--style-reports-shares">
          <div className="table__wrapper" style={{ margin: 10 }}>
            <table className="table__table table-video-recipients">
              <thead className="table__head">
                <tr>
                  <th title="name">video</th>
                  <th title="views">views</th>
                  <th title="unique views">
                    unique
                    <br />
                    views
                  </th>
                  {DISPLAY_PLAYS_ANALYTICS && (
                    <>
                      <th title="plays">
                        plays
                        <br />
                        count
                      </th>
                      <th title="played seconds">
                        played
                        <br />
                        seconds
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody className="table__body">
                {selectedCustomer.videos.map(record => {
                  return (
                    <tr key={record.groupId}>
                      <td title={record.title}>
                        <Link to={`/video-analytic/videos/${record.videoId}`}>
                          {record.title}
                        </Link>
                      </td>
                      <td>{record.viewsCount}</td>
                      <td>{record.uniqueViewsCount}</td>
                      {DISPLAY_PLAYS_ANALYTICS && (
                        <>
                          <td>{record.uniquePlaysCount}</td>
                          <td>{record.playedSecondsCount}</td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    );
  }
}

CustomerStats.propTypes = {
  customer: PropTypes.object.isRequired
};

export default CustomerStats;
