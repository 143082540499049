import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { Link } from 'react-router-dom';
import ChatGPTLogo from 'Images/chatgpt-library-logo.png';
import Sell100Icon from 'Images/sell100-icon.png';
import TextMessageIcon from 'Images/text-message-icon.png';
import UploadVideoIcon from 'Images/upload-video-icon.png';
import './CategoryList.css';
import { sortCategories } from '../utils';

import CategoryLoader from './CategoryLoader';
import { MESSAGE_SENDER } from '../../../roles';
import UploadVideoButton from 'Components/UploadVideoButton';

class CategoryList extends Component {
  static propTypes = {
    ProfileStore: PropTypes.observableObject,
    CategoryStore: PropTypes.observableObject,
    Routing: PropTypes.observableObject,
  };

  componentWillMount() {
    this.props.CategoryStore.loadMessageTemplateTaxonomy();
  }

  getItemSize(currentIndex) {
    const index = this.canSendMessages ? currentIndex + 2 : currentIndex + 3;
    if (index % 2 === 0) {
      return (index & 2) === 0 ? 'double' : 'normal';
    }

    return (index & 2) === 0 ? 'normal' : 'double';
  }

  createEmptyVideo = () => this.props.Routing.push('/video/record');

  get canSendMessages() {
    const {
      ProfileStore: { roles },
    } = this.props;
    return roles.indexOf(MESSAGE_SENDER) !== -1;
  }

  get title() {
    return this.canSendMessages
      ? 'Start from scratch, ask ChatGPT for a script, or choose a topic template'
      : 'Start from scratch or choose a video script';
  }

  render() {
    const { categories, isLoading } = this.props.CategoryStore;

    return (
      <React.Fragment>
        <div className="library__categories-intro">{this.title}</div>

        <div className="library__categories-list">
          {isLoading && <CategoryLoader />}
          {!isLoading && this.canSendMessages && (
            <React.Fragment>
              <Link
                to={`/builder/from/empty/video`}
                className={`library__categories-item library__categories-item--align-left library__categories-item--size-normal`}
              >
                <div
                  className="builder__type builder__type--small"
                  style={{ marginTop: 30 }}
                >
                  <div
                    className="builder__type-icon"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        backgroundImage: `url(${Sell100Icon})`,
                        display: 'block',
                        width: 73,
                        height: 94,
                        backgroundSize: 'cover',
                        backgroundColor: 'white',
                      }}
                    />
                  </div>
                  <div className="builder__type-title">Video Message</div>
                  <div className="builder__type-description">
                    Create your own custom script.
                  </div>
                </div>
              </Link>
            </React.Fragment>
          )}
          {!isLoading && (
            <Link
              to={
                this.canSendMessages
                  ? `/builder/from/empty/video`
                  : `/video/record`
              }
              className={`library__categories-item library__categories-item--align-left library__categories-item--size-double`}
            >
              <div
                className="builder__type builder__type--small"
                style={{ marginTop: 30 }}
              >
                <div
                  className="builder__type-icon"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      backgroundImage: `url(${ChatGPTLogo})`,
                      display: 'block',
                      width: 132,
                      height: 94,
                      backgroundSize: 'cover',
                    }}
                  />
                </div>
                <div className="builder__type-title">Access ChatGPT</div>
                <div className="builder__type-description">
                  Ask ChatGPT for a video script.
                </div>
              </div>
            </Link>
          )}
          {!isLoading &&
            sortCategories(categories).map((category, index) => (
              <Link
                to={`/library/category/${encodeURIComponent(category.title)}`}
                key={index}
                className={`library__categories-item library__categories-item--align-left library__categories-item--size-${this.getItemSize(
                  index
                )}`}
                style={{ backgroundImage: `url('${category.imageUrl}')` }}
              >
                <div className="library__categories-item-title category">
                  {category.title}
                </div>
                <div className="library__categories-item-description">
                  {category.description}
                </div>
                <button
                  className="library__categories-item-button button button--style-outline button--color-white button--size-m"
                  style={{ background: 'rgb(11,89,240,0.6)' }}
                >
                  select
                </button>
              </Link>
            ))}
          <div className="library__categories-item library__categories-item--align-left library__categories-item--size-normal">
            <div
              className="builder__type builder__type--small"
              style={{ marginTop: 30 }}
            >
              <div
                className="builder__type-icon"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    backgroundImage: `url(${UploadVideoIcon})`,
                    display: 'block',
                    width: 122,
                    height: 94,
                    backgroundSize: 'cover',
                    backgroundColor: 'white',
                  }}
                />
              </div>
              <div style={{ textAlign: 'center', marginTop: 30 }}>
                <UploadVideoButton />
              </div>
            </div>
          </div>
          <Link
            to={`/builder/from/empty/text`}
            className="library__categories-item library__categories-item--align-left library__categories-item--size-double"
          >
            <div
              className="builder__type builder__type--small"
              style={{ marginTop: 30 }}
            >
              <div
                className="builder__type-icon"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    backgroundImage: `url(${TextMessageIcon})`,
                    display: 'block',
                    width: 145,
                    height: 94,
                    backgroundSize: 'cover',
                    backgroundColor: 'white',
                  }}
                />
              </div>
              <div className="builder__type-title">Text Message</div>
              <div className="builder__type-description">
                Create your custom text message from scratch.
              </div>
            </div>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

export default inject(
  'CategoryStore',
  'Routing',
  'ProfileStore'
)(observer(CategoryList));
