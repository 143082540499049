import { observable, action, flow, decorate } from 'mobx';
import { MessageApi, CustomerApi } from 'Api';
import { getTrackingReferrer } from 'Utils/url';

class LandingPageStore {
  isLoadingMessage = false;
  isLoadingHistory = false;
  customizedMessage = {};
  messagesHistory = [];

  customizeMessage = flow(function*(messageId, customerId, previewMode) {
    if (this.isLoadingMessage) {
      return;
    }
    this.isLoadingMessage = true;
    try {
      this.customerId = customerId || null;
      this.customizedMessage = yield MessageApi.customize(
        messageId,
        customerId
      );
      this.isLoadingMessage = false;

      if (!previewMode) {
        MessageApi.trackView(messageId, this.customerId, getTrackingReferrer());
      }
    } catch (error) {
      this.isLoadingMessage = false;
    }
  });

  loadMessagesHistory = flow(function*(customerId) {
    if (this.isLoadingHistory) {
      return;
    }
    this.isLoadingHistory = true;
    try {
      const response = yield CustomerApi.getMessagesHistory(customerId);
      this.messagesHistory = response.data;
      this.isLoadingHistory = false;
    } catch (error) {
      this.isLoadingHistory = false;
    }
  });

  trackShare = source => {
    MessageApi.trackShare(this.customizedMessage.id, this.customerId, source);
  };

  reportPlayedSeconds = playedSeconds => {
    MessageApi.reportPlayedSeconds(
      this.customizedMessage.id,
      this.customerId,
      playedSeconds
    );
  };
}

const MobxLandingPageStore = decorate(LandingPageStore, {
  isLoadingMessage: observable,
  isLoadingHistory: observable,
  customizedMessage: observable,
  customizeMessage: action
});

export default new MobxLandingPageStore();
