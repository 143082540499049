import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { Toolbar } from 'Components/Messages';
import Loader from './Loader';
import MessageListPage from './MessageListPage';
import MessageViewPage from './MessageViewPage';

class Messages extends Component {
  static propTypes = {
    MessagesListStore: PropTypes.observableObject,
    ProfileStore: PropTypes.observableObject
  };

  componentWillMount() {
    const { MessagesListStore } = this.props;
    MessagesListStore.reset();
    MessagesListStore.loadMessages();
    MessagesListStore.loadCounters();
  }

  selectedMessageType = () => {
    const {
      isLoading,
      messages,
      selectedMessages
    } = this.props.MessagesListStore;
    if (isLoading || selectedMessages.length !== 1) {
      return null;
    }

    const selectedMessage = messages.find(
      message => message.id === selectedMessages[0]
    );
    return selectedMessage ? selectedMessage.type : null;
  };

  render() {
    const { MessagesListStore } = this.props;
    const {
      isLoading,
      messages,
      messagesCount,
      selectedMessages,
      activeFilters,
      tabs,
      previewMessage,
      activeTab
    } = MessagesListStore;

    const resendInsteadOfCopy = activeTab === 'sent';

    if (previewMessage) {
      return <MessageViewPage {...{ resendInsteadOfCopy }} />;
    }

    return (
      <React.Fragment>
        <Toolbar
          {...{
            selectedMessageId:
              selectedMessages.length === 1 ? selectedMessages[0] : null,
            selectedMessageType: this.selectedMessageType(),
            toggleAllMessagesSelection:
              MessagesListStore.toggleAllMessagesSelection,
            selectedMessagesCount: selectedMessages.length,
            allMessagesSelected:
              messages.length > 0 &&
              messages.length === selectedMessages.length,
            setFilter: MessagesListStore.setFilter,
            setActiveTab: MessagesListStore.setActiveTab,
            availableTabs: tabs,
            onEdit: resendInsteadOfCopy
              ? null
              : () => MessagesListStore.editSelectedMessage(),
            onCopy: () =>
              MessagesListStore.copySelectedMessages(null, resendInsteadOfCopy),
            onDelete: () => MessagesListStore.deleteSelectedMessages(),
            messagesCount,
            activeFilters,
            resendInsteadOfCopy
          }}
        />
        {isLoading ? <Loader /> : <MessageListPage />}
      </React.Fragment>
    );
  }
}

export default inject('MessagesListStore', 'ProfileStore')(observer(Messages));
