import React, { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import CustomerToolbar from './CustomerToolbar';
import CustomerList from './CustomerList';
import CustomerSegments from './CustomerSegments';
import './Customer.css';
import CustomerEmpty from './CustomerEmpty';
import Pagination from 'Components/Pagination';
import ReactPropTypes from 'prop-types';
import InvalidCustomers from './InvalidCustomers';
import { TAB_INVALID_CONTACTS } from './const';

class CustomerManagement extends Component {
  static propTypes = {
    CommonStore: PropTypes.observableObject,
    CustomerStore: PropTypes.observableObject,
    match: ReactPropTypes.object
  };

  componentWillMount() {
    const { tag, importId = null } = this.props.match.params || {};
    const { CommonStore, CustomerStore } = this.props;
    const { activePage } = CommonStore;
    if (activePage === 'people') {
      CustomerStore.setCurrentTab('all');
    }
    CustomerStore.setImportId(importId);
    CustomerStore.setFilterTag(tag);
    CustomerStore.loadCustomers();
    CustomerStore.loadTags();
    CustomerStore.loadInvalidCustomers();
  }

  componentWillUnmount() {
    this.props.CustomerStore.resetSelectedCustomers();
  }

  componentWillReceiveProps(nextProps) {
    const { tag, term } = this.props.match.params || {};
    const { tag: nextTag, term: nextTerm } = nextProps.match.params || {};
    const { CustomerStore } = this.props;

    let changed = false;

    if (nextTag !== tag) {
      CustomerStore.setFilterTag(nextTag);
      CustomerStore.setCurrentTab('all');
      changed = true;
    }

    if (nextTerm !== term) {
      changed = true;
    }

    if (changed) {
      CustomerStore.loadCustomers();
    }
  }

  render() {
    const {
      currentTab,
      totalCustomers,
      limit,
      offset,
      isLoading,
      customers,
      searchTerm,
      filterTag
    } = this.props.CustomerStore;

    const anyFilteringEnabled = searchTerm || filterTag;
    if (!anyFilteringEnabled && !isLoading && customers.length === 0) {
      return <CustomerEmpty />;
    }
    return (
      <React.Fragment>
        <CustomerToolbar />
        <div className="table table--style-people">
          <div className="cnt">
            <div className="table__wrapper">
              {currentTab === 'all' && (
                <React.Fragment>
                  <CustomerList />
                  <Pagination
                    total={totalCustomers}
                    limit={limit}
                    page={offset + 1}
                    onPageClick={page =>
                      this.props.CustomerStore.setOffset(page - 1)
                    }
                  />
                </React.Fragment>
              )}
              {currentTab === 'segments' && <CustomerSegments />}
              {currentTab === TAB_INVALID_CONTACTS && <InvalidCustomers />}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default inject(
  'CommonStore',
  'CustomerStore'
)(withRouter(observer(CustomerManagement)));
