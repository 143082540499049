import { observable, action, flow, decorate, reaction } from 'mobx';
import { apiError } from 'Utils/alert';
import { MerchantApi } from 'Api';

class FavoriteMessagesStore {
  isLoading = false;
  messages = [];
  totalMessages = 0;
  currentPage = 1;
  pageSize = 10;
  type = '';
  searchTerm = '';

  lastQueryId = null;

  constructor() {
    reaction(
      () => this.currentPage,
      () => this.load(),
      { delay: 100 }
    );
    reaction(
      () => this.type,
      () => this.load(),
      { delay: 100 }
    );
    reaction(
      () => this.searchTerm,
      () => this.load(),
      { delay: 1000 }
    );
  }

  load = flow(function*() {
    this.isLoading = true;
    const queryId = new Date().getTime();
    this.lastQueryId = queryId;

    try {
      const [{ data, count }, fetchedQueryId] = yield Promise.all([
        MerchantApi.listFavouriteMessages(
          Math.max(this.currentPage - 1, 0),
          this.pageSize,
          this.type,
          this.searchTerm
        ),
        (() => queryId)()
      ]);

      if (fetchedQueryId !== this.lastQueryId) {
        return;
      }
      this.totalMessages = count;
      this.messages = data;
    } catch (e) {
      apiError(e);
    }

    this.isLoading = false;
  });

  removeFromFavorites = flow(function*(messageIds) {
    yield MerchantApi.removeMessagesFromFavorites(messageIds);
    this.load();
  });

  setPage = page => {
    if (page !== this.currentPage) {
      this.currentPage = page;
    }
  };

  setType = type => {
    this.type = type;
    this.currentPage = 1;
    this.searchTerm = '';
  };

  setSearchTerm = searchTerm => {
    this.searchTerm = searchTerm;
  };
}

const MobxFavoriteMessagesStore = decorate(FavoriteMessagesStore, {
  isLoading: observable,
  messages: observable,
  totalMessages: observable,
  currentPage: observable,
  pageSize: observable,
  type: observable,
  searchTerm: observable,

  setPage: action,
  setType: action,
  setSearchTerm: action
});

export default new MobxFavoriteMessagesStore();
